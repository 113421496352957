import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import MlCode from "./components/admin/plugins/mercadoLibre/MlCode";
import AliExpCode from "./components/admin/plugins/aliExpress/AliExpCode";
import Admin from "./layouts/Admin";
import UnderConstruction from "./layouts/UnderConstruction";
import GlobalContextProvider from "./contexts/GlobalContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/home/Login";
import Tracking from "./components/home/Tracking";
import LandingPage from "./components/home/LandingPage";
//Para que se mande la cookie al servidor
axios.defaults.withCredentials = true;

const hist = createBrowserHistory();

const renderApp = () => {
  if (process.env.REACT_APP_UNDER_CONSTRUCTION === 'yes') {
    return <UnderConstruction />;
  } else {
    return (
      <GlobalContextProvider>
        <Router history={hist}>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/LandingPage" component={LandingPage} />
            <Route path="/login" component={Login} />
            <Route path="/tracking" component={Tracking} />
            <Route path="/ml-code" component={MlCode} />
            <Route path="/aliexp-code" component={AliExpCode} />
            <Redirect from="/" to="/LandingPage" />
          </Switch>
        </Router>
      </GlobalContextProvider>
    );
  }
};

ReactDOM.render(renderApp(), document.getElementById("root"));
