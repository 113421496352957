import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../../assets/js/SessionStorageNames";
import { apiRoutes } from "../../../../../config";

const sendCasheaProd = (
 {
  enterpriseId,
  productIds, 
  merchantName,
  cuota,
  categoryId
 }
) => { 

  return axios.post(
    `${apiRoutes.cashea.sendProducts}`,
      {
        enterpriseId, 
        productIds,
        merchantName: merchantName[0],
        cuota,
        categoryId,
        productAction: 'upload',
    },
    { 
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    }
  );
};

const postOneOdooCashea = async (
  enterpriseId,
  paramsToSend,
  officialStoreName,
  Cuotas,
  Categoria
) => {
  let data = [];
    try {
      const send = await sendCasheaProd(
        {
          enterpriseId,
          productIds: paramsToSend,
          merchantName: officialStoreName,
          cuota: Cuotas,
          categoryId: Categoria
        } 
      );
      console.log("respuesta ++++++++++", send.data);
      // datagood.push(send.data.message);
      data = send.data
      return data;
    } catch (error) {
      console.error(error);
    }
};

export default postOneOdooCashea;
