import React, { useState, useContext } from "react";
import PedidosYaStyle from "../../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { BiX } from "react-icons/bi";
// import Select from '../../../../general/Select';
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import {
    sendAllProducts,
    updateDimensions,
    // updateImages,
    sendAllProductVariants,
    sendOneProduct,
    linkOdooProd,
    syncWooProductsFunction,
} from "../functions/productMethos";
import getOdooCat from "../functions/getCatodoo";
// import getAttribOdoo from "../functions/getAttributes";
import sendCatOdoo from "../functions/sendCatOdoo";
// import getAttribodoo from "../functions/getAttribOdoo";
import updateCatOdoo from "../functions/updateCatOdoo";
import sendOdooAttr from "../functions/sendOdooAttr";

// import { SyncCatWoo } from "../functions/categoriesMethos";

//
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
    currentPageCredential,
} from "../../../../../assets/js/SessionStorageNames";

export default function Productos({ showNewConfig }) {
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState("");
    // const [fileproductsToOff, setFileProductsToOff] = useState(null);
    const [fileproductsToLink, setFileProductsToLink] = useState(null);
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    // const [brandPriceAndStock, setBrandPriceAndStock] = useState("");
    // const [brandImages, setBrandImages] = useState("");
    // const [brandDescription, setBrandDescription] = useState("");
    // const [brandToSendProd, setBrandToSendProd] = useState("");
    const [categoryId, setCategoryId] = useState("");
    // const [brandVariants, setBrandVariants] = useState("");
    const [idProd, setIdProd] = useState("");
    const [websiteId, setWebsiteId] = useState("");
    // const [brandOdoo, setBrandOdoo] = useState("");

    //
    // const [wooProdMongoId, setWooProdMongoId] = useState("");
    const [wooProdBrand, setWooProdBrand] = useState("");
    const [brandsNames, setBrandsNames] = useState("");
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    };

    // function to show the notification message with a badge
    async function showMessage({
        error = false,
        message,
        autoHide = true,
        time = 13000,
    }) {
        // validacion del tipo de error error
        if (typeof error !== "boolean") {
            console.error('El parámetro "error" debe ser un booleano.');
            return false;
        }

        // Validación de mensaje
        if (typeof message !== "string") {
            console.error(
                'El parámetro "message" debe ser una cadena de texto.'
            );
            return false;
        }

        // Validación de autoHide
        if (typeof autoHide !== "boolean") {
            console.error('El parámetro "autoHide" debe ser un booleano.');
            return false;
        }

        // Validación de time
        if (isNaN(parseInt(time))) {
            console.error('El parámetro "time" debe ser un número.');
            return false;
        }

        await setColorError(error);
        await setError(message);
        await setShowBadge(true);
        autoHide &&
            !isNaN(parseInt(time)) &&
            (await setTimeout(async () => {
                await setShowBadge(false);
            }, time));
    }

    const sendAllProductsByEnt = async () => {
        // console.log(categoryId);
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true);
        if (!entId) {
            showMessage({
                error: true,
                message: `Por favor seleccione una empresa.`,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message: `Por favor seleccione una credencial para publicar.`,
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea enviar ${!wooProdBrand && !categoryId ? 'TODOS' : ''} los productos ${wooProdBrand || categoryId ? "pertenecientes a ": ''}${wooProdBrand ? wooProdBrand+" " : ""}${wooProdBrand && categoryId ? "y a " : ""}${categoryId ? "la categoría "+categoryId+" "  : ""}a ésta página web?`);

        if(sure) {
            await sendAllProducts(entId, wooProdBrand, categoryId, idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano!: ${res.data?.message}`,
                    });
                })
                .catch(async (err) => {
                    showMessage({
                        error: true,
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                    });
                });
        }
        setIsLoad(false);
    };

    const sendAllProductsVariantsByEnt = async () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true);
        if (!entId) {
            showMessage({
                error: true,
                message: `Por favor seleccione una empresa`,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message: `Por favor seleccione una credencial para publicar.`,
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea enviar todas las variantes${wooProdBrand ? " de la marca "+wooProdBrand : ""}?`);
        if (sure) {
            await sendAllProductVariants(entId, wooProdBrand, idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch(async (err) => {
                    showMessage({
                        error: true,
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                    });
                });
        }
        setIsLoad(false);
    };

    // const updateAllProductsVariantsByEnt = () => {
    //     if (isLoad) return;
    //     const entId = globalContext.currentEnterpriseId;
    //     setIsLoad(true);
    //     if (!entId) {
    //         setColorError(true);
    //         setError(`Por favor seleccione una empresa`);
    //         setShowBadge(true);
    //         setIsLoad(false);
    //         return;
    //     }
    //     updateAllProductVariants(entId, wooProdBrand)
    //         .then(() => {
    //             setColorError(false);
    //             setError("EL proceso se ejecuta en segundo plano!");
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         })
    //         .catch((err) => {
    //             setColorError(true);
    //             setError(`Algo salio mal ${err}`);
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         });
    // };

    // const updateStockAndPrice = () => {
    //     if (isLoad) return;
    //     const entId = globalContext.currentEnterpriseId;
    //     setIsLoad(true);
    //     if (!entId) {
    //         setColorError(true);
    //         setError(`Por favor seleccione una empresa`);
    //         setShowBadge(true);
    //         setIsLoad(false);
    //         return;
    //     }
    //     updatePriceAndStock(entId, wooProdBrand)
    //         .then(() => {
    //             setColorError(false);
    //             setError("EL proceso se ejecuta en segundo plano!");
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         })
    //         .catch((err) => {
    //             setColorError(true);
    //             setError(`Algo salio mal ${err}`);
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         });
    // };

    // const updateAllImages = () => {
    //     if (isLoad) return;
    //     const entId = globalContext.currentEnterpriseId;
    //     setIsLoad(true);
    //     if (!entId) {
    //         setColorError(true);
    //         setError(`Por favor seleccione una empresa`);
    //         setShowBadge(true);
    //         setIsLoad(false);
    //         return;
    //     }
    //     const idPage = localStorage.getItem(currentPageCredential);
    //     if (!idPage) {
    //         setColorError(true);
    //         setError(`Por favor seleccione una credencial para publicar.`);
    //         setShowBadge(true);
    //         setIsLoad(false);
    //         return;
    //     }
    //     updateImages(entId, wooProdBrand, idPage)
    //         .then(() => {
    //             setColorError(false);
    //             setError("EL proceso se ejecuta en segundo plano!");
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         })
    //         .catch((err) => {
    //             setColorError(true);
    //             setError(`Algo salio mal ${err}`);
    //             setShowBadge(true);
    //             setIsLoad(false);
    //         });
    // };

    const updateDimensionsAndDescription = async () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        const idPage = localStorage.getItem(currentPageCredential);
        setIsLoad(true);
        if (!entId) {
            showMessage({
                message: `Por favor seleccione una empresa.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para publicar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        const sure = window.confirm(`¿Desea actualizar la descripcion y dimensiones de todos los productos${wooProdBrand ? " de la marca "+wooProdBrand : ""}?`);
        if (sure) {
            await updateDimensions(entId, wooProdBrand, idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    const sendOneProd = async () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        const idPage = localStorage.getItem(currentPageCredential);
        setIsLoad(true);
        if (!entId) {
            showMessage({
                message: `Por favor seleccione una empresa`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para publicar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        if (idProd.length === 0) {
            showMessage({
                message: "Por favor debe colocar el id del producto",
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea enviar éste producto a ésta página web?`);
        if (sure) {
            await sendOneProduct(entId, idProd, idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    const linkProducts = () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;

        setIsLoad(true);
        if (!entId) {
            setColorError(true);
            setError(`Por favor seleccione una empresa`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        if (!fileproductsToLink) {
            setColorError(true);
            setError(`Por favor abjunte el archivo xlsx`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        const sure = window.confirm("¿Desea realizar ésta acción?")
        if (sure) {
            linkOdooProd(entId, fileproductsToLink)
                .then(() => {
                    setColorError(false);
                    setError("EL proceso se ejecuta en segundo plano!");
                    setShowBadge(true);
                    setIsLoad(false);
                })
                .catch((err) => {
                    setColorError(true);
                    setError(`Algo salio mal ${err}`);
                    setShowBadge(true);
                    setIsLoad(false);
                });
        }
    };

    const downloadOdooCat = async () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        const idPage = localStorage.getItem(currentPageCredential);

        setIsLoad(true);
        if (!entId) {
            showMessage({
                message: "Por favor seleccione una empresa",
                error: true,
            });
            setIsLoad(false);
            return;
        }

        if (!websiteId && !showNewConfig) {
            showMessage({
                message: "Por favor debe colocar el id del sitio web",
                error: true,
            });
            setIsLoad(false);
            return;
        }

        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para publicar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        
        const sure = window.confirm(`¿Desea descargar las categorías?`);
        if(sure) {
            await getOdooCat(entId, websiteId, showNewConfig && idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    console.error("Error al descargar las categorías: ",err.response.data)
                    showMessage({
                        message:
                            err.response?.data.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    const sendCatOdooWoo = () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true);
        if (!entId) {
            showMessage({
                message: `Por favor seleccione una empresa.`,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message: `Por favor seleccione una credencial para publicar.`,
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm("¿Desea enviar las nuevas categorías a Wordpress?")
        if (sure) {
            sendCatOdoo(entId, showNewConfig && idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                })
        }
        setIsLoad(false);
    };

    const updateCatOdooWoo = async () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true);
        if (!entId) {
            showMessage({
                message: `Por favor seleccione una empresa.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para actualizar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const categoriesIds = categoryId?.toString().split(",").filter((v) => !!v);
        console.log("categoryId: ",categoriesIds)

        const sure = window.confirm(`¿Desea actualizar ${categoriesIds.length > 0 ? "las categorías "+categoriesIds.join(", ") : "TODAS las categorías"} en Wordpress?; Esto actualizará también los padres.`)
        if(sure) {
            await updateCatOdoo(entId, idPage, categoriesIds)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    // const SyncCatWooOdoo = () => {
    //     if (isLoad) return;
    //     const entId = globalContext.currentEnterpriseId;
    //     setIsLoad(true);
    //     if (!entId) {
    //         showMessage({
    //             message: `Por favor seleccione una empresa.`,
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }
    //     const idPage = localStorage.getItem(currentPageCredential);
    //     if (!idPage) {
    //         showMessage({
    //             message: `Por favor seleccione una credencial para sincronizar.`,
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }
    //     SyncCatWoo(entId, idPage)
    //         .then((res) => {
    //             showMessage({
    //                 message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
    //             });
    //         })
    //         .catch((err) => {
    //             showMessage({
    //                 message:
    //                     err.response?.data?.message || `Algo salio mal ${err}`,
    //                 error: true,
    //             });
    //         })
    //         .finally(() => {
    //             setIsLoad(false);
    //         });
    // };

    // const downloadAttrib = () => {
    //     if (isLoad) return;
    //     const entId = globalContext.currentEnterpriseId;
    //     setIsLoad(true);
    //     if (!entId) {
    //         showMessage({
    //             message:
    //                 `Por favor seleccione una empresa`,
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }

    //     getAttribodoo(entId)
    //         .then((res) => {
    //             showMessage({
    //                 message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
    //             });
    //             setIsLoad(false);
    //         })
    //         .catch((err) => {
    //             showMessage({
    //                 message:
    //                     err.response?.data?.message || `Algo salio mal ${err}`,
    //                 error: true,
    //             });
    //             setIsLoad(false);
    //         });
    // };

    const sendAttrib = () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        setIsLoad(true);
        if (!entId) {
            showMessage({
                message:
                    `Por favor seleccione una empresa`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                message:
                    `Por favor seleccione una credencial para publicar`,
                error: true,
            });
            setIsLoad(false);
            return;
        }
        const sure = window.confirm("¿Desea enviar TODOS los atributos a Wordpress?")
        if (sure) {
            sendOdooAttr(entId, idPage)
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    //
    const wooUpdateOneOdooProduct = () => {
        if (isLoad) return;
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            showMessage({
                error: true,
                message: "Debe seleccionar una empresa.",
            });
            setIsLoad(false);
            return;
        }

        if (!idProd) {
            showMessage({
                error: true,
                message: "Debe ingresar el ID del producto.",
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message: "Por favor seleccione una credencial para publicar.",
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea actualizar el producto?`);
        if (sure) {
            axios
                .put(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-one-odoo-product`,
                    {
                        enterpriseId: entId,
                        ...(idPage && { idPage: idPage }),
                        id: idProd,
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    handleAxiosError(err);
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                })
        }
        setIsLoad(false);
    };

    const wooUpdateManyOdooProduct = async () => {
        if (isLoad) return;
        setIsLoad(true);

        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Por favor seleccione una empresa");
            setIsLoad(false);
            return;
        }

        // const wooProdBrand = inputs.wooProdBrand;
        if (!wooProdBrand && !categoryId) {
            showMessage({
                message: `Ingrese una marca y/o categoría para de productos.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para publicar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        // globalContext.setLoaderPage(true);
        console.log("categoryId: ",categoryId);
        const sure = window.confirm(`¿Desea enviar ${!wooProdBrand && !categoryId ? 'TODOS' : ''} los productos ${wooProdBrand || categoryId ? "pertenecientes a ": ''}${wooProdBrand ? wooProdBrand+" " : ""}${wooProdBrand && categoryId ? "y a " : ""}${categoryId ? `la categoría ${categoryId} `  : ""}a ésta página web?`);
        if(sure) {
            await axios
                .put(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-many-odoo-products`,
                    {
                        enterpriseId: entId,
                        ...(idPage && { idPage: idPage }),
                        ...(wooProdBrand && {brand: wooProdBrand}),
                        ...(categoryId && {cateId: categoryId}),
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((res) => {
                    // console.log(res.data);
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                    // globalContext.setLoaderPage(false);
                })
                .catch((err) => {
                    handleAxiosError(err);
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                    // globalContext.setLoaderPage(false);
                });
        }
        setIsLoad(false);
    };

    const syncWooAttrib = async () => {
        if (isLoad) return;
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            showMessage({
                message: `Debe seleccionar una empresa para sincronizar los productos.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                message: `Por favor seleccione una credencial para publicar.`,
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm("¿Desea sincronizar TODOS los atributos de Wordpress?")
        if (sure) {
            await axios
                .post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/sync-attributes`,
                    {
                        enterpriseId: entId,
                        ...(showNewConfig && { idPage: idPage }),
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((res) => {
                    showMessage({
                        message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                    handleAxiosError(err);
                });
        }
        setIsLoad(false);
    };

    // const wooUpdateOneOdooImage = async () => {
    //     if (isLoad) return;
    //     setIsLoad(true);
    //     const entId = globalContext.currentEnterpriseId;
    //     if (!entId) {
    //         showMessage({
    //             message: `Debe seleccionar una empresa para sincronizar los productos.`,
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }

    //     const idPage = localStorage.getItem(currentPageCredential);
    //     if (!idPage && showNewConfig) {
    //         showMessage({
    //             message: `Por favor seleccione una credencial para publicar.`,
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }

    //     if ((idProd && wooProdBrand) || (!idProd && !wooProdBrand)) {
    //         showMessage({
    //             message: "Debe ingresar el id del producto o la marca.",
    //             error: true,
    //         });
    //         setIsLoad(false);
    //         return;
    //     }

    //     await updateImages(
    //         entId,
    //         idProd ? idProd : null,
    //         idPage ? idPage : null,
    //         wooProdBrand ? wooProdBrand : null
    //     )
    //         .then((res) => {
    //             showMessage({
    //                 message: `¡EL proceso se ejecuta en segundo plano! ${res.data?.message}`,
    //             });
    //         })
    //         .catch((err) => {
    //             handleAxiosError(err);
    //             showMessage({
    //                 message:
    //                     err.response?.data?.message || `Algo salio mal ${err}`,
    //                 error: true,
    //             });
    //         });
    //     setIsLoad(false);
    // };

    const odooWooReport = () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }
        // const brandsNames = inputs.brandsNames && inputs.brandsNames.split("|").map(n => n.trim()) || [];
        if (!brandsNames) {
            globalContext.showModalWithMsg("La marca...");
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            setColorError(true);
            setError(`Por favor seleccione una credencial para publicar.`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        globalContext.setLoaderPage(true);
        const sure = window.confirm("¿Desea realizar ésta acción?")
        if(sure) {
            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/woo`,
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                        params: {
                            brands: brandsNames,
                            ...(!showNewConfig && { idPage: idPage }),
                        },
                    }
                )
                .then((resp) => {
                    // console.log(resp.data);
                    globalContext.setLoaderPage(false);
                })
                .catch((err) => {
                    handleAxiosError(err);
                    globalContext.setLoaderPage(false);
                });
        }
    };

    const odooWooCateReport = () => {
        if (isLoad) return;
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("La empresa...");
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage) {
            setColorError(true);
            setError(`Por favor seleccione una credencial para publicar.`);
            setShowBadge(true);
            setIsLoad(false);
            return;
        }

        globalContext.setLoaderPage(true);
        const sure = window.confirm("¿Desea realizar ésta acción?")
        if(sure) {
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/categories-report`,
                    // `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/${entId}/categories-report`,
                    {
                        idPage,
                        enterpriseId: entId,
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((resp) => {
                    // console.log(resp.data);
                    globalContext.setLoaderPage(false);
                })
                .catch((err) => {
                    handleAxiosError(err);
                    globalContext.setLoaderPage(false);
                });
        }
    };

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response?.data?.message) {
                globalContext.showModalWithMsg(err.response?.data?.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    async function downloadAllFromOdoo() {
        if (isLoad) return;
        setIsLoad(true);
        
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg(
                "Por favor, seleccione una empresa."
            );
            setIsLoad(false);
            return;
        }

        const brandsNames = wooProdBrand.split("|").filter((v) => !!v);
        if (
            !wooProdBrand ||
            wooProdBrand.length < 2 ||
            brandsNames.length < 1
        ) {
            globalContext.showModalWithMsg("Debe ingresar una marca válida.");
                setIsLoad(false);
                return;
        }

        const categoriesIds = categoryId.split(",").filter((v) => !!v);

        const sure = window.confirm(`¿Desea descargar ${!wooProdBrand && !categoryId ? 'TODOS' : ''} los productos ${wooProdBrand || categoryId ? "pertenecientes a ": ''}${wooProdBrand ? wooProdBrand+" " : ""}${wooProdBrand && categoryId ? "y a " : ""}${categoryId ? "la categoría "+categoryId+" "  : ""}a ésta página web?`);

        if(sure) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/sync`, {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    params: {
                        brandsNames,
                        ...(categoriesIds && { categoriesIds }),
                    },
                })
                .then((resp) => {
                    showMessage({
                        message: `El proceso se ejecutará en segundo plano: ${resp.data?.message}.`,
                    });
                    
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                })
        }
        setIsLoad(false);
    }

    async function syncWooProducts() {
        if (isLoad) return;
        setIsLoad(true);

        const enterpriseId = globalContext.currentEnterpriseId;
        if (!enterpriseId) {
            showMessage({
                error: true,
                message: "Por favor, seleccione una empresa.",
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message:
                    "Por favor seleccione una credencial para actualizar los datos",
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea sincronizar ${!wooProdBrand && !idProd ? 'TODOS' : ''} ${idProd ? 'el' : 'los'} productos ${wooProdBrand || idProd ? `perteneciente${idProd ? '' : 's'} a `: ''}${wooProdBrand ? wooProdBrand+" " : ""}${wooProdBrand && idProd ? " con el" : ""}${idProd ? "identificador "+idProd+" "  : ""}a ésta página web?`);

        if (sure) {
            await syncWooProductsFunction({
                enterpriseId,
                idPage,
                ...(wooProdBrand && { brand: wooProdBrand }),
                ...(idProd && {idProd})
            })
                .then((res) => {
                    showMessage({
                        message: `El proceso se ejecutará en segundo plano: ${res.data?.message}.`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    }

    const odooSyncBrandImg = async () => {
        if (isLoad) return;
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            showMessage({
                error: true,
                message: "Por favor, seleccione una empresa.",
            });
            setIsLoad(false);
            return;
        }
        const brands = brandsNames
            ? brandsNames.split("|").map((n) => n.trim())
            : [];
        if (!brands.length) {
            showMessage({
                error: true,
                message:
                    "Por favor ingrese una marca para sincronizar las imágenes.",
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea sincronizar las imágenes de: ${brands.join(", ")}?`)
        if (sure) {
            await axios
                .get(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${entId}/download-images-brand`,
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                        params: {
                            brandsNames: brands,
                        },
                    }
                )
                .then((res) => {
                    showMessage({
                        message: `El proceso se ejecutará en segundo plano: ${res.data?.message}.`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    const wooUpdateOneOdooImagePlus = async () => {
        if (isLoad) return;
        setIsLoad(true);
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            showMessage({
                error: true,
                message: "Por favor, seleccione una empresa.",
            });
            setIsLoad(false);
            return;
        }

        if (!idProd && !wooProdBrand) {
            showMessage({
                message: "Debe ingresar el id del producto o la marca.",
                error: true,
            });
            setIsLoad(false);
            return;
        }

        const idPage = localStorage.getItem(currentPageCredential);
        if (!idPage && showNewConfig) {
            showMessage({
                error: true,
                message:
                    "Por favor seleccione una credencial para actualizar los datos.",
            });
            setIsLoad(false);
            return;
        }

        const sure = window.confirm(`¿Desea actualizar las las imágenes de ${idProd ? idProd : wooProdBrand}?`)
        if (sure) {
            await axios
                .post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/update-odoo-images-on-woo`,
                    {
                        enterpriseId: entId,
                        ...(idProd && { idProd: idProd }),
                        ...(idPage && { idPage: idPage }),
                        ...(wooProdBrand && { brand: wooProdBrand }),
                    },
                    {
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    }
                )
                .then((res) => {
                    showMessage({
                        message: `El proceso se ejecutará en segundo plano: ${res.data?.message}.`,
                    });
                })
                .catch((err) => {
                    showMessage({
                        message:
                            err.response?.data?.message || `Algo salio mal ${err}`,
                        error: true,
                    });
                });
        }
        setIsLoad(false);
    };

    const Section = ({ children, title }) => {
        return (
            <PedidosYaStyle.Section>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size='13pt' fw='500'>
                        {title}
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>

                <PedidosYaStyle.BodyFormGrid>
                    {children}
                </PedidosYaStyle.BodyFormGrid>
            </PedidosYaStyle.Section>
        );
    };

    const LayoutField = ({ children, label, strong }) => {
        return (
            <PedidosYaStyle.ContInputAndLabel>
                <PedidosYaStyle.Text size='12pt'>
                    {strong ? <strong>{label}</strong> : label}
                </PedidosYaStyle.Text>
                <div style={{ marginBottom: 5 }}>{children}</div>
            </PedidosYaStyle.ContInputAndLabel>
        );
    };

    const LayoutFieldGrouped = ({ children, label, strong = false }) => {
        return (
            <LayoutField label={label} strong={strong}>
                <PedidosYaStyle.ContInputButtonGroup>
                    {children}
                </PedidosYaStyle.ContInputButtonGroup>
            </LayoutField>
        );
    };

    return (
        <>
            <PedidosYaStyle.ContBadgeError
                active={showBadge}
                error={colorError}
            >
                <PedidosYaStyle.Text size='.875rem' cl='#FFFFFF'>
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size='14pt' fw='500' cl='#898989'>
                        Acciones para los productos en la pagina web
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Descarga de Productos de Odoo
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>
                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Descargar Productos por Marcas y/o Categoría de Odoo a
                                        Kapi
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup>
                                    <PedidosYaStyle.Input
                                        title='La división de las marcas debe hacerse a través del caracter "|".'
                                        placeholder='Marcas (opcional)'
                                        value={wooProdBrand}
                                        onChangeCapture={(e) =>
                                            setWooProdBrand(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.Input
                                        placeholder='Categoria Id (opcional)'
                                        value={categoryId}
                                        onChangeCapture={(e) =>
                                            setCategoryId(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        onClick={() =>
                                            downloadAllFromOdoo()
                                        }
                                    >
                                        {isLoad ? "Espere" : "Descargar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                                <PedidosYaStyle.Text size='10pt' mb="5px">
                                    Descargar los precios de los productos sólo será posible si se descargan por <strong>Marca y Categoría</strong> (si desea ingresar más de una categoría, debe separarlas por una coma ",").
                                </PedidosYaStyle.Text>
                            </PedidosYaStyle.ContInputAndLabel>

                            {(showNewConfig && localStorage.getItem(currentPageCredential)==="1") && (
                                <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                    <PedidosYaStyle.Text size='12pt'>
                                        <strong>
                                            Sincronizar Productos Publicados
                                            con la nueva Configuración
                                        </strong>
                                    </PedidosYaStyle.Text>
                                    <PedidosYaStyle.ContInputButtonGroup mb="5px">
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (Opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <PedidosYaStyle.Input
                                            placeholder='Id Mongo del Producto'
                                            value={idProd}
                                            onChangeCapture={(e) =>
                                                setIdProd(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() =>
                                                syncWooProducts()
                                            }
                                        >
                                            {isLoad
                                                ? "Espere"
                                                : "Sincronizar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                    <PedidosYaStyle.Text size='10pt' mb="5px">
                                        La sincronización de productos sólo funcionará con las páginas principales <strong style={{color: "red"}}>Wilson</strong> y <strong style={{color: "red"}}>212 Global</strong>.
                                    </PedidosYaStyle.Text>
                                </PedidosYaStyle.ContInputAndLabel>
                            )}
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    {/* seccion */}
                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Categorias de Odoo
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>
                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Descargar categorias de Odoo a Kapi
                                    </strong>
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    {!showNewConfig ? (
                                        <PedidosYaStyle.ContInputButtonGroup>
                                            <PedidosYaStyle.Input
                                                placeholder='id sitio web'
                                                value={websiteId}
                                                onChange={(e) =>
                                                    setWebsiteId(e.target.value)
                                                }
                                            />
                                            <PedidosYaStyle.BtnSend
                                                disabled
                                                onClick={() =>
                                                    downloadOdooCat()
                                                }
                                            >
                                                {isLoad
                                                    ? "Espere"
                                                    : "Descargar"}
                                            </PedidosYaStyle.BtnSend>
                                        </PedidosYaStyle.ContInputButtonGroup>
                                    ) : (
                                        <PedidosYaStyle.BtnSend
                                            disabled
                                            onClick={() => downloadOdooCat()}
                                        >
                                            {isLoad ? "Espere" : "Descargar"}
                                        </PedidosYaStyle.BtnSend>
                                    )}
                                </div>
                            </PedidosYaStyle.ContInputAndLabel>

                            <LayoutField
                                strong={true}
                                label={
                                    "Enviar categorias de Kapi a Woocommerce"
                                }
                            >
                                <PedidosYaStyle.BtnSend
                                    onClick={() => sendCatOdooWoo()}
                                >
                                    {isLoad ? "Espere" : "Enviar"}
                                </PedidosYaStyle.BtnSend>
                            </LayoutField>


                            <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>Actualizar categorias de Kapi a Woocommerce</strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                                    <PedidosYaStyle.Input
                                        placeholder='ID de Odoo de la Categoría (opcional)'
                                        value={categoryId}
                                        onChangeCapture={(e) =>
                                            setCategoryId(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        onClick={() =>
                                            updateCatOdooWoo()
                                        }
                                    >
                                        {isLoad ? "Espere" : "Actualizar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                                <PedidosYaStyle.Text size='10pt' mb="5px">
                                    Si desea ingresar más de una categoría, debe separarlas por una coma ",".
                                </PedidosYaStyle.Text>
                            </PedidosYaStyle.ContInputAndLabel>
                            {/* 
                            {showNewConfig && (
                                <LayoutField
                                    strong={true}
                                    label={
                                        "Sincronizar categorias de Woocommerce a Kapi"
                                    }
                                >
                                    <PedidosYaStyle.BtnSend
                                        onClick={() => SyncCatWooOdoo()}
                                    >
                                        {isLoad ? "Espere" : "Sincronizar"}
                                    </PedidosYaStyle.BtnSend>
                                </LayoutField>
                            )} */}
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>
                    {/* seccion end */}

                    <Section title={"Atributos de Odoo"}>
                        {/* <LayoutField
                            strong={true}
                            label={"Descargar Atributos de Odoo a Kapi"}
                        >
                            <PedidosYaStyle.BtnSend
                                onClick={() => downloadAttrib()}
                            >
                                {isLoad ? "Espere" : "Descargar"}
                            </PedidosYaStyle.BtnSend>
                        </LayoutField> */}

                        <LayoutField
                            strong={true}
                            label={"Enviar Atributos de Kapi a Woocommerce"}
                        >
                            <PedidosYaStyle.BtnSend
                                onClick={() => sendAttrib()}
                            >
                                {isLoad ? "Espere" : "Enviar"}
                            </PedidosYaStyle.BtnSend>
                        </LayoutField>

                        <LayoutField
                            strong={true}
                            label={
                                "Sincronizar los Atributos de Woocommerce con Kapi"
                            }
                        >
                            <PedidosYaStyle.BtnSend
                                title='syncWooAttrib'
                                onClick={() => syncWooAttrib()}
                            >
                                {isLoad ? "Espere" : "Sincronizar"}
                            </PedidosYaStyle.BtnSend>
                        </LayoutField>
                    </Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Envio de Productos
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Enviar un Producto a Woocommerce
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom='5px'>
                                    <PedidosYaStyle.Input
                                        placeholder='Id Mongo del Producto'
                                        value={idProd}
                                        onChangeCapture={(e) =>
                                            setIdProd(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        onClick={() => sendOneProd()}
                                    >
                                        {isLoad ? "Espere" : "Enviar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>

                            <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Enviar Todos los Productos a Woocommerce
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom='5px'>
                                    <PedidosYaStyle.Input
                                        placeholder='Marca (opcional)'
                                        value={wooProdBrand}
                                        onChangeCapture={(e) =>
                                            setWooProdBrand(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.Input
                                        placeholder='Categoria Id (opcional)'
                                        value={categoryId}
                                        onChangeCapture={(e) =>
                                            setCategoryId(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        onClick={() =>
                                            sendAllProductsByEnt()
                                        }
                                    >
                                        {isLoad ? "Espere" : "Enviar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Informacion de Productos
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            {/* <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Actualizar el Stock y Precio de todos los
                                    Productos
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() =>
                                                updateStockAndPrice()
                                            }
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel> */}

                            <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Actualizar la Descripcion y Dimensiones de
                                    todos los Productos
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() =>
                                                updateDimensionsAndDescription()
                                            }
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel>

                            {/* <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Actualizar las Imagenes de todos los
                                    Productos
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() => updateAllImages()}
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel> */}
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Variantes
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Enviar todas las variantes
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() =>
                                                sendAllProductsVariantsByEnt()
                                            }
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel>

                            {/* <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Actualizar todas las variantes
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='Marca (opcional)'
                                            value={wooProdBrand}
                                            onChangeCapture={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() =>
                                                updateAllProductsVariantsByEnt()
                                            }
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel> */}
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Actualizar información de productos Woocommerce
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel  w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Actualizar un Producto de Kapi a
                                        Woocommerce
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup mb="5px">
                                    <PedidosYaStyle.Input
                                        placeholder='Id Mongo del Producto'
                                        value={idProd}
                                        onChangeCapture={(e) =>
                                            setIdProd(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        title='wooUpdateOneOdooProduct'
                                        onClick={() =>
                                            wooUpdateOneOdooProduct()
                                        }
                                    >
                                        {isLoad ? "Espere" : "Actualizar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>

                            <PedidosYaStyle.ContInputAndLabel  w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Actualizar Muchos Productos de Kapi a
                                        Woocommerce
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom='5px'>
                                    <PedidosYaStyle.Input
                                        placeholder='Marca (Opcional)'
                                        value={wooProdBrand}
                                        onChangeCapture={(e) =>
                                            setWooProdBrand(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.Input
                                        placeholder='Categoria Id (opcional)'
                                        value={categoryId}
                                        onChangeCapture={(e) =>
                                            setCategoryId(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        title='wooUpdateManyOdooProduct'
                                        onClick={() =>
                                            wooUpdateManyOdooProduct()
                                        }
                                    >
                                        {isLoad ? "Espere" : "Actualizar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>

                            <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    Nombre de Marca
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            placeholder='brand1|brand2|brand3'
                                            value={brandsNames}
                                            onChangeCapture={(e) =>
                                                setBrandsNames(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={() => odooWooReport()}
                                        >
                                            {isLoad ? "Espere" : "Enviar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel>

                            <LayoutField label={"Reporte Odoo Woo Cate"}>
                                <PedidosYaStyle.BtnSend
                                    onClick={() => odooWooCateReport()}
                                >
                                    {isLoad ? "Espere" : "Enviar"}
                                </PedidosYaStyle.BtnSend>
                            </LayoutField>
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Actualizar Imagenes de productos Woocommerce
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Sincronizar Imagenes de Kapi con Odoo
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom='5px'>
                                    <PedidosYaStyle.Input
                                        placeholder='brand1|brand2|brand3'
                                        value={brandsNames}
                                        onChangeCapture={(e) =>
                                            setBrandsNames(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        title='odooSyncBrandImg'
                                        onClick={odooSyncBrandImg}
                                    >
                                        {isLoad ? "Espere" : "Sincronizar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>

                            {/* <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Actualizar Imagenes de Kapi a
                                        Woocommerce
                                    </strong>
                                </PedidosYaStyle.Text>
                                <div style={{ marginBottom: 5 }}>
                                    <PedidosYaStyle.ContInputButtonGroup>
                                        <PedidosYaStyle.Input
                                            size='sm'
                                            type='text'
                                            placeholder='Marca (Opcional)'
                                            name='wooProdBrand'
                                            value={wooProdBrand || ""}
                                            onChange={(e) =>
                                                setWooProdBrand(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.Input
                                            placeholder='Id Mongo del Producto'
                                            value={idProd}
                                            onChangeCapture={(e) =>
                                                setIdProd(e.target.value)
                                            }
                                        />
                                        <PedidosYaStyle.BtnSend
                                            onClick={wooUpdateOneOdooImage}
                                        >
                                            {isLoad ? "Espere" : "Actualizar"}
                                        </PedidosYaStyle.BtnSend>
                                    </PedidosYaStyle.ContInputButtonGroup>
                                </div>
                            </PedidosYaStyle.ContInputAndLabel> */}

                            <PedidosYaStyle.ContInputAndLabel w='100%' colSpan="2">
                                <PedidosYaStyle.Text size='12pt'>
                                    <strong>
                                        Actualizar Imagenes de Kapi a
                                        Woocommerce (PLUS)
                                    </strong>
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.ContInputButtonGroup marginBottom='5px'>
                                    <PedidosYaStyle.Input
                                        size='sm'
                                        type='text'
                                        placeholder='Marca (Opcional)'
                                        name='wooProdBrand'
                                        value={wooProdBrand || ""}
                                        onChange={(e) =>
                                            setWooProdBrand(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.Input
                                        placeholder='Id Mongo del Producto'
                                        value={idProd}
                                        onChangeCapture={(e) =>
                                            setIdProd(e.target.value)
                                        }
                                    />
                                    <PedidosYaStyle.BtnSend
                                        title='wooUpdateOneOdooImagePlus'
                                        onClick={wooUpdateOneOdooImagePlus}
                                    >
                                        {isLoad ? "Espere" : "Actualizar"}
                                    </PedidosYaStyle.BtnSend>
                                </PedidosYaStyle.ContInputButtonGroup>
                            </PedidosYaStyle.ContInputAndLabel>
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>

                    <PedidosYaStyle.Section>
                        <PedidosYaStyle.HeaderForm>
                            <PedidosYaStyle.Text size='13pt' fw='500'>
                                Descarga y Carga de Reportes
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.HeaderForm>

                        <PedidosYaStyle.BodyFormGrid>
                            <LayoutFieldGrouped
                                label={`Excell de productos a vincular "Solo una columna (slug, id, type, visible)"`}
                            >
                                <PedidosYaStyle.InputFileGrouped>
                                    <label className='file'>
                                        <input
                                            type='file'
                                            aria-label='File browser example'
                                            onChange={(e) =>
                                                setFileProductsToLink(
                                                    e.target.files[0]
                                                )
                                            }
                                        />
                                        <span className='file-custom'></span>
                                    </label>
                                </PedidosYaStyle.InputFileGrouped>
                                {fileproductsToLink && (
                                    <PedidosYaStyle.Text
                                        size='12pt'
                                        fw='500'
                                        cl='#898989'
                                    >
                                        {fileproductsToLink.name} busca
                                    </PedidosYaStyle.Text>
                                )}
                                <PedidosYaStyle.BtnSend
                                    onClick={() => linkProducts()}
                                >
                                    {isLoad ? "Espere" : "Enviar"}
                                </PedidosYaStyle.BtnSend>
                            </LayoutFieldGrouped>
                        </PedidosYaStyle.BodyFormGrid>
                    </PedidosYaStyle.Section>
                </PedidosYaStyle.BodyForm>
            </PedidosYaStyle.ContForm>
        </>
    );
}
