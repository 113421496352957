import React, { useContext, useEffect, useState } from 'react'
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled'
import { RiGlobalLine } from "react-icons/ri"
import { AiOutlineAmazon } from 'react-icons/ai'
import { RiStore2Line } from 'react-icons/ri'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames'
export default function Two() {

    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [apiKeyGoogleTranslate, setApiKeyGoogleTranslate] = useState('');
    const [seconsScraping, setSeconsScraping] = useState('');
    const [idApp, setIdApp] = useState('');
    const [keyApp, setKeyApp] = useState('');
    const [precioMinimoPublicar, setPrecioMinimoPublicar] = useState('');
    const [precioMinimoPublicarUsd, setPrecioMinimoPublicarUsd] = useState('');
    const [urlRedireccionMl, setUrlRedireccionMl] = useState('');
    const [idAppCo, setIdAppCo] = useState('');
    const [keyAppCo, setKeyAppCo] = useState('');
    const [precioMinimoPublicarCo, setPrecioMinimoPublicarCo] = useState('');
    const [urlRedireccionMlCo, setUrlRedireccionMlCo] = useState('');
    const [numParallelProcess, setNumParallelProcess] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [inputs, setInputs] = useState({});
    const [idAppCBT, setIdAppCBT] = useState('')
    const [keyAppCBT, setKeyAppCBT] = useState('')
    const [precioMinimoPublicarCBT, setPrecioMinimoPublicarCBT] = useState('')
    const [urlRedireccionMlCBT, setUrlRedireccionMlCBT] = useState('')
    const [cronSyncEntIds, setCronSyncEntIds] = useState([])

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const setConfig = () => {
        setIsSending(true);
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api-config`, {
            amazon: {
                puppeteerTimeoutMs: seconsScraping,
            },
            global: {
                googleTranslateKey: apiKeyGoogleTranslate,
                numParallelProcess: numParallelProcess
            },
            ml: {
                co: {
                    appId: idAppCo,
                    appKey: keyAppCo,
                    minPrice: precioMinimoPublicarCo,
                    redirectUri: urlRedireccionMlCo,
                },
                ve: {
                    appId: idApp,
                    appKey: keyApp,
                    minPrice: precioMinimoPublicar,
                    minPriceUsd: precioMinimoPublicarUsd,
                    redirectUri: urlRedireccionMl,
                },
                cbt: {
                    appId: idAppCBT,
                    appKey: keyAppCBT,
                    minPrice: precioMinimoPublicarCBT,
                    redirectUri: urlRedireccionMlCBT
                }
            },
            odoo: {
                cronSyncEntIds,
            }
        }, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
        }
        ).then(resp => {
            setIsSending(false);
            globalContext.showModalWithMsg(
                `Datos agregados exitosamente!`
            );
        }).catch((err) => {
            handleAxiosError(
                err,
                "Disculpe, no se pudo uardar la informacion."
            );
            setIsSending(false);
        });
    }

    useEffect(() => {
        setIsSending(true)
        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api-config`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            cancelToken: axiosCancelTokenSource.token,
        }
        ).then(resp => {
            setApiKeyGoogleTranslate(resp.data.data.global.googleTranslateKey);
            setNumParallelProcess(resp.data.data.global.numParallelProcess);
            setSeconsScraping(resp.data.data.amazon.puppeteerTimeoutMs);
            setIdApp(resp.data.data.ml.ve.appId);
            setKeyApp(resp.data.data.ml.ve.appKey);
            setPrecioMinimoPublicar(resp.data.data.ml.ve.minPrice);
            setPrecioMinimoPublicarUsd(resp.data.data.ml.ve.minPriceUsd);
            setUrlRedireccionMl(resp.data.data.ml.ve.redirectUri);
            setIdAppCo(resp.data.data.ml.co.appId);
            setKeyAppCo(resp.data.data.ml.co.appKey);
            setPrecioMinimoPublicarCo(resp.data.data.ml.co.minPrice);
            setUrlRedireccionMlCo(resp.data.data.ml.co.redirectUri);
            setIdAppCBT(resp.data.data.ml.cbt.appId);
            setKeyAppCBT(resp.data.data.ml.cbt.appKey);
            setPrecioMinimoPublicarCBT(resp.data.data.ml.cbt.minPrice);
            setUrlRedireccionMlCBT(resp.data.data.ml.cbt.redirectUri);
            const data = resp.data.data
            setCronSyncEntIds(data.odoo && data.odoo.cronSyncEntIds || []);
            setIsSending(false)

        }).catch((err) => {
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la informacion."
            );
            setIsSending(false);
        });
    }, [])

    const updateCountries = () => {
        globalContext.setLoaderPage(true);
        setIsSending(true);
        const processCheckIntervalTime = 5000;

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-country/sync`, {}, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
        }
        ).then(resp => {
            const data = resp.data.data;

            if (data.monitor) {
                //If the process ID was received, start monitoring
                let waitingMonitorResponse = false;
                const interval = setInterval(() => {
                    if (!waitingMonitorResponse) {
                        waitingMonitorResponse = true;

                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                {
                                    cancelToken: axiosCancelTokenSource.token,
                                    headers,
                                }
                            )
                            .then((resp) => {
                                const data = resp.data.data;

                                if (data.advance >= 100) {
                                    clearInterval(interval);
                                    globalContext.showModalWithMsg(`Descarga exitosa!`);
                                    globalContext.setLoaderPage(false);
                                    setIsSending(false);
                                } else {
                                    globalContext.setLoaderPage(true, `Descargando paises ${data.data.savedCategories}/${data.data.totalCategories} (${data.advance.toFixed(2)}%). ${data.data.savedSubcategories} subcategorias guardadas`);
                                }
                            })
                            .catch((err) => {
                                handleAxiosError(
                                    err,
                                    "Disculpe, no se pudo culminar la descarga de paises."
                                );
                                clearInterval(interval);
                                globalContext.setLoaderPage(false);
                                setIsSending(false);
                            })
                            .finally(() => {
                                waitingMonitorResponse = false;
                            });
                    }
                }, processCheckIntervalTime);
            }
        }).catch((err) => {
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la informacion."
            );
            globalContext.setLoaderPage(false);
            setIsSending(false);
        });
    }

    useEffect(() => {
        getAmazonScrapConfig()
        //eslint-disable-next-line
    }, []);

    const updateInputState = (e) => {
        const name = e.target.name;
        const newState = Object.assign({}, inputs);
    
        switch (e.target.type) {
          case "checkbox":
            newState[name] = e.target.checked;
            break;
    
          default:
            newState[name] = e.target.value;
            break;
        }
    
        setInputs(newState);
      };

    const getAmazonScrapConfig = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            console.log(resp.data);
            const newState = Object.assign({}, inputs);
            newState.amazonScrapConfig = JSON.stringify(resp.data.data.config, null, 4);
            setInputs(newState);
        }).catch(err => handleAxiosError(err));
    }

    const setAmazonScrapConfig = () => {
        setIsSending(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/config`, {
            config: JSON.parse(inputs.amazonScrapConfig),
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            setIsSending(false);
            console.log(resp.data);
            globalContext.showModalWithMsg(
                `Datos agregados exitosamente!`
            );
        }).catch(err => {
            handleAxiosError(err)
            setIsSending(false);
        }
            );
    }

    return (
        <ConfigStyled.ContFormTwo>
            <ConfigStyled.ContSectionFomr isSingle={true}>
                <ConfigStyled.TagSectionForm>
                    Global {<RiGlobalLine />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Cantidad de hilos en paralelo
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        name="numParallelProcess"
                        onChange={(e) => { setNumParallelProcess(parseInt(e.target.value)) }}
                        defaultValue={numParallelProcess}
                        placeholder="Escriba aqui" 
                        />
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr isSingle={true}>
                <ConfigStyled.TagSectionForm>
                    Odoo
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Empresas para el cron de actualizacion
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        name="cronSyncEntIds"
                        onChange={(e) => {
                            const parts = e.target.value.split(",")
                            const ids = parts.length && parts.map(p => p.trim()) || []
                            setCronSyncEntIds(ids)
                        }}
                        defaultValue={cronSyncEntIds.join(",")}
                        placeholder="Escriba aqui" 
                        />
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr isSingle={true}>
                <ConfigStyled.TagSectionForm>
                    Amazon {<AiOutlineAmazon />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Limite de milisegundos para el scraper
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        type="number"
                        placeholder="Escriba aqui"
                        name="numParallelProcess"
                        onChange={(e) => { setNumParallelProcess(parseInt(e.target.value)) }}
                        defaultValue={numParallelProcess}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size="12pt">
                    Selectores de Amazon
                </ConfigStyled.text>
                <ConfigStyled.textArea 
                    hg='300px' 
                    defaultValue={inputs.amazonScrapConfig || ''}
                    onChange={updateInputState}
                />
            </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Mercado Libre VE {<RiStore2Line />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        ID de la aplicacion
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="IdApp"
                        onChange={(e) => { setIdApp(e.target.value) }}
                        defaultValue={idApp}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Key de la aplicacion
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="KeyApp"
                        onChange={(e) => { setKeyApp(e.target.value) }}
                        defaultValue={keyApp}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Precio minimo para publicar
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        type="number"
                        placeholder="Escriba aqui"
                        name="PrecioMinimoPublicar"
                        onChange={(e) => { setPrecioMinimoPublicar(e.target.value) }}
                        defaultValue={precioMinimoPublicar}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Precio minimo para publicar (USD)
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        type="number"
                        placeholder="Escriba aqui"
                        name="PrecioMinimoPublicarUsd"
                        onChange={(e) => { setPrecioMinimoPublicarUsd(e.target.value) }}
                        defaultValue={precioMinimoPublicarUsd}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Url a la que redirecciona mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="UrlRedireccionMl"
                        onChange={(e) => { setUrlRedireccionMl(e.target.value) }}
                        defaultValue={urlRedireccionMl}
                    />
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Mercado Libre CO {<RiStore2Line />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        ID de la aplicacion
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="IdAppCo"
                        onChange={(e) => { setIdAppCo(e.target.value) }}
                        defaultValue={idAppCo}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Key de la aplicacion
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="KeyAppCo"
                        onChange={(e) => { setKeyAppCo(e.target.value) }}
                        defaultValue={keyAppCo}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Precio minimo para publicar
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        type="number"
                        placeholder="Escriba aqui"
                        name="PrecioMinimoPublicarCo"
                        onChange={(e) => { setPrecioMinimoPublicarCo(e.target.value) }}
                        defaultValue={precioMinimoPublicarCo}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Url a la que redirecciona mercadolibre
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="UrlRedireccionMlCo"
                        onChange={(e) => { setUrlRedireccionMlCo(e.target.value) }}
                        defaultValue={urlRedireccionMlCo}
                    />
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ContSectionFomr>
                <ConfigStyled.TagSectionForm>
                    Mercado Libre CBT {<RiStore2Line />}
                </ConfigStyled.TagSectionForm>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        ID de la aplicacion CBT
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="IdAppCBT"
                        onChange={(e) => { setIdAppCBT(e.target.value) }}
                        defaultValue={idAppCBT}
                    />
                </ConfigStyled.ContInputAndLabel>
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Key de la aplicacion CBT
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="KeyAppCBT"
                        onChange={(e) => { setKeyAppCBT(e.target.value) }}
                        defaultValue={keyAppCBT}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Precio minimo para publicar CBT
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        type="number"
                        placeholder="Escriba aqui"
                        name="PrecioMinimoPublicarCBT"
                        onChange={(e) => { setPrecioMinimoPublicarCBT(e.target.value) }}
                        defaultValue={precioMinimoPublicarCBT}
                    />
                </ConfigStyled.ContInputAndLabel>

                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size="12pt">
                        Url a la que redirecciona mercadolibre CBT
                    </ConfigStyled.text>
                    <ConfigStyled.input
                        readOnly={isSending}
                        placeholder="Escriba aqui"
                        name="UrlRedireccionMlCBT"
                        onChange={(e) => { setUrlRedireccionMlCBT(e.target.value) }}
                        defaultValue={urlRedireccionMlCBT}
                    />
                </ConfigStyled.ContInputAndLabel>
            </ConfigStyled.ContSectionFomr>

            <ConfigStyled.ARow jc="space-between">
                <ConfigStyled.btnSend disabled={isSending} onClick={() => updateCountries()}>
                    Actualizar Paises
                </ConfigStyled.btnSend>
                <ConfigStyled.btnSend mr="10px" disabled={isSending} onClick={() => setAmazonScrapConfig()}>
                    Guardar Configuracion de Amazon
                </ConfigStyled.btnSend>
                <ConfigStyled.btnSend disabled={isSending} onClick={() => { setConfig() }}>
                    Guardar
                </ConfigStyled.btnSend>
            </ConfigStyled.ARow>
        </ConfigStyled.ContFormTwo>
    )
}
