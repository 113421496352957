
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../../assets/js/SessionStorageNames';

const getDataOdoo = (
    entId,
    currentPage,
    pageItemCount,
    filter,
    filterLinkedTo,
    filterPrice,
    filterStock,
    filterWithCompany,
    filterWithTaxes,
    axiosCancelTokenSource,
    currentCategory
) => {
    const res = axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}`, {
        params: {
            pageNumber: currentPage,
            pageItemCount: pageItemCount,
            filter: {
                title: filter,
                barCode: filter,
                variBarCode: filter,
                sku: filter,
                variSku: filter,
                filterLinkedTo,
                skipVariants: true,
                filterPrice,
                filterStock,
                filterWithCompany,
                filterWithTaxes,
                categoryId: currentCategory.odooId
            },
            checkIsFullyLinked: true,
            includeVariantData: true,
        },
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export default getDataOdoo;