import React, { useState, useContext, useEffect } from "react";
import ListVariantStyle from "../../../../../assets/css/components/admin/products/ListVariants.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgTest from "../../../../../assets/img/jpeg/img_test.jpg";
import axios from "axios";
import { getVariantDescription } from "../../../../../assets/js/productHelper";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import ListFiltersStyles from "../../../../../assets/css/components/admin/filters/ListFiltersCss";
import { Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";

export default function ListVariants(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [loading, setLoading] = useState(true);

    const loader = () => {
        switch (props.variants.length > 0) {
            case true:
                setLoading(false)
                break;
            case false:
                setLoading(true)
                break;
            default:
                break;
        }
    }    
    useEffect(() => {
        loader();
    },[props])
    
    /**
     * Update the product data
     */
    const updateProductToLink = (id) => {
        const headerParams = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        switch (props.plugin) {
            case "odoo":
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${props.entId}/${id}`,
                        {
                            cancelToken: axiosCancelTokenSource.token,
                            headers: headerParams,
                            params: {
                                linked: true,
                            },
                        }
                    )
                    .then((resp) => {
                        const data = resp.data.data;

                        const linked = data.linked;
                        //Set the linked variants description
                        if (linked.length > 0) {
                            for (const l of linked) {
                                l.saved = true;
                                if (l.product.isVariant) {
                                    l.product.variantDesc =
                                        getVariantDescription(
                                            l.product,
                                            l.product.plugin
                                        );
                                }
                            }
                        }

                        props.setProductData({
                            productToLinkId: data._id,
                            title: data.title,
                            price: data.price,
                            stock: data.stock,
                            thumbnail:
                                data.thumbnail &&
                                typeof data.thumbnail == "object" &&
                                data.thumbnail.url
                                    ? data.thumbnail.url
                                    : data.thumbnail &&
                                      typeof data.thumbnail == "string"
                                    ? `data:image/png;base64,${data.thumbnail}`
                                    : false,
                            isVariant: data.isVariant,
                            hasVariants: data.hasVariants,
                            linked,
                            barCode: data.attrib.barCode,
                        });

                        //Set the variation data
                        if (data.isVariant) {
                            props.setVariantDesc(
                                getVariantDescription(data, "odoo")
                            );
                        }
                    })
                    .catch((err) => {
                        if (axios.isCancel(err)) return; //Don't show axios cancel error

                        globalContext.showModalWithMsg(
                            "Disculpe, hubo un error al intentar cargar los datos del producto."
                        );
                        console.error(err, err.response);
                    });
                break; //End odoo

            case "ml":
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${props.entId}/${id}`,
                        {
                            cancelToken: axiosCancelTokenSource.token,
                            headers: headerParams,
                            params: {
                                linked: true,
                            },
                        }
                    )
                    .then((resp) => {
                        const data = resp.data.data;

                        const linked = data.linked;
                        //Set the linked variants description
                        if (linked.length > 0) {
                            for (const l of linked) {
                                l.saved = true;
                                if (l.product.isVariant) {
                                    l.product.variantDesc =
                                        getVariantDescription(
                                            l.product,
                                            l.product.plugin
                                        );
                                }
                            }
                        }

                        props.setProductData({
                            productToLinkId: data._id,
                            title: data.title,
                            price: data.price,
                            stock: data.stock,
                            thumbnail: data.thumbnail,
                            isVariant: data.isVariant,
                            hasVariants: data.hasVariants,
                            linked,
                            barCode: data.attrib.barCode,
                            officialStoreId: data.attrib.official_store_id,
                        });

                        //Set the variant data (if the product is a variant)
                        if (data.isVariant) {
                            const desc = getVariantDescription(data, "ml");
                            props.setVariantDesc(desc);
                        }
                    })
                    .catch((err) => {
                        if (axios.isCancel(err)) return; //Don't show axios cancel error

                        globalContext.showModalWithMsg(
                            "Disculpe, hubo un error al intentar cargar los datos del producto."
                        );
                        console.error(err, err.response);
                    });
                break; //End ml
            /**
             * * linio
             */
            case "ln":
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/linio/${props.entId}/${id}`,
                        {
                            cancelToken: axiosCancelTokenSource.token,
                            headers: headerParams,
                            params: {
                                linked: true,
                            },
                        }
                    )
                    .then((resp) => {
                        const data = resp.data.data;

                        const linked = data.linked;
                        //Set the linked variants description
                        if (linked.length > 0) {
                            for (const l of linked) {
                                l.saved = true;
                                if (l.product.isVariant) {
                                    l.product.variantDesc =
                                        getVariantDescription(
                                            l.product,
                                            l.product.plugin
                                        );
                                }
                            }
                        }

                        props.setProductData({
                            productToLinkId: data._id,
                            title: data.title,
                            price: data.price,
                            stock: data.stock,
                            thumbnail: data.thumbnail,
                            isVariant: data.isVariant,
                            hasVariants: data.hasVariants,
                            linked,
                            barCode: data.attrib.sellerSku,
                            //officialStoreId: data.attrib.official_store_id,
                        });

                        //Set the variant data (if the product is a variant)
                        if (data.isVariant) {
                            const desc = getVariantDescription(data, "ln");
                            props.setVariantDesc(desc);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (axios.isCancel(err)) return; //Don't show axios cancel error

                        globalContext.showModalWithMsg(
                            "Disculpe, hubo un error al intentar cargar los datos del producto."
                        );
                        console.error(err, err.response);
                    });
                break; //End linio
            case "df":
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-product/${props.entId}/${id}`,
                        {
                            cancelToken: axiosCancelTokenSource.token,
                            headers: headerParams,
                            params: {
                                linked: true,
                            },
                        }
                    )
                    .then((resp) => {
                        const data = resp.data.data;

                        const linked = data.linked;
                        //Set the linked variants description
                        if (linked.length > 0) {
                            for (const l of linked) {
                                l.saved = true;
                                if (l.product.isVariant) {
                                    l.product.variantDesc =
                                        getVariantDescription(
                                            l.product,
                                            l.product.plugin
                                        );
                                }
                            }
                        }

                        props.setProductData({
                            productToLinkId: data._id,
                            title: data.title,
                            price: data.price,
                            stock: data.stock,
                            thumbnail: data.thumbnail,
                            isVariant: data.isVariant,
                            hasVariants: data.hasVariants,
                            linked,
                            barCode: data.attrib.sellerSku,
                            //officialStoreId: data.attrib.official_store_id,
                        });

                        //Set the variant data (if the product is a variant)
                        if (data.isVariant) {
                            const desc = getVariantDescription(data, "df");
                            props.setVariantDesc(desc);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (axios.isCancel(err)) return; //Don't show axios cancel error

                        globalContext.showModalWithMsg(
                            "Disculpe, hubo un error al intentar cargar los datos del producto."
                        );
                        console.error(err, err.response);
                    });
                break; //End dafiti
            case "fl":
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/fala-product/${props.entId}/${id}`,
                        {
                            cancelToken: axiosCancelTokenSource.token,
                            headers: headerParams,
                            params: {
                                linked: true,
                            },
                        }
                    )
                    .then((resp) => {
                        const data = resp.data.data;
                        console.log("data: ", data)

                        const linked = data.linked;
                        //Set the linked variants description
                        if (linked.length > 0) {
                            for (const l of linked) {
                                l.saved = true;
                                if (l.product.isVariant) {
                                    l.product.variantDesc =
                                        getVariantDescription(
                                            l.product,
                                            l.product.plugin
                                        );
                                }
                            }
                        }

                        props.setProductData({
                            productToLinkId: data._id,
                            title: data.title,
                            price: data.price,
                            stock: data.stock,
                            thumbnail: data.thumbnail,
                            isVariant: data.isVariant,
                            hasVariants: data.hasVariants,
                            linked,
                            barCode: data.attrib.sellerSku,
                            //officialStoreId: data.attrib.official_store_id,
                        });

                        //Set the variant data (if the product is a variant)
                        if (data.isVariant) {
                            const desc = getVariantDescription(data, "fl");
                            props.setVariantDesc(desc);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (axios.isCancel(err)) return; //Don't show axios cancel error

                        globalContext.showModalWithMsg(
                            "Disculpe, hubo un error al intentar cargar los datos del producto."
                        );
                        console.error(err, err.response);
                    });
                break; //End falabella
                default:
                  break;
        }
    };
    return (
        <ListVariantStyle height={props.height || "300px"}>
            <div className={`list-variant ${loading || props.isVariant ? 'd-flex align-items-center justify-content-center' : ''}`}>
            {loading || props.baseProduct ? 
                <Col className="">
                    {props.baseProduct ? 
                        <div className="text-center">
                            <p className="text-sp">
                                El producto es una variante
                            </p>
                            <a href={`/admin/productos/detail-products/${props.pluginName}/${props.baseProduct}`} style={{margin: '0px auto'}} className="btn btn-primary">
                                    Ir al producto padre
                                    {/* <FontAwesomeIcon icon={Arrow} /> */}
                            </a>
                        </div>
                    :
                        <ListFiltersStyles.contLoader>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </ListFiltersStyles.contLoader>
                    }
                </Col>
            :
                <Container>
                    <Row>
                        {props.variants.map((p) => {
                            const thumbnail =
                                p.thumbnail &&
                                typeof p.thumbnail == "object" &&
                                p.thumbnail.url
                                    ? p.thumbnail.url
                                    : p.thumbnail &&
                                      typeof p.thumbnail == "string" &&
                                      p.plugin === "odoo"
                                    ? `data:image/png;base64,${p.thumbnail}`
                                    : p.plugin === "ln" || p.plugin === "df"
                                    ? p.thumbnail
                                    : false;

                            return (
                                <Col
                                    key={p._id}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className='d-flex justify-content-center align-items-center pt-2 pb-2'
                                >
                                    <Image
                                        title={p.variantDesc}
                                        className='imgVariant'
                                        src={thumbnail || ImgTest}
                                        alt={p.variantDesc}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            updateProductToLink(p._id);
                                        }}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            }
            </div>
        </ListVariantStyle>
    );
}
