import styled from "styled-components";

const NavbarAdminStyle = styled.div`
  .page-content {
    background-color: #F7F7F7;
    width: 100%;
    display: block;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: 1rem;
    scroll-behavior: smooth;
  }

`;
export default NavbarAdminStyle;
