import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken, userEmail } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";
import FileSaver from 'file-saver';
import { getReport } from "../../../../../hooks/api/cashea";

export default function DownloadProductsDataOption(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [userEmailVali, setUserEmailVali] = useState(null);
    const configData = {};

    switch (props.format) {
        case 'csv':
            configData.icon = faFileCsv;
            configData.title = "Descarga data en CSV";
            configData.responseType = 'json';
            break;

        case 'xlsx':
            configData.icon = faFileExcel;
            configData.title = "Descarga data en Excel";
            configData.responseType = 'arraybuffer';
            break;
    }

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);
    
    // useEffect(() => {

    //     const v_userEmail = localStorage.getItem(userEmail);

    //     if (!v_userEmail) {
    //         // setRedirect(<Redirect to="/login" />);
    //         globalContext.setVerifyLogin(true);
    //     } else {
    //         globalContext.setVerifyLogin(false);
    //         setUserEmailVali(v_userEmail);

    //     }
    // }, [localStorage.getItem(userEmail)]);

    /**
     * Make the download request and then open the data as a file
     */
    const downloadData = () => {
        globalContext.setLoaderPage(true);
        
        if(props.currentPlugin === 'cashea'){
            getReport(globalContext.currentEnterpriseId, props.format).then(res =>{
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `La descarga inicio con exito, se estara ejecutando en segundo plano.`
                );
            }).catch(()=>{
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `Ocurrio un error al descargar su archivo`
                );
            })
        }
        else{
            axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/product/${globalContext.currentEnterpriseId}/report/${props.currentPlugin}/${props.format}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    params: {
                        filter: {
                            title: props.filter,
                            barCode: props.filter,
                            refInt: props.filter,
                            filterLinkedTo: props.filterLinkedTo,
                            filterPrice: props.filterPrice,
                            filterStock: props.filterStock,
                            filterWithCompany: props.filterWithCompany,
                        },
                    },
                    responseType: configData.responseType,
                }
            )
            .then((res) => {
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `La descarga inicio con exito, se estara ejecutando en segundo plano.`
                );

            })
            .catch((err) => {
                if (axios.isCancel(err)) return; //Don't show axios cancel error

                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg(
                    `Ocurrio un error al descargar su archivo`
                );
            });
        }
    };


    return (
        <div
            className={`items`}
            onClick={downloadData}
        >
            <span className="ic">
                <FontAwesomeIcon icon={configData.icon} />
            </span>
            <p>{configData.title}</p>

        </div>
    )
}