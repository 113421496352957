import axios from "axios"

/**
 * Handle the axios error
 * @param {Error} err The thrown error
 * @param {string} msg A message to show in the modal
 * @param {boolean} consoleLog If should log the error in the console
 */
export const handleUXError = ({ 
    error,
    message = null,
    consoleLog = true,
    globalContext
}) => {
    if (axios.isCancel(error)) return;

    if (message){

        console.log("༒︎ ------------------------------------------༒︎");
        console.log("༒︎ ⌿ file: index.js:19 ⌿ message:", message);
        console.log("༒︎ ------------------------------------------༒︎");

        // globalContext.showModalWithMsg(message);
    }

    if (consoleLog) {
        console.log("༒︎ -------------------------------------------------------------------༒︎");
        console.log("༒︎ ⌿ file: index.js:13 ⌿ handleAxiosError ⌿ consoleLog:", error?.message, error);
        console.log("༒︎ -------------------------------------------------------------------༒︎");
    }
};

