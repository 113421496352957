import styled from "styled-components";

const OdooStyle = styled.div`
  a.nav-link {
    transition: .25s
  }
  a.nav-link.active {
    background: #7367f0;
    color: #fff;
  }
  a.nav-link:not(.active) {
    color: #212529;
  }
  a.nav-link.disabled {
    color: #c4c4c4;
  }
  .title {
    color: #5e548e
  }
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .content-breadcrumb {
    padding: 10px;
  }
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }

  .odooFormBody {
    font-family: "Roboto Slab", serif !important;
    /*box-shadow: rgba(0, 0, 0, 0.11) 1px 1px 1px 1px;*/
    width: 60%;
    background-color: #f8f9fa;
    padding: 20px 10px;
  }
  .hearderFormOdoo {
    font-family: "Roboto Slab", serif !important;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    margin-bottom: 1.5%;
  }
  .footerFormOdoo {
    font-family: "Roboto Slab", serif !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .download-tarifa {
      font-family: "Roboto Slab", serif !important;
      margin-bottom: 3%;
      width: 30%;
      color: #5e548e;
      border: 1px solid #5e548e;
      background-color: transparent;
      padding: 7px 15px !important;
      border-radius: 30px;
      transition: .3s;

      &:disabled {
        outline: none;
        border: 1px solid #c4c4c4;
        color: #c4c4c4;
      }
      &:hover:not(:disabled) {
        color: #fff;
        background-color: #5e548e;
        -webkit-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        -moz-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        transition: all ease 450ms;
      }
      &:active,
      &:focus,
      &:visited,
      &active:focus {
        outline: none;
        // border: none;
      }
    }
  }
  .progress-bar {
    font-family: "Roboto Slab", serif !important;
    background-color: #9f86c0;
  }
  .StepOne,
  .StepTwo,
  .StepThree,
  .amazon {
    input,
    .react-select-container {
      font-family: "Roboto Slab", serif !important;
      border-color: #5e548e;
      outline: none;

      &:focus {
        border-color: #cec28e;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: 0 0 0 1px #cec28e;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    button {
      font-family: "Roboto Slab", serif !important;
      margin-bottom: 3%;
      width: 30%;
      color: #5e548e;
      border: 1px solid #5e548e;
      background-color: transparent;
      padding: 7px 15px !important;
      border-radius: 30px;

      &:hover {
        color: #fff;
        background-color: #5e548e;
        -webkit-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        -moz-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
        transition: all ease 450ms;
      }
      &:active,
      &:focus,
      &:visited,
      &active:focus {
        outline: none;
        border: none;
      }
    }
  }
  .formDataSuccess {
    font-family: "Roboto Slab", serif !important;
    label {
      font-family: "Roboto Slab", serif !important;
      color: #5e548e;
    }
    input {
      font-family: "Roboto Slab", serif !important;
      border: none;
      outline: none;
      &:focus {
        border: none;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .headerTitleSuccess {
      p {
        font-family: "Roboto Slab", serif !important;
        margin: 0;
        padding: 0 10px;
      }
      span {
        font-family: "Roboto Slab", serif !important;
        width: 35%;
        height: 2px;
        background-color: rgba(159, 134, 192, 0.6);
      }
    }
    button {
      font-family: "Roboto Slab", serif !important;
      width: 30%;
      border: none;
      color: white;
      background-color: #5e548e;
      padding: 7px 15px;
      border-radius: 30px;
      -webkit-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
      -moz-box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
      box-shadow: -1px 11px 35px 0px rgba(94, 84, 142, 1);
      transition: all ease 450ms;

      &:hover {
        color: #5e548e;
        background-color: #cec28e;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .StepOne,
    .StepTwo,
    .StepThree,
    .formDataSuccess {
      button {
        width: 100%;
      }
    }
    .footerFormOdoo {
      .download-tarifa {
        width: 100%;
      }
    }
  }
`;
export default OdooStyle;
