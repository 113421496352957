import React from "react";
import PedidosYaStyle from "../../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { BiHide, BiShow, BiX } from "react-icons/bi";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { useState, useContext } from "react";
import {
    getCredencials,
    sendCredencials,
} from "../functions/credentialsMethods";
import validateDataBeforeSend from "../functions/validateDataBeforeSend";
import { useEffect } from "react";
import { currentPageCredential } from "../../../../../assets/js/SessionStorageNames";

export default function CredentialsWoo(props) {
    /*
     * states
     */
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [wpUser, setWpUser] = useState("");
    const [wpPass, setWpPass] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [url, setUrl] = useState(null);
    const [consumerKey, setConsumerKey] = useState(null);
    const [consumerSecret, setConsumerSecret] = useState(null);
    const [version, setVersion] = useState(null);
    const [website, setWebsite] = useState('');
    const [source, setSource] = useState('');
    const globalContext = useContext(GlobalContext);
    /*
     * states  end
     */

    useEffect(() => {
        console.log("props.currentCred: ",props.currentCred)
        setUrl(props.currentCred?.url);
        setVersion(props.currentCred?.version);
        setWpUser(props.currentCred?.wpUser);
        setWebsite(props.currentCred?.idOrigin);
        setSource(props.currentCred?.idSource);
    }, [props.currentCred]);

    /**
     * setear datos basicos en caso de no tener activa la configuracion nueva
     */
    useEffect(() => {
        const ent = globalContext.currentEnterpriseId;
        if (props.showNewConfig) return;
        if(!ent) return;
        getCredencials(ent)
            .then((response) => {
                const data = response.data.data;
                console.log(data);
                setUrl(data.url);
                setVersion(data.version);
                setWpUser(data.wpUser || "");
                setWpPass(data.wpPass || "");
                setWebsite(data.idOrigin || "");
                setSource(data.idSource || "");
            })
            .catch((error) => {
                console.error("error al obtener las credenciales", error);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalContext.currentEnterpriseId]);

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    };

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPass(!showPass);
    };

    const sendCred = async () => {
        const enterpriseId = globalContext.currentEnterpriseId;
        const credId = localStorage.getItem(currentPageCredential);
        if (!enterpriseId) return;

        const valUrl = props.isValidHttpUrl(url);
        if (!valUrl) {
            setError("Debe ingresar un formato válido para una URL.");
            setShowBadge(true);
            return;
        }
        const dataToSend = {
            url,
            ...(consumerKey && {consumerKey}),
            ...(consumerSecret && {consumerSecret}),
            version,
            enterpriseId,
            wpUser: wpUser || undefined,
            wpPass: wpPass || undefined,
            ...(props.showNewConfig && { credId: credId }),
            ...(source && {source}),
            ...(website && {website}),
        };

        const validationArray = [
            url,
            version,
            enterpriseId,
        ];
        props.showNewConfig && validationArray.push(credId);
        const hasAnyError = validateDataBeforeSend(validationArray);

        if (hasAnyError) {
            await sendCredencials(dataToSend)
                .then((response) => {
                    setColorError(false);
                    setError("Credenciales actualizadas correctamente.");
                    setShowBadge(true);
                    setIsLoad(false);
                    setConsumerKey("");
                    setConsumerSecret("");
                    setTimeout(() => {
                        setShowBadge(false);
                    }, 15000);
                })
                .catch((error) => {
                    setColorError(true);
                    setError(
                        `Error al enviar las credenciales, ${error.message}`
                    );
                    setShowBadge(true);
                    setIsLoad(false);
                    setTimeout(() => {
                        setShowBadge(false);
                    }, 15000);
                });
        } else {
            setError(
                "Verifique que todos los datos son correctos o si le falta algun valor!"
            );
            setShowBadge(true);
            setIsLoad(false);
            setTimeout(() => {
                setShowBadge(false);
            }, 15000);
        }
    };

    return (
        <>
            <PedidosYaStyle.ContBadgeError
                active={showBadge}
                error={colorError}
            >
                <PedidosYaStyle.Text size='.875rem' cl='#FFFFFF'>
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContForm>
                <PedidosYaStyle.HeaderForm>
                    <PedidosYaStyle.Text size='14pt' fw='500' cl='#898989'>
                        Ingrese los datos para{" "}
                        {props.showNewConfig
                            ? "modificar"
                            : "modificar/registrar"}{" "}
                        las credenciales de su tienda en woocommerce{" "}
                        {props.showNewConfig && "seleccionada"}
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>
                <PedidosYaStyle.BodyForm>
                    <PedidosYaStyle.BodyFormGrid>
                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Clave del cliente
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.ContIconNadInputPass>
                                <PedidosYaStyle.Input
                                    value={consumerKey}
                                    type={showPass ? "text" : "password"}
                                    placeholder='Escriba aqui su clave del cliente'
                                    onChange={(e) =>
                                        setConsumerKey(e.target.value)
                                    }
                                />
                                <PedidosYaStyle.BtnShowPass
                                    onClick={(e) => toggleShowPassword(e)}
                                >
                                    {showPass ? <BiHide /> : <BiShow />}
                                </PedidosYaStyle.BtnShowPass>
                            </PedidosYaStyle.ContIconNadInputPass>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Clave secreta de cliente
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.ContIconNadInputPass>
                                <PedidosYaStyle.Input
                                    value={consumerSecret}
                                    type={showPass ? "text" : "password"}
                                    placeholder='Escriba aqui su clave secreta de cliente'
                                    onChange={(e) =>
                                        setConsumerSecret(e.target.value)
                                    }
                                />
                                <PedidosYaStyle.BtnShowPass
                                    onClick={(e) => toggleShowPassword(e)}
                                >
                                    {showPass ? <BiHide /> : <BiShow />}
                                </PedidosYaStyle.BtnShowPass>
                            </PedidosYaStyle.ContIconNadInputPass>
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Url de la pagina
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                value={url || ""}
                                placeholder='Escriba aqui el host de acceso'
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Vesion del API
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                value={version || ""}
                                placeholder='Escriba aqui la versión de acceso (wc/v3)'
                                onChange={(e) => setVersion(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Usuario wordpress
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                placeholder='Escriba aqui el usuario de wordpress'
                                onChange={(e) => setWpUser(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                Clave aplicaciones Wordpress
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                placeholder='Escriba aqui la clave para aplicaciones'
                                onChange={(e) => setWpPass(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                ID de Página Web en Odoo (website)
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                placeholder='Escriba aqui el ID de Página Web en Odoo (website)'
                                value={website||''}
                                type='number'
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                        <PedidosYaStyle.ContInputAndLabel>
                            <PedidosYaStyle.Text size='12pt'>
                                ID de Fuente en Odoo (source)
                            </PedidosYaStyle.Text>
                            <PedidosYaStyle.Input
                                placeholder='Escriba aqui el ID de Fuente en Odoo (source)'
                                value={source||''}
                                type='number'
                                onChange={(e) => setSource(e.target.value)}
                            />
                        </PedidosYaStyle.ContInputAndLabel>

                    </PedidosYaStyle.BodyFormGrid>
                </PedidosYaStyle.BodyForm>
                <PedidosYaStyle.FooterForm>
                    <PedidosYaStyle.BtnSend
                        disabled={isLoad}
                        onClick={sendCred}
                    >
                        {isLoad ? "Enviando" : "Actualizar Credenciales"}
                    </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.FooterForm>
            </PedidosYaStyle.ContForm>
        </>
    );
}
