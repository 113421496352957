import React, { useContext, useEffect, useState } from "react";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { BiHide, BiShow } from "react-icons/bi";
import { accVtex } from "../../../../hooks/api/vtex";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import ListOfProductsToSetParams from "../../../../assets/css/components/admin/marketplace-to-marketplace/products/ListOfProductsToSetParams";
import {
  Select,
} from "../../../../assets/css/components/admin/products/ShowProductInfo/ShowProductInfo.css";
import handleAxiosError from "../../update-and-publish/functions/handleAxiosError";
import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function VtexCredentials({
  username,
  loading,
  setLoading,
  modalNewCredential,
  setModalNewCredential,
  listUsers,
}) {
  const globalContext = useContext(GlobalContext);
  const [showPass, setShowPass] = useState(false);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [tarifas, setTarifas] = useState([]);
  const [tarifa, setTarifa] = useState([]);
  const toggleShowPassword = (e) => {
    setShowPass(!showPass);
  };

  const [accVtexData, setAccVtexData] = useState({
    username: "",
    apiKey: "",
    apiToken: "",
    odooPricelist: "",
  });

  const [getAccVtexData, setGetAccVtexData] = useState({
    username: "",
    apiKey: "",
    apiToken: "",
    odooPricelist: "",
  });

  const handleNewAcc = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAccVtexData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleGetAccVtexData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setGetAccVtexData((prevData) => ({
      ...prevData,
      [name]: value || "", // Asegúrate de que predetermine a una cadena vacía
    }));
  };
  const handleSubmitData = async (save) => {
    const entId = await globalContext.currentEnterpriseId;
    setLoading(true);
    try {
      const resp =await accVtex("POST", entId, save ? getAccVtexData : accVtexData);
      await globalContext.showModalWithMsg(`Haz actualizado la cuenta de ${username} correctamente.`);
    } catch (err) {
      console.error("Error al guardar credenciales de Vtex", err);
      await globalContext.showModalWithMsg(`Ha ocurrido un error al actualizar la cuenta de ${username}.`);
      alert(
        "Error al guardar credenciales de Vtex, verifica los campos y vuelve a intentarlo"
      );
    } finally {
      setLoading(false);
      if (!save) setModalNewCredential(false);
    }
  };

  useEffect(() => {
    if (listUsers) {
      const isUser = listUsers.filter((user) => user.username === username);
      if (isUser.length > 0) {
        setGetAccVtexData({
          username: isUser[0].username || "",
          apiKey: isUser[0].apiKey || "",
          apiToken: isUser[0].apiToken || "",
          odooPricelist: isUser[0].odooPricelist || "",
        });
      }
    }
    getPriceList()
  }, [modalNewCredential, listUsers]);


   /**
   * Function to consult the lists of available rates
   */
   async function getPriceList() {
    const entId = await globalContext.currentEnterpriseId;
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`;
    if (entId)
      await axios
        .get(url, {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        })
        .then((resp) => {
          const data = resp.data.data;
          setTarifas(data.priceLists);
        })
        .catch((err) => {
          console.log(err);
          handleAxiosError(
            err,
            "Disculpe, no se pudo cargar la lista de tarifas."
          );
        });
  }
  
  return (
    <div style={{ marginTop: 55 }}>
      {!modalNewCredential ? (
        <>
          <PedidosYaStyle.ContForm style={{ marginTop: 55 }}>
            <PedidosYaStyle.HeaderForm>
              <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                Credenciales de {username}
              </PedidosYaStyle.Text>
            </PedidosYaStyle.HeaderForm>

            <PedidosYaStyle.BodyForm>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 30 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Username</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.Input
                  name="username"
                  value={getAccVtexData.username || ""}
                  placeholder="Username"
                  onChange={handleGetAccVtexData}
                  disabled={true}
                  style={{ backgroundColor: "#efefef" }}
                ></PedidosYaStyle.Input>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 25 }}
              >
                <Select
                  className="tarifa"
                  name="odooPricelist"
                  value={getAccVtexData.odooPricelist || ""}
                  onChange={handleGetAccVtexData}

                >
                  <option>{getAccVtexData.odooPricelist || "Tarifa sin seleccionar"}</option>
                  {tarifas.map((val, i) => (
                    <option key={i} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </Select>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 10 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Apikey</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContIconNadInputPass>
                  <PedidosYaStyle.Input
                    style={{ width: "100%" }}
                    name="apiKey"
                    type={showPass ? "text" : "password"}
                    value={getAccVtexData.apiKey || ""}
                    disabled={loading}
                    placeholder="ApiKey"
                    autoComplete="off"
                    onChange={handleGetAccVtexData}
                  />
                  <PedidosYaStyle.BtnShowPass
                    onClick={(e) => toggleShowPassword(e)}
                  >
                    {showPass ? <BiHide /> : <BiShow />}
                  </PedidosYaStyle.BtnShowPass>
                </PedidosYaStyle.ContIconNadInputPass>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="65%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 10 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Apitoken</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContIconNadInputPass>
                  <PedidosYaStyle.Input
                    style={{ width: "100%" }}
                    name="apiToken"
                    type={showPass ? "text" : "password"}
                    value={getAccVtexData.apiToken || ""}
                    disabled={loading}
                    placeholder="apiToken"
                    autoComplete="off"
                    onChange={handleGetAccVtexData}
                  />
                  <PedidosYaStyle.BtnShowPass
                    onClick={(e) => toggleShowPassword(e)}
                  >
                    {showPass ? <BiHide /> : <BiShow />}
                  </PedidosYaStyle.BtnShowPass>
                </PedidosYaStyle.ContIconNadInputPass>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 25 }}
              >
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.BtnSend
                    onClick={() => handleSubmitData(true)}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Guardar Credencial"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel>
            </PedidosYaStyle.BodyForm>
          </PedidosYaStyle.ContForm>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#5c5c5c63",
          }}
        >
          <PedidosYaStyle.ContForm
            style={{
              position: "absolute",
              maxWidth: 700,
              margin: "auto",
              width: "100%",
            }}
          >
            <ListOfProductsToSetParams.Close
              style={{ top: 10, right: 10 }}
              onClick={() => setModalNewCredential(false)}
            ></ListOfProductsToSetParams.Close>
            <PedidosYaStyle.HeaderForm>
              <PedidosYaStyle.Text size="14pt">
                Agregar nueva crendencial para Vtex
              </PedidosYaStyle.Text>
            </PedidosYaStyle.HeaderForm>

            <PedidosYaStyle.BodyForm>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 30 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Username</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.Input
                  name="username"
                  value={accVtexData.username || ""}
                  placeholder="Username"
                  onChange={handleNewAcc}
                ></PedidosYaStyle.Input>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 25 }}
              >
                <Select
                  className="tarifa"  
                  name="odooPricelist"
                  value={accVtexData.odooPricelist || ""}
                  onChange={handleNewAcc}
                >
                  <option selected>SELECCIONE UNA TARIFA</option>
                  {tarifas.map((val, i) => (
                    <option key={i} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </Select>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 10 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Apikey</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContIconNadInputPass>
                  <PedidosYaStyle.Input
                    style={{ width: "100%" }}
                    name="apiKey"
                    type={showPass ? "text" : "password"}
                    value={accVtexData.apiKey || ""}
                    disabled={loading}
                    placeholder="ApiKey"
                    autoComplete="off"
                    onChange={handleNewAcc}
                  />
                  <PedidosYaStyle.BtnShowPass
                    onClick={(e) => toggleShowPassword(e)}
                  >
                    {showPass ? <BiHide /> : <BiShow />}
                  </PedidosYaStyle.BtnShowPass>
                </PedidosYaStyle.ContIconNadInputPass>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="65%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 10 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Apitoken</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContIconNadInputPass>
                  <PedidosYaStyle.Input
                    style={{ width: "100%" }}
                    name="apiToken"
                    type={showPass ? "text" : "password"}
                    value={accVtexData.apiToken || ""}
                    disabled={loading}
                    placeholder="apiToken"
                    autoComplete="off"
                    onChange={handleNewAcc}
                  />
                  <PedidosYaStyle.BtnShowPass
                    onClick={(e) => toggleShowPassword(e)}
                  >
                    {showPass ? <BiHide /> : <BiShow />}
                  </PedidosYaStyle.BtnShowPass>
                </PedidosYaStyle.ContIconNadInputPass>
              </PedidosYaStyle.ContInputAndLabel>
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 25 }}
              >
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.BtnSend
                    onClick={() => handleSubmitData(false)}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Guardar nueva Credencial"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel>

            </PedidosYaStyle.BodyForm>
          </PedidosYaStyle.ContForm>
        </div>
      )}
    </div>
  );
}
