import styled from 'styled-components';

const contParent = styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
flex-direction:column;
padding:10px;
width:100%;
background:#FFFFFF;
margin-top:15px;
border: 0.5px solid rgba(112, 112, 112, 0.65);
border-radius: 5px;
`;
const cont = styled.div`
margin-bottom:10px;
width:100%;
display: grid;
grid-gap: 0.5rem;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const input = styled.input`
    padding-left:10px;
    // font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: #000;
    font-size: 12pt;
    font-weight: 400;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: #595858;
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;
const text = styled.p`
margin-bottom:  ${props => props.mb || '0'};
color: ${props => props.color || '#707070'};
font-size: ${props => props.size || '13pt'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '0'};
text-align: start;
transition: all ease-in-out 0.2s;
`;

const btn = styled.button`
border-radius: 5px;
background-color:${props => props.bg || '#7367f0'};
color:${props => props.color || '#ffffff'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
outline: none!important;
border:1px solid;
border-color: ${props => props.bdc || '#7367f0'};
font-size: 12pt;
font-weight: 400;
padding: 6px 18px;
transition: all ease 0.2s;
&:hover {
    background-color:${props => props.bgHover || '#7367f0'};
    color:${props => props.colorHover || '#ffffff'};
}
`;
const contInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
margin-bottom:10px;
`;
const inputRadio = styled.input`

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #7367f0;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12pt;
    line-height: 14px;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #707070;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;

const OtrosParametrosCss = {
    contParent,
    cont,
    contInputAndLabel,
    input,
    text,
    btn,
    inputRadio,
    contInputRadio
}

export default OtrosParametrosCss;