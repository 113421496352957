import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import Routes from "../../../../../Routes";
import verifyAccessRole from "../../../../../assets/js/verifyAccessRole";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { Button } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import PedidosYaStyle from "../../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import axios from "axios";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../../assets/js/SessionStorageNames";

export default function FormConfigMercantil() {
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [valid /* setValid */] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        clientID: "",
        mechanId: null,
        encryptionKey: "",
    });
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        consoleLog && console.error(err);
        if (msg) globalContext.showModalWithMsg(msg);
    };

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 65);
        if (!hasAccess /* || globalContext.parentEntId */) {
            setRedirect(<Redirect to={`/admin/dashboard`} />);
        }
    }, [globalContext.parentEntId]);

    /**
     * funtion to save the credemptials
     * @param {*} e
     */
    function saveCredemptials(e) {
        e.preventDefault();
        if (loading) return;
        const { clientID, mechanId, encryptionKey } = data;
        const enterpriseId = globalContext.currentEnterpriseId;
        if (!enterpriseId) return;

        console.log({ clientID, mechanId, encryptionKey });

        if (!clientID || !mechanId || !encryptionKey) return;

        setLoading(true);
        axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/v1/mercantil`,
                {
                    clientID,
                    mechanId,
                    encryptionKey,
                    enterpriseId,
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                //Set the actual config as the default data
                globalContext.showModalWithMsg(
                    `Se ha guardado la nueva configuración.`
                );
            })
            .catch((err) => {
                console.log(err.response);
                handleAxiosError(
                    err,
                    err.response?.data?.message ||
                        "Disculpe, no se pudieron guardar los cambios."
                );
            })
            .finally(() => {

                for (const [key] of Object.entries(data)) {
                    setData(prevData => ({...prevData, [key]: ''})
                    )   
                }
                setLoading(false);
            });
    }

    function testCreds() {
        if (loading) return;
        setLoading(true);
        const url = `${process.env.REACT_APP_BACKEND_URL}/v1/mercantil/plugin/get-auth`;
        axios
            .post(
                url,
                {
                    idDocument: "18366876",
                    documentType: "V",
                    debitCardNumber: "501878200066287386",
                    dev: true,
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                //Set the actual config as the default data
                globalContext.showModalWithMsg(
                    `Las credenciales funcionan correctamente.`
                );
            })
            .catch((err) => {
                console.error(err.response);
                handleAxiosError(
                    err,
                    err.response?.data?.message ||
                        "Disculpe, no se pudo realizar la prueba."
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }

    /**
     * funcion para actualizar los datos del formulario
     * @param {*} param0
     */
    function handleChangeData({ target }) {
        const { name, value } = target;

        setData((prevData) => ({
            ...prevData,
            [name]: name === "mechanId" ? value.replace(/[^\d]/, "") : value,
        }));

        /*
        todo hay que activar o desactivar la validez segun los datos ingresados
        const { clientID, mechanId, encryptionKey } = data;

        if (!!clientID && !!mechanId && !!encryptionKey) {
            setValid((prevData) => (prevData = true));
        } else {
            setValid((prevData) => (prevData = false));
        } 
        */
    }

    return (
        <>
            {redirect}
            <Container
                fluid
                style={{ height: "90%" }}
                className='d-flex align-items-center'
            >
                <Row className='w-100'>
                    <Col className='text-center'>
                        <Row className='justify-content-md-center'>
                            <Col md='9' lg='6'>
                                <Card>
                                    <Card.Header className='bg-white'>
                                        <PedidosYaStyle.Text
                                            size='15pt'
                                            fw='500'
                                        >
                                            Configuración de credenciales de
                                            Mercantil
                                        </PedidosYaStyle.Text>
                                    </Card.Header>
                                    <Card.Body className='text-left'>
                                        <Card.Body>
                                            <Form onSubmit={saveCredemptials}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId='formBasicEmail'>
                                                            <Form.Label>
                                                                ID de Cliente de
                                                                Mercantil
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                autoComplete="off"
                                                                onChange={
                                                                    handleChangeData
                                                                }
                                                                value={
                                                                    data.clientID
                                                                }
                                                                name='clientID'
                                                                placeholder='Ingrese su clientID'
                                                            />
                                                            <Form.Text className='text-muted'>
                                                                We'll never
                                                                share your email
                                                                with anyone
                                                                else.
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId='formBasicPassword'>
                                                            <Form.Label>
                                                                Merchan ID
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                autoComplete="off"
                                                                onChange={
                                                                    handleChangeData
                                                                }
                                                                value={
                                                                    data.mechanId
                                                                }
                                                                name='mechanId'
                                                                placeholder='Ingrese su mechanId'
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId='formBasicPassword'>
                                                            <Form.Label>
                                                                Clave de Cifrado
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                autoComplete="off"
                                                                onChange={
                                                                    handleChangeData
                                                                }
                                                                value={
                                                                    data.encryptionKey
                                                                }
                                                                name='encryptionKey'
                                                                placeholder='Ingrese su Clave de Cifrado'
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={6}
                                                        className='text-start'
                                                    >
                                                        <Button
                                                            disabled={
                                                                !valid ||
                                                                loading
                                                            }
                                                            variant='primary'
                                                            type='submit'
                                                            className='me-2'
                                                        >
                                                            Guardar Datos
                                                        </Button>
                                                    </Col>

                                                    <Col
                                                        xs={12}
                                                        md={6}
                                                        className='d-flex justify-content-end'
                                                    >
                                                        <Button
                                                            disabled={loading}
                                                            color='secondary'
                                                            type='button'
                                                            className='ms-2'
                                                            onClick={testCreds}
                                                        >
                                                            Probar Credenciales
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card.Body>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
