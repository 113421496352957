import React, { useContext, useEffect, useState } from "react";
import Routes from "../../../Routes";
import CardsMenu from "../../../components/admin/menus/CardsMenu";
import { keyUserActiveEnterpriseId, third } from "../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { MdRouter } from "react-icons/md";
export default function MyPluginsMenu() {
    const [routes, setRoutes] = useState([]);
    const globalContext = useContext(GlobalContext);
    const [isThirdPartyUser, setIsThirdPartyUser] = useState(globalContext.third);

    function verifyThird() {
        if (globalContext.third === null) {
            if (localStorage.getItem(third)) {
                globalContext.setThird(JSON.parse(localStorage.getItem(third)));
            } else {
                globalContext.setThird(false);
            }
        }
        setIsThirdPartyUser(globalContext.third);
    }

    async function availableRoutes() {
      await verifyThird();
      let r = await isThirdPartyUser ? Routes.filter(v => v.third) : Routes; 
      await setRoutes(r);
    }

    // Routes;

    useEffect(() => {
        availableRoutes()
    }, [globalContext.third, localStorage.getItem(keyUserActiveEnterpriseId)]);

    return <CardsMenu routes={routes} father='/plugins/my-plugins' />;
}
