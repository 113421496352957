import React, { useContext } from 'react'
import { BiArrowToBottom } from 'react-icons/bi'
import { BodyContListFilter, ContIconBtnfilter, ContInputRadio, ContListFilter, CurrentCategoryContent, FilterCont, FilterContGeneral, HeaderContListFilter, InputChecbox, InputRadio, OneItemFilter, TwoItemFilter, Button } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles';
import Pager from "../../../../general/Pager";
import { Text } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts'
// import { GlobalContext } from '../../../../../contexts/GlobalContext';
// import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getDataPyByExcel from '../functions/getDataPyByExcel';
import axios from 'axios';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyAccessToken, keyUserId, parentEntId } from '../../../../../assets/js/SessionStorageNames';
export default function FiltersProducts({ setPluginSearch, pluginSearch, setCurrentPage, total, currentPage, pageItemCount, load, setFilterLinkedTo,
    setFilterPrice,
    setFilterStock,
    setFilterWithCompany,
    setFilterWithTaxes,
    filterLinkedTo,
    filterPrice,
    filterStock,
    filterWithCompany,
    filterWithTaxes,
    setShowFinder,
    currentCategory,
    setCurrentCategory,
    currentThirdPartyUser,
    setCurrentThirdPartyUser,
    validateAdminUser,
    validateThird,
}) {
    const [currentFilter, setCurrentFilter] = useState(1);
    const globalContext = useContext(GlobalContext);
    const [sendData, setSendData] = useState(false);
    const adminUser = validateAdminUser();
    const validThird = validateThird();
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    //const globalContext = useContext(GlobalContext);
    const changePluginFilter = (v) => {
        setPluginSearch(v)
    };

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        switch (currentFilter) { 
            case 1:
                setFilterLinkedTo("")
                setFilterPrice("")
                setFilterStock("")
                setFilterWithCompany(null)
                setFilterWithTaxes(null)
                break;
            case 2:
                setFilterLinkedTo("linked")
                break;
            case 3:
                setFilterLinkedTo("unlinked")
                break;
            case 4:
                setFilterPrice('=0')
                break;
            case 5:
                setFilterPrice('>0')
                break;
            case 6:
                setFilterStock('=0')
                break;
            case 7:
                setFilterStock('>0')
                break;
            case 8:
                setFilterWithCompany(true)
                break;
            case 9:
                setFilterWithCompany(false)
                break;
            case 10:
                setFilterWithTaxes(true)
                break;
            case 11:
                setFilterWithTaxes(false)
                break;
        
            default:
                break;
        }

    }, [currentFilter]);

    const getDataForPeya = () => {
        if(sendData) return;
        const entId = globalContext.currentEnterpriseId;
        setSendData(true);
        getDataPyByExcel(entId)
        .then(resp => {
            globalContext.showModalWithMsg(`El proceso se ejecuta en segundo plano`);
            setSendData(false);
        }).catch(err => {
            console.log('el error del front', err)
            handleAxiosError('error al crear el proceso')
            setSendData(false);
        })
    }

    const descargarListaPrecios = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.showModalWithMsg("Debe seleccionar una empresa en el selector de empresas, arriba en la barra de navegación.");
            return;
        }

        const confirm = window.confirm(`¿Desea descargar la lista de precios?${!localStorage.getItem(parentEntId) ? "; Ésto descargará los precios de Odoo." : ""}`)
        if(confirm) {
            globalContext.setLoaderPage(true);
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/prices`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then(resp => {
                console.log(resp.data)
                globalContext.showModalWithMsg(resp?.message?.trim() || "Generando reporte.");
            })
            .catch(err => {
                const message = err.response?.data?.message || "Error al intentar generar el reporte." 
                const msgEsp = message === "There is already a process running" ? "El proceso ya se está ejecutando." : `Error: ${message}${message.endsWith(".") ? "" : "."}`;
                handleAxiosError(err, msgEsp)
            })
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
        }
    }

    const renderOptionsFromPlugin = () => {
        switch (pluginSearch) {
            case 'py':
                return (
                    <ContListFilter>
                    <HeaderContListFilter>
                        <Text size="12pt" fw="500">Opciones Pedidos Ya:</Text>
                    </HeaderContListFilter>
                    <BodyContListFilter>
                        <TwoItemFilter onClick={() => getDataForPeya()}>
                            <Text size="12pt" cl="#FFFFFF" fw="500">
                                { sendData ? 'Enviando...' : 'Productos en Excel'}
                            </Text>
                            {
                                !sendData && (
                                    <ContIconBtnfilter>
                                        <BiArrowToBottom />
                                    </ContIconBtnfilter>
                                )
                            }
                        </TwoItemFilter>
                    </BodyContListFilter>
                </ContListFilter>
                )
            default:
                break;
        }
    }
    

    return (
        <FilterContGeneral>
            <Text size="14pt" fw="500" style={{ alignSelf: 'flex-start' }} mb="1rem">Filtros</Text>
            <FilterCont>

                    <ContListFilter>
                        <HeaderContListFilter>
                            <Text size="12pt" fw="500">Plugins:</Text>
                        </HeaderContListFilter>
                        <BodyContListFilter>
                            <OneItemFilter>
                                <ContInputRadio>
                                    <InputRadio
                                        type="radio"
                                        id="p1"
                                        value='odoo'
                                        disabled={load}
                                        onChange={() => {
                                            setCurrentCategory({})
                                            changePluginFilter('odoo')
                                            globalContext.setPluginSearchForpost('odoo')
                                        }}
                                        checked={pluginSearch === 'odoo'}
                                    />
                                    <label htmlFor="p1">Odoo</label>
                                </ContInputRadio>
                            </OneItemFilter>
                            <OneItemFilter>
                                <ContInputRadio>
                                    <InputRadio
                                        type="radio"
                                        id="p2"
                                        value="ml"
                                        disabled={load}
                                        onChange={() => {
                                            setCurrentCategory({})
                                            changePluginFilter('ml')
                                            globalContext.setPluginSearchForpost('ml')
                                        }}
                                        checked={pluginSearch === 'ml'}
                                    />
                                    <label htmlFor="p2">Mercado Libre</label>
                                </ContInputRadio>
                            </OneItemFilter>
                            {!validThird &&
                                <>
                                    <OneItemFilter>
                                        <ContInputRadio>
                                            <InputRadio
                                                type="radio"
                                                id="p5"
                                                value="py"
                                                disabled={load}
                                                onChange={() => {
                                                    changePluginFilter('py');
                                                    globalContext.setPluginSearchForpost('py')
                                                }}
                                                checked={pluginSearch === 'py'}
                                            />
                                            <label htmlFor="p5">Pedidos Ya</label>
                                        </ContInputRadio>
                                    </OneItemFilter>
                                    <OneItemFilter>
                                        <ContInputRadio>
                                            <InputRadio
                                                type="radio"
                                                id="p6"
                                                value="anicam"
                                                disabled={load}
                                                onChange={() => {
                                                    changePluginFilter('anicam')
                                                    globalContext.setPluginSearchForpost('anicam')
                                                }}
                                                checked={pluginSearch === 'anicam'}
                                            />
                                            <label htmlFor="p6">Anicam</label>
                                        </ContInputRadio>
                                    </OneItemFilter>
                                </>
                            }
                        </BodyContListFilter>
                    </ContListFilter>

                {
                    renderOptionsFromPlugin()
                }

                {pluginSearch === "odoo" &&
                    <ContListFilter>
                        <HeaderContListFilter>
                            <Text size="12pt" fw="500">Categoría:</Text>
                        </HeaderContListFilter>
                        <BodyContListFilter>
                            <Button className='w-100' onClick={() => setShowFinder()} disabled={(globalContext.currentEnterpriseId && !load) ? false : true}>
                                Buscar
                            </Button>
                            {currentCategory.name &&
                                <CurrentCategoryContent>
                                    <div className={`row ${load ? 'disabled': ''}`}>
                                        <div className="col" onClick={() => !load && setShowFinder()}>
                                            {currentCategory.name}
                                        </div>
                                        <div className="col col-2">
                                            {/* <i className="fa fa-check-circle w-30px m-t-5"></i> */}
                                            <FontAwesomeIcon onClick={() => !load && setCurrentCategory({})} className='w-30px' icon={faTimesCircle} />
                                        </div>
                                    </div>
                                </CurrentCategoryContent>
                            }
                        </BodyContListFilter>
                    </ContListFilter>
                }
                <ContListFilter>
                    <HeaderContListFilter>
                        <Text size="12pt" fw="500">Precios:</Text>
                    </HeaderContListFilter>
                    <BodyContListFilter>
                        <Button className='w-100' onClick={descargarListaPrecios} disabled={!globalContext.currentEnterpriseId}>
                            Descargar Reporte
                        </Button>
                    </BodyContListFilter>
                </ContListFilter>

                {adminUser &&
                    <ContListFilter>
                        <HeaderContListFilter>
                            <Text size="12pt" fw="500">Cliente Tercero:</Text>
                        </HeaderContListFilter>
                        <BodyContListFilter>
                            <Button className='w-100' onClick={() => setShowFinder(1)} disabled={(globalContext.currentEnterpriseId && !load) ? false : true}>
                                Buscar
                            </Button>
                            {currentThirdPartyUser.name &&
                                <CurrentCategoryContent>
                                    <div className={`row ${load ? 'disabled': ''}`}>
                                        <div className="col" onClick={() => !load && setShowFinder(1)}>
                                            {currentThirdPartyUser.name}
                                        </div>
                                        <div className="col col-2">
                                            {/* <i className="fa fa-check-circle w-30px m-t-5"></i> */}
                                            <FontAwesomeIcon onClick={() => !load && setCurrentThirdPartyUser({})} className='w-30px' icon={faTimesCircle} />
                                        </div>
                                    </div>
                                </CurrentCategoryContent>
                            }
                        </BodyContListFilter>
                    </ContListFilter>
                }

                <ContListFilter>
                    <HeaderContListFilter>
                        <Text size="12pt" fw="500">Filtrar por:</Text>
                    </HeaderContListFilter>
                    <BodyContListFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f1"  disabled={load} checked={currentFilter === 1} onChange={() => setCurrentFilter(1)} />
                                <label htmlFor="f1">Todos los Productos</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f2" 
                                    value="linked" 
                                    disabled={load} 
                                    checked={filterLinkedTo ==="linked"} 
                                    onChange={() => setCurrentFilter(2)} />
                                <label htmlFor="f2">Prod sincronizados</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f3" 
                                    value={false} 
                                    disabled={load} 
                                    checked={filterLinkedTo === "unlinked"}  
                                    onChange={() => setCurrentFilter(3)}/>
                                <label htmlFor="f3">Prod no sincronizados</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f4" 
                                    value='=0' disabled={load} 
                                    checked={filterPrice === '=0'} 
                                    onChange={() => setCurrentFilter(4)}
                                    />
                                <label htmlFor="f4">Prod con precio 0</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f5" 
                                    value='>0' disabled={load} 
                                    checked={filterPrice === '>0'}  
                                    onChange={() => setCurrentFilter(5)}/>
                                <label htmlFor="f5">Prod con precio diferente de 0</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f6" 
                                    value='=0' 
                                    disabled={load} 
                                    checked={filterStock === '=0'} 
                                    onChange={() => setCurrentFilter(6)} />
                                <label htmlFor="f6">Prod con stock 0</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f7"
                                    disabled={load} 
                                    value='>0' 
                                    checked={filterStock === '>0'} 
                                    onChange={() => setCurrentFilter(7)}/>
                                <label htmlFor="f7">Prod con stock diferente de 0</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f8" 
                                    value={true} 
                                    disabled={load} 
                                    checked={filterWithCompany} 
                                    onChange={() => setCurrentFilter(8)}/>
                                <label htmlFor="f8">Prod con empresa</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f9" 
                                    value={false} disabled={load} 
                                    checked={!filterWithCompany && filterWithCompany !== null}
                                    onChange={() => setCurrentFilter(9)}/>
                                <label htmlFor="f9">Prod sin empresa</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f10" value={false} disabled={load} checked={filterWithTaxes} onChange={() => setCurrentFilter(10)}/>
                                <label htmlFor="f10">Prod con impuesto</label>
                            </ContInputRadio>
                        </OneItemFilter>
                        <OneItemFilter>
                            <ContInputRadio>
                                <InputChecbox
                                    type="radio" id="f11" value={false} disabled={load} checked={!filterWithTaxes && filterWithTaxes !== null } onChange={() => setCurrentFilter(11)}/>
                                <label htmlFor="f11">Prod sin impuesto</label>
                            </ContInputRadio>
                        </OneItemFilter>
                    </BodyContListFilter>
                </ContListFilter>
            </FilterCont>
            <div style={{ width: 100 + '%', }} className="d-flex justify-content-center mt-3">
                <Pager
                    handleSetPage={setCurrentPage}
                    totalResults={total}
                    currentPage={currentPage}
                    resultsPerPage={pageItemCount}
                />
            </div>
        </FilterContGeneral>
    )
}
