import React, { useState, useEffect, useContext } from "react";
import PluginWorksStyles from "../../../../assets/css/components/admin/products/PluginWork.css";
import { Form } from "react-bootstrap";
import { keyProductsListDropdownPlugin } from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";

export default function PluginWork(props) {
  const globalContext = useContext(GlobalContext);
  const axios = require("axios");
  const [statusDropDown, setStatusDropDown] = useState([]);
  const accessTokenKey = localStorage.getItem("accessTokenKey");
  const idUser = localStorage.getItem("_id");

  /**
   * ? metodo para guardar el valor del select
   */
  const selectPlugin = (e) => {
    props.setCurrentPlugin(e.target.value);
  };

  /**
   * Set the selected brand
   */
  const selectBrand = (e) => {
    props.updateBrand(e.target.value);
  };

  /**
   * Get the available plugins
   */
  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/available`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": accessTokenKey,
        "x-user-id": idUser,
      },
    })
      .then((res) => {
        setStatusDropDown(res.data.data);

        //If there isn't a selected plugin
        if (!localStorage.getItem(keyProductsListDropdownPlugin)) {
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin`, {
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": accessTokenKey,
                "x-user-id": idUser,
              },
            })
            .then((res) => props.setCurrentPlugin(res.data.data))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        globalContext.showModalWithMsg(
          "Disculpe ha ocurrido un error al intentar cargar la lista de plugins"
        );
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
    //eslint-disable-next-line
  }, []);

  //Select the currently active plugin ID on the first load
  useEffect(() => {
    const currentEntId = localStorage.getItem(keyProductsListDropdownPlugin);

    if (currentEntId) {
      props.setCurrentPlugin(currentEntId);
    }
    //eslint-disable-next-line
  }, []);

  //Change the plugin value in storageSession
  useEffect(() => {
    localStorage.setItem(keyProductsListDropdownPlugin, props.currentPlugin);
  }, [props.currentPlugin]);

  return (
    <PluginWorksStyles>
      <Form.Control
        as='select'
        onChange={selectPlugin}
        value={props.currentPlugin || ""}
      >
        <option key='' value='' selected>
          Seleccione un plugin
        </option>
        {statusDropDown.map(
          (plugin) =>
            plugin.name && (
              <option key={plugin.id} value={plugin.id}>
                {plugin.name}
              </option>
            )
        )}
      </Form.Control>
    </PluginWorksStyles>
  );
}
