import styled from "styled-components";

const SwitchLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const Switch = styled.div`
    width: ${({ size }) =>
        size === "lg"
            ? "60"
            : size === "md"
            ? "45"
            : size === "sm"
            ? "30"
            : "60"}px;
    position: relative;
    height: ${({ size }) =>
        size === "lg"
            ? "32"
            : size === "md"
            ? "24"
            : size === "sm"
            ? "16"
            : "32"}px;
    background: #b3b3b3;
    border-radius: 32px;
    padding: 4px;
    transition: 300ms all;

    &:before {
        transition: 300ms all;
        content: "";
        position: absolute;
        width: ${({ size }) =>
            size === "lg"
                ? "28"
                : size === "md"
                ? "20"
                : size === "sm"
                ? "14"
                : "28"}px;
        height: ${({ size }) =>
            size === "lg"
                ? "28"
                : size === "md"
                ? "20"
                : size === "sm"
                ? "14"
                : "28"}px;
        border-radius: 35px;
        top: 50%;
        left: 2px;
        background: white;
        transform: translate(0, -50%);
    }
`;
const SwitchInput = styled.input`
    display: none;

    &:checked + ${Switch} {
        background: #867bff;

        &:before {
            transform: translate(
                ${({ size }) =>
                    size === "lg"
                        ? "28"
                        : size === "md"
                        ? "20"
                        : size === "sm"
                        ? "13"
                        : "28"}px,
                -50%
            );
        }
    }
`;

export { SwitchLabel, Switch, SwitchInput };
