import React, { useState, useRef, useContext } from "react";
import { useEffect } from "react";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import FormProduct from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct";
import ListOfProductsToSetParams from "./ListProductsToSetParams";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";

export default function SetParamProdToPost({
  isLoad,
  setIsLoad,
  paramsToSend,
  setParamsToSend,
  listinType,
  setListinType,
  setPublishGroup,
  publishGroup,
  validateInput,
  categoryAttr
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [urlImgPlus, setUrlImgPlus] = useState("");
  const [imgPlusArr, setImgPlusArr] = useState([]);
  const urlPlusInput = useRef(null);
  const globalContext = useContext(GlobalContext);
  const [blockField, setBlockField] = useState(true);
  const [categoryAttrData, setCategoryAttrData] = useState({});

  useEffect(() => {
    console.log("parametros a enviar (paramsToSend): ", paramsToSend);
    if (globalContext.pluginSearchForpost === "anicam") {
      setBlockField(true);
    } else {
      setBlockField(false);
    }
  }, [globalContext.pluginSearchForpost]);

  const sendToFormData = async (v, currentPosition) => {
    setCurrentIndex(currentPosition);
  };

//Se actualiza ParamsToSend y le agrega los valores que se guardaron en customAttributes
  useEffect(()=>{
    const updatedParamsToSend = paramsToSend.map((param, index) =>({
      ...param,
      ...categoryAttrData[index]
    }))
    setParamsToSend(updatedParamsToSend)
  }, [categoryAttrData])

  // Almacenana los datos cargados dinamicos de cada categoria y se guardan en un objeto llamado => customAttributes
  const handleDynamicFieldChange = (name, value) => {
    setCategoryAttrData((prev) => {
      const newFields = { ...prev };
      if (!newFields[currentIndex]) {
        newFields[currentIndex] = {};
      }
      if (!newFields[currentIndex].customAttributes) {
        newFields[currentIndex].customAttributes = [];
      }
      // Encuentra el índice del atributo si ya existe
      const attrIndex = newFields[currentIndex].customAttributes.findIndex(
        (attr) => attr.name === name
      );
  
      if (attrIndex > -1) {
        // Si el atributo ya existe, actualiza su valor
        newFields[currentIndex].customAttributes[attrIndex].value = value;
      } else {
        // Si no existe, agrega un nuevo objeto con name y value
        newFields[currentIndex].customAttributes.push({ name, value });
      }
      
      // Retornar el nuevo objeto
      return newFields;
    });
  };

  const titleFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].title = v;
    setParamsToSend(copyArray);
  };
  const pausePublicationFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].pausePublication = v;
    setParamsToSend(copyArray);
  };

  const publishGroupedV = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].publishGroup = v;
    setParamsToSend(copyArray);
  };
  const brandFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].brand = v;
    setParamsToSend(copyArray);
  };
  const modelFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].model = v;
    setParamsToSend(copyArray);
  };
  const weightFunc = (v) => {
    const value = validateInput(v);
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].weight = value;
    setParamsToSend(copyArray);
  };
  const heightFunc = (v) => {
    const value = validateInput(v);
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].height = value;
    setParamsToSend(copyArray);
  };
  const widthFunc = (v) => {
    const value = validateInput(v);
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].width = value;
    setParamsToSend(copyArray);
  };
  const length_prodFunc = (v) => {
    const value = validateInput(v);
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].length_prod = value;
    setParamsToSend(copyArray);
  };
  const bodyFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].body = v;
    setParamsToSend(copyArray);
  };

  const warrantyTypeFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].warranty_type = v;
    setParamsToSend(copyArray);
  };

  const warrantyValueFunc = (v) => {
    let copyArray = [...paramsToSend];
    let currentData = copyArray[currentIndex].warranty_value;
    let unit = currentData ? currentData.replace(/\d/g, '') : '';
    if (v !== '') {
      copyArray[currentIndex].warranty_value = `${v}${unit}`;
    } else {
      copyArray[currentIndex].warranty_value = `${unit}`;
    }
    setParamsToSend(copyArray);
  };

  const concatUnit = (value) => {
    let copyArray = [...paramsToSend];
    let currentData = copyArray[currentIndex].warranty_value?.replace(/[^\d.-]/g, '');
    copyArray[currentIndex].warranty_value = `${currentData} ${value}`;
    setParamsToSend(copyArray)
  }
  const addImgPlus = (event) => {
    event.preventDefault();
    if (urlImgPlus.length > 0) {
      let copyArray = [...paramsToSend];
      let copyArrImg =
        copyArray[currentIndex].imagesPlus === ""
          ? []
          : copyArray[currentIndex].imagesPlus.split(",");

      copyArrImg.push(urlImgPlus);
      copyArray[currentIndex].imagesPlus = copyArrImg.join(",");
      setParamsToSend(copyArray);
      setUrlImgPlus("");
      urlPlusInput.current.value = "";
    }
  };

  const removeImg = (url) => {
    const existUrl = imgPlusArr.find((v) => v === url);
    if (existUrl !== undefined) {
      let copyImgPlusArr = [...imgPlusArr.filter((v) => v !== existUrl)];
      setImgPlusArr(copyImgPlusArr);
      let copyArray = [...paramsToSend];
      copyArray[currentIndex].imagesPlus = copyImgPlusArr.join(",");
      setParamsToSend(copyArray);
    }
  };
  console.log("validamos el index", currentIndex, paramsToSend);

  const groupedOptions = [
    {
      value: true,
      description: "Agrupadas",
    },
    {
      value: false,
      description: "Separadas",
    },
  ];
  return (
    <div style={{ width: "100%", height: "auto" }}>
      <ListOfProductsToSetParams sendToFormData={sendToFormData} />
      {paramsToSend.length > 0 && (
        <FormProduct.ContForms>
          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Parametros Generales
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel w='340px' mr='5px'>
                <Text size='12pt' fw='500'>
                  Tipo de publicacion
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  onChange={(e) => setListinType(e.target.value)}
                >
                  <option value=''>Seleccionar</option>
                  <option value='gold_pro'>Premiun CO</option>
                  <option value='gold_premium'>Oro Premiun CO</option>
                  <option value='gold'>Oro CO</option>
                  <option value='gold_special'>Premiun VE</option>
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>
              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Como publicar variantes
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  value={paramsToSend[currentIndex].publishGroup}
                  onChange={(e) => {
                    setPublishGroup(e.target.value);
                  }}
                >
                  <option disabled>Seleccionar</option>
                  {groupedOptions.map((val, i) => (
                    <option
                      value={val.value}
                      selected={val.value === publishGroup && true}
                    >
                      {val.description}
                    </option>
                  ))}
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm>
          </FormProduct.Contform>

          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Llene los parametros para el producto a publicar
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel mr='5px'>
                <Text size='12pt' fw='500'>
                  Titulo
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Titulo de la Publicacion'
                  value={paramsToSend[currentIndex].title || ""}
                  onChange={(e) => titleFunc(e.target.value)}
                  maxLength={60}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Precio
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Precio para la publicacion'
                  value={paramsToSend[currentIndex].price}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Estatus de la Publicacion
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  value={paramsToSend[currentIndex].pausePublication}
                  onChange={(e) => {
                    pausePublicationFunc(e.target.value);
                  }}
                >
                  <option value=''>Seleccionar</option>
                  <option value='active'>Activa</option>
                  <option value='paused'>Pausada</option>
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Marca de Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='La marca para el producto'
                  value={paramsToSend[currentIndex].brand}
                  onChange={(e) => brandFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Modelo del Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Modelo del producto'
                  value={paramsToSend[currentIndex].model}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Tipo de Garantia
                </Text>
                <FormProduct.Select
                  value={paramsToSend[currentIndex].warranty_type}
                  onChange={(e) => warrantyTypeFunc(e.target.value)}
                >
                  <option value=''>Seleccionar</option>
                  <option value='Garantía del vendedor'>Garantía del vendedor</option>
                  <option value='Garantía de fábrica'>Garantía de fábrica</option>
                  <option value='Sin garantía'>Sin garantía</option>
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Tiempo expresado dias o meses
                </Text>
               <div style={{display:'flex', width: '100%'}}>
                <FormProduct.Input
                    placeholder='Ex: 90'
                    value={paramsToSend[currentIndex].warranty_value ? paramsToSend[currentIndex].warranty_value.replace(/\D/g, '') : ''}
                    onChange={(e) => warrantyValueFunc(e.target.value)}
                  />
                  <FormProduct.Select onChange={(e)=> concatUnit(e.target.value)}>
                    <option value="">Sin unidad de tiempo</option>
                    <option value="dias">Días</option>
                    <option value="meses">Meses</option>
                    <option value="años">Años</option>
                  </FormProduct.Select>
               </div>
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='160px'>
                <Text size='12pt' fw='500'>
                  Peso P
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Peso para el producto'
                  value={paramsToSend[currentIndex].weight}
                  onChange={(e) => weightFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='160px'>
                <Text size='12pt' fw='500'>
                  Altura P
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Altura para el producto'
                  value={paramsToSend[currentIndex].height}
                  onChange={(e) => heightFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='160px'>
                <Text size='12pt' fw='500'>
                  Ancho P
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Ancho para el producto'
                  value={paramsToSend[currentIndex].width}
                  onChange={(e) => widthFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='160px'>
                <Text size='12pt' fw='500'>
                  Longitud P
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Longitud para el producto'
                  value={paramsToSend[currentIndex].length_prod}
                  onChange={(e) => length_prodFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel>
                <Text size='12pt' fw='500'>
                  Descripcion del Producto
                </Text>
                <FormProduct.TextArea
                  placeholder='Descripcion para el producto'
                  value={paramsToSend[currentIndex].body}
                  onChange={(e) => bodyFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              {blockField ? (
                paramsToSend[currentIndex].imagesPlus.length > 0 && (
                  <FormProduct.ContImgPlus>
                    {paramsToSend[currentIndex].imagesPlus.map((v, i) => {
                      return (
                        <FormProduct.ImgPlus
                          url={v}
                          key={i}
                        ></FormProduct.ImgPlus>
                      );
                    })}
                  </FormProduct.ContImgPlus>
                )
              ) : (
                <>
                  <form
                    onSubmit={(e) => addImgPlus(e)}
                    style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                  >
                    <FormProduct.ContInputAndLabel>
                      <Text size='12pt' fw='500'>
                        Imagenes adicionales
                      </Text>
                      <FormProduct.Input
                        placeholder='URLs de la imagen'
                        ref={urlPlusInput}
                        onChange={(e) => setUrlImgPlus(e.target.value)}
                      />
                    </FormProduct.ContInputAndLabel>
                  </form>
                  {!blockField &&
                    paramsToSend[currentIndex].imagesPlus.length > 0 && (
                      <FormProduct.ContImgPlus>
                        {paramsToSend[currentIndex].imagesPlus
                          .split(",")
                          .map((v, i) => {
                            return (
                              <FormProduct.ImgPlus
                                url={v}
                                key={i}
                                onClick={() => removeImg(v)}
                              ></FormProduct.ImgPlus>
                            );
                          })}
                      </FormProduct.ContImgPlus>
                    )}
                </>
              )}
              {/* Campos requeridos de cada categoria*/}

              {categoryAttr.map((e)=>{
                if(e.value_type === 'string' || e.value_type === 'number'){
                  return(
                    <>
                      <FormProduct.ContInputAndLabel w='340px' key={currentIndex+e.id}>
                        <Text size='12pt' fw='500'>
                          {e.name}
                        </Text>
                        <FormProduct.Input
                          type={e.value_type === 'string' ? 'text' : 'number'}
                          placeholder={e.name}
                          value={
                            paramsToSend[currentIndex].customAttributes &&
                            paramsToSend[currentIndex].customAttributes.find(attr => attr.name === e.id)?.value
                          }
                          onChange={(v) => handleDynamicFieldChange(e.id, v.target.value)}
                        />
                        </FormProduct.ContInputAndLabel>
                    </>
                  )
                }
                if(e.value_type === 'list'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={currentIndex+e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name}
                      </Text>
                      <FormProduct.Select name={e.id}
                       value={
                              paramsToSend[currentIndex].customAttributes &&
                              paramsToSend[currentIndex].customAttributes.find(attr => attr.name === e.id)?.value
                            }
                      onChange={(v) => handleDynamicFieldChange(e.id, v.target.value)}
                      >
                          <option value={null}>{e.name}</option>
                        {e.values.map(val =>(
                          <option value={val.name}>{val.name}</option>
                        ))}
                      </FormProduct.Select>
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
                if(e.value_type === 'boolean'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={currentIndex+e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name}
                      </Text>
                      <FormProduct.Select name={e.id}
                       value={
                             paramsToSend[currentIndex].customAttributes &&
                             paramsToSend[currentIndex].customAttributes.find(attr => attr.name === e.id)?.value
                           }
                      onChange={(v) => handleDynamicFieldChange(e.id, v.target.value)}
                      >
                        <option value={null}>Seleccione un valor</option>
                        {e.values.map(val =>(
                          <option value={val.name}>{val.name}</option>
                        ))}
                      </FormProduct.Select>
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
                if(e.value_type === 'number_unit'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={currentIndex+e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name} ({e.default_unit === '"' ? 'Pulgadas' : e.default_unit})
                      </Text>
                      <FormProduct.Input
                        type='number'
                        placeholder={e.name}
                         value={
                             (paramsToSend[currentIndex].customAttributes && 
                              paramsToSend[currentIndex].customAttributes?.find(attr => attr.name === e.id)?.value.replace(/[^\d.-]/g, '') || '')
                          }
                        onChange={(v) => handleDynamicFieldChange(e.id, v.target.value + e.default_unit)}
                      />
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
              })}
            </FormProduct.BodyContForm>
          </FormProduct.Contform>
        </FormProduct.ContForms>
      )}
    </div>
  );
}
