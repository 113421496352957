import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { keyUserId, keyAccessToken } from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";

export default function SyncImagePlus(props) {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    /**
     * Cancel axios requests before unmount
     */
    useEffect(() => {
        return () => {
            axiosCancelTokenSource.cancel("Canceled by unmount");
        };
        //eslint-disable-next-line
    }, []);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Sync the images
     */
    const syncImages = () => {
        const entId = globalContext.currentEnterpriseId;

        if (!entId) return;
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        switch (props.currentPlugin) {
            case "odoo":
                globalContext.setLoaderPage(true);
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${entId}/download-additional-images`, {
                        cancelToken: axiosCancelTokenSource.token,
                        headers,
                    })
                    .then((resp) => {
                        const data = resp.data.data;

                        if (data.monitor) {
                            //If the process ID was received, start monitoring
                            let waitingMonitorResponse = false;
                            const interval = setInterval(() => {
                                if (!waitingMonitorResponse) {
                                    waitingMonitorResponse = true;

                                    axios
                                        .get(
                                            `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                            {
                                                cancelToken: axiosCancelTokenSource.token,
                                                headers,
                                            }
                                        )
                                        .then((resp) => {
                                            const data = resp.data.data;

                                            if (data.advance >= 100) {
                                                clearInterval(interval);
                                                globalContext.showModalWithMsg(
                                                    `Se descargaron y actualizaron ${data.data.savedProductsImages} imagenes de productos y ${data.data.savedVariantsImages} de variantes`
                                                );
                                                props.setNumRefresh(props.numRefresh + 1);
                                                globalContext.setLoaderPage(false);
                                            } else {
                                                const advance = data.advance.toFixed(2);
                                                globalContext.setLoaderPage(true, `${advance}% (${data.data.numProcessed}/${data.data.totalProducts})`);
                                            }
                                        })
                                        .catch((err) => {
                                            handleAxiosError(
                                                err,
                                                "Disculpe, no se pudo culminar la descarga de las imagenes."
                                            );
                                            clearInterval(interval);
                                            globalContext.setLoaderPage(false);
                                        })
                                        .finally(() => {
                                            waitingMonitorResponse = false;
                                        });
                                }
                            }, processCheckIntervalTime);
                        } else {
                            globalContext.showModalWithMsg(
                                `Se descargaron y actualizaron ${data.data.savedProductsImages} imagenes de productos y ${data.data.savedVariantsImages} de variantes`
                            );
                            props.setNumRefresh(props.numRefresh + 1);
                            globalContext.setLoaderPage(false);
                        }
                    })
                    .catch((err) => {
                        handleAxiosError(
                            err,
                            "Disculpe, no se pudo culminar la descarga de las imagenes."
                        );
                        globalContext.setLoaderPage(false);
                    });
                break;
        }
    };

    return (
        <div
            className={`items`}
            onClick={syncImages}
        >
            <span className="ic">
                <FontAwesomeIcon icon={faDownload} />
            </span>
            <p>Descargar Imagenes Adicionales</p>

        </div>
    );
}
