import React, { useContext, useEffect, useState } from "react";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { getAllCredencials } from "../../plugins/woo/functions/credentialsMethods";
import axios from "axios";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import { currentPageCredential } from "../../../../assets/js/SessionStorageNames";

export const SelectCredential = ({ returnValue }) => {
    const globalContext = useContext(GlobalContext);
    const [message, setMessage] = useState(null);
    const [currentCred, setCurrentCred] = useState({});
    const [credsList, setCredsList] = useState([]);

    /**
     * function to extract the host name from a url
     * @param {string} txt
     * @returns
     */
    function extractHostName(txt) {
        const url = new URL(txt);
        const host = url.hostname;
        return host;
    }

    /**
     * function to change the current web credentials
     * @param {*} e
     * @returns
     */
    function changeCurrentWeb(e) {
        if (e === "") return;
        setMessage(null);
        let selected = credsList.filter((item) => item.idPage === parseInt(e));
        setCurrentCred(selected[0]);
        localStorage.setItem(currentPageCredential, selected[0].idPage);
        returnValue(selected[0].idPage);
        setTimeout(() => {
            showMessage(
                `Credencial cambiada a ${extractHostName(selected[0].url)}.`
            );
        }, 100);
    }

    function showMessage(txt, type = "success") {
        setMessage({ text: txt, type });
        setTimeout(() => {
            setMessage(null);
        }, 15000);
    }

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * function to get the credentials of that enterprise
     */
    function getCredentialsData() {
        const ent = globalContext.currentEnterpriseId;
        if (!ent) return;
        getAllCredencials(ent)
            .then(({ data: { data } }) => {
                setCredsList(data);
                if (localStorage.getItem(currentPageCredential)) {
                    let selected = data.filter(
                        (item) =>
                            item.idPage ===
                            parseInt(
                                localStorage.getItem(currentPageCredential)
                            )
                    );
                    console.log(selected[0]);
                    setCurrentCred(selected[0]);
                }
            })
            .catch((err) =>
                handleAxiosError(err, "Error al obtener credenciales")
            );
    }

    useEffect(() => {
        localStorage.setItem(currentPageCredential, "");
        setCurrentCred({});
        getCredentialsData();
        //eslint-disable-next-line
    }, [globalContext.currentEnterpriseId]);

    return (
        <PedidosYaStyle.Header textAlign='flex-end'>
            <PedidosYaStyle.Alert
                className={message ? "active" : ""}
                onClick={() => setMessage(null)}
            >
                <Alert variant={message?.type}>
                    {message?.text}{" "}
                    {message?.type === "success" ? (
                        <FaCheck />
                    ) : (
                        <FaExclamationTriangle />
                    )}
                </Alert>
            </PedidosYaStyle.Alert>
            <PedidosYaStyle.SelectWeb
                height='55px'
                className='w-100'
                value={currentCred?.idPage || ""}
                onChange={(e) => changeCurrentWeb(e.target.value)}
                disabled={credsList.length <= 0 && true}
            >
                <option
                    value=''
                    selected={currentCred?.idPage === "" ? true : false}
                >
                    {credsList.length <= 0
                        ? "Registre Credenciales"
                        : "Seleccione Credencial"}
                </option>
                {credsList.map((res, k) => (
                    <option
                        value={res.idPage}
                        key={k}
                        selected={
                            currentCred?.idPage === res.idPage ? true : false
                        }
                    >
                        {extractHostName(res.url)}
                    </option>
                ))}
            </PedidosYaStyle.SelectWeb>
        </PedidosYaStyle.Header>
    );
};

export default SelectCredential;
