import React from "react";
import ConfigStyled from "../../../../assets/css/components/admin/config/ConfigStyled";
import Eight from "../steps/Eight";
import Five from "../steps/Five";
import Four from "../steps/Four";
import Nine from "../steps/Nine";
import One from "../steps/One";
import Seven from "../steps/Seven";
import Six from "../steps/Six";
import Ten from "../steps/Ten";
import Three from "../steps/Three";
import Two from "../steps/Two";
import Twelve from "../steps/Twelve";
import { Thirteen } from "../steps/Thirteen";

export default function ContRenderStep(props) {
    const { step } = props;

    const renderStep = () => {
        switch (step) {
            case 1:
                return <One />;
            case 2:
                return <Two />;
            case 3:
                return <Three />;
            case 4:
                return <Four />;
            case 5:
                return <Five />;
            case 6:
                return <Six />;
            case 7:
                return <Seven />;
            case 8:
                return <Eight />;
            case 9:
                return <Nine />;
            case 10:
                return <Ten />;
            case 12:
                return <Twelve />;
            case 13:
                return <Thirteen />;

            default:
                return <One />;
        }
    };
    return <ConfigStyled.ContSteps>{renderStep()}</ConfigStyled.ContSteps>;
}
