import React, { useContext, useEffect, useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import handleAxiosError from '../../../update-and-publish/functions/handleAxiosError';
import { getAllCredencials } from '../functions/credentialsMethods';
import { currentPageCredential } from '../../../../../assets/js/SessionStorageNames';

export default function Navbar(props) {
    const {setCurrentStep, currentStep, setIdPage} = props;
    const globalContext = useContext(GlobalContext);
    const [message, setMessage] = useState(null);
    const [currentCred, setCurrentCred] = useState({});
    const [credsList, setCredsList] = useState([]);
    const changeStep = (v) => {
        setCurrentStep(v)
    }
        /**
     * function to get the credentials of that enterprise
     */
        function getCredentialsData() {
            const ent = globalContext.currentEnterpriseId;
            console.log('Interprise', ent)
            if (!ent) return;
            getAllCredencials(ent)
                .then(({ data: { data } }) => {
                    setCredsList(data);
                        console.log(data);
                        console.log('Hubo un console.log aca');
                })
                .catch((err) =>
                    handleAxiosError(err, "Error al obtener credenciales")
                );
        }
    
        useEffect(() => {
            setCurrentCred({});
            getCredentialsData();
            //eslint-disable-next-line
        }, [globalContext.currentEnterpriseId]);
        const handleSelectChange = (e) =>{
            const selectedValue = e.target.value;
            setIdPage(selectedValue)
        }
    return (
        <PedidosYaStyle.ContNavbar>
            <PedidosYaStyle.MenuOptions>
                <PedidosYaStyle.OptionMenu active={currentStep === 4} onClick={() => changeStep(4)}>
                    Crear Campañas pagina web 
                </PedidosYaStyle.OptionMenu>
                <PedidosYaStyle.OptionMenu active={currentStep === 5} onClick={() => changeStep(5)}>
                    Listado de Campañas pagina web
                </PedidosYaStyle.OptionMenu>
            </PedidosYaStyle.MenuOptions>
            {credsList.length ? (<PedidosYaStyle.SelectWeb style={{maxWidth:280, height:50}} onChange={handleSelectChange}>
                <option selected value='' >Seleccione una credencial</option>
                {credsList.map((data)=>(
                    <option key={data.idPage + data.url} value={data.idPage}>{data.url}</option>
                ))}
               
            </PedidosYaStyle.SelectWeb>): <p>No hay credencial registrada</p>}
        </PedidosYaStyle.ContNavbar>
    )
}
