import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import GridStyles from '../../../../../assets/css/components/admin/products/Orders/GridStyles'
import AmazonStyles from '../../../../../assets/css/components/admin/products/amazon/listProducts';
import { Redirect } from 'react-router';
export default function DetailProduct(props) {
    const [redirectTo, setRedirectTo] = useState(null);
    const { 
        plugin,
        isLoader, 
        amazonData, 
        dataPrice, 
        asin, 
        defaultImg, 
        setDefaultImg, 
        setAsin, 
        variantsData, 
        isClothing,
        imgActiveTwo,
        setImgActiveTwo, 
        showVariation,
        setShowVariation,
        setAmazonData,
        setImgActiveVariant
    } = props;
    const [defaulPosition, setDefaultPosition] = useState(0);
    const setDefaultImgFun = (v) => {
        setDefaultImg(v)
    }
    const setAsinFunc = (v) => {
        setAsin(v);
    }
    const setAmazonDataFunc = (v) => {
        setAmazonData(v);
    }
    const setImgActiveVariantFunc = (v) => {
        setImgActiveVariant(v)
    }
    const setImgActiveTwoFunc = (v) => {
        setImgActiveTwo(v)
    }
    const numberFormat = new Intl.NumberFormat('de-DE');

    //redirec to detail
    const redirectDetailt = (idProduct) => {
        setRedirectTo(
            <Redirect to={`/admin/productos/detail-kbt/${idProduct}`} />
        )
    }
    return (
        <GridStyles.stepOne>
            {redirectTo}
            {isLoader ?
                (
                    <GridStyles.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </GridStyles.contLoader>
                )
                :
                (
                    <>
                        {
                                variantsData && (
                                    <div className="variants text-left mb-2" style={{width: '100%', padding: 10}}>
                                        <p className="text-color-light">VARIANTES</p>
                                        <AmazonStyles.contImgManyImgVariants isClothing={isClothing ? true : false} >
                                            {
                                                variantsData.map((v, idx) => {
                                                    if (isClothing) {
                                                        return (
                                                            <AmazonStyles.contImgGridVariants
                                                                className="card-variant"
                                                                key={idx} img={v.images.length > 0 ? v.images[0] : ''}
                                                                sh={idx === imgActiveTwo ? true : false}
                                                                onClick={() => {
                                                                    setShowVariation(v.variations)
                                                                    setImgActiveTwoFunc(idx)
                                                                    setAsinFunc(v.asin)
                                                                    setImgActiveVariantFunc(0)
                                                                    setAmazonDataFunc(v);
                                                                    setDefaultImgFun(v.images[0])
                                                                }
                                                                }
                                                                />
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <AmazonStyles.constDataTextVariant
                                                                key={idx}
                                                                isActive={idx === imgActiveTwo ? true : false}
                                                                onClick={() => {
                                                                    setShowVariation(v.variations)
                                                                    setImgActiveTwoFunc(idx)
                                                                    setAsinFunc(v.asin)
                                                                    setImgActiveVariantFunc(0);
                                                                    setAmazonDataFunc(v);
                                                                    setDefaultImgFun(v.images[0])
                                                                }
                                                                }        
                                                            >
                                                                {
                                                                   v.variations[0]?.name?.es || v.variations[0]?.name?.en
                                                                }
                                                -
                                                                {
                                                                    v.variations[0]?.value?.en ? v.variations[0]?.value?.en : v.variations[0]?.value?.es
                                                                }
                                                            </AmazonStyles.constDataTextVariant>
                                                        )
                                                    }
                                                })

                                            }
                                        </AmazonStyles.contImgManyImgVariants>
                                    </div>
                                )
                                }
                        <GridStyles.stepOneHeader style={{marginBottom: 10}}>
                            <GridStyles.text fw="400" size="15pt" ml="10px" style={{textAlign: 'justify'}}>
                                {plugin === "ml" ? 
                                    amazonData.title
                                : (amazonData.title) ? amazonData.title.es ? amazonData.title.es : amazonData.title.en : ''}
                            </GridStyles.text>
                        </GridStyles.stepOneHeader>
                        <GridStyles.stepOneBody>
                            <GridStyles.contImgStepOne url={defaultImg} />
                            <GridStyles.contSubImgStepOne>
                                {
                                    amazonData.images?.map((v,i) => {
                                        return (
                                            <GridStyles.subImgStepOne active={defaulPosition === i} key={i} url={v} onClick={() => {setDefaultPosition(i);setDefaultImgFun(v)}}  />
                                        )
                                    })
                                }
                            </GridStyles.contSubImgStepOne>
                            <GridStyles.textAreaCont>
                            <GridStyles.contTwoText>
                                    <GridStyles.textLink href={amazonData.url} target="_black" longText={true} mt="0" mb="0" cl="#7367F0" fw="450">
                                    {`${amazonData.url}`}
                                    </GridStyles.textLink>
                                </GridStyles.contTwoText>
                                <GridStyles.contTwoText onClick={() => redirectDetailt(amazonData._id)}>
                                    <GridStyles.text mt="0" mb="0" style={{cursor: 'pointer'}} >
                                        SKU-
                                    </GridStyles.text>
                                    <GridStyles.text style={{cursor: 'pointer'}} mt="0" mb="0" cl="#7367F0" fw="450">
                                        {amazonData.asin}
                                    </GridStyles.text>
                                </GridStyles.contTwoText>
                                {amazonData.weight &&
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            PESO-
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {`${amazonData.weight ? amazonData.weight : ''} LBS`}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                }
                                {amazonData.dimensions && 
                                    <>
                                        <GridStyles.contTwoText>
                                            <GridStyles.text mt="0" mb="0">
                                                DIMENSIONES-
                                            </GridStyles.text>
                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {
                                                // amazonData.dimensions && (
                                                    `
                                                    LONG-${amazonData.dimensions?.length ? amazonData.dimensions?.length : ''}
                                                    L-${amazonData.dimensions?.width ? amazonData.dimensions?.width : ''}
                                                    A-${amazonData.dimensions?.height ? amazonData.dimensions?.height : ''}
                                                    `
                                                // )
                                            }
                                            </GridStyles.text>
                                        </GridStyles.contTwoText>
                                        <GridStyles.contTwoText>
                                            <GridStyles.text mt="0" mb="0">
                                                PESO V-
                                            </GridStyles.text>
                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {amazonData.dimensions && (`${(((parseFloat(amazonData.dimensions.height ? amazonData.dimensions.height : 0) *parseFloat(amazonData.dimensions.width ? amazonData.dimensions.width : 0) * parseFloat(amazonData.dimensions.length ? amazonData.dimensions.length : 0)) * 1.2) / 166).toString().slice(0,5)} (LBS) `)}
                                            </GridStyles.text>
                                        </GridStyles.contTwoText>
                                    </>
                                }
                                {plugin !== "ml" && 
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            PRECIO PROVEDOR -
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {`$${amazonData.price}`}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                }
                                {amazonData.deliveryDays && 
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            TIEMPO DE ENVIO -
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                            {`${amazonData.deliveryDays ? amazonData.deliveryDays : 0} D`}
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                }

                                <GridStyles.contTwoText>
                                    <GridStyles.text mt="0" mb="0">
                                        PRECIO USD -
                                    </GridStyles.text>
                                    <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {plugin === "ml" ? 
                                            `$${amazonData.price}`
                                            :
                                            <>
                                                {dataPrice ?
                                                    (!dataPrice.variants) ?
                                                        `$ ${numberFormat.format(dataPrice.totalUsd)}`
                                                    : dataPrice.variants.map( v => {
                                                        if (v.asin === asin) {
                                                            return `$${numberFormat.format(v.totalUsd)}`
                                                        }
                                                    })
                                                    : ""
                                                }
                                            </>
                                        }
                                    </GridStyles.text>
                                </GridStyles.contTwoText>
                                {dataPrice && <>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            GANANCIA USD -
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {
                                            dataPrice ?
                                            (!dataPrice.variants) ?
                                            `$ ${numberFormat.format(dataPrice.earningUsd)}`
                                            :
                                            dataPrice.variants.map( v => {
                                                if (v.asin === asin) {
                                                    return `$${numberFormat.format(v.earningUsd)}`
                                                }
                                            })
                                            :""
                                        }
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                    <GridStyles.contTwoText>
                                        <GridStyles.text mt="0" mb="0">
                                            TOTAL(COL/BFS) -
                                        </GridStyles.text>
                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                        {
                                            dataPrice ?
                                            (!dataPrice.variants) ?
                                            `$ ${numberFormat.format(dataPrice.total)}`
                                            :
                                            dataPrice.variants.map( v => {
                                                if (v.asin === asin) {
                                                    return `${numberFormat.format(v.total)}`
                                                }
                                            })
                                            :""
                                        }
                                        </GridStyles.text>
                                    </GridStyles.contTwoText>
                                </>}

                            </GridStyles.textAreaCont>

                            {amazonData.details && 
                                <>
                                    <GridStyles.contDataText>
                                        <GridStyles.text mt="0" mb="0">
                                            DETALLES
                                        </GridStyles.text>
                                    </GridStyles.contDataText>
                                    <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                        <GridStyles.listItemsinfo>
                                            {plugin === 'ml' ? amazonData.details :
                                            (amazonData.details !== undefined) &&
                                                (amazonData.details.additionalInfo !== undefined) ?
                                                    amazonData.details.additionalInfo.additionalInfo.map((v,i) => {
                                                        return (
                                                                <GridStyles.listIteminfo key={i}>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0" fw="500">
                                                                        {v.name.es}
                                                                    </GridStyles.text>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0">
                                                                        {`- ${v.value.es}`}
                                                                    </GridStyles.text>
                                                                </GridStyles.listIteminfo>
                                                        )
                                                    })
                                                    :
                                                    (amazonData.details !== undefined && amazonData.details.productDetails !== undefined) && 
                                                        amazonData.details.productDetails.map((v,i) => {
                                                            return (
                                                                    <GridStyles.listIteminfo key={i}>
                                                                        <GridStyles.text style={{width: '100%'}} mt="0" mb="0" fw="500">
                                                                            {v.name.es}
                                                                        </GridStyles.text>
                                                                        <GridStyles.text style={{width: '100%'}} mt="0" mb="0">
                                                                            {`- ${v.value.es}`}
                                                                        </GridStyles.text>
                                                                    </GridStyles.listIteminfo>
                                                            )
                                                            })
                                            }
                                        </GridStyles.listItemsinfo>
                                    </GridStyles.textAreaCont>

                                    <GridStyles.contDataText>
                                        <GridStyles.text mt="0" mb="0">
                                            DETALLES TECNICOS
                                        </GridStyles.text>
                                    </GridStyles.contDataText>
                                    <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                        <GridStyles.listItemsinfo>
                                            {
                                                (amazonData.details !== undefined) &&
                                                    amazonData.details.additionalInfo &&
                                                    amazonData.details.additionalInfo.technicalDetails &&
                                                        amazonData.details.additionalInfo.technicalDetails.map((v,i) => {
                                                            return (
                                                                <GridStyles.listIteminfo key={i}>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0" fw="500">
                                                                        {v.name.es}
                                                                    </GridStyles.text>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0">
                                                                        {`- ${v.value.es}`}
                                                                    </GridStyles.text>
                                                                </GridStyles.listIteminfo>
                                                    )
                                                })
                                            }
                                        </GridStyles.listItemsinfo>
                                    </GridStyles.textAreaCont>
                                </>
                            }
                            {amazonData.features && <>
                                <GridStyles.contDataText>
                                    <GridStyles.text mt="0" mb="0">
                                        CARACTERISTICAS
                                    </GridStyles.text>
                                </GridStyles.contDataText>
                                <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                    <GridStyles.listItemsinfo>
                                        {amazonData.features &&
                                            amazonData.features.map((v,i) => {
                                                return (
                                                        <GridStyles.listIteminfo key={i}>
                                                            <GridStyles.text style={{width: '100%'}} mt="0" mb="0" fw="500">
                                                                {v.es}
                                                            </GridStyles.text>
                                                        </GridStyles.listIteminfo>
                                                    )
                                            })
                                        }
                                    </GridStyles.listItemsinfo>
                                </GridStyles.textAreaCont>
                            </>}
                            
                            {
                                showVariation &&
                                    (
                                        <>
                                        <GridStyles.contDataText>
                                            <GridStyles.text mt="0" mb="0">
                                                VARIACIONES
                                            </GridStyles.text>
                                        </GridStyles.contDataText>
                                        <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                            <GridStyles.listItemsinfo>
                                                {
                                                    showVariation.map((v,i) => {
                                                        return (
                                                                <GridStyles.listIteminfo key={i}>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0" fw="500">
                                                                        {v.name.es || v.name.en}
                                                                    </GridStyles.text>
                                                                    <GridStyles.text style={{width: '100%'}} mt="0" mb="0">
                                                                        {`- ${v.value.es || v.value.en}`}
                                                                    </GridStyles.text>
                                                                </GridStyles.listIteminfo>
                                                        )
                                                    })
                                                }
                                        </GridStyles.listItemsinfo>
                                        </GridStyles.textAreaCont>
                                        </>
                                    )
                            }

                            {amazonData.desc && 
                                <>
                                    <GridStyles.contDataText>
                                        <GridStyles.text mt="0" mb="0">
                                            DESCRIPCION
                                        </GridStyles.text>
                                    </GridStyles.contDataText>
                                    <GridStyles.textAreaCont style={{ padding: '10px' }}>
                                        <GridStyles.text mt="0" mb="0" style={{ textAlign: 'justify' }}>
                                        {amazonData.desc ? amazonData.desc.es : ''}
                                        </GridStyles.text>
                                    </GridStyles.textAreaCont>
                                </>
                            }
                        </GridStyles.stepOneBody>
                    </>
                )
            }
        </GridStyles.stepOne>
    )
}
