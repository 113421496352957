import React, { useContext, useEffect, useState } from "react";
import PedidosYaStyle from "../../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import Navbar from "../components/Navbar";
import CredentialsWoo from "../components/CredentialsWoo";
import UsuariosWoo from "../components/UsuariosWoo";
import Productos from "../components/Productos";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import {
    addNewCredential,
    getAllCredencials,
    deleteCredential,
    getEnterpriseConfig,
} from "../functions/credentialsMethods";
import axios from "axios";
import { Button } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import {
    FaCheck,
    FaExclamationTriangle,
    FaPlus,
    FaTrash,
} from "react-icons/fa";
import { BiHide, BiShow } from "react-icons/bi";
import { Alert, Form, InputGroup, Modal } from "react-bootstrap";
import { currentPageCredential } from "../../../../../assets/js/SessionStorageNames";
import verifyAccessRole from "../../../../../assets/js/verifyAccessRole";
import Routes from "../../../../../Routes";
import { Redirect } from "react-router-dom";
import ModalDetailProduct from "../../../../general/ModalDetailProduct";

export default function FormIndexWoo() {
    const globalContext = useContext(GlobalContext);
    const [currentStep, setCurrentStep] = useState(1); //change to one
    const [currentCred, setCurrentCred] = useState({});
    const [credsList, setCredsList] = useState([]);
    const [modal, setModal] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [version, setVersion] = useState(null);
    const [consumerKey, setConsumerKey] = useState(null);
    const [consumerSecret, setConsumerSecret] = useState(null);
    const [url, setUrl] = useState(null);
    const [wpUser, setWpUser] = useState("");
    const [wpPass, setWpPass] = useState("");
    const [message, setMessage] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewConfig, setShowNewConfig] = useState(false);
    const [website, setWebsite] = useState('');
    const [source, setSource] = useState('');
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 65);
        if(!hasAccess || globalContext.parentEntId) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[globalContext.parentEntId]);

    /**
     * function to extract the host name from a url
     * @param {string} txt
     * @returns
     */
    function extractHostName(txt) {
        const url = new URL(txt);
        const host = url.hostname;
        return host;
    }

    /**
     * function to change the current web credentials
     * @param {*} e
     * @returns
     */
    function changeCurrentWeb(e) {
        if (e === "" || !showNewConfig) return;
        setMessage(null);
        let selected = credsList.filter((item) => item.idPage === parseInt(e));
        setCurrentCred(selected[0]);
        localStorage.setItem(currentPageCredential, selected[0].idPage);
        setTimeout(() => {
            showMessage(
                `Credencial cambiada a ${extractHostName(selected[0].url)}.`
            );
        }, 100);
    }

    /**
     * function to show the modal
     */
    function modalNewCredential() {
        if (!showNewConfig) return;
        setModal(!modal);
    }

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * function to get the credentials of that enterprise
     */
    function getCredentialsData() {
        const ent = globalContext.currentEnterpriseId;
        if (!ent || !showNewConfig) return;
        getAllCredencials(ent)
            .then(({ data: { data } }) => {
                setCredsList(data);
                if (localStorage.getItem(currentPageCredential)) {
                    let selected = data.filter(
                        (item) =>
                            item.idPage ===
                            parseInt(
                                localStorage.getItem(currentPageCredential)
                            )
                    );
                    console.log(selected[0]);
                    setCurrentCred(selected[0]);
                }
            })
            .catch((err) =>
                handleAxiosError(err, "Error al obtener credenciales")
            );
    }

    function showMessage(txt, type = "success") {
        setMessage({ text: txt, type });
        setTimeout(() => {
            setMessage(null);
        }, 15000);
    }

    function isValidHttpUrl(string) {
        try {
            const url = new URL(string);
            return url.protocol === "http:" || url.protocol === "https:";
        } catch (err) {
            return false;
        }
    }

    function addCredential() {
        if (!showNewConfig) return;
        const enterpriseId = globalContext.currentEnterpriseId;
        const valUrl = isValidHttpUrl(url);
        if (!enterpriseId) return;

        if (!valUrl) {
            showMessage(
                "Debe ingresar un formato válido para una URL.",
                "danger"
            );
            return;
        }

        const data = {
            url,
            consumerKey,
            consumerSecret,
            version,
            enterpriseId,
            wpPass,
            wpUser,
            source,
            website,
        };
        if (url && version && enterpriseId) {
            addNewCredential(data)
                .then(({ data }) => {
                    const newCreds = [...credsList, data.data];
                    setCredsList(newCreds);
                    setUrl(null);
                    setConsumerKey(null);
                    setConsumerSecret(null);
                    setVersion(null);
                    modalNewCredential();
                    showMessage("Credencial registrada correctamente.");
                })
                .catch((err) => {
                    handleAxiosError(err, "Error al obtener credenciales");
                    showMessage(err.msg, "danger");
                });
        }
    }

    function DeleteCredential() {
        if (!showNewConfig) return;
        const enterpriseId = globalContext.currentEnterpriseId;
        const error = "danger";
        if (!enterpriseId)
            return showMessage("Debe seleccionar una empresa", error);
        if (!currentCred?.idPage)
            return showMessage("Debe seleccionar unas credenciales", error);
        setShowDeleteModal(true);
    }

    function eliminarCredencial() {
        if (!showNewConfig) return;
        const enterpriseId = globalContext.currentEnterpriseId;
        const { idPage } = currentCred;
        const error = "danger";
        if (!enterpriseId)
            return showMessage("Debe seleccionar una empresa", error);
        if (!currentCred?.idPage)
            return showMessage("Debe seleccionar unas credenciales", error);

        deleteCredential({ enterpriseId, idPage })
            .then(({ data }) => {
                if (data.data.modifiedCount < 1) {
                    showMessage(
                        "Error al intentar eliminar la credencial. ",
                        error
                    );
                    return;
                }
                if (data.data.modifiedCount > 0) {
                    localStorage.setItem(currentPageCredential, "");
                    const nuevoArray = credsList.filter(
                        (obj) => obj.idPage !== currentCred?.idPage
                    );
                    setCredsList(nuevoArray);
                    setShowDeleteModal(false);
                    showMessage("Credenciales eliminadas correctamente");
                }
            })
            .catch((err) => {
                console.log(err.response);
                showMessage(
                    "Error al intentar eliminar la credencial. ",
                    error
                );
            });
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <CredentialsWoo showNewConfig={showNewConfig} isValidHttpUrl={isValidHttpUrl} currentCred={currentCred}/>;
            case 2:
                return <UsuariosWoo />;
            case 3:
                return <Productos showNewConfig={showNewConfig} />;
            default:
                //return (<SectionsList />)
                return <CredentialsWoo showNewConfig={showNewConfig} isValidHttpUrl={isValidHttpUrl} currentCred={currentCred}/>;
        }
    };

    async function getConfig() {
        const enterpriseId = globalContext.currentEnterpriseId;
        if (!enterpriseId) return;
        await getEnterpriseConfig({
            enterpriseId: enterpriseId,
            plugin: "wc",
        })
            .then((res) => {
                const newConfig =
                    res.data?.data?.configData?.newConfig || false;
                console.log("newConfig: ", newConfig);
                setShowNewConfig(newConfig);
            })
            .catch((err) => {
                console.log(
                    "Error consultando la configuración: ",
                    err.response?.data
                );
            });
    }

    useEffect(() => {
        getConfig();
        setUrl("");
        setVersion("");
        localStorage.setItem(currentPageCredential, "");
        setCurrentCred({});
        getCredentialsData();
        //eslint-disable-next-line
    }, [globalContext.currentEnterpriseId, showNewConfig]);

    return (
        <>
            {showNewConfig ? (
                <Modal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            ¡¿Desea Eliminar la Credencial?!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Ésta acción eliminará la credencial seleccionada de
                            manera permanente, ésta acción es irreversible y
                            para volver a utilizarla deberá registrarla
                            nuevamente.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color='secondary'
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cerrar
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => eliminarCredencial()}
                        >
                            Eliminar Credencial
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                ""
            )}
            <PedidosYaStyle.Cont>
                {redirect}
                <div class='row w-100'>
                    <div class='col col-12 col-md-5'>
                        <PedidosYaStyle.Header>
                            <PedidosYaStyle.Text size='15pt' fw='500'>
                                Configuracion para Woocommerce
                            </PedidosYaStyle.Text>
                        </PedidosYaStyle.Header>
                    </div>
                    {showNewConfig ? (
                        <div className='col col-12 col-md-7'>
                            <div className='row w-100'>
                                <div class='col col-12 col-md-8 col-lg-9 col-xl-7'>
                                    <PedidosYaStyle.Header textAlign='flex-end'>
                                        <PedidosYaStyle.Alert
                                            className={message ? "active" : ""}
                                            onClick={() => setMessage(null)}
                                        >
                                            <Alert variant={message?.type}>
                                                {message?.text}{" "}
                                                {message?.type === "success" ? (
                                                    <FaCheck />
                                                ) : (
                                                    <FaExclamationTriangle />
                                                )}
                                            </Alert>
                                        </PedidosYaStyle.Alert>
                                        <PedidosYaStyle.SelectWeb
                                            height='55px'
                                            className='w-100'
                                            value={currentCred?.idPage || ""}
                                            onChange={(e) =>
                                                changeCurrentWeb(e.target.value)
                                            }
                                            disabled={
                                                credsList.length <= 0 && true
                                            }
                                        >
                                            <option
                                                value=''
                                                selected={
                                                    currentCred?.idPage === ""
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {credsList.length <= 0
                                                    ? "Registre Credenciales"
                                                    : "Seleccione Credencial"}
                                            </option>
                                            {credsList.map((res, k) => (
                                                <option
                                                    value={res.idPage}
                                                    key={k}
                                                    selected={
                                                        currentCred?.idPage ===
                                                        res.idPage
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {extractHostName(res.url)}
                                                </option>
                                            ))}
                                        </PedidosYaStyle.SelectWeb>
                                    </PedidosYaStyle.Header>
                                </div>
                                <div className='col-12 col-md-4 col-lg-3 col-xl-5 pb-3 pb-md-0 pt-0 pt-md-2'>
                                    <div className='d-flex'>
                                        <Button
                                            width='100%'
                                            height='55px'
                                            className='d-flex w-100 mr-1'
                                            minWidth='0px'
                                            onClick={modalNewCredential}
                                        >
                                            <div className='d-none d-xl-block'>
                                                Nueva Credencial&nbsp;
                                            </div>
                                            <div>
                                                <FaPlus />
                                            </div>
                                        </Button>
                                        <Button
                                            width='100%'
                                            height='55px'
                                            className='d-flex w-100'
                                            minWidth='0px'
                                            onClick={DeleteCredential}
                                        >
                                            <div className='d-none d-xl-block'>
                                                Borrar Credencial&nbsp;
                                            </div>
                                            <div>
                                                <FaTrash />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                <Navbar
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
                <PedidosYaStyle.ContStep>
                    {renderStep()}
                </PedidosYaStyle.ContStep>
            </PedidosYaStyle.Cont>
            {showNewConfig && (
                <ModalDetailProduct
                    show={modal}
                    handleModal={modalNewCredential}
                    title='Ingrese los datos para registrar las credenciales de su nueva tienda en woocommerce'
                    size='lg'
                >
                    <Form>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <Form.Group
                                        className='mb-3 w-100'
                                        controlId='exampleForm.ControlInput1'
                                    >
                                        <Form.Label>
                                            Clave del cliente
                                        </Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                type={
                                                    showPass
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder='Escriba aqui su clave del cliente'
                                                onChange={(e) =>
                                                    setConsumerKey(
                                                        e.target.value
                                                    )
                                                }
                                                value={consumerKey}
                                            />
                                            <InputGroup.Text
                                                onClick={() =>
                                                    setShowPass(!showPass)
                                                }
                                            >
                                                {showPass ? (
                                                    <BiHide />
                                                ) : (
                                                    <BiShow />
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group
                                        className='mb-3 w-100'
                                        controlId='exampleForm.ControlInput1'
                                    >
                                        <Form.Label>
                                            Clave secreta de cliente
                                        </Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                type={
                                                    showPass
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder='Escriba aqui su clave secreta de cliente'
                                                onChange={(e) =>
                                                    setConsumerSecret(
                                                        e.target.value
                                                    )
                                                }
                                                value={consumerSecret}
                                            />
                                            <InputGroup.Text
                                                onClick={() =>
                                                    setShowPass(!showPass)
                                                }
                                            >
                                                {showPass ? (
                                                    <BiHide />
                                                ) : (
                                                    <BiShow />
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>
                                            Url de la pagina
                                        </Form.Label>
                                        <Form.Control
                                            placeholder='Escriba aqui el host de acceso'
                                            onChange={(e) =>
                                                setUrl(e.target.value)
                                            }
                                            value={url}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>Vesion del API</Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                type='text'
                                                placeholder='Escriba aqui la versión de acceso (wc/v3)'
                                                onChange={(e) =>
                                                    setVersion(e.target.value)
                                                }
                                                value={version}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>
                                            Usuario wordpress
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                type='text'
                                                placeholder='Escriba aqui el usuario de wordpress'
                                                onChange={(e) =>
                                                    setWpUser(e.target.value)
                                                }
                                                value={wpUser}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>
                                            Clave aplicaciones Wordpress
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                type='text'
                                                placeholder='Escriba aqui la clave para aplicaciones'
                                                onChange={(e) =>
                                                    setWpPass(e.target.value)
                                                }
                                                value={wpPass}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>
                                        ID de Página Web en Odoo (website)
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                placeholder='Escriba aqui el ID de Página Web en Odoo (website)'
                                                value={website||''}
                                                type='number'
                                                onChange={(e) => setWebsite(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Form.Group className='mb-3 w-100'>
                                        <Form.Label>
                                            ID de Fuente en Odoo (source)
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                placeholder='Escriba aqui el ID de Fuente en Odoo (source)'
                                                value={source||''}
                                                type='number'
                                                onChange={(e) => setSource(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <PedidosYaStyle.FooterForm>
                        <Button
                            disabled={
                                url && consumerKey && consumerSecret && version
                                    ? false
                                    : true
                            }
                            onClick={addCredential}
                        >
                            Guardar
                        </Button>
                    </PedidosYaStyle.FooterForm>
                </ModalDetailProduct>
            )}
        </>
    );
}
