import React, { useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiDotsVerticalRounded, BiLinkAlt, BiRightArrow, BiOutline, BiPackage } from 'react-icons/bi'
import { BtnNextImg, BtnOptionsCard, ContIconInfo, ContIconsInfo, ContImgCard } from '../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle'
import { FaPinterestP } from "react-icons/fa";
export default function CardContImage({ urlImage, setShowMenu, dataIconInfo }) {
    const [imgPosition, setImgPosition] = useState(0);
    const toggleMenu = () => {
        setShowMenu(true)
    }

    const nextImg = () => {
        const maxImg = urlImage.length - 1;
        if (imgPosition === maxImg) return;
        setImgPosition(imgPosition + 1);
    }
    const backImg = () => {
        if (imgPosition === 0) return;
        setImgPosition(imgPosition - 1);
    }
    return (
        <ContImgCard url={urlImage[imgPosition]}>
            <BtnOptionsCard onClick={() => toggleMenu(true)}>
                <BiDotsVerticalRounded />
            </BtnOptionsCard>
            {
                imgPosition > 0 && (
                    <BtnNextImg onClick={() => backImg()}>
                        <BiChevronLeft />
                    </BtnNextImg>
                )
            }
            {
                (imgPosition < urlImage.length - 1 && urlImage.length > 1) && (
                    <BtnNextImg l="auto" r="10px" onClick={() => nextImg()}>
                        <BiChevronRight />
                    </BtnNextImg>
                )
            }
            <ContIconsInfo>
                {
                    dataIconInfo.isFullyLinked && (
                        <ContIconInfo mr="5px">
                            <BiLinkAlt />
                        </ContIconInfo>
                    ) 
                }
                {
                    
                    (dataIconInfo.peyaInfo !== undefined) &&
                    (dataIconInfo.peyaInfo.status !== undefined) &&
                    !dataIconInfo.peyaInfo.status &&
                    (
                        <ContIconInfo mr="5px">
                            <BiRightArrow />
                        </ContIconInfo>
                    )
                }
                {
                    dataIconInfo.hasVariants && (
                        <ContIconInfo mr="5px">
                            <BiPackage />
                        </ContIconInfo>
                    )

                }
                {
                    dataIconInfo.isVariant && (
                        <ContIconInfo>
                            <BiOutline />
                        </ContIconInfo>
                    )
                }
                {
                    dataIconInfo.peyaInfo && (
                        <ContIconInfo>
                            <FaPinterestP />
                        </ContIconInfo>
                    )
                }
                {
                    dataIconInfo.inCashea && (
                        <ContIconInfo>
                            <img src={require('../../../../../../../assets/img/jpeg/cashea-icon.jpg')} alt="Logo" style={{ width: 20, borderRadius: '50%' }}/>
                        </ContIconInfo>
                    )
                }
                
            </ContIconsInfo>
        </ContImgCard>
    )
}
