import React from 'react';
import Select from 'react-select';
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';

const SelectRate = ({ setRate, rate, label, rates }) => {

    let options = [];
    if(rates){
        options = rates.map(rt => ({
            value: rt.id,
            label: rt.name,
        }));
    }

    

    return (
        <div style={{ marginBottom: '15px' }}>
            <RC_Style.Label htmlFor="rate">Seleccionar tarifa {label}</RC_Style.Label>
            <Select
                id="rate"
                value={rate !== undefined ? options.find(option => option.value === rate.value) : null}
                onChange={(selectedOption) => setRate(selectedOption)}
                options={options}
                styles={RC_Style.customStyles}
                isClearable={false}
            />
        </div>
    );
};

export default SelectRate;