import axios from "axios";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../../assets/js/SessionStorageNames";
export const sendOdooAttr = (enterpriseId, idPage) => {
    const res = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/send-attributes-odoo-woo`,
        {
            enterpriseId,
            ...(idPage && { idPage: idPage }),
        },
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    );
    return res;
};

export default sendOdooAttr;
