import axios from "axios";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../../assets/js/SessionStorageNames";

const getCategoriesOdoo = (
    limitPerPage = 10,
    currentPage,
    enterpriseId,
    {token},
    searchVal ='',
    pluginSearch
) => {
    let cont = {
        entId: enterpriseId,
        pageNumber: currentPage,
        pageItemCount: limitPerPage,
        ...(pluginSearch && {pluginSearch})
    };

    const find = {
        searchVal
    };
    cont = searchVal !== '' ? Object.assign(find, cont) : cont;
    const res = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCategoriesList`,
        cont,
        {
            cancelToken: token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    );
    return res;
};

export const getCategory = ( categoryId, enterpriseId, axiosCancelTokenSource, pluginSearch ) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/odooCategory`;
    const res = axios.post(
        url,
        {
            entId: enterpriseId,
            cat: categoryId,
            ...(pluginSearch && {pluginSearch})
        },
        {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
    )
    return res;
};

export default getCategoriesOdoo;
