import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from "react-router-dom";
import Routes from "../../../../../Routes";
import verifyAccessRole from "../../../../../assets/js/verifyAccessRole";
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import Navbar from '../components/Navbar';
import FormuserFtp from '../components/FormuserFtp';
import SucursalList from '../components/SucursalList'
import getEnterprise from '../functions/getEnterprises';
import FormSucursal from '../components/FormSucursal';
import SectionsList from '../components/SectionsList';
import FormSections from '../components/FormSections';
import RestaurantsList from '../components/RestaurantsList';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
export default function FormIndex() {
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [StatusDropDown, setStatusDropDown] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);//change to one
    const [currentSucursal, setCurrentSucursal] = useState(null);
    const [sections, setSections] = useState([]);
    const [currentSections, setCurrentSections] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 45);
        if(!hasAccess || globalContext.parentEntId) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
      },[globalContext.parentEntId]);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (<FormuserFtp StatusDropDown={StatusDropDown} />)
            case 2:
                return (
                    <SucursalList
                        setCurrentStep={setCurrentStep}
                        currentSucursal={currentSucursal}
                        setCurrentSucursal={setCurrentSucursal}
                    />)
            case 3:
                return (
                    <FormSucursal
                        StatusDropDown={StatusDropDown}
                        setCurrentStep={setCurrentStep}
                        currentSucursal={currentSucursal}
                        setCurrentSucursal={setCurrentSucursal}
                    />
                )
            case 4:
                return (
                    <SectionsList
                        sections={sections}
                        setSections={setSections}
                        setCurrentStep={setCurrentStep}
                        setCurrentSections={setCurrentSections}
                    />)
            case 5:
                return (
                    <FormSections
                        setCurrentStep={setCurrentStep}
                        sections={sections}
                        setSections={setSections}
                        currentSections={currentSections}
                    />
                    )
            case 6:
                return (
                    <RestaurantsList />
                    )
            default:
                //return (<SectionsList />)
                return (<FormuserFtp StatusDropDown={StatusDropDown} />);
        }
    }

    useEffect(() => {
        getEnterprise()
            .then((res) => {
                const data = res.data.data.enterprises;
                setStatusDropDown(data);
            })
            .catch(() => {
                console.log('No posee empresas')
            });


    }, []);
    return (
        <PedidosYaStyle.Cont>
            {redirect}
            <PedidosYaStyle.Header>
                <PedidosYaStyle.Text size="15pt" fw="500">
                    Configuracion para PedidosYa
                </PedidosYaStyle.Text>
            </PedidosYaStyle.Header>
            <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <PedidosYaStyle.ContStep>
                {renderStep()}
            </PedidosYaStyle.ContStep>
        </PedidosYaStyle.Cont>
    )
}
