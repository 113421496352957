import styled from "styled-components";
const ProductsAssocStyle = styled.div`
  height: 100% !important;
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  body {
    display: grid;
    font-family: "Roboto Slab", san-serif !important;
    text-transform: uppercase;
  }
  .list-products-asocc {
    background-color: white;
    border: 1px solid #e5e9f2;
    border-radius: 0.25rem;
    visibility: visible;
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 150px !important;
    max-height: 500px !important;
    height: 100% !important;
    overflow: hidden scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .nv {
    color: rgb(94, 84, 142);
  }
  .dv {
    color: gray;
  }
  .nv,
  .dv {
    margin-bottom: 0.2rem;
    font-size: 10pt;
  }
  .img-variant {
    min-width: 70px !important;
    max-width: 70px !important;
    width: 70px !important;
    height: 70px !important;
  }
  button {
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    width: 40px;
    height: 40px;
    margin-left: 5%;
    background: transparent;
    border-radius: 50%;
    transition: all ease 450ms;
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }

  .tagHeader {
    font-size: 8pt;
    color: rgb(94, 84, 142);
    font-weight: bolder;
  }
  input {
    outline: none;
    transition: all ease 450ms;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 0.5px solid #5e548e;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    &:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      color: #5e548e;
      border-color: #cec28e;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  /**
  *? styles table
   */
  .limiter,
  .limiterHeader {
    width: 100%;
    margin: 0 auto;
  }
  .ActionsContent {
    padding: 8px 0;
  }
  .colHeader {
    padding: 10px 10px;
  }
  .linkProduct {
    color: #808080;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #5e548e;
    }
  }
  .container-table100,
  .container-header {
    width: 100%;
    min-height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 15px;
  }
  .wrap-table100 {
    width: 700px;
  }
  .ActionsContent {
    border: 1px solid #e5e9f2;
    background: white;
    border-radius: 10px;
    padding: 0 5px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table * {
    position: relative;
  }
  table td,
  table th {
    padding-left: 8px;
  }
  table thead tr {
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #5E548E;
  }
  table tbody tr {
    height: 55px;
    padding: 10px 0;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td,
  table th {
    text-align: left;
  }
  table td.l,
  table th.l {
    text-align: right;
  }
  table td.c,
  table th.c {
    text-align: center;
  }
  table td.r,
  table th.r {
    text-align: center;
  }

  .table100-head th {
    font-size: 15px;
    font-weight: bold;
    color: #5e548e;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px;
    line-height: 1.2;
    font-weight: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tbody tr {
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
    box-shadow: rgba(0, 0, 0, 0.11) 0 1px;
  }

  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
  }
  .column1 img {
    min-width: 60px !important;
    width: 60px !important;
    max-width: 60px !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .column1 {
    width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .column2 {
    width: 170px;
  }

  .column3 {
    width: 100px;
  }
  .column4 {
    width: 100px;
  }
  .column5 {
    width: 100px;
  }
  .column6 {
    width: 100px;
  }
  .column7 {
    width: 50px;
    padding-right: 20px;
  }

  @media screen and (max-width: 992px) {
    table {
      display: block;
    }
    table > *,
    table tr,
    table td,
    table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-size: 14px;
      color: #999999;
      line-height: 1.2;
      font-weight: unset;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "IMAGEN";
    }
    table tbody tr td:nth-child(2):before {
      content: "PROD";
    }
    table tbody tr td:nth-child(3):before {
      content: "PLUGIN";
    }
    table tbody tr td:nth-child(4):before {
      content: "MUL. PRECIO";
    }
    table tbody tr td:nth-child(5):before {
      content: "MAX STOCK";
    }
    table tbody tr td:nth-child(5):before {
      content: "DIV. STOCK";
    }
    table tbody tr td:nth-child(6):before {
      content: "";
    }

    .column1,
    .column2,
    .column3,
    .column4,
    .column5,
    .column6 {
      width: 100%;
    }
    .column7 {
      text-align: center;
    }

    tbody tr {
      font-size: 14px;
    }
  }

  @media (max-width: 576px) {
    .container-header {
      padding: 10px 0;
    }

    .container-table100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  /**
  * ? end styles table
   */
`;
export default ProductsAssocStyle;
