import React from 'react'
import FormProduct from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct'
import { Text } from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
export default function ViewProductsAfterToPost({ isLoad, errors, goods, data }) {
  console.log('porque?', errors);
  console.log('goods', goods);
  console.log('data', data);
  return (
    <FormProduct.Contform>
      <FormProduct.HeaderContForm>
        <Text size="13pt" fw="500" cl="#FFFFFF">
          {
            isLoad ?
            'Proceso Finalizado' : 'Cargando...'
          }
          
        </Text>
      </FormProduct.HeaderContForm>
      <FormProduct.BodyContForm>
        <FormProduct.ContInputAndLabel>
          <Text size="14pt" fw="500">
            Total de productos Publicados
          </Text>
          {/* <Text size="12pt" fw="500">
            {
              isLoad ?
              goods?.length
              :
              'Cargando'
            }
          </Text> */}
        </FormProduct.ContInputAndLabel>
        <FormProduct.ContInputAndLabel>
          <Text size="14pt" fw="500">
            Total de productos con errores
          </Text>
          {/* <Text size="12pt" fw="500">
            {
              isLoad ?
                errors.length
                : 'Cargando'
          }
          </Text> */}
        </FormProduct.ContInputAndLabel>
        {/* {
          (errors.length > 0) &&
          errors.map((v, i) => {
            return (
              <FormProduct.ContInputAndLabel b="1px solid #a2abbd" key={i} mb="10px">
                <Text size="12pt" fw="500">
                  {v.title}
                </Text>
                <Text size="12pt" fw="500">
                  {v.message}
                </Text>
                <Text size="12pt" fw="500">
                  {v.error}
                </Text>
              </FormProduct.ContInputAndLabel>
            )
          })
        } */}
      </FormProduct.BodyContForm>
    </FormProduct.Contform>
  )
}
