import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';

const getAllCategoriesCashea = (entId, currentPage, pageItemCount, filter, axiosCancelTokenSource) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-categories/get-categories`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
}

export default getAllCategoriesCashea;