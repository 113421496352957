import { userRole } from "./SessionStorageNames";
import Routas from "../../Routes"
const verifyAccessRole = (Routes, nroMatrch) => {
        const dataRoute = Routas.find(e => e.idMatch === nroMatrch)?.rolAccess.includes(localStorage.getItem(userRole))
        if(!dataRoute) {
            return false
        }else {
            return true
        }
}
export default verifyAccessRole;