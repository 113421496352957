import styled from 'styled-components';

const ContForms = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
`;
const Contform = styled.div`
min-width: 300px;
width: 735px;
min-height: 100px;
border-radius: .429rem;
background: #FFFFFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
margin-bottom: 1rem;
`;

const HeaderContForm = styled.div`
width: 100%;
text-align: left;
border-radius: 10px  10px 0 0;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
background: #7367F0;
`;

const BodyContForm = styled.div`
width: 100%;
height: auto;
border-radius: 0 0 .428rem  .428rem;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: flex-start;
padding: 1rem;
`;

const InputChecbox = styled.div`
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #7367f0;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #7367f0;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #7367f0;
    border-color: #7367f0;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(0.9);
    }
    }
    @media(max-width: 600px) {
        width:100%;
    }
`;

const Select = styled.select`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const TextArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: ${props => props.hg || '150px'};
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const Input = styled.input`
    width: ${props => props.w || '100%'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    border: 1px solid #d8d6de;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    &:disabled {
        background: #d8d6de;
        cursor: not-allowed;
    }
    margin-top : ${props => props.mt || 0};
`;

const ContInputAndLabel = styled.div`
    border-radius: 5px;
    border: ${props => props.b || 'none'};
    width:${props => props.w || '100%'};
    margin-left:${props => props.ml || '0'};
    margin-right:${props => props.mr || '0'};
    margin-top:${props => props.mt || '0'};
    margin-bottom:${props => props.mb || '0'};
    height:auto;
    display:flex;
    flex-direction:${props => props.fld || 'column'};
    justify-content: ${props => props.jsc || 'space-between'};
    align-items:${props => props.ali || 'flex-start'};
    `;

const ContImgPlus = styled.ul`
    width: 100%;
    list-style: none;
    padding-left: 0;
    padding: 10px;
    background: #d8d6de;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    border-radius: 10px;
`
const ImgPlus = styled.li.attrs(props => ({
    url: props.url || '',
    brbc: props.active ? 'solid 10px #7367F0' : 'solid 10px transparent',
    }))`
    background-image: ${props => `url(${props.url})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 10px;
    border-bottom: ${props => props.brbc};
`

const FormProduct = {
    Contform,
    HeaderContForm,
    BodyContForm,
    InputChecbox,
    Select,
    TextArea,
    Input,
    ContInputAndLabel,
    ContForms,
    ContImgPlus,
    ImgPlus
}


export default FormProduct