import React, { useContext, useEffect, useState } from "react";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Link } from "react-router-dom";
import { Form, Col, Row, InputGroup, Container, Nav, Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import Routes from "../../../../Routes";
import { BiHide, BiShow, BiX } from "react-icons/bi";
import {
  uploadProducts,
  bulkUpdateProducts,
  addMerchantsName,
  syncMerchantsName,
  deleteMerchantsName,
  downloadCategory,
  changeUrlCashea,
  getUrlCashea,
  getMerchantKey
} from "../../../../hooks/api/cashea";
import { handleUXError } from "../../../../utils";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import axios from "axios";
import handleAxiosError from "../../update-and-publish/functions/handleAxiosError";
import {
    Select,
} from "../../../../assets/css/components/admin/products/ShowProductInfo/ShowProductInfo.css";

export default function CasheaConfig() {
  const globalContext = useContext(GlobalContext);

  const [redirect, setRedirect] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const [productId, setProductId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [createApiKey, setCreateApiKey] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [tarifas, setTarifas] = useState([]);
  const [tarifa, setTarifa] = useState([]);
  const [listMerchantName, setListMerchantName] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [selectedUrlBase, setSelectedUrlBase] = useState('');

  /**
   * This object indicates the type of synchronization.
   */
  const syncMerchantConfig = {
    merchantAdd: "",
    merchantsAddAll: Boolean,
  };
  
  // config
  const [syncMerchant, updateSyncMerchant] = useState(syncMerchantConfig);

  /**
   * Change rate
   */
  function changeTarifa(value) {
    console.log(`Tarifa seleccionada: ${value}`)
    localStorage.setItem(tarifa, value)
    setTarifa(value)
  }

  /**
   * Change marchantName
   */
  function changeMerchantName(e){
    setMerchantName(e.target.value)
    console.log(e.target.value)
  }

  /**
   * Function to consult the lists of available rates
   */
  async function getPriceList() {
    const entId = await globalContext.currentEnterpriseId;
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`;
    if (entId)
      await axios
        .get(url, {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        })
        .then((resp) => {
          const data = resp.data.data;
          setTarifas(data.priceLists);
        })
        .catch((err) => {
          console.log(err);
          handleAxiosError(
            err,
            "Disculpe, no se pudo cargar la lista de tarifas."
          );
        });
  }

  /**
   * Bulk upload/update products
   * Action: upload/update
   * bulkStatus: indicates to scan all merchants of all merchants
   */
  const handleBulkUploadProducts = async (action, bulkStatus) => {
    let message;
    try {
      let data = {
        enterpriseId: globalContext.currentEnterpriseId,
      };
      console.log('Merchant: data', merchantName)
         data = {
          ...data,
          productAction: action,
          merchantName,
          allMerchants: bulkStatus

         }
      
      const res = await bulkUpdateProducts(data);
      message = res?.message;
      
      globalContext.showModalWithMsg(message)
    } catch (err) {
      handleUXError(err.message, "Disculpe, ha ocurrido un error.");
    } finally {
    }
  }; 

  /**
   * Update products individually
   * Action: Update
   */
  const handleUpdateProduct = async (value) => {
    setLoading(true);
    let message;

    try {
      const data = await uploadProducts({
        productIds: [productId],
        productAction: value,
        enterpriseId: globalContext.currentEnterpriseId,
        merchantName
      });
      message = data?.message;
    } catch (err) {
      handleUXError(err.message, "Disculpe, ha ocurrido un error.");
    } finally {
      setLoading(false);
      await globalContext.showModalWithMsg(message)
    }
  }; 

 
  /**
   * CRUD Merchant
   */
  const handleMerchant = async (action, value) => {
    setLoading(true);
    let data;
    const ent = globalContext.currentEnterpriseId;
    const actionsMap = {
      add: async () => {            //Añadir merchant
        data = await addMerchantsName({
          enterpriseId: ent,
          name: value,
          apiKey: createApiKey,
          tarifa,
        });
      },
      update: async () => {       //Actualizar merchant
        data = await addMerchantsName({
          enterpriseId: ent,
          name: value,
          apiKey,
          tarifa,
        });
      },
      uptTarifa: async () => {    //Actualizar todas las tarifas de todos los merchants
        data = await addMerchantsName({
          enterpriseId: ent,
          tarifa,
          uptTarifa: true,
        });
      },
      sync: async () => {       //Sincroniza los merchants, individual o masivos a travez de merchantsAddAll, si este es igual a false sera individual, si es true es masivo
        data = await syncMerchantsName({
          ...syncMerchant,
          merchantsAddAll: value === 'all',
          enterpriseId: ent,
        });
      },
      delete: async () => {       //Elimina el merchant de la BD
        console.log('delete', value);
        data = await deleteMerchantsName({ name: value });
      },
      'download-categories': async () => {        //Descarga todas las categorias de Cashea
        data = await downloadCategory({ enterpriseId: ent });
      },
    };
    try {

      if (actionsMap[action]) {
        await actionsMap[action]();
      } else {
        throw new Error(`Acción no soportada: ${action}`);
      }
      
    } catch (err) {
      handleUXError(err.message, "Disculpe, ha ocurrido un error.");
      console.log(`${err} error`)
    } finally {
      setLoading(false);
      if(data?.message) await globalContext.showModalWithMsg(data.message);
    }
  };

  function changeStep(number) {
    if (step !== 0) setStep(number);
  }



  /**
   * Merchant selected to sync
   */
  const hdlSetSyncMerchants = (e) => {
    console.log(`Merchant seleccionado${e.target.name}`)
    updateSyncMerchant({
      ...syncMerchant,
      [e.target.name]: e.target.value,
    });
    console.log('syncMerchant data: ', syncMerchant)
  };

  const getMerchant = async (e) =>{
    setApiKey('')
    await getMerchantKey({name: e.target.value}).then((e)=>{
      setApiKey(e.data.data.apiKey)
    })
    
  }

  //Cambiar url de entorno, Produccion / desarrollo
  const selectChangeUrlCashea = async (e) =>{
    const enterpriseId = globalContext.currentEnterpriseId;
    changeUrlCashea({enterpriseId, urlBase: e.target.value} )
    setSelectedUrlBase(e.target.value)

  }

  useEffect(() => {
    const getBaseUrl = async() =>{
        await getUrlCashea().then((e)=>{
            setSelectedUrlBase(e.data.urlBase)
        })
    }
    getBaseUrl()
  }, []);
  useEffect(()=>{
    const getMerchantLists = async () =>{
      const data = await getMerchantKey({name: 'all'})
      setListMerchantName(Object.values(data.data.data))
    }
    getMerchantLists()
  }, [loading])
useEffect(() => {
  getPriceList();
}, [globalContext.currentEnterpriseId]);



const toggleShowPassword = (e) => {
  e.preventDefault();
  setShowPass(!showPass)
}

  const authConfigView = () => {
    return (
      <>
        <Row>
          <Col className="d-flex justify-content-center">
            <Container fluid>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Card className="my-5" xs={12} sm={12} md={12} lg={7}>
                    <Card.Body>
                      <Card.Title className="title text-center">
                        Rellene los Siguientes Campos para el plugin de Cashea
                      </Card.Title>
                      <hr/>
                      <Row>
                        {/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Card.Title className="title" style={{marginTop:10}}>
                            Añadir Merchant
                          </Card.Title>
                          <PedidosYaStyle.Text size="12pt">
                                  Tarifa
                          </PedidosYaStyle.Text>
                            <Select
                              className="tarifa"
                              onChange={(e) => {
                                changeTarifa(e.target.value);
                              }}
                            >
                              <option
                                selected
                              >
                                SELECCIONE UNA TARIFA
                              </option>
                              {tarifas.map((val, i) => (
                                <option
                                  key={i}
                                  name="tarifa"
                                  value={val.name}
                                >
                                  {val.name}
                                </option>
                              ))}
                            </Select>
                            <PedidosYaStyle.BodyFormGrid>
                              <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size="12pt">
                                  Merchant 
                                </PedidosYaStyle.Text>
                                <PedidosYaStyle.Input placeholder="Nombre del merchant" autoComplete="off"  onChange={(e)=>changeMerchantName(e)}/>
                                
                                  <Form.Group style={{width: 319}}>
                                      <PedidosYaStyle.Text size="12pt">
                                        ApiKey
                                      </PedidosYaStyle.Text>
                                    <InputGroup className="mb-3">
                                      <PedidosYaStyle.ContIconNadInputPass>
                                      <PedidosYaStyle.Input style={{width: '100%'}}
                                            name="apiKey"
                                            type={showPass ? 'text' : 'password'}
                                            value={createApiKey}
                                            disabled={loading}
                                            placeholder="ApiKey"
                                            autoComplete="off"
                                            onChange={(e) => setCreateApiKey(e.target.value)}
                                          />  
                                        <PedidosYaStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                            {
                                                showPass ? (
                                                    <BiHide />
                                                ) : (
                                                    <BiShow />
                                                )
                                            }
                                        </PedidosYaStyle.BtnShowPass>
                                      </PedidosYaStyle.ContIconNadInputPass>
                                      <div style={{marginTop:10, textAlign:'center'}}>
                                          <PedidosYaStyle.BtnSend style={{width:125}}
                                                onClick={() => {handleMerchant('add', merchantName)}}>
                                                {loading ? "Espere" : "Añadir"}
                                          </PedidosYaStyle.BtnSend>
                                      </div>
                                    </InputGroup>
                                </Form.Group>
                              </PedidosYaStyle.ContInputAndLabel>
                            </PedidosYaStyle.BodyFormGrid>       
                        </Col> */}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card.Title className="title" style={{marginTop:10}}>
                            Actualizar Merchant
                          </Card.Title>
                          <PedidosYaStyle.Text size="12pt">
                                  Tarifa
                          </PedidosYaStyle.Text>
                            <Select
                              className="tarifa"
                              onChange={(e) => {
                                changeTarifa(e.target.value);
                              }}
                            >
                              <option
                                selected
                              >
                                SELECCIONE UNA TARIFA
                              </option>
                              {tarifas.map((val, i) => (
                                <option
                                  key={i}
                                  name="tarifa"
                                  value={val.name}
                                >
                                  {val.name}
                                </option>
                              ))}
                            </Select>
                            <PedidosYaStyle.BodyFormGrid>
                              <PedidosYaStyle.ContInputAndLabel>
                                <PedidosYaStyle.Text size="12pt">
                                  Merchant 
                                </PedidosYaStyle.Text>
                                <Select
                                  className="merchant"
                                  onChange={(e) => {
                                    changeMerchantName(e);
                                    getMerchant(e)
                                  }}>
                                  <option
                                  value=""
                                  disabled
                                  selected
                                  >
                                    Seleccione un merchant 
                                  </option>
                                  {listMerchantName.map((val, i) => (
                                    <option
                                      key={i}
                                      name={val.name}
                                      value={val.name}
                                      onClick={()=> setMerchantName(val.name)}
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                </Select>
                                
                                  <Form.Group style={{width: 319}}>
                                      <PedidosYaStyle.Text size="12pt">
                                        ApiKey
                                      </PedidosYaStyle.Text>
                                    <InputGroup className="mb-3">
                                      <PedidosYaStyle.ContIconNadInputPass>
                                      <PedidosYaStyle.Input style={{width: '100%'}}
                                            name="apiKey"
                                            type={showPass ? 'text' : 'password'}
                                            value={apiKey}
                                            disabled={loading}
                                            placeholder="ApiKey"
                                            autoComplete="off"
                                            onChange={(e) => setApiKey(e.target.value)}
                                          />  
                                        <PedidosYaStyle.BtnShowPass onClick={(e) => toggleShowPassword(e)}>
                                            {
                                                showPass ? (
                                                    <BiHide />
                                                ) : (
                                                    <BiShow />
                                                )
                                            }
                                        </PedidosYaStyle.BtnShowPass>
                                      </PedidosYaStyle.ContIconNadInputPass>
                                      <div style={{marginTop:10, display:'flex', alignItems:'end', gap: 25, textAlign:'center'}}>
                                          <div>
                                          <PedidosYaStyle.BtnSend style={{width:125}}
                                                onClick={() => {handleMerchant('update', merchantName)}}>
                                                {loading ? "Espere" : "Actualizar"}
                                          </PedidosYaStyle.BtnSend>
                                          </div>
                                          <div>
                                            <PedidosYaStyle.BtnSend style={{backgroundColor: '#ca0b0b',textAlign:'center', width: '100%'}}
                                                  onClick={async() => await handleMerchant('delete', merchantName)}>
                                                  {loading ? "Espere" : "Eliminar"}
                                            </PedidosYaStyle.BtnSend>
                                          </div>
                                      </div>
                                    </InputGroup>
                                </Form.Group>
                              </PedidosYaStyle.ContInputAndLabel>
                            </PedidosYaStyle.BodyFormGrid>       
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card.Title className="title" style={{marginTop:10}}>
                            Actualizar todas las tarifas
                          </Card.Title>
                         <div style={{display:'flex', alignItems: 'end', gap: 10}}>
                            <Select
                              className="tarifa"
                              onChange={(e) => {
                                changeTarifa(e.target.value);
                              }}
                            >
                              <option
                                selected
                              >
                                SELECCIONE UNA TARIFA
                              </option>
                              {tarifas.map((val, i) => (
                                <option
                                  key={i}
                                  name="tarifa"
                                  value={val.name}
                                >
                                  {val.name}
                                </option>
                              ))}
                            </Select>
                            <PedidosYaStyle.BtnSend style={{width:125, textAlign:'center'}}
                                onClick={() => {handleMerchant('uptTarifa', merchantName)}}>
                                {loading ? "Espere" : "Actualizar"}
                            </PedidosYaStyle.BtnSend> 
                         </div>
                        </Col>
                      </Row>
                      <hr/>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card.Title className="title" style={{textAlign:'center', marginBottom:0}}>
                            Sincronizacion de merchants
                          </Card.Title>
                          <PedidosYaStyle.BodyFormGrid  style={{alignItems:'end'}}>
                            <div style={{display: 'flex', alignItems:'end', gap:20}}>
                              <div>
                              <PedidosYaStyle.Text size="12pt">
                                  Sincronizar individual
                                </PedidosYaStyle.Text>
                                <Select
                                  className="merchant"
                                  name="merchantAdd"
                                  onChange={hdlSetSyncMerchants}
                                  >
                                  <option
                                  value=""
                                  disabled
                                  selected
                                  >
                                    Seleccione un merchant 
                                  </option>
                                  {listMerchantName.map((val, i) => (
                                    <option
                                      key={i}
                                      name={val.name}
                                      value={val.name}
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                              <PedidosYaStyle.BtnSend style={{width:125}}
                                  onClick={async(e) => {await handleMerchant('sync', syncMerchant)}}>
                                  {loading ? "Espere" : "Sincronizar"}
                              </PedidosYaStyle.BtnSend>
                               
                            </div>
                            <PedidosYaStyle.BtnSend style={{width: '100%', backgroundColor:'#006A4E', textAlign:'center', height:36}}
                                onClick={()=> handleMerchant('sync', 'all')}>
                                Sincronizar todos
                            </PedidosYaStyle.BtnSend>
                             <hr/>
                          </PedidosYaStyle.BodyFormGrid>     
                        </Col>
                          <PedidosYaStyle.BodyFormGrid style={{display:'flex', flexDirection:'column', alignItems:'center', maxWidth:350, margin:'auto'}}>
                            <Card.Title style={{marginBottom:0}} className="title">
                              Descargar Categorias de Cashea
                            </Card.Title>
                              <PedidosYaStyle.BtnSend style={{width: '100%', backgroundColor:'#006A4E', textAlign:'center'}}
                                    onClick={()=> handleMerchant('download-categories')}>
                                    Descargar Categorias 
                              </PedidosYaStyle.BtnSend>
                            </PedidosYaStyle.BodyFormGrid> 
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </>
    );
  };

  const productView = () => {
    return (
      <>
        <PedidosYaStyle.ContForm style={{marginTop:55}}>
          <PedidosYaStyle.HeaderForm>
            <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
              Operaciones de Producto en Cashea
            </PedidosYaStyle.Text>
          </PedidosYaStyle.HeaderForm>
          <PedidosYaStyle.BodyForm>
            <PedidosYaStyle.Section>
              <PedidosYaStyle.BodyForm>
              <div style={{display: 'flex'}}>

                <PedidosYaStyle.ContInputAndLabel w="50%" colSpan="1" style={{marginTop:40}}>
                  <PedidosYaStyle.Text size="12pt" style={{textAlign:'center'}}>
                    <strong>Actualizar un producto individual</strong>
                  </PedidosYaStyle.Text>
                  <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                    <PedidosYaStyle.Input
                      placeholder="Id Mongo del Producto"
                      value={productId}
                      onChangeCapture={(e) => setProductId(e.target.value)}
                    />
                    <PedidosYaStyle.BtnSend
                      onClick={() => {handleUpdateProduct('update')}}
                    >
                      {loading ? "Espere" : "Actualizar"}
                    </PedidosYaStyle.BtnSend>
                  </PedidosYaStyle.ContInputButtonGroup>
                </PedidosYaStyle.ContInputAndLabel>

                <PedidosYaStyle.ContInputAndLabel w="50%" colSpan="1" style={{marginTop:40}}>
                  <PedidosYaStyle.Text size="12pt" style={{textAlign:'center'}}>
                    <strong>Actualización masiva por merchants</strong>
                  </PedidosYaStyle.Text>
                  <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                     <Select
                     style={{width: '100%'}}
                      className="merchant"
                      onChange={(e) => {
                        changeMerchantName(e);
                        changeMerchantName(e);
                      }}>
                      <option
                        value=""
                        disabled
                        selected
                      >
                        Seleccione un merchant 
                      </option>
                      {listMerchantName.map((val, i) => (
                        <option
                          key={i}
                          name={val.name}
                          value={val.name}
                          onClick={()=> setMerchantName(val.name)}
                        >
                          {val.name}
                        </option>
                      ))} 
                    </Select>
                    <PedidosYaStyle.BtnSend
                      onClick={() => handleBulkUploadProducts('bulkUpdate')}
                    >
                      {loading ? "Espere" : "Actualizar"}
                    </PedidosYaStyle.BtnSend>
                  </PedidosYaStyle.ContInputButtonGroup>
                  </PedidosYaStyle.ContInputAndLabel>
              </div>
                <PedidosYaStyle.ContInputAndLabel w="50%" colSpan="2" style={{margin:'auto', marginTop:60, }}>
                  <PedidosYaStyle.Text size="12pt" style={{textAlign:'center'}}>
                    <strong>Actualizar todos los merchants</strong>
                  </PedidosYaStyle.Text>
                  <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                    <PedidosYaStyle.BtnSend
                      onClick={() => handleBulkUploadProducts('bulkUpdate', 'true')}
                      style={{width: '100%', textAlign:'center'}}
                    >
                      {loading ? "Espere" : "Actualizar todo"}
                    </PedidosYaStyle.BtnSend>
                  </PedidosYaStyle.ContInputButtonGroup>
                </PedidosYaStyle.ContInputAndLabel>


              </PedidosYaStyle.BodyForm>
            </PedidosYaStyle.Section>
          </PedidosYaStyle.BodyForm>
        </PedidosYaStyle.ContForm>
      </>
    );
  };

  const showedView = () => {
    switch (step) {
      case 0:
        return (
          <Container>
            <Row>
              <Col className="d-flex justify-content-center mt-5 pt-5">
                <h5 className="text-danger">Debe seleccionar una empresa.</h5>
              </Col>
            </Row>
          </Container>
        );
      case 1:
        return authConfigView();
      case 2:
        return productView();
      default:
        return authConfigView();
    }
  };

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 49);
    if (!hasAccess || globalContext.parentEntId) {
      setRedirect(<Redirect to={`/admin/dashboard`} />);
    }
  }, [globalContext.parentEntId]);

  useEffect(() => {
    setTimeout(() => {
      setNotification({});
    }, 10000);
  }, [notification]);

  useEffect(() => {
    if (!globalContext.currentEnterpriseId) {
      setStep(0);
    } else {
      setStep(1);
    }
  }, [globalContext.currentEnterpriseId]);

  return (
    <>
      <PedidosYaStyle.ContBadgeError
        active={notification?.msg && true}
        error={notification.error}
      >
        <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
          {notification.msg}
        </PedidosYaStyle.Text>
        <PedidosYaStyle.BtnToggleBadge
        //  onClick={(e) => closeBadge(e)}
        >
          <BiX />
        </PedidosYaStyle.BtnToggleBadge>
      </PedidosYaStyle.ContBadgeError>
      <OdooStyle>
        {redirect}
        <div className="goBack d-flex justify-content-between mt-2">
          <div className="row">
            <div className="col-2">
              <Link to="/admin/plugins/my-plugins/my-menu-plugins">
                <button className="btn-back">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
              </Link>
            </div>
            <div className="col">
              <Nav variant="pills" defaultActiveKey="configurar">
                <Nav.Item>
                  <Nav.Link
                    onClick={() => changeStep(1)}
                    eventKey={loading || step === 0 ? "disabled" : "configurar"}
                    disabled={loading || (step === 0 && true)}
                  >
                    Configuración
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => changeStep(2)}
                    eventKey={loading || step === 0 ? "disabled" : "app"}
                    disabled={loading || (step === 0 && true)}
                  >
                    Operaciones
                  </Nav.Link>
                </Nav.Item> 
              </Nav>
            </div>
          </div>
          <PedidosYaStyle.SelectWeb style={{maxWidth:280, height:50}} 
          onChange={selectChangeUrlCashea} value={selectedUrlBase}>
                <option value='DEV' >Entorno de Pruebas</option>
                <option value='DES' >Entorno de Produccion</option>
            </PedidosYaStyle.SelectWeb>
        </div>
        {showedView()}
      </OdooStyle>
    </>
  );
}
