import React, { useContext, useEffect, useState } from "react";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import { Link } from "react-router-dom";
import { Form, Col, Row, InputGroup, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import Routes from "../../../../Routes";
export default function LinioConfig() {
    /**
     * ! julio culo mamo
     */
    
    const[usuario, setUsuario] = useState('');
    const[apiKey, setApiKey] = useState('');
    const [redirect, setRedirect] = useState(null);
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 49);
        if(!hasAccess || globalContext.parentEntId) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[globalContext.parentEntId]);
   /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/v1/linio-acc/linUser`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
          data: {
            apiKey: apiKey,
            usuario: usuario,
            enterpriseId: globalContext.currentEnterpriseId,
          },
        })
          .then((res) => {
            globalContext.showModalWithMsg(
                'Guardado exitoso'
            )
            console.log(res)
          })
          .catch((err) => {
            handleAxiosError(
                err,
                "Disculpe, a ocurrido un error."
            );
          });
      };

    return (
        <OdooStyle>
            {redirect}
            <div className="goBack d-flex justify-content-start mt-2">
                <Link to="/admin/plugins/my-plugins/my-menu-plugins">
                    <button className="btn-back">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </Link>
            </div>
            <Row>
                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-center"
                >
                    <div className="odooFormBody">
                        <Container fluid>
                            <Row>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="hearderFormOdoo"
                                >
                                    <p> Rellene los Siguientes Campos para el plugin de Linio</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Usuario"
                                                id="formContacInput-three"
                                                name="usuario"
                                                onChange={(e) => setUsuario(e.target.value)}
                                            ></Form.Control>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="ApiKey"
                                                id="formContacInput-three"
                                                name="apiKey"
                                                onChange={(e) => setApiKey(e.target.value)}
                                            ></Form.Control>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="footerFormOdoo">
                    <button className="download-tarifa" onClick={(e)=> {submitHandler(e)}}>
                        Guardar
                    </button>
                </Col>
            </Row>
        </OdooStyle>
    )
}
