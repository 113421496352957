import React, { useState, useRef, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    InputGroup,
    Form,
    Button,
    Image,
    Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
    tarifa,
    keyUserActiveEnterpriseId,
} from "../../../../assets/js/SessionStorageNames";
import {
    ContenedorProductos,
    Select,
} from "../../../../assets/css/components/admin/products/ShowProductInfo/ShowProductInfo.css";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import { GlobalContext } from "../../../../contexts/GlobalContext";
 
export default function ShowProductInfo() {
    const globalContext = useContext(GlobalContext);
    const numberFormat = new Intl.NumberFormat("de-DE");
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const inputRef = useRef(null);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [producto, setProducto] = useState(null);
    const [error, setError] = useState("");
    const [redirect, setRedirect] = useState(null);
    const [form, setForm] = useState(false);
    const [tarifas, setTarifas] = useState([]);
    const [mobil, setMobil] = useState(true);

    /**
     * Funcion para filtrar texto y eliminar los caracteres no deseados.
     * @param {string} texto
     */
    function filtrarTXT(texto) {
        const caracteresPermitidos = /^[a-z0-9]+$/i;
        let resultado = "";
        for (let i = 0; i < texto.length; i++) {
            if (caracteresPermitidos.test(texto[i])) {
                resultado += texto[i];
            }
        }
        setInput(resultado);
    }

    /**
     * funcion de busqueda del producto por codigo de barras
     */
    async function buscar() {
        const entId = await globalContext.currentEnterpriseId;
        setError("");
        if (input.length < 9) {
            setError("POR FAVOR INGRESE UN CÓDIGO DE BARRAS VÁLIDO");
            setTimeout(() => {
                setError("");
            }, 10000);
        } else {
            setInput("");
            setLoading(true);
            const headers = {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            };
            // const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/${entId}/prices`;
            const url = `${process.env.REACT_APP_BACKEND_URL}/v1/product/${input}/${entId}`;
            // 7591031001959
            axios
                .get(url, {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: headers,
                })
                .then((res) => {
                    if (res.status === 200) {
                        const resp = res.data?.data?.prodPrices;
                        console.log(res.data?.data?.prodPrices[0]);
                        if (resp.length === 1) {
                            const price = resp[0].pricelists.find(
                                (e) => e.name === localStorage.getItem(tarifa)
                            );

                            !price.price &&
                                setError(
                                    "No se ha encontrado costo del productp asociado a esta tarifa."
                                );
                            const settedProd = {
                                ...resp[0],
                                price: price.price,
                            };
                            inputRef.current.focus();
                            setProducto(settedProd);
                        } else {
                            inputRef.current.focus();
                            setError("Producto no encontrado");
                        }
                    } else {
                        inputRef.current.focus();
                        setError("Error al consultar el producto");
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    inputRef.current.focus();
                    setError(err.message);
                    setLoading(false);
                    console.error(err);
                });
            // Vaciar el estado después de 10 segundos
            setTimeout(() => {
                setError("");
                setProducto(null);
                inputRef.current.focus();
            }, 10000);
        }
    }
    
    /**
     * Funcion para cambiar la tarifa seleccionada
     * @param {*} txt 
     */
    function changeTarifa(txt) {
        localStorage.setItem(tarifa, txt);
        globalContext.currentEnterpriseId && setForm(true);
        inputRef.current.focus();
    }

    /**
     * accion del boton de busqueda
     * @param {*} e 
     */
    function action(e) {
        if ((e.keyCode === 13 || e.keyCode === 9) && !loading) buscar();
    }

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
        inputRef.current.focus();
    };

    /**
     * Funcion para redondear los decimales de un numero
     * @param {*} num 
     * @returns 
     */
    function roundDecimals(num) {
        const round = parseFloat(num.toFixed(2));
        return numberFormat.format(round);
    }

    /**
     * Funcion para consultar la listas de tarifas disponibles
     */
    async function getPriceList() {
        const entId = await globalContext.currentEnterpriseId;
        const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`;
        console.log("url: ", url);
        if (entId)
            await axios
                .get(url, {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                })
                .then((resp) => {
                    inputRef.current.focus();
                    const data = resp.data.data;
                    console.log(data);
                    setTarifas(data.priceLists);
                })
                .catch((err) => {
                    console.log(err);
                    handleAxiosError(
                        err,
                        "Disculpe, no se pudo cargar la lista de tarifas."
                    );
                });
    }

    function checkIsMobile() {
        if (navigator.userAgent.indexOf("Mobile") > 0) {
            setMobil(true);
        } else {
            setMobil(false);
        }
    }

    useEffect(() => {
        checkIsMobile();
        inputRef.current.focus();
        if (
            localStorage.getItem(tarifa) !== null ||
            localStorage.getItem(tarifa) !== undefined ||
            globalContext.currentEnterpriseId !== null
        ) {
            console.log(localStorage.getItem(tarifa));
            setForm(true);
        } else {
            localStorage.setItem(tarifa, "");
            setForm(false);
        }
        const hasAccess = verifyAccessRole(Routes, 87);
        if (!hasAccess) {
            setRedirect(<Redirect to={`/admin/dashboard`} />);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPriceList();
    }, [globalContext.currentEnterpriseId]);

    return (
        <div className='contenido'>
            {!mobil && 
                <Select
                    className='tarifa'
                    onChange={(e) => {
                        changeTarifa(e.target.value);
                    }}
                >
                    <option
                        value=''
                        disabled
                        selected={localStorage.getItem(tarifa) === "" && true}
                    >
                        SELECCIONE UNA TARIFA
                    </option>
                    {tarifas.map((val, i) => (
                        <option
                            key={i}
                            value={val.name}
                            selected={
                                localStorage.getItem(tarifa) === val.name && true
                            }
                        >
                            {val.name}
                        </option>
                    ))}
                </Select>
            }
            {redirect}
            <ContenedorProductos>
                <Image
                    className='logo'
                    src='https://i0.wp.com/212global.com/wp-content/uploads/2022/11/Logos-de-212-200x150-1.png?fit=200%2C150&ssl=1'
                />
                <Container>
                    <Row>
                        {producto ? (
                            <>
                                <Col
                                    xs={12}
                                    lg={4}
                                    xl={3}
                                    className='text-center'
                                >
                                    <Image src={producto.image} height={200} />
                                </Col>
                                <Col>
                                    <h3>{producto.title?.trim()}</h3>
                                    <h4>
                                        <strong>Codigo de Barras:</strong>{" "}
                                        {producto.barCode?.trim()}
                                    </h4>
                                    <h4>
                                        <strong>SKU:</strong>{" "}
                                        {producto.sku?.trim()}
                                    </h4>

                                    <h4>
                                        <strong>Precio:</strong>{" "}
                                        {roundDecimals(producto.price)}$
                                    </h4>
                                </Col>
                            </>
                        ) : (
                            <div className='empty'>
                                {loading ? (
                                    <Col className='d-flex justify-content-center text-center'>
                                        <Spinner
                                            animation='grow'
                                            size='xl'
                                            style={{ color: "#7367f0" }}
                                        />
                                    </Col>
                                ) : (
                                    <Col xs={12}>
                                        <div className='text-center wellcome'>
                                            <h1>
                                                <strong>
                                                    ¡Bienvenido a 212 GLOBAL!
                                                </strong>
                                            </h1>
                                            <p>
                                                Consulte el precio de sus
                                                productos aquí.
                                            </p>
                                            <Image
                                                className='background'
                                                src='https://i0.wp.com/212global.com/wp-content/uploads/2022/11/Logos-de-212-200x150-1.png?fit=200%2C150&ssl=1'
                                            />
                                        </div>
                                    </Col>
                                )}
                                <p className='text-danger'>
                                    <strong>{error}</strong>
                                    {!form && (
                                        <>
                                            <br />
                                            <strong>
                                                Debe seleccionar una tarifa
                                            </strong>
                                        </>
                                    )}
                                    {!globalContext.currentEnterpriseId && (
                                        <>
                                            <br />
                                            <strong>
                                                Debe seleccionar una empresa
                                            </strong>
                                        </>
                                    )}
                                </p>
                            </div>
                        )}
                    </Row>
                    <Row className='pt-3 form'>
                        <Col>
                            <InputGroup className='mb-3' size='lg' disabled>
                                <Form.Control
                                    autoFocus
                                    placeholder='Código de Barras'
                                    aria-label='Código de Barras'
                                    aria-describedby='basic-addon2'
                                    value={input}
                                    onChange={(e) => filtrarTXT(e.target.value)}
                                    onKeyDown={(e) => action(e)}
                                    ref={inputRef}
                                    disabled={!form}
                                />
                                <Button
                                    variant='outline-secondary'
                                    id='button-addon2'
                                    onClick={buscar}
                                    disabled={!form && loading ? true : null}
                                >
                                    Busca
                                    {loading ? (
                                        <>
                                            ndo{" "}
                                            <Spinner
                                                animation='border'
                                                size='sm'
                                            />
                                        </>
                                    ) : (
                                        <>
                                            r{" "}
                                            <FontAwesomeIcon icon={faSearch} />
                                        </>
                                    )}
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </ContenedorProductos>
        </div>
    );
}
