import React, { useState, useContext } from "react";
import { useEffect } from "react";
import {
  ContListcategories,
  HeaderListCategories,
  BodyListCategories,
  FooterListCategories,
  ListCategories,
  ItemListCategories,
  ContLabelItem,
  ContIconOpenSubList,
  SubListCategories,
  ContbtnAddCategoryToLink,
  BtnAddToList,
} from "../../../../../../assets/css/components/admin/categories/LinkCategories";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts";

import axios from "axios";

import { Spinner } from "react-bootstrap";
import { BiCaretDown, BiCheckCircle } from "react-icons/bi";

import MassiveScrapingCss from "../../../../../../assets/css/components/admin/products/amazon/MassiveScrapingCss";
import { useRef } from "react";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";
import getAllParentsMl from "../../../../categories/functions/getAllParentsMl";
import getChildsML from "../../../../categories/functions/getChildsMl";
import Pager from "../../../../../general/Pager";
import { LoadingSpinner } from "../../views/DetailProductMTM";
import getAllCategoriesCashea from "../../../../categories/functions/getAllCategoriesCashea";

export default function SelectCasheaCategory({
  setCategory,
  isLoad,
  setIsLoad,
  currentStep,
  setCurrentStep,
}) { 
  const pageItemCount = 20;
  const globalContext = useContext(GlobalContext);
  const [datacategoryOn, setdatacategoryOn] = useState(null);
  const [totalCategories, setTotalCategories] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [actualCategory, setActualCategory] = useState(null);
  const [loadChild, setLoadChild] = useState(null);
  const [load, setLoad] = useState(true);
  const [filter, setFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [hasChild, setHasChild] = useState(null);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [idReadyToLink, setIdReadyToLink] = useState([]);
  const [filterTSearch, setFilterTSearch] = useState("");

  const urlRef = useRef(null);

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (err.response && err.response.data) {
      if (err.response.data.message) {
        globalContext.showModalWithMsg(err.response.data.message);
      } else {
        globalContext.showModalWithMsg(err.response.data);
      }
    } else if (msg) {
      globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  const setFilterToPublish = (e) => {
    e.preventDefault();
    setFilterTSearch(filter);
  };

  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    getAllCategoriesCashea()
      .then((r) => {
        const data = r.data.data.data;
        setCategories(data);
        setTotalCategories(data.length);
        setIsLoad(false);
      })
      .catch((e) => {
        console.log(e);
        handleAxiosError(
          e,
          "Presentamos un error al cargar las categorias de Cashea",
          e
        );
      });
  }, [currentPage, filterTSearch, globalContext.currentEnterpriseId]);

  const removeAll = () => {
    //? buscamos el padre y lo eliminamos
    setHasChild(null);
    setActualCategory(null);
  };

  /**
   *
   * @param {*} node
   *  @description buscamos los hijos de los padres principales o de los que no tiene padre
   */
  const searchChild = (id) => {
    if (actualCategory === id) {
      removeAll();
      return;
    }

    const entId = globalContext.currentEnterpriseId;

    setActualCategory(id);
    
    //? agg el node al array de padres con hijos
  };

  const selectChild = (subCategory) =>{
    if(hasChild === subCategory){
      setHasChild(null)
      return;
    }
    setHasChild(subCategory)
  }

  const renderChild = (node, name, _id) => {
    if (node) {
      // Encontrar la categoría actual basándonos en su _id
      const category = categories.find((c) => c._id === node);
  
      // Si la categoría existe
      if (category) {
        return (
          <SubListCategories>
            {/* Mapeamos el array de subcategorías */}
            {category.subCategories.map((v, index) => {
              return (
                <ItemListCategories key={index + v.name}>
                  <ContLabelItem
                    active={hasChild ===v.name}
                    onClick={() => selectChild(v.name)}>
                    {v.name}
                      <ContIconOpenSubList active={hasChild ===v.name}>
                        <BiCaretDown />
                      </ContIconOpenSubList>
                    </ContLabelItem>
  
                  {/* Mostrar el botón de seleccionar directamente al hacer clic */}
                  {hasChild === v.name && (
                    <ContbtnAddCategoryToLink j="flex-start">
                      <BtnAddToList onClick={() => setCategoryToPost(name, v.name)}>
                        Seleccionar
                      </BtnAddToList>
                    </ContbtnAddCategoryToLink>)}
                </ItemListCategories>
              );
            })}
          </SubListCategories>
        );
      }
    }
  };
  
  const setCategoryToPost = (c,subCategory) => {
    setCategory({category: c, subCategory});
    console.log('SELECCIONADO')
    setCurrentStep(currentStep + 1);
  };

  return (
    <ContListcategories w='400px' mh='100vh'>
      <HeaderListCategories>
        <Text size='13pt' fw='bold' cl='#696969'>
          Categorias de Cashea{" "}
        </Text>
        {/* <form style={{ width: "100%" }} onSubmit={(e) => setFilterToPublish(e)}>
          <MassiveScrapingCss.inputOne
            placeholder='URL'
            type='text'
            onChange={(e) => setFilter(e.target.value)}
            ref={urlRef}
          />
        </form> */}
      </HeaderListCategories>
      <BodyListCategories>
        {isLoad ? (
          <LoadingSpinner />
        ) : (
          <ListCategories>
            {categories.length > 0 &&
              categories.map((v) => {
                return (
                  <ItemListCategories key={v._id}>
                    <ContLabelItem
                      active={actualCategory === v._id}
                      onClick={() => {
                        searchChild(v._id);
                      }}
                    >
                      {v.name}
                      {loadChild === v._id ? (
                        <ContIconOpenSubList>
                          <Spinner animation='border' role='status'>
                            <span className='sr-only'>Cargando...</span>
                          </Spinner>
                        </ContIconOpenSubList>
                      ) : (
                        <ContIconOpenSubList active={actualCategory === v._id}>
                          <BiCaretDown />
                        </ContIconOpenSubList>
                      )}
                    </ContLabelItem>
                    {loadChild !== v._id &&
                      actualCategory === v._id &&
                      renderChild(v._id, v.name, v.id)}
                  </ItemListCategories>
                );
              })}
          </ListCategories>
        )}
      </BodyListCategories>
      <FooterListCategories>
        <Pager
          handleSetPage={setCurrentPage}
          totalResults={totalCategories}
          currentPage={currentPage}
          resultsPerPage={pageItemCount}
        />
      </FooterListCategories>
    </ContListcategories>
  );
}
