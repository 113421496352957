import React, { useState, useEffect, useContext } from 'react'
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken
} from "../../../../../assets/js/SessionStorageNames";
import FormProduct from '../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct';
import { Text } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection';
export default function ParametrosPublicacion(props) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [stores, setStores] = useState(null)
    const [dynamicValues, setDynamicValues] = useState({});
    const {
        setTitle,
        setDescripcion,
        setListingType,
        setListingTypeText,
        descripcion,
        setTienda,
        setTiendaText,
        tienda,
        tiendaText,
        howToPost,
        setHowToPost,
        listingType,
        setCustomAttributes,
        categoryMlData
    } = props;

    const funcSetHowToPost = (v) => {
        setHowToPost(v)
    }

    const funcSetTitle = (v) => {
        setTitle(v)
    }
    const funcSetDescripcio = (v) => {
        setDescripcion(v)
    }
    const functSetTienda = (v) => {
        setTienda(v)
    }
    const functSetTiendaText = (v) => {
        setTiendaText(v)
    }
    const funcSetListingType = (v) => {
        setListingType(v);
    }
    const funcSetListingTypeText = (v) => {
        setListingTypeText(v);
        console.log(v)
    }

     // Manejar cambios en los campos
     const handleChange = (id, value) => {
        setDynamicValues(prev => {
          const updatedValues = {
            ...prev,
            [id]: value,
          };
      
          // Transformar dynamicValues en customAttributes
          const transformedCustomAttributes = Object.entries(updatedValues).map(([key, val]) => ({
            name: key,
            value: val,
          }));
      
          // Actualizar customAttributes
          setCustomAttributes(transformedCustomAttributes);
      
          return updatedValues;
        });
      };
      
    /**
    * Handle the axios error
    * @param {Error} err The thrown error
    * @param {string} msg A message to show in the modal
    * @param {boolean} consoleLog If should log the error in the console
    */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    /**
         * Load the brands list
         */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then((resp) => {
                const brands = resp.data.data.brands;
                setStores(brands);
            })
            .catch((err) => handleAxiosError(err));
    }, [globalContext.currentEnterpriseId]);

    const setCategoryToPost = () => {
        const excludeAttrs = ['brand', 'model', 'width', 'height', 'length', 'weight', 'color', 'size', 'flavor']
        const requiredAttr = categoryMlData.attributes.filter(attr => (attr.tags.required === true) && !excludeAttrs.includes(attr.id.toLowerCase()))
        console.log(requiredAttr, 'holaaa xdxdxd', typeof requiredAttr)
        return requiredAttr
      };
    return (
        <PublishProduct.contForm>
            <div styled={{ width: '100%' }}>
                <div className='text-left'>
                    <PublishProduct.textBody mb='5px' fw='bolder' >
                        Titulo de la publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.input wd='100%' onChange={(e) => funcSetTitle(e.target.value)} />
                </div>
            </div>
            <PublishProduct.contRowForm>
                <div className='text-left' style={{ width: '100%', padding: '0 10px' }}>
                    <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                        Tipo de Publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.select defaultValue={listingType} 
                    onChange={
                        (e) => { 
                            funcSetListingType(e.target.value);
                            funcSetListingTypeText(e.target.options[e.target.selectedIndex].text)  
                            }}>
                        <option value="">Seleccionar</option>
                        <option value='gold_special'>Clasica CO</option>
                        <option value='gold_pro'>Premiun CO</option>
                        <option value='gold_premium'>Oro Premiun CO</option>
                        <option value='gold'>Oro CO</option>
                        <option value='gold_special'>Premiun VE</option>
                        
                    </PublishProduct.select>
                </div>
            </PublishProduct.contRowForm>
            <PublishProduct.contRowForm>
                <div className='text-left' style={{ width: '50%', padding: '0 10px' }}>
                    <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                        Estatus de la publicacion
                    </PublishProduct.textBody>
                    <PublishProduct.select value={howToPost} onChange={(e) => { funcSetHowToPost(e.target.value) }}>
                        <option value="">Seleccionar</option>
                        <option value='active'>Activa</option>
                        <option value='paused'>Pausada</option>
                    </PublishProduct.select>
                </div>
                <div className='text-left' style={{ width: '50%', padding: '0 10px' }}>
                    <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                        Tienda oficial
                    </PublishProduct.textBody>
                    <PublishProduct.select defaultValue={{ label: tiendaText, value: tienda }} onChange={
                        (e) => {
                            functSetTienda(e.target.value);
                            functSetTiendaText(e.target.options[e.target.selectedIndex].text);
                        }
                    }>
                        <option value="">Seleccionar</option>
                        {
                            stores &&
                            stores.map((s) => {
                                return (
                                    <option key={s.id} value={s.id}>{s.name}</option>
                                )
                            })
                        }
                    </PublishProduct.select>
                </div>
            </PublishProduct.contRowForm>
            <div className='text-left' styled={{ width: 100 + '%' }}>
                <PublishProduct.textBody mb='5px' fw='bolder' mt='5px' >
                    Descripcion
                </PublishProduct.textBody>
                <PublishProduct.textArea onChange={(e) => funcSetDescripcio(e.target.value)} defaultValue={descripcion} />
            </div>

            {setCategoryToPost()?.map((e)=>{
                if(e.value_type === 'string' || e.value_type === 'number'){
                  return(
                    <>
                      <FormProduct.ContInputAndLabel w='340px' key={e.id}>
                        <Text size='12pt' fw='500'>
                          {e.name} {e.id}
                        </Text>
                        <FormProduct.Input
                          type={e.value_type === 'string' ? 'text' : 'number'}
                          placeholder={e.name}
                          value={dynamicValues[e.id] || ''} // Valor actual desde el estado
                          onChange={event => handleChange(e.id, event.target.value)} // Actualiza el estado
                        />
                        </FormProduct.ContInputAndLabel>
                    </>
                  )
                }
                if(e.value_type === 'list'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name} {e.id}
                      </Text>
                      <FormProduct.Select name={e.id}
                        value={dynamicValues[e.id] || ''}
                        onChange={event => handleChange(e.id, event.target.value)} // Actualiza el estado
                        >
                          <option value={null}>{e.name}</option>
                        {e.values.map(val =>(
                          <option value={val.name}>{val.name}</option>
                        ))}
                      </FormProduct.Select>
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
                if(e.value_type === 'boolean'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name} {e.id}
                      </Text>
                      <FormProduct.Select name={e.id}
                        value={dynamicValues[e.id] || ''}
                        onChange={event => handleChange(e.id, event.target.value)} // Actualiza el estado
                      >
                        <option value={null}>Seleccione un valor</option>
                        {e.values.map(val =>(
                          <option value={val.name}>{val.name}</option>
                        ))}
                      </FormProduct.Select>
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
                if(e.value_type === 'number_unit'){
                  return(
                    <FormProduct.ContInputAndLabel w='340px' key={e.id}>
                       <Text size='12pt' fw='500'>
                        {e.name} {e.id} ({e.default_unit === '"' ? 'Pulgadas' : e.default_unit})
                      </Text>
                      <FormProduct.Input
                        type='number'
                        placeholder={e.name}
                        value={dynamicValues[e.id] || ''}
                        onChange={event => handleChange(e.id, event.target.value)} // Actualiza el estado
                      />
                    </FormProduct.ContInputAndLabel>
                  
                  )
                }
              })}
        </PublishProduct.contForm>
    )
}
