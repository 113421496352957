import styled from "styled-components";

const ContenedorProductos = styled.div`
    padding: 130px;
    transition: 0.3s;
    h1,
    h3,
    h4 > strong {
        color: #7367f0;
    }
    h4 {
        color: #5e5873;
    }
    h1 {
        font-size: 35px;
    }
    button {
        background: #7367f0;
        color: white;
    }
    button:hover {
        background: #867cec;
    }

    button:active {
        background: #6558f1 !important;
    }
    .wellcome {
        width: 100%;
        z-index: 0;
    }
    .wellcome > p {
        font-size: 20px;
    }
    .background {
        width: 400px;
        position: absolute;
        top: 0px !important;
        left: 300px !important;
        border: 0;
        opacity: 0.1;
        display: block;
        z-index: 0;
    }
    .form {
        z-index: 2
    }
    .logo {
        position: absolute;
        right: 20px;
        top: 90px !important;
        border: 0px;
        opacity: 0.5;
    }
    img {
        border-radius: 3px;
        border: solid 1px #ccc;
    }
    .empty {
        height: 200px;
        width: 100%;
    }

    @media screen and (max-width: 576px) {
        /* Estilos para pantallas de al menos 576px de ancho */
        padding: 0px;
        padding-top: 100px;

        .logo {
            position: absolute;
            right: 20px;
            top: 120px !important;
            border: 0px;
            opacity: 0.15;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        /* Estilos para pantallas entre  768px y de ancho */
        padding: 0px;
        padding-top: 100px;
        .background {
            display: none;
        }
        .empty {
            height: 100px;
        }
    }
`;

const Select = styled.select`
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #7367f0;
    z-index: 4 !important;

    @media screen and (max-width: 576px) {
        /* Estilos para pantallas de al menos 576px de ancho */
        width: 290px;
    }
`;

export { ContenedorProductos,Select };
