import styled from "styled-components";
const CardProductDetailStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  body {
    font-family: "Roboto Slab", sans-serif !important;
  }
  .CardBody {
    background-color: white;
    border: 1px solid #e5e9f2;
    border-radius: 0.25rem;
    visibility: visible;
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    min-width: 100%;
    max-width: 100%;
    ${({maxHeight}) => maxHeight && `max-height: ${maxHeight};`}
    ${({minHeight}) => minHeight && `min-height: ${minHeight};`}
    width: 100%;
    // min-height: 300px !important;
    // max-height: 300px !important;
    // height: 300px !important;
    overflow: auto scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .info-produc {
    word-break: break-word;
  }
  button {
    width: auto;
    padding: 0.5rem;
    // height: 40px;
    background: #5e548e;
    display: inline-block;
    outline: 0;
    border: 0;
    margin: 1.5px;
    text-align: center;
    border-radius: 0.25rem;
    letter-spacing: 1px;
    color: white;
    cursor: pointer;
    font-size: 10pt;
    text-transform: uppercase;
  }
  .button:hover {
    background: #655c91;
  }
  .button:focus {
    background: #5e548e;
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .button:active {
    background: #51487b !important;
    outline: none !important;
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  img {
    width: 100% !important;
    height: auto !important;
  }
  .text-lp {
    font-size: 0.9em;
    letter-spacing: 1px;
  }
  .text-pp {
    margin-top: -5px;
  }
  .text-np {
    font-size: 1.2em;
    margin-top: -5px;
  }
  .text-sp {
    font-size: 10pt;
  }
  .text-lp,
  .text-pp,
  .text-np,
  .text-sp {
    font-family: "Roboto Slab", sans-serif !important;
    margin-top: 0;
    margin-bottom: 4px;
    color: rgb(94, 84, 142);
    font-weight: bold;
  }

  .primary-text {
    color: rgb(94, 84, 142);
  }
`;
export default CardProductDetailStyle;
