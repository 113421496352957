import React, { useContext, useState, useEffect } from "react";
import {
  Image,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { GlobalContext } from "../../../../contexts/GlobalContext";
/**
 * ? import loader spinner
 import LoaderSpinner from "../../general/LoaderSpinner";
 */
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import { Redirect } from "react-router-dom";
/**
 * ! import dropdown button options
 */
import DropdownButtonOptions from "../listProducts/DropDownButtonOptions";
/**
 * ! select plugin work
 */
import PluginWork from "./PluginWork";
import FilterProduct from "./FilterProduct";
/**
 * ! impor imag para hacer prueba y los componentes extras
 */
import ImgOdoo from "../../../../assets/img/jpeg/odoo-img.jpg";
import ImgMl from '../../../../assets/img/jpeg/ml-img.jpg';
import TableDashboardStyle from "../../../../assets/css/components/admin/products/TableDashboardStyle.css";
import Pager from "../../../general/Pager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDotCircle,
  faRedo,
  faSadTear,
  faMeh,
  faShare,
  faTimes,

} from "@fortawesome/free-solid-svg-icons";
import DropDownButtonOptionsFilter from "../listProducts/DropDownButtonsOptionsFilter";
import CardFilter from "../listProducts/CardFilter";
import SelectBrand from "./SelectBrand";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import Routes from "../../../../Routes";

export default function ListProducts() {
  const globalContext = useContext(GlobalContext);
  const pageItemCount = 20;

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [currentPlugin, setCurrentPlugin] = useState("");
  const [mainPlugin, setMainPlugin] = useState("");
  const [products, setProducts] = useState(null);
  const [totalProducts, setTotalProducts] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [trMsg, setTrMsg] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [numRefresh, setNumRefresh] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterLinkedTo, setFilterLinkedTo] = useState("");
  const [onCbtMarketplaces, setonCbtMarketplaces] = useState(null);
  const [filterPrice, setFilterPrice] = useState("");
  const [filterStock, setFilterStock] = useState("");
  const [filterWithCompany, setFilterWithCompany] = useState(null);
  const [filterWithTaxes, setFilterWithTaxes] = useState(null);
  const [amazonMaxManufacturingTime, setAmazonMaxManufacturingTime] = useState(false);
  const [currentBrand, setCurrentBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [itemsChangeStore, setItemsChangeStore] =  useState([]);
  
useEffect(() => {
  const hasAccess = verifyAccessRole(Routes, 31);
  if(!hasAccess) {
      setRedirect(
          <Redirect to={`/admin/dashboard`} />
      )
  }
},[]);

  const additemsChangeStore = (id) => {
    const isHere = itemsChangeStore.find((e) => (e === id));
        if (isHere === undefined) {
            setItemsChangeStore([...itemsChangeStore, id]);
        } else {
          setItemsChangeStore(itemsChangeStore.filter((e) => (e !== id)))
        }
  }
  let productsTrs = (
    <tr>
      <td colSpan="7" className="text-center">
        <Spinner animation="grow" />
      </td>
    </tr>
  );

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error

    if (err.response && err.response.data) {
        if (err.response.data.message) {
            globalContext.showModalWithMsg(err.response.data.message);
        } else {
            globalContext.showModalWithMsg(err.response.data);
        }
    } else if (msg) {
        globalContext.showModalWithMsg(msg);
    }

    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Updates the filter
   */
  const updateFilter = (filter) => {
    setFilter(filter);
  };

  /**
   * Updates the provided filters
   * @param {Array} filterArray All the filters to update
   */
  const updateAllFilters = (filterArray) => {
    if (typeof filterArray["filter"] != "undefined") setFilter(filterArray["filter"]);
    if (typeof filterArray["filterLinkedTo"] != "undefined") setFilterLinkedTo(filterArray["filterLinkedTo"]);
    if (typeof filterArray["onCbtMarketplaces"] != "undefined")setonCbtMarketplaces(filterArray["onCbtMarketplaces"]);
    if (typeof filterArray["filterPrice"] != "undefined") setFilterPrice(filterArray["filterPrice"]);
    if (typeof filterArray["filterStock"] != "undefined") setFilterStock(filterArray["filterStock"]);
    if (typeof filterArray["filterWithCompany"] != "undefined") setFilterWithCompany(filterArray["filterWithCompany"]);
    if (typeof filterArray["filterWithTaxes"] != "undefined") setFilterWithTaxes(filterArray["filterWithTaxes"]);
    if (typeof filterArray["amazonMaxManufacturingTime"] != "undefined") setAmazonMaxManufacturingTime(filterArray["amazonMaxManufacturingTime"]);
  }
  /**
   * Change the current plugins and reset the plugin specific filters
   */
  const updateCurrentPlugin = (plugin) => {
    setCurrentPlugin(plugin);
    setFilterWithCompany(null);
  };

  /**
   * Changes the selected brand
   */
  const updateBrand = (brand) => {
    setCurrentBrand(brand);
  };

  /**
   * Loads the products list
   */
  const loadProducts = () => {
    //*Get the products
    if (!globalContext.currentEnterpriseId) {
      setProducts(null);
      setTrMsg(
        <tr>
          <td colSpan="7" className="text-center">
            Debe seleccionar una empresa <FontAwesomeIcon icon={faMeh} />
          </td>
        </tr>
      );
    } else if (!currentPlugin || currentPlugin === "") {
      setProducts(null);
      setTrMsg(
        <tr>
          <td colSpan="7" className="text-center">
            Debe seleccionar un plugin <FontAwesomeIcon icon={faMeh} />
          </td>
        </tr>
      );
      
    } else {//if we got currentEnterpriseId and currentPlugin then get the products 
      setProducts(null);
      setTrMsg(null);
      const entId = globalContext.currentEnterpriseId;

      switch (currentPlugin) {
        case "odoo":
          axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}`, {
              params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                  title: filter,
                  barCode: filter,
                  variBarCode: filter,
                  sku: filter,
                  variSku: filter,
                  filterLinkedTo,
                  skipVariants: true,
                  filterPrice,
                  filterStock,
                  filterWithCompany,
                  filterWithTaxes,
                },
                checkIsFullyLinked: true,
                includeVariantData: true,
              },
              cancelToken: axiosCancelTokenSource.token,
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
              },
            })
            .then((res) => {
              const data = res.data.data;
              setTotalProducts(data.totalProducts);
              setProducts(
                data.products.map((prod) => {
                  const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                  const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                  const variSkuOrig = prod.variants.map(v => v.attrib.ref_int).filter(s => s !== undefined);
                  const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                  return {
                    id: prod._id,
                    title: prod.title,
                    price: new Intl.NumberFormat("de-DE").format(prod.price),
                    stock: prod.stock,
                    linked: prod.linkedTo && prod.linkedTo.length > 0,
                    isFullyLinked: prod.isFullyLinked,
                    isPartiallyLinked: prod.isPartiallyLinked,
                    thumbnail:
                      (prod.thumbnail && typeof prod.thumbnail == "object" && prod.thumbnail.url) ? prod.thumbnail.url
                      : (prod.thumbnail && typeof prod.thumbnail == "string") ? `data:image/png;base64,${prod.thumbnail}`
                      : false,
                    barCode: prod.attrib.barCode,
                    variBarCode,
                    sku: prod.attrib.ref_int,
                    variSku,
                    hasTaxes: prod.hasTaxes,
                  };
                })
              );
            })
            .catch((err) =>
              handleAxiosError(
                err,
                err
              )
            );
          break; //End odoo

          case "fl":
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/v1/fala-product/getProducts/${entId}`,
              {
                params: {
                  pageNumber: currentPage,
                  pageItemCount: pageItemCount,
                  filter: {
                    title: filter,
                    barCode: filter,
                    variBarCode: filter,
                    sellerSku: filter,
                    variSku: filter,
                    filterLinkedTo,
                    brand: currentBrand,
                    skipVariants: true,
                    filterPrice,
                    filterStock,
                  },
                  checkIsFullyLinked: true,
                  includeVariantData: true,
                },
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "x-access-token": localStorage.getItem(
                    keyAccessToken
                  ),
                  "x-user-id": localStorage.getItem(keyUserId),
                },
              }
            )
            .then((res) => {
              const data = res.data.data;
              console.log(res)
              setTotalProducts(data.totalProducts)
              if(data.totalProducts === 0){
                handleAxiosError(
                  'No se encontro nada',
                  "Disculpe, no tiene registrado ningun productos para falabella."
                )
              }else {
                setProducts(
                  data.products.map((prod) => {
                    const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                    const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                    const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                    const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                    return {
                      id: prod._id,
                      title: prod.title,
                      price: new Intl.NumberFormat("de-DE").format(prod.price),
                      stock: prod.stock,
                      linked: prod.linkedTo && prod.linkedTo.length > 0,
                      isFullyLinked: prod.isFullyLinked,
                      isPartiallyLinked: prod.isPartiallyLinked,
                      thumbnail: prod.thumbnail,
                      officialStoreId: prod.attrib.official_store_id,
                      barCode: prod.attrib.barCode,
                      variBarCode,
                      sku: prod.attrib.sellerSku,
                      variSku,
                      status: prod.attrib.status,
                    };
                  })
                )
                console.log(data)
              }
            })
            .catch((err) =>
              handleAxiosError(
                err,
                err
              )
            );
          break;

        case "ml":
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}`,
              {
                params: {
                  pageNumber: currentPage,
                  pageItemCount: pageItemCount,
                  filter: {
                    title: filter,
                    barCode: filter,
                    variBarCode: filter,
                    sku: filter,
                    variSku: filter,
                    filterLinkedTo,
                    brand: currentBrand,
                    skipVariants: true,
                    onCbtMarketplaces,
                    filterPrice,
                    filterStock,
                    ...(amazonMaxManufacturingTime && {amazonMaxManufacturingTime : 6})
                  },
                  checkIsFullyLinked: true,
                  includeVariantData: true,
                },
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "x-access-token": localStorage.getItem(
                    keyAccessToken
                  ),
                  "x-user-id": localStorage.getItem(keyUserId),
                },
              }
            )
            .then((res) => {
              const data = res.data.data;
              console.log('la data de ml', data)
              setTotalProducts(data.totalProducts);
              setProducts(
                data.products.map((prod) => {
                  const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                  const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                  const variSkuOrig = prod.variants.map(v => v.attrib.sku).filter(s => s !== undefined);
                  const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                  return {
                    id: prod._id,
                    title: prod.title,
                    price: new Intl.NumberFormat("de-DE").format(prod.price),
                    stock: prod.stock,
                    linked: prod.linkedTo && prod.linkedTo.length > 0,
                    isFullyLinked: prod.isFullyLinked,
                    isPartiallyLinked: prod.isPartiallyLinked,
                    thumbnail: prod.thumbnail,
                    officialStoreId: prod.attrib.official_store_id,
                    barCode: prod.attrib.barCode,
                    variBarCode,
                    sku: prod.attrib.sku,
                    variSku,
                    status: prod.attrib.status,
                    cbtMarketplace:prod.attrib.cbtMarketplace,
                    cbt: prod.attrib.isCbt || false
                  };
                })
              );
            })
            .catch((err) =>
              handleAxiosError(
                err,
                err
              )
            );
          break; //End ml
          /**
           * ! lineo
           */
          case "ln":
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/v1/linio/getProducts/${entId}`,
              {
                params: {
                  pageNumber: currentPage,
                  pageItemCount: pageItemCount,
                  filter: {
                    title: filter,
                    barCode: filter,
                    variBarCode: filter,
                    sellerSku: filter,
                    variSku: filter,
                    filterLinkedTo,
                    brand: currentBrand,
                    skipVariants: true,
                    filterPrice,
                    filterStock,
                  },
                  checkIsFullyLinked: true,
                  includeVariantData: true,
                },
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                  "x-api-key": process.env.REACT_APP_API_KEY,
                  "x-access-token": localStorage.getItem(
                    keyAccessToken
                  ),
                  "x-user-id": localStorage.getItem(keyUserId),
                },
              }
            )
            .then((res) => {
              const data = res.data.data;
              console.log(res)
              setTotalProducts(data.totalProducts)
              if(data.totalProducts === 0){
                handleAxiosError(
                  'No se encontro nada',
                  "Disculpe, no tiene registrado ningun productos para linio."
                )
              }else {
                setProducts(
                  data.products.map((prod) => {
                    const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                    const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                    const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                    const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);

                    return {
                      id: prod._id,
                      title: prod.title,
                      price: new Intl.NumberFormat("de-DE").format(prod.price),
                      stock: prod.stock,
                      linked: prod.linkedTo && prod.linkedTo.length > 0,
                      isFullyLinked: prod.isFullyLinked,
                      isPartiallyLinked: prod.isPartiallyLinked,
                      thumbnail: prod.thumbnail,
                      officialStoreId: prod.attrib.official_store_id,
                      barCode: prod.attrib.barCode,
                      variBarCode,
                      sku: prod.attrib.sellerSku,
                      variSku,
                      status: prod.attrib.status,
                    };
                  })
                )
                console.log(data)
              }
            })
            .catch((err) =>
              handleAxiosError(
                err,
                err
              )
            );
          break; //End lineo
          case "df":
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-product/getProducts/${entId}`,
                {
                  params: {
                    pageNumber: currentPage,
                    pageItemCount: pageItemCount,
                    filter: {
                      title: filter,
                      barCode: filter,
                      variBarCode: filter,
                      sellerSku: filter,
                      variSku: filter,
                      filterLinkedTo,
                      brand: currentBrand,
                      skipVariants: true,
                      filterPrice,
                      filterStock,
                    },
                    checkIsFullyLinked: true,
                    includeVariantData: true,
                  },
                  cancelToken: axiosCancelTokenSource.token,
                  headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(
                      keyAccessToken
                    ),
                    "x-user-id": localStorage.getItem(keyUserId),
                  },
                }
              )
              .then((res) => {
                const data = res.data.data;
                console.log(res)
                setTotalProducts(data.totalProducts)
                if(data.totalProducts === 0){
                  handleAxiosError(
                    'No se encontro nada',
                    "Disculpe, no tiene registrado ningun productos para Dafiti."
                  )
                }else {
                  setProducts(
                    data.products.map((prod) => {
                      const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                      const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                      const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                      const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);
  
                      return {
                        id: prod._id,
                        title: prod.title,
                        price: new Intl.NumberFormat("de-DE").format(prod.price),
                        stock: prod.stock,
                        linked: prod.linkedTo && prod.linkedTo.length > 0,
                        isFullyLinked: prod.isFullyLinked,
                        isPartiallyLinked: prod.isPartiallyLinked,
                        thumbnail: prod.thumbnail,
                        officialStoreId: prod.attrib.official_store_id,
                        barCode: prod.attrib.barCode,
                        variBarCode,
                        sku: prod.attrib.sellerSku,
                        variSku,
                        status: prod.attrib.status,
                      };
                    })
                  )
                }
              })
              .catch((err) =>
                handleAxiosError(
                  err,
                  err
                )
              );
            break; //End dafiti
            case "coco":
              console.log('para coco')
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/productos-coco/coco-get-products/${entId}`,
                
                  {
                    pageNumber: currentPage,
                    pageItemCount: pageItemCount,
                    filter: {
                      title: filter,
                      barCode: filter,
                      variBarCode: filter,
                      sellerSku: filter,
                      variSku: filter,
                      filterLinkedTo,
                      brand: currentBrand,
                      skipVariants: true,
                      filterPrice,
                      filterStock,
                    },
                    checkIsFullyLinked: true,
                    includeVariantData: true,
                  },
                  {
                    cancelToken: axiosCancelTokenSource.token,
                  headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(
                      keyAccessToken
                    ),
                    "x-user-id": localStorage.getItem(keyUserId),
                  },
                  }
              )
              .then((res) => {
                const data = res.data.data;
                console.log(res)
                setTotalProducts(data.totalProducts)
                if(data.totalProducts === 0){
                  handleAxiosError(
                    'No se encontro nada',
                    "Disculpe, no tiene registrado ningun productos para Coco Mercado."
                  )
                }else {
                  setProducts(
                    data.products.map((prod) => {
                      const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                      const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                      const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                      const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);
  
                      return {
                        id: prod._id,
                        title: prod.title,
                        price: new Intl.NumberFormat("de-DE").format(prod.price),
                        stock: prod.stock,
                        linked: prod.linkedTo && prod.linkedTo.length > 0,
                        isFullyLinked: prod.isFullyLinked,
                        isPartiallyLinked: prod.isPartiallyLinked,
                        thumbnail: prod.thumbnail,
                        officialStoreId: prod.attrib.official_store_id,
                        barCode: prod.attrib.barCode,
                        variBarCode,
                        sku: prod.attrib.sellerSku,
                        variSku,
                        status: prod.attrib.status,
                      };
                    })
                  )
                }
              })
              .catch((err) =>
                handleAxiosError(
                  err,
                  err
                )
              );
            break; //End coco
          case "cashea":
              axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-prod/get-products`, {
                  cancelToken: axiosCancelTokenSource.token,
                  headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                  },
                  params: {
                    name: currentBrand === 'cashea' ? currentBrand : '212 GLOBAL',
                    pageNumber: currentPage,
                    pageItemCount: pageItemCount,
                  }
                })
                .then((resp) => {
                  const data = Object.values(resp.data.data.products);
                  setTotalProducts(resp.data.data.totalProducts)
                  setTotalProducts(data.totalProducts)
                  if(data.totalProducts === 0){
                    handleAxiosError(
                      'No se encontro nada',
                    )
                  }else {
                    setProducts(
                      data.map((prod) => {
                        const variBarCodeOrig = prod.variants.map(v => v.attrib.barCode).filter(b => b !== undefined);
                        const variBarCode = variBarCodeOrig.filter((b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx);
                        const variSkuOrig = prod.variants.map(v => v.attrib.sellerSku).filter(s => s !== undefined);
                        const variSku = variSkuOrig.filter((s, sIdx) => variSkuOrig.indexOf(s) === sIdx);
                        return {
                          id: prod._id,
                          title: prod.title,
                          price: new Intl.NumberFormat("de-DE").format(prod.price),
                          stock: prod.stock,
                          thumbnail: prod.thumbnail.url,
                          officialStoreId: prod.attrib.official_store_id,
                          barCode: prod.attrib.barCode,
                          variBarCode,
                          sku: prod.attrib.ref_int,
                          variSku,
                        };
                      })
                    )
                    console.log(data)
                  }
                })
                .catch((err) => handleAxiosError(err))
              .finally(() => {
                globalContext.setLoaderPage(false);
              });
            break;
            default:
              break;
      }
    }
  };

  /**
   * *Reset brand list
   */
  useEffect(() => {
    setCurrentBrand("");
  }, [currentPlugin, globalContext.currentEnterpriseId]);

  /**
   * *Load the brands list
   */
  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    switch(currentPlugin){
      case 'ml':
          axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-access-token": localStorage.getItem(keyAccessToken),
              "x-user-id": localStorage.getItem(keyUserId),
            },
          })
          .then((resp) => {
            const brands = resp.data.data.brands;
            setBrands(brands);
          })
          .catch((err) => handleAxiosError(err));
          break
      case 'cashea':
          axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/merchants-name/merchantsName`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-access-token": localStorage.getItem(keyAccessToken),
              "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
              name: 'all'
            }
          })
          .then((resp) => {
            const brands = resp.data.data;
            setBrands(Object.values(brands));
          })
          .catch((err) => handleAxiosError(err));  
        break
      default:
        return
      }
  }, [globalContext.currentEnterpriseId, currentPlugin]);

  /**
   * Search the user main plugin
   */
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((res) => setMainPlugin(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  /**
   * Load the products list if currently is in the first page, else, set the currentPage to 1 or see for any filter changes
   */
  useEffect(() => {
    if (currentPage === 1) {
      loadProducts();
    } else {
      setCurrentPage(1);
    }
  }, [
    currentPlugin,
    currentBrand,
    globalContext.currentEnterpriseId,
    filter,
    filterLinkedTo,
    filterPrice,
    filterStock,
    filterWithCompany,
    filterWithTaxes,
    amazonMaxManufacturingTime,
    onCbtMarketplaces,
  ]);

  /**
   * Load the products list when the page number changes or a refresh is requested
   */
  useEffect(() => {
    loadProducts();
  }, [currentPage, numRefresh]);

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Redirect to the detail page
   * @param {string} plugin The product plugin
   * @param {string} id The product ID
   */
  const goToDetail = (plugin, id) => {
    setRedirect(
      <Redirect to={`/admin/productos/detail-products/${plugin}/${id}`} />
    );
  };
  const goToPublic = (id) => {
    setRedirect(
      <Redirect to={`/admin/productos/public-product/${id}`} />
    )
  }

  if (products) {
    if (products.length === 0) {
      productsTrs = (
        <tr>
          <td colSpan="7" className="text-center">
            No se encontraron productos que mostrar!{" "}
            <FontAwesomeIcon icon={faSadTear} />
          </td>
        </tr>
      );
    } else {
      productsTrs = products.map((prod) => {
        let mlBrandName = "";

        if (prod.officialStoreId && brands.length > 0) {
          const brand = brands.filter((b) => b.id === prod.officialStoreId);

          if (brand.length > 0) {
            mlBrandName = brand[0].name;
          }
        }

        return (
          <tr key={prod.id}>
            {currentPlugin === "odoo" ? (
              <td className="column1">
                <Image
                  src={
                    (!prod.thumbnail || prod.thumbnail === "data:image/png;base64,false")
                      ? ImgOdoo
                      : prod.thumbnail
                  }
                  rounded
                />
              </td>
            ) : (
              <td className="column1">
                <Image src={prod.thumbnail || ImgMl} rounded />
              </td>
            )}
            <td className="column2">
              <a
                className="linkProduct"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  goToDetail(currentPlugin, prod.id);
                }}
              >
                {prod.title}
                {mlBrandName && (
                  <>
                    <br />
                    <span>
                      <i>{mlBrandName}</i>
                    </span>
                  </>
                )}
              </a>
            </td>
            {/* <td className="column3">
              {prod.price}
              {
                currentPlugin === "odoo" && prod.hasTaxes
                  && <span style={{fontSize: "10pt", color: "lightgray"}}><br/>(Posee impuestos)</span>
              }
            </td>
            <td className="column4">{prod.stock}</td> */}
            <td className="column5">
              {(prod.barCode && prod.variBarCode.indexOf(prod.barCode) === -1
                ? prod.variBarCode.concat(prod.barCode)
                : prod.variBarCode
              ).map((b) => (
                <span key={b}>
                  {b}
                  <br />
                </span>
              ))}
            </td>
            <td className="column5">
              {
                (prod.sku && prod.variSku.indexOf(prod.sku) === -1
                  ? prod.variSku.concat(prod.sku)
                  : prod.variSku
                ).filter((s) => s ).map((v) => {
                  return (
                    <span key={v.toString()}>
                      {v.toString()}
                      <br />
                    </span>
                  )
                }
                )
              }
            </td>
            {currentPlugin === "ml" || currentPlugin === "ln" || currentPlugin === "df" && (
              <td className="column6">{prod.status}</td>
            )}
            <td className="column7">
              <span
                className={
                  "sincronizado " +
                  " " +
                  (prod.isFullyLinked
                    ? " active2"
                    : prod.isPartiallyLinked
                    ? " active"
                    : "")
                }
              >
                <FontAwesomeIcon icon={faDotCircle} />
              </span>
            </td>
            {
              currentPlugin === "odoo" && (
                <td className="column8">
                  <button className="publish-btn" onClick={() => goToPublic(prod.id)}>
                    <FontAwesomeIcon icon={faShare} />
                  </button>
                </td>
              ) 
            }
            {
              currentPlugin === "ml" && (
                <td className="column8">
                  <input
                      className="inp-cbx"
                      style={{ display: 'none' }}
                      id={`cbx${prod.id}`}
                      type="checkbox"
                      onChange={() => additemsChangeStore(prod.id)}
                      checked={itemsChangeStore.includes(prod.id)}
                  />
                  <label className="cbx" htmlFor={`cbx${prod.id}`}>
                      <span>
                          <svg width="12px" height="10px" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                          </svg>
                      </span>
                  </label>
                {/*if cbtMarketplace is null show btn */}
                {!prod?.cbtMarketplace &&
                (
                  <button onClick={() => { 
                    cbtToMarket(prod)
                  }}>
                    CBT 
                  </button>
                )
                } 
                </td>
              ) 
            }
          </tr>
        );
      });
    }
  }

  const sendDataToPublisOtheStore = (v) => {
    globalContext.setActiveModalPublicOtherStore(true);
    globalContext.setItemsPublicOtherStore(itemsChangeStore)
  }

  const cbtToMarket = (idProd) => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) {
        globalContext.showModalWithMsg("La empresa...");
        return;
    }
    if(!idProd.cbt) {
        globalContext.showModalWithMsg("No es de CBT");
        return;
    }
    globalContext.setLoaderPage(true);
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/publish-cbt-to-market`, {
        countries: [{site:"MCO"}],
        products: [idProd.id],
        status: "paused",
    }, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    .then(resp => {console.log(resp.data); globalContext.setLoaderPage(false);}).catch(err => {handleAxiosError(err); globalContext.setLoaderPage(false);});
}

  return (
    <TableDashboardStyle>
      {redirect}

      <Container fluid>
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="limiterHeader">
              <div className="container-header">
                <div className="ActionsContent">
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="colHeader"
                    >
                      <PluginWork
                        currentPlugin={currentPlugin}
                        setCurrentPlugin={updateCurrentPlugin}
                        brands={brands}
                        currentBrand={currentBrand}
                        updateBrand={updateBrand}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="colHeader"
                    >
                      <FilterProduct
                        filter={filter}
                        updateFilter={updateFilter}
                      />
                    </Col>
                    <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="colHeader"
                    >
                      <SelectBrand
                      currentPlugin={currentPlugin}
                      setCurrentPlugin={updateCurrentPlugin}
                      brands={brands}
                      currentBrand={currentBrand}
                      updateBrand={updateBrand}
                      />
                    </Col>
                    <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="colHeader d-flex justify-content-center align-items-center"
                    >
                      {
                        itemsChangeStore.length > 0 && (
                          <>
                            <button 
                            className="btn-publish-in-another-store"
                            onClick={() => sendDataToPublisOtheStore()}
                            >
                              {`Publicar ${itemsChangeStore.length} en otra tienda`}
                            </button>
                            <button 
                            className="btn-publish-in-another-store-clear"
                            onClick={() => setItemsChangeStore([])}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </>
                        )
                      }
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="colHeader d-flex justify-content-center align-items-center"
                    >
                      <DropdownButtonOptions
                        buttonTittle="Opciones"
                        currentPlugin={currentPlugin}
                        mainPlugin={mainPlugin}
                        numRefresh={numRefresh}
                        setNumRefresh={setNumRefresh}
                        filter={filter}
                        filterLinkedTo={filterLinkedTo}
                        filterPrice={filterPrice}
                        filterStock={filterStock}
                        filterWithCompany={filterWithCompany}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="colHeader d-flex justify-content-center align-items-center"
                    >
                      {
                        currentPlugin !== "cashea" && (
                      <DropDownButtonOptionsFilter
                        currentPlugin={currentPlugin}
                        filterLinkedTo={filterLinkedTo}
                        filterPrice={filterPrice}
                        filterStock={filterStock}
                        onCbtMarketplaces={onCbtMarketplaces}
                        filterWithCompany={filterWithCompany}
                        filterWithTaxes={filterWithTaxes}
                        amazonMaxManufacturingTime={amazonMaxManufacturingTime}
                        updateFilters={updateAllFilters}
                      />
                        )
                      }
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="colHeader"
                    >
                      <CardFilter
                        currentPlugin={currentPlugin}
                        filterLinkedTo={filterLinkedTo}
                        filterPrice={filterPrice}
                        onCbtMarketplaces={onCbtMarketplaces}
                        filterStock={filterStock}
                        filterWithCompany={filterWithCompany}
                        filterWithTaxes={filterWithTaxes}
                        amazonMaxManufacturingTime={amazonMaxManufacturingTime}
                        updateFilters={updateAllFilters}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
            <div className="limiter">
              <div className="container-table100">
                <div className="wrap-table100 col-12 mt-3">
                  <div className="table100">
                    <table>
                      <thead>
                        <tr className="table100-head">
                          <th className="column1">IMAGEN</th>
                          <th className="column2">TITULO</th>
                          {/* <th className="column3">PRECIO</th>
                          <th className="column4">STOCK</th> */}
                          <th className="column5">COD BARRAS</th>
                          <th className="column6">SKU</th>
                          {currentPlugin === "ml" && (
                            <th className="column6">STATUS. PUB</th>
                          )}
                          <th className="column7">
                            <button
                              className="btn-refresh"
                              onClick={() => {
                                setNumRefresh(numRefresh + 1);
                              }}
                            >
                              <span className="icon">
                                <FontAwesomeIcon icon={faRedo} />
                              </span>
                            </button>
                          </th>
                          {
                              currentPlugin === "odoo" && (
                                <th className="column8">

                                </th>
                              ) 
                            }
                        </tr>
                      </thead>
                      <tbody>{trMsg || productsTrs}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {products && products.length > 0 && (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex justify-content-center"
            >
              <Pager
                handleSetPage={setCurrentPage}
                totalResults={totalProducts}
                currentPage={currentPage}
                resultsPerPage={pageItemCount}
              />
            </Col>
          )}
        </Row>
      </Container>
    </TableDashboardStyle>
  );
}
