import { apiRoutes } from "../../config";
import { api } from "../../utils/lib/api"


export const getTrackingInfo = async ({trackingCode}) => {
    try {
        return (await api.get(`${apiRoutes.tracking.getTracking}`, {
            params: {
                id: trackingCode
            },
            headers:{
                'x-api-key': process.env.REACT_APP_API_KEY,
            }
        }));
    } catch (error) {
        let errorMessage = "Something went wrong trying on getTrackingInfo";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
};