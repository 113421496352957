import React, { useEffect, useState, useContext } from "react";
import RatesStyles from '../../assets/css/components/admin/rates/RatesStyles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pager from "../../components/general/Pager";
import {
    Container,
    Row,
    Col,
    Form,
    FormControl,
    InputGroup,
    Button,
} from "react-bootstrap";
import { GlobalContext } from "../../contexts/GlobalContext";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../assets/js/SessionStorageNames";
import { Redirect } from "react-router-dom";
import Routes from "../../Routes";
import verifyAccessRole from "../../assets/js/verifyAccessRole";

export default function Rates() {
    const globalContext = useContext(GlobalContext);
    const pageItemCount = 20;

    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [campaigns, setCampaigns] = useState([]);
    const [priceLists, setPriceLists] = useState([]);
    const [currentCampaign, setCurrentCampaign] = useState("");
    const [currentPriceList, setCurrentPriceList] = useState("");
    const [percent, setPercent] = useState("");
    const [campaignsSavedTotal, setCampaignsSavedTotal] = useState(0);
    const [campaignsSaved, setCampaignsSaved] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numRefresh, setNumRefresh] = useState(0);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 12);
        if(!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * Load the campaigns with pricelist
     */
    const loadCampaignsSaved = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-campaign/${entId}`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    pageNumber: currentPage,
                    pageItemCount,
                }
            })
            .then(resp => {
                const data = resp.data.data;
                setCampaignsSavedTotal(data.totalCampaigns);
                setCampaignsSaved(data.campaigns);
            })
            .catch((err) =>
                handleAxiosError(
                    err,
                    (err.response && err.response.data && err.response.data.message)
                        ? err.response.data.message
                        : "Disculpe, no se pudo cargar la lista de campañas guardadas"
                )
            )
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
    }

    /**
     * Deletes all products deom a campaign
     * @param {*} campaignId 
     */
    const deleteCampaignSaved = (campaignId) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        if (!window.confirm("Seguro de querer eliminar los productos de la campaña?")) return;

        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        globalContext.setLoaderPage(true);
        axios
            .delete(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-campaign/${entId}/${campaignId}`, {
                cancelToken: axiosCancelTokenSource.token,
                headers,
            })
            .then(resp => {
                const data = resp.data.data;

                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;

                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);
                                        globalContext.setLoaderPage(false);
                                        setNumRefresh(numRefresh +1);
                                    } else {
                                        const advance = data.advance.toFixed(2);
                                        const msg = `Eliminando productos de la campaña. ${advance}% (${data.data.numProcessed}/${data.data.totalProds})`;
                                        globalContext.setLoaderPage(true, msg);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo terminar de enviar los productos."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                }
            })
            .catch((err) =>
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo eliminar los productos de la campaña"
                )
            )
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
    }
    
    /**
     * Load the data if currently is in the first page, else, set the currentPage to 1
     */
    useEffect(() => {
        if (currentPage === 1) {
            loadCampaignsSaved();
        } else {
            setCurrentPage(1);
        }
    }, [globalContext.currentEnterpriseId]);
    
    /**
     * Load the data when the page number changes or a refresh is requested
     */
    useEffect(() => {
        loadCampaignsSaved();
    }, [currentPage, numRefresh]);

    /**
     * Setter
     */
    const updateCurrentCampaign = (e) => {
        setCurrentCampaign(e.target.value);
    }
    
    /**
     * Setter
     */
    const updateCurrentPriceList = (e) => {
        setCurrentPriceList(e.target.value);
    }
    
    /**
     * Setter
     */
    const updatePercent = (e) => {
        setPercent(e.target.value);
    }

    /**
     * Make a register request
     */
    const postProducts = () => {
        const pricelistId = currentPriceList;
        const campaignId = currentCampaign;
        const plusPercent = percent;

        if (!pricelistId || !campaignId || (!plusPercent && plusPercent !== 0)) return;

        const campaignType = campaigns.find(c => c.id === campaignId)?.type;

        sendProducts(true, pricelistId, campaignId, plusPercent, campaignType);
    }

    /**
     * Make an update request 
     */
    const updateProducts = (campaignId, pricelistId, currentPercent) => {
        if (!pricelistId || !campaignId) return;

        const plusPercent = window.prompt("Ingrese el nuevo porcentaje", currentPercent);

        if (!plusPercent) return;

        const campaignType = campaigns.find(c => c.id === campaignId)?.type;

        sendProducts(false, pricelistId, campaignId, plusPercent, campaignType);
    }

    /**
     * Send the products data to mercadolibre
     */
    const sendProducts = (isRegister, pricelistId, campaignId, plusPercent, campaignType) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        globalContext.setLoaderPage(true);
        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-campaign/${entId}/send-from-odoo-pricelist`, {
                isRegister,
                pricelistId,
                campaignId,
                plusPercent,
                campaignType
            }, {
                cancelToken: axiosCancelTokenSource.token,
                headers,
            }).then(resp => {
                const data = resp.data.data;

                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;

                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        clearInterval(interval);

                                        let msg = `Se agregaron ${data.data.sentProducts} productos a la campaña`;

                                        if (data.data.errors && data.data.errors.length > 0) {
                                            msg = (
                                                <div>
                                                    {msg}<br/>
                                                    <br/>Se encontraron los siguientes errores:
                                                    {data.data.errors.map((e, i) => <span key={i}><br/>- {e}</span>)}
                                                </div>
                                            );
                                        }

                                        globalContext.showModalWithMsg(msg, "", {size: "lg"});
                                        setNumRefresh(numRefresh+1);
                                        globalContext.setLoaderPage(false);
                                    } else {
                                        let msg = '';

                                        switch (data.data.doing) {
                                            case 'getMlProductIds':
                                                msg = 'Obteniendo lista de productos actualmente en la campaña';
                                                break;
                                            case 'getOdooProducts':
                                                msg = `Descargando productos de Odoo asociados a los items de la tarifa (${data.data.currentPricelistItem} de ${data.data.totalPricelistItems})`;
                                                msg = (
                                                    <div>
                                                        Descargando productos de Odoo asociados a la tarifa
                                                        {
                                                            data.data.currentPricelistItem && (
                                                                <span><br/>Procesando item {data.data.currentPricelistItem} de {data.data.totalPricelistItems}</span>
                                                            )
                                                        }
                                                        {
                                                            data.data.currentPricelistItemProduct && (
                                                                <span><br/>Producto {data.data.currentPricelistItemProduct} de {data.data.totalCurrentPricelistItemProducts}</span>
                                                            )
                                                        }
                                                    </div>
                                                );
                                                break;
                                            case 'getMlProducts':
                                                msg = 'Descargando productos de Mercadolibre asociados a los de Odoo';
                                                break;
                                            case 'sendProducts':
                                                const advance = data.advance.toFixed(2);
                                                msg = `Enviando productos a la campaña. ${advance}% (${data.data.numProcessed}/${data.data.totalProducts})`;
                                                break;
                                        }
                                        
                                        globalContext.setLoaderPage(true, msg);
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo terminar de enviar los productos."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => {
                                    waitingMonitorResponse = false;
                                });
                        }
                    }, processCheckIntervalTime);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    (err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Disculpe, no se pudieron enviar los datos de los productos"
                );
                
                globalContext.setLoaderPage(false);
            });
    }

    /**
     * Redirect to the detail page
     * @param {string} campaignId The ML campaign ID
     */
    const goToDetail = (campaignId) => {
        setRedirect(
            <Redirect to={`/admin/rates-detail/${campaignId}`} />
        );
    };

    /**
     * Load the campaigns
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-campaign/${entId}/ml`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    filter: { canStillPost: true }
                },
            }).then(resp => {
                const data = resp.data.data;
                setCampaigns(data.campaigns);
            })
            .catch((err) =>
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la lista de campañas."
                )
            );
    }, [globalContext.currentEnterpriseId]);

    /**
     * Load the pricelists
     */
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }
        ).then(resp => {
            const data = resp.data.data;
            setPriceLists(data.priceLists);
        })
        .catch((err) =>
            handleAxiosError(
                err,
                "Disculpe, no se pudo cargar la lista de tarifas."
            )
        );
    }, [globalContext.currentEnterpriseId]);

    return (
        <RatesStyles>
            {redirect}
            <Container fluid>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="limiterHeader">
                            <div className="container-header">
                                <div className="ActionsContent">
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            className="colHeader"
                                        >
                                            <Form.Control
                                                as="select"
                                                name="currentCampaign"
                                                onChange={updateCurrentCampaign}
                                                value={currentCampaign || ""}
                                            >
                                                <option key="" value="">
                                                    Seleccione una Campaña
                                                </option>
                                                {
                                                    campaigns.map(c => {
                                                        return (
                                                            <option key={c.id} value={c.id} title={c.description}>
                                                                {c.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </Form.Control>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            className="colHeader"
                                        >
                                            <Form.Control
                                                as="select"
                                                name="currentPriceList"
                                                onChange={updateCurrentPriceList}
                                                value={currentPriceList || ""}
                                            >
                                                <option key="" value="">
                                                    Seleccione una Tarifa
                                                </option>
                                                {
                                                    priceLists.map(pl => {
                                                        return (
                                                            <option key={pl.id} value={pl.id}>
                                                                {pl.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </Form.Control>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            className="colHeader"
                                        >
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Porcentaje"
                                                    aria-label="Porcentaje"
                                                    aria-describedby="basic-addon2"
                                                    value={percent}
                                                    onChange={updatePercent}
                                                />
                                                <InputGroup.Append>
                                                    <Button onClick={postProducts}>
                                                        <FontAwesomeIcon icon={faSave} />
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                        <div className="limiter">
                            <div className="container-table100">
                                <div className="wrap-table100 col-12 mt-5">
                                    <div className="table100">
                                        <table>
                                            <thead>
                                                <tr className="table100-head">
                                                    <th className="column1"> Campaña </th>
                                                    <th className="column2"> Tarifa </th>
                                                    <th className="column3"> % </th>
                                                    <th className="column4"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    campaignsSaved.map(camp => {
                                                        const pricelist = priceLists.filter(pl => pl.id == camp.odooPricelistId).shift();
                                                        const pricelistName = pricelist && pricelist.name;
                                                        return (
                                                            <tr className="table100-head" key={camp._id}>
                                                                <td className="column1">
                                                                    <a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        goToDetail(camp.id)
                                                                    }}>
                                                                        {camp.name}
                                                                    </a>
                                                                </td>
                                                                <td className="column2">{pricelistName || ""}</td>
                                                                <td className="column3">{camp.plusPercent}</td>
                                                                <td className="column4">
                                                                    <div className="IconsActions justify-content-between">

                                                                        <button className="btn-action-update" onClick={() => updateProducts(camp.id, camp.odooPricelistId, camp.plusPercent)}>
                                                                            <FontAwesomeIcon icon={faPen} />
                                                                        </button>
                                                                        <button
                                                                            className="btn-action-trash"
                                                                            onClick={() => {deleteCampaignSaved(camp._id)}}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        lg={12}
                        className="d-flex justify-content-center mt-2"
                    >
                        <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={campaignsSavedTotal}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                        />
                    </Col>
                </Row>
            </Container>
        </RatesStyles>
    );
}
