import styled, { keyframes } from "styled-components";



const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner1 = styled.div`
    width: 110px;
    height: 110px;
    border-bottom: 2px solid #7367F0;
    animation: ${rotate} 2s linear infinite;
    border-radius: 100%;
`