import { third } from "../../assets/js/SessionStorageNames";

 /**
   * verify if the user is third
   * @returns 
   */
 function verifyThirdPartyUser() {
    if(localStorage.getItem(third) === "true") return true;
    else if(localStorage.getItem(third) === "false") return false;
  }

  export default verifyThirdPartyUser;