import React from "react";
//* import css style
import CardsMenuStyle from "../../../assets/css/components/admin/menus/CardsMenu.css";
import printCardList from "../../../assets/js/printCardList";

export default function CardsMenu({ routes, father }) {

    let rutas = (
        <div className='cards-list'>
            {routes.map((propC, keyC) => {
                return printCardList(father, propC, keyC);
            })}
        </div>
    );

    return <><CardsMenuStyle>{rutas}</CardsMenuStyle></>;
}
