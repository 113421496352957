import React, { useState, useEffect, useContext, useRef } from "react";
import { BiChevronDown } from 'react-icons/bi'
import SelectEnterpriseStyle from '../../../../assets/css/components/admin/menus/components/SelectEnterpriseStyle'
import { keyUserId, keyAccessToken, keyUserActiveEnterpriseId, parentEntId } from '../../../../assets/js/SessionStorageNames';
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from 'axios';
export default function SelectEnterprise() {
    const globalContext = useContext(GlobalContext);
    const [StatusDropDown, setStatusDropDown] = useState([]);
    const [idOption, setIdOption] = useState("");
    const [openList, setOpenList] = useState(false);
    const [enterPriseText, setEnterprisetext] = useState('Debe seleccionar una empresa');
    const wrapperRef = useRef(null);
    
    const selectEnterprise = (v) => {
        setIdOption(v);
      };
  /**
   * Get the dropdown options
   */
   useEffect(() => { 
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/`,
      params: {
        pageNumber: 1,
        pageItemCount: 500,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    })
      .then((res) => {
        setStatusDropDown(res.data.data.enterprises);
      })
      .catch(() => {
        setEnterprisetext('Error al cargar las empresas');
      });
    //eslint-disable-next-line
  }, [globalContext.navEnterpriseOptionsRefresh]);

  /**
   * Select the currently active enterprise ID on the first load
   */
  useEffect(() => {
    const currentEntId = localStorage.getItem(keyUserActiveEnterpriseId);

    if (currentEntId) {
      setIdOption(currentEntId);
    }
  }, []);

  /**
   * Change the enterprise ID value in storageSession
   */
  useEffect(() => {
    localStorage.setItem(keyUserActiveEnterpriseId, idOption);
    globalContext.setCurrentEnterpriseId(idOption);
    //eslint-disable-next-line
  }, [idOption]);

  useEffect(() => {
    const parent = StatusDropDown.find(v => v._id.toString()===idOption.toString())?.parentId?._id;
    if (parent) {
      localStorage.setItem(parentEntId, parent);
      globalContext.setParentEntId(parent);
    } else {
      localStorage.removeItem(parentEntId);
      globalContext.setParentEntId(false);
    }
  },[globalContext.parentEntId, globalContext.currentEnterpriseId, idOption, StatusDropDown])
  
useEffect(() => {
    if(StatusDropDown.length === 0) return;
    if(idOption.length === 0) return;
    const valueText = StatusDropDown.find(v => v._id === idOption);
    if(valueText !== undefined) {
      setEnterprisetext(valueText.name);
    }else {
      setEnterprisetext('Debe seleccionar una empresa');
    }
}, [idOption, StatusDropDown]);

function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenList(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);


    return (
        <SelectEnterpriseStyle.ContSelect ref={wrapperRef}>
            <SelectEnterpriseStyle.SelectCustom onClick={() => setOpenList(!openList)}>
                <SelectEnterpriseStyle.Text size="12pt" fw="500" cl={idOption.length === 0 ? '#ea5455' : '#707070'}>
                    {
                        enterPriseText
                    }
                </SelectEnterpriseStyle.Text>
                <SelectEnterpriseStyle.BtnShowOptions>
                    <BiChevronDown />
                </SelectEnterpriseStyle.BtnShowOptions>
            </SelectEnterpriseStyle.SelectCustom>
            <SelectEnterpriseStyle.ContListOptions active={openList}>
                <SelectEnterpriseStyle.ListEnterprises>
                {
                    StatusDropDown.map((values) => (
                        <SelectEnterpriseStyle.ItemListEnterprise key={values._id} onClick={() => {
                            selectEnterprise(values._id)
                            setEnterprisetext(values.name.toUpperCase())
                            setOpenList(false)
                            }}>
                        <SelectEnterpriseStyle.Text size="12pt">
                            {values.name.toUpperCase()}
                        </SelectEnterpriseStyle.Text>
                    </SelectEnterpriseStyle.ItemListEnterprise>
                    ))
                }
                    
                </SelectEnterpriseStyle.ListEnterprises>
            </SelectEnterpriseStyle.ContListOptions>
        </SelectEnterpriseStyle.ContSelect>
    )
}
