import React from "react";
import Modal from "react-bootstrap/Modal";
import DetailProductMTM from "../admin/productos/public-products-m-to-m/views/DetailProductMTM";

export const ModalDetailProduct = (props) => {
    const title = props.title || `Detalle del Producto`;
    return (
        <Modal
            show={props.show}
            onHide={props.handleModal}
            {...props}
            size={props.size || "xl"}
            dialogClassName='modal-100w'
            style={{ width: "100%", height: "100%", margin: "0px !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children || (
                    <DetailProductMTM
                        prodId={props.id}
                        setModal={props.handleModal}
                        plugin={props.plugin}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ModalDetailProduct;
