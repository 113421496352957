import React from 'react';
import Select from 'react-select';
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';

const SelectReport = ({ reporteSeleccionado, setReporteSeleccionado , isReadOnly}) => {
    const options = [
        { value: 'reporte-inventario', label: 'Reporte de inventario' },
        { value: 'reporte-precios', label: 'Reporte de precios' },
    ];

    return (
        <div style={{ marginBottom: '15px' }}>
            <RC_Style.Label htmlFor="reporte">Seleccionar reporte</RC_Style.Label>
            <Select
                id="reporte"
                value={options.find(option => option.value === reporteSeleccionado)}
                onChange={(selectedOption) => setReporteSeleccionado(selectedOption.value)}
                options={options}
                styles={RC_Style.customStyles}
                isClearable={false} 
                isDisabled={isReadOnly}
            />
        </div>
    );
};

export default SelectReport;