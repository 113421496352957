import styled from 'styled-components';

export const FilterContGeneral = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 300px;
`

export const FilterCont = styled.div`
min-width: 260px;
min-height: 500px;
box-shadow: 0 2px 8px 0 rgb(34 41 47 / 14%);
border-radius: 0.3rem;
background: #FFFFFF;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

export const ContListFilter = styled.div`
width: 100%;
padding: 1rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`

export const HeaderContListFilter = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
position: relative;
`
export const BodyContListFilter = styled.ul`
width: 100%;
padding-left: 5px;
list-style: none;
`;

export const OneItemFilter = styled.li`
width: 100%;
height: 30px;
padding: .2rem;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const ContInputRadio = styled.div`
display: flex;
align-items:center;
width:100%;
justify-content: flex-start;
`;

export const InputChecbox = styled.input`
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    
    --active: #7367f0;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #5e5873;
    --border-hover: #7367f0;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: inherit;
        position: relative;
        margin: 10;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        margin-left: ${props => props.ml || '0'};
        margin-right: ${props => props.mr || '0'};
        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
            opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
            }
            & + label {
            cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
            &:not(:disabled) {
                --bc: var(--border-hover);
            }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
            opacity: var(--o, 0);
            }
            &:checked {
            --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
        
        &:not(.switch) {
            border-radius: 7px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
            --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
            left: 2px;
            top: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: var(--ab, var(--border));
            transform: translateX(var(--x, 0));
            }
            &:checked {
            --ab: var(--active-inner);
            --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                    opacity: 0.6;
                    }
                }
            }
        }
        
    }
`

export const InputRadio = styled.input`
  outline: none !important;
@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  --active: #7367f0;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, .3);
  --border: #5e5873;
  --border-hover: #7367f0;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none !important;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 1rem;
    line-height: 14px;
    font-weight: 400;
    display: inline-block;
    vertical-align: inherit;
    cursor: pointer;
    margin-left: 5px;
    color: #5e5873;
    margin-bottom: 0 !important;
  }


  border-radius: 50%;
  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}
`;

export const TwoItemFilter = styled.li`
width: 100%;
height: 40px;
padding: 10px;
display: flex;
cursor: pointer;
border: .325rem;
align-items: center;
justify-content: flex-start;
transition: all ease-in-out .2s;
border-radius: 5px;
background: #7367f0;
color: white;
margin-bottom: 5px;
position: relative;
&:hover {
}
`;

export const ContIconBtnfilter = styled.span`
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
font-weight: 500;
border-radius: 5px;
color: #7367f0;
border: 0.5px solid #FFFFFF;
background: #FFFFFF;
position: absolute;
left: auto;
right: 5px;
top: auto;
bottom: auto;
`

export const CurrentCategoryContent = styled.div`
  margin: 5px;
  color: #7367f0;
  cursor: pointer;
  .disabled {
    cursor: default !important;
  }
`;

export const Button = styled.button.attrs(props => ({
  width: props.width || 'auto',
  height: props.height || '2.5rem',
  minWidth: props.minWidth || '100px',
  display: props.display || 'flex',
  color:
    props.color === "danger"
      ? ["#de0202", "#de3737", "#db4d4d"]
      : props.color === "success"
      ? ["#18ba06", "#66ba5d", "#6fad68"]
      : props.color === "secondary"
      ? ["#6e6e6e", "#919191", "#a6a6a6"]
      : props.color === "warning"
      ? ["#d1a102", "#c9a93c", "#c2a953"]
      : props.color === "primary"
      ? ["#867bff", "#9990fc", "#9c96e6"]
      : ["#867bff", "#9990fc", "#9c96e6"],
}))`
  min-width: ${props => props.minWidth};
  width: ${props => props.width};
  outline: none !important;
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  padding: .5rem 1rem;
  color: #FFFFFF;
  font-weight: 500;
  background: ${props => props.color[0]};
  border-radius: .25rem;
  transition: all ease-in-out .2s;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  border: none;
  &:hover {
      background-color: ${props => props.color[1]};
      // color: #7367f0;
      border: none;
      box-shadow: 0 3px 5px 0 #c3c3c3;
  }
  :disabled {
    background: ${props => props.color[2]};
    cursor: default !important;
    color: #fff;
    box-shadow: none; 
  }
  :active:not(:disabled) {
    background-color: #6557ff;
    border: 0px;
  }
`;