import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';

const ContScrollToTop = styled.div`
    position: fixed;
    bottom: 40px; 
    right: 25px;  
    z-index: 1000; 
    cursor: pointer;

    a {
        text-decoration: none; /* Elimina el subrayado */
        color: inherit; /* Hereda el color del elemento padre */
        cursor: pointer; /* Cambia el cursor a puntero */
    }
`;


const ScrollToTop = () => {

    return (
        <>
                <ContScrollToTop>
                    <a href="#navbarAdmin">
                        <FontAwesomeIcon icon={faArrowUp} size="2x"/>
                    </a>
                </ContScrollToTop>
        </>
    )
};

export default ScrollToTop;

