import styled from "styled-components";

export const ContGeneral = styled.div`
width: 100%;
display: flex;
flex-direction: column;
position: relative;
align-items: center;
justify-content: flex-start;
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
margin-bottom: 60px;
`
export const ContHeader = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
height: 50px;
max-height: 50px;
`;

export const Text = styled.p.attrs(props => ({
    cl: props.cl || '#707070',
    size: props.size || '.857rem',
    ml: props.ml || '0',
    mr: props.mr || '0',
    fw: props.fw || 'lighter',
    mt: props.mt || '.2857rem',
    mb: props.mb || '.2857rem',
    longText: props.longText ? 'ellipsis' : '',
    longTextTwo: props.longText ? 'hidden' : '',
    longTextThree: props.longText ? 'nowrap' : '',
})) `
color: ${props => props.cl };
font-size: ${props => props.size };
margin-left: ${props => props.ml };
margin-right: ${props => props.mr };
font-weight: ${props => props.fw };
margin-top: ${props => props.mt };
margin-bottom: ${props => props.mb };
text-overflow:${props => props.longText };
overflow:${props => props.longTextTwo };
white-space:${props => props.longTextThree };
`;


export const ContInputAndLabel = styled.div.attrs(props => ({
    w: props.w || '300px',
    fld: props.fld || 'column',
    jsc: props.jsc || 'space-between',
    ali: props.ali || 'flex-start',
    mr: props.mr || 0
}))`
height:auto;
display:flex;
width:${props=> props.w};
flex-direction:${props => props.fld};
justify-content: ${props => props.jsc};
align-items:${props => props.ali};
margin-right: ${props=> props.mr};
`;

export const Input = styled.input`
display: block;
width: 100%;
height: 38px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding: 0.438rem 1rem;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #d8d6de;
border-radius: 0.357rem;
position: relative;
&:focus {
    color: #6e6b7b;
    outline: 0;
    background-color: #fff;
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;

export const InputSearch = styled.input`
width: 100%;
height: 40px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding-left: 1.25rem;
background-color: #fff;
border-radius: 0.357rem;
line-height: 1.45;
color: #6e6b7b;
outline: none !important;
border: none !important;
&:focus {
    outline: none !important;
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
`;

export const ContInputSearch = styled.form`
    box-shadow: 0 2px 8px 0 rgb(34 41 47 / 14%);
    border-radius: 0.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: #FFFFFF;
    min-height: 40px;
`;

export const ContIconInputSearch = styled.div`
min-width: 40px;
min-height: 40px;
display: flex;
justify-content: center;
align-items: center;
`

export const ContFilterAndListGrid = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: flex-start;
min-height: 300px;
margin-top: 1rem;
`;

export const ContGridAndCards = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
margin-left: 2rem;
min-height: 500px;
`;

export const ContGridCards = styled.div`
max-width: 100%;
width: 100%;
display: grid;
grid-gap: 2rem;
grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
margin-top: 1rem;
`;

export const BtnSendProduct = styled.button`
    background: #7367F0;
    position: fixed;
    // left: 100%;
    right: 1rem;
    top: auto;
    bottom: 1rem;
    z-index: 5;
    width: 130px;
    height: 40px;
    border: none;
    border-radius: 5px;
    outline: none !important;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12pt;
    &:disabled {
        background: #7367f085;
        cursor: no-drop;
    }
    &:hover {
        border: none;
        box-shadow: 0 3px 5px 0 #c3c3c3;
    }
`;
export const BtnAuthorize = styled.button`
    background: #7367F0;
    position: fixed;
    // left: 100%;
    right: ${({right}) => (right || '19rem')};
    top: auto;
    bottom: ${({bottom}) => (bottom|| '1rem')};
    padding-left: 15px;
    padding-right: 15px;
    z-index: 5;
    min-width: 130px;
    height: 40px;
    border: none;
    border-radius: 5px;
    outline: none !important;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12pt;
    &:disabled {
        background: #7367f085;
        cursor: no-drop;
    }
    &:hover {
        border: none;
        box-shadow: 0 3px 5px 0 #c3c3c3;
    }
`;
export const BtnSendProductClear = styled.button`
    background: #7367F0;
    position: fixed;
    // margin-right: 140px;
    // margin-left: auto;
    right: 10rem;
    top: auto;
    bottom: 1rem;
    z-index: 5;
    width: 130px;
    height: 40px;
    border: none;
    border-radius: 5px;
    outline: none !important;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12pt;
    &:disabled {
        background: #7367f085;
        cursor: no-drop;
    }
    &:hover {
        border: none;
        box-shadow: 0 3px 5px 0 #c3c3c3;
    }
`;

export const TabContainer = styled.div`
    position: fixed !important;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    transition: 0.5s;
    min-width: 100vw;
    top: 0;
    left: 0;
    background: rgba(128, 128, 128, 0.363);
    z-index: 15;
    &.hidden {
        left: -100%;
        opacity: 0;
    }
    .list-style-none>li {
        border-bottom: 1px solid #ececec;
    }
    .list-style-none>li:hover {
        transition: 0.3s;
        background: #cecece;
        .font-medium {
            font-weight: bold;
            color: #887fe9;
        }
    }
    .list-style-none>li, .close {
        cursor: pointer;
    }
    .close {
        color: #7367f0;
        position: fixed;
        right: 15px;
        top: 15px;
    }
    .content{
        min-height: 92vh;
    }
    input.filter {
        padding: 10px;
        padding-left: 15px;
        width: 100%;
        margin-bottom: 15px;
        border 1px solid #cecece;
        border-radius: .25rem;
        :active {
            border: 1px solid #7367f0 !important;
        }
    }

    input.filter:focus {
        border: 1px solid #7367f0 !important;
        outline: none;
    }
`;

export const ButtonIcon = styled.button`
    padding: 15px;
    min-width: 64px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #fff;
    color: #7367f0;
    border: 1px solid #7367f0;
    border-radius: 0.3rem;
    transition: .2s;
    &.active {
        color: #fff !important;
        background: #7367f0 !important;
    }
    :active, :focus {
        outline: none;
        border: 1px solid #7367f0;
    }
    :disabled {
        color: #ececec;
        border: 1px solid #ececec;
    }
`;