import { keyAccessToken, keyUserId } from "../../../../assets/js/SessionStorageNames";
import axios from 'axios';

const getAllChildsCategoriesVtex = (entId, axiosCancelTokenSource, categoryId) => {
    console.log('categoryId', categoryId)
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/vtex-categories/${entId}/getCategories`, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
        cancelToken: axiosCancelTokenSource.token,
        params : {
            categoryId: categoryId
        },
    })
}

export default getAllChildsCategoriesVtex;