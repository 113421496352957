import React, {useContext, useState} from 'react'
import { ContGridAndCards, ContGridCards, ContIconInputSearch, ContInputSearch, InputSearch, Text, TabContainer, ButtonIcon } from '../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts';
import { BiSearch, BiX } from "react-icons/bi";
import CardOdooProd from './cards/odoo/CardOdooProd';
import CardLoad from './cards/general/CardLoad';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import CardMlProd from './cards/ml/CardMlProd';
import CardPyProd from './cards/pedidos-ya/CardPyProd';
import CardAnicamProd from './cards/anican/CardAnicamProd';
import axios from 'axios';
import { useEffect } from 'react';
import getCategoriesOdoo from '../functions/getCategoriesOdoo';
import {
    keyAccessToken,
    keyUserId,
  } from "../../../../../assets/js/SessionStorageNames";
import Pager from '../../../../general/Pager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faTag, faTasks, faTimes, faTimesCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from '../views/DetailProductMTM';
import verifyThirdPartyUser from '../../../../general/VerifyThirdPartyUser';

export default function ContGrid({
    currentThirdPartyUser,
    setCurrentThirdPartyUser,
    finderType,
    finderTitle,
    listProd,
    totalProducts,
    load,
    pluginSearch,
    setFilter,
    showCategory,
    setShowCategory,
    setCurrentCategory,
    findByThatCat,
    selectedProds,
    validateThird
}) {
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const limitPerPage = 15;
    const globalContext = useContext(GlobalContext);
    const [filterValue, setFilterValue] = useState('')
    const [filterLastValue, setFilterLastValue] = useState('')
    const [isLoad, setIsLoad] = useState(true);
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [categoryFind, setCategoryFind] = useState('');
    const validThird = validateThird();

    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    
    const addItemToSend = (data) => {
        let copyArr = [...globalContext.listProductsToSend];
        //? buscamos el valor a ver si ya existe
        const existId = copyArr.find(v => v._id === data._id);
        if (existId) {
            const newData = copyArr.filter(v => v._id !== data._id);
            globalContext.setListProductsToSend(newData);
        }else {
            copyArr.push(data);
            globalContext.setListProductsToSend(copyArr);
        }
    }

    /**
     * Funcion para agregar todos los productos visibles en la pagina 
     * a la lista de productos seleccionados
     */
    function addAllItems(all){
        let arr = [ ...globalContext.listProductsToSend ];
        const prods = !all ? listProd.filter(v => !v.isFullyLinked) : listProd;
        const newData = arr.filter(item => {
            // Iterar prods y comparar cada _id
            const match = prods.find(prod => prod._id === item._id); 
            return !match;
          });
        console.log(newData);
        const mergedArr = newData.concat(prods);
        globalContext.setListProductsToSend(selectedProds ? [] : mergedArr);
        // chaneSelectedAllButton()
    }

    useEffect(() => {
        globalContext.setListProductsToSend([]);
        if(finderType==0) {
            setCurrentCategory({})
        } else {
            setCurrentThirdPartyUser({})
        }
        
    },[globalContext.currentEnterpriseId])

    const setFilterValueFunc = (e) => {
        e.preventDefault();
        if(filterValue !== filterLastValue) {
            setFilter(filterValue);
            setFilterLastValue(filterValue);
        }else {
            return
        }
    }

    /**
     * Funcion para mostrar las categorias de los productos que tiene la empresa.
     * @returns 
     */
    function getCategories() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId || !axiosCancelTokenSource) return;
        setIsLoad(false)
        getCategoriesOdoo(limitPerPage, currentPage,entId,axiosCancelTokenSource, categoryFind, pluginSearch)  
            .then(({data}) => {
                // console.log('data categories', data)
                setRows(data.data.cat);
                setTotalRecords(data.data.totalCat);
                setIsLoad(true);
            })
            .catch((err) => {
                setIsLoad(true);
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar las categorías."
                )
            }
            );
    }

    function getThirdPartyUsers() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsLoad(false);
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/user/third/`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    entId: entId,
                    pageNumber: currentPage,
                    pageItemCount: limitPerPage,
                    ...(
                        categoryFind.length > 0 && {
                            filter: { 
                                name: categoryFind, 
                                email: categoryFind 
                            },
                        }
                    )
                },
            })
            .then(({data}) => {
                setIsLoad(true);
                const { data: info } = data;
                setTotalRecords(info.totalUsers);
                setRows(info.users);
            })
            .catch((err) => {
                if (axios.isCancel(err)) return; //Don't show axios cancel error
                setIsLoad(true);
                globalContext.showModalWithMsg(
                    "Disculpe, hubo un error al intentar cargar la lista de usuarios."
                );
                console.error(err, err.response);
            });
    };

    useEffect(()=>{
        if(finderType===0) {
            getCategories();
        } else {
            !verifyThirdPartyUser() && getThirdPartyUsers();
        }
    },[globalContext.currentEnterpriseId,currentPage, setShowCategory]);

    function searchCategory(e) {
        e.preventDefault();
        console.log(categoryFind);
        if(finderType===0) {
            getCategories();
        } else {
            !verifyThirdPartyUser() && getThirdPartyUsers();
        }
    }

    function selectProductCategory(value) {
        if(finderType===0) {
            setCurrentCategory(value); 
        } else {
            setCurrentThirdPartyUser(value); 
        }
        setShowCategory();
        setCategoryFind('');
    }   
 
    const renderCardByPlugin = (pluginSearch, v, globalContext, addItemToSend) => {
        switch (pluginSearch) {
            case 'odoo':
                return (
                    <CardOdooProd
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend}
                        findByThatCat={findByThatCat}
                        currentThirdPartyUser={currentThirdPartyUser}
                    />
                );
            case 'ml':
                return (
                    <CardMlProd
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                />
                )
            case 'py':
                return (
                    <CardPyProd 
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                    />
                )
            case 'anicam':
                return (
                    <CardAnicamProd 
                        pluginSearch={pluginSearch}
                        key={v._id}
                        active={ globalContext.listProductsToSend.find(vs => vs._id === v._id ) }
                        data={v}
                        addItemToSend={addItemToSend} 
                    />
                )
            default:
                break;
        }
    }
    
    return (
        <ContGridAndCards>
            <div className="row col-12">
                <div className="col text-left">
                    <Text size="14pt" fw="500" cl="#5e5873" style={{alignSelf: 'flex-start'}} mb="1rem">{`Total de resultados: ${totalProducts}`}</Text>
                </div>
                <div className="col text-right">
                    <ButtonIcon disabled={load} onClick={() => addAllItems(true)} className={ selectedProds ? 'active' : ''} title='Seleccionar todos los productos de esta vista'>
                        {selectedProds ? 
                            <FontAwesomeIcon className='mt-1' icon={faTimes} />
                        :
                            <FontAwesomeIcon className='mt-1' icon={faCheckDouble} />
                        }
                    </ButtonIcon>
                    {!validThird &&
                    <ButtonIcon disabled={load} onClick={() => addAllItems(false)} className={ selectedProds ? 'active' : ''} title='Seleccionar todos los productos de esta vista'>
                        {selectedProds ? 
                            <FontAwesomeIcon className='mt-1' icon={faTimes} />
                        :
                            <FontAwesomeIcon className='mt-1' icon={faTasks} />
                        }
                    </ButtonIcon>
                    }
                </div>
            </div>
            <ContInputSearch onSubmit={(e) => setFilterValueFunc(e)}>
                <InputSearch placeholder='¿Que desea buscar?' onChange={(e) => setFilterValue(e.target.value)} disabled={load} />
                <ContIconInputSearch>
                    <BiSearch />
                </ContIconInputSearch>
            </ContInputSearch>

            <TabContainer className={`${!showCategory ? 'hidden' : ''}`}>
                <div className="row d-flex">
                    <div className="col content">
                        <div className="card col-12 col-md-6 col-lg-5 col-xl-4 col-xxl-2">
                            <div className="card-body text-center">
                                <h4 className="card-title m-b-0">{finderTitle}</h4>
                            </div>
                            <div>
                                <form onSubmit={(e) => searchCategory(e)}>
                                    <input type="text" className='filter' placeholder={`Buscar ${finderType===0 ? "Categoría" : "Tercero"}`} onChange={(e) => setCategoryFind(e.target.value)} value={categoryFind}/>
                                </form>
                            </div>
                            <div >
                                <ul className="list-style-none">
                                    {isLoad ? 
                                        rows.length > 0 ?
                                            rows.map((value, index) => (
                                                <li onClick={() => { selectProductCategory(value); }} className="d-flex no-block card-body pt-5" key={index}>
                                                    <FontAwesomeIcon className='mt-1' icon={finderType===0 ? faTag : faUser} />
                                                    &nbsp;
                                                    &nbsp;
                                                    <div className='w-100'>
                                                        <p  className="m-b-0 font-medium p-0">{value.name}</p>
                                                    </div>
                                                </li>
                                                ))
                                                :
                                                <>No se han conseguido registros.</>
                                            
                                        : <LoadingSpinner/>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FontAwesomeIcon onClick={setShowCategory} className='close' icon={faTimesCircle} />
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-center m-3">
                        <Pager 
                            totalResults={totalRecords} 
                            currentPage={currentPage} 
                            handleSetPage={setCurrentPage} 
                            resultsPerPage={limitPerPage}
                        />
                    </div>
                </div>
            </TabContainer>

            <ContGridCards>
                {
                    (listProd.length > 0 && !load) ?
                    listProd.map(v => renderCardByPlugin(pluginSearch, v, globalContext, addItemToSend))
                    :
                    (
                        <>
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                            <CardLoad />
                        </>
                    )
                }
            </ContGridCards>
        </ContGridAndCards>
    )
}
