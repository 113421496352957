import styled from 'styled-components';

const cont = styled.div`
display: block;
width: 100%;
@media (max-width: 990px){
    padding: 10px;
}
`;
const contChild = styled.div`
display: flex;
justify-content: center;
align-items: center;
width:100%;
`;

const header = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
/* border: 1px solid; */
margin-top: 10px;
margin-bottom: 10px;
`;

const contGridData = styled.div`
display: block;
width:100%;
height: 100%;
overflow-x:hidden;
overflow-y: auto;
/* border: 1px solid; */
`;

const headerGridData = styled.div`
display: flex;
width:100%;
justify-content: space-between;
align-items:center;
/* border: 1px solid; */
`;

const filtersCont = styled.div`
    padding-right: 10px;
    width: 360px;
    height: auto;
    position: sticky;
    top:10px;
    /* border: 1px solid black; */
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 900px){
        width:100%;
        margin-bottom:10px;
        position: relative;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    }
`;

const filtersBody = styled.div`
width: 100%;
display: block;
background-color: #FFF;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
border-radius: .5rem;
padding:10px 5px;
@media (max-width: 900px){
    display: flex;
    justify-content: space-between;
    padding:10px;
    align-items:center;
    }
`;

const listData = styled.ul`
    list-style: none;
    padding-left:0;
    width:100%;
    height: 100%;
    max-height:100%;
    overflow-x: hidden;
    overflow-y: auto;
`;
const itemListData = styled.li`
width:100%;
padding:0;
margin-top:10px;
margin-bottom:10px;
overflow-x: hidden;
`;

const contInputAndLabel = styled.div`
width:auto;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const text = styled.p`
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
`;

const textLink = styled.a`
color: ${props => props.cl || '#707070'};
font-size: ${props => props.size || '.857rem'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
font-weight: ${props => props.fw || 'lighter'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
text-overflow:${props => props.longText ? 'ellipsis' : ''};
overflow:${props => props.longText ? 'hidden' : ''};
white-space:${props => props.longText ? 'nowrap' : ''};
text-decoration: none;
`

const textTwo = styled.h6`
align-self: flex-start;
color: ${props => props.cl || '#5e5873'};
margin-left: ${props => props.ml || '0'};
margin-right: ${props => props.mr || '0'};
line-height: 1.2;
font-weight: ${props => props.fw || '500'};
margin-top: ${props => props.mt || '.2857rem'};
margin-bottom: ${props => props.mb || '.2857rem'};
`;

const input = styled.input`
width: 100%;
height: 40px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding-left: 1.25rem;
background-color: #fff;
border-radius: 0.357rem;
line-height: 1.45;
// color: #6e6b7b;
outline: none !important;
border: 1px solid #d8d6de;
&:focus {
    outline: none !important;
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;`

const inputByChat = styled.div`
    padding-left:10px;
    // font-family: "Berlin Rounded";
    max-width:340px;
    width: ${props => props.w || '100%'};
    min-height:30px;
    height: auto;
    display: inline-block;
    border-radius: .357rem;
    background: #fff;
    border: 1px solid #d8d6de;
    outline: none !important;
    color: rgba(112, 112, 112, 0.61) !important;
    font-size: 12pt;
    font-weight: 300;
    text-align:left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
`;

const select = styled.select`
    padding-left:10px;
    padding-right:10px;
    // font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    border: 1px solid #d8d6de;
    outline: none !important;
    color: #6e6b7b;
    font-size: 12pt;
    font-weight: 300;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        // color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const contInputSearch = styled.form`
    width: 100%;
    line-height: 1;
    height:auto;
    display:flex;
    box-sizing: content-box;
    box-sizing:content-box;
    justify-content: flex-end;
    align-items:center;
    margin-right: 10px;
`;

const inputSearch = styled.input`
width: 100%;
height: 40px;
font-size: .875rem;
font-weight: 400;
line-height: 1.45;
color: #6e6b7b;
padding-left: 1.25rem;
background-color: #fff;
border-radius: 0.357rem;
line-height: 1.45;
color: #6e6b7b;
outline: none !important;
border: none !important;
&:focus {
    outline: none !important;
}
::placeholder {
    color: #c0c0c1;
    font-size: 1rem;
}
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;`

const btnFloat = styled.button`
    width: 30px;
    height: 30px;
    background: #7367f0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50px;
    border: none !important;
    outline: none !important;
    position: absolute;
    margin-right: 5px;
    transition: all ease 0.5s;
    font-size:12pt;
`;

const ButtonList = styled.ul`
display: flex;
justify-content: center;
align-items: center;
list-style: none;
padding-left: 0;
width: auto;
min-height: 38px;
margin-bottom: 0;
border: 1px solid #7367F0;
border-radius: .325rem;
`;

const ButtonItem = styled.li.attrs(props => ({
    borderLeft: props.bl || 'none',
    borderRight: props.br || 'none'
}))`
border-left: ${props => props.bl};
border-right: ${props => props.br};
border-color: #7367f0;
width: auto;
min-height: 38px;
padding: 0;
`;

const OptionListButton = styled.button`
border: none;
outline: none !important;
background: transparent;
width: 38px;
height: 38px;
display: flex;
justify-content: center;
align-items: center;
color: #7367f0;
&:hover {
    color: #FFFFFF;
    background: #7367F0;
}
transition: all .15s ease-in-out;
`
const dataPikerInput = styled.div`
width: 100%;
.input-date {
    width: 100%;
    outline:none !important;
    height: 38px;
    background: #fff;
    color: rgba(112, 112, 112, 0.61);
    font-weight: 600;
    cursor: pointer;
    font-size:14px;
    padding: .438rem 1rem;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
}
.btn-date {
    border-radius: 50px;
    width: 30px;
    height: 30px;
    outline: none !important;
    background: #7367F0;
    box-shadow: 2px 0px 12px #7367f0;
    border: none;
    color: white;
    transition: all ease-in-out 0.02s;
}
.cont-calendar {
    display: 'flex'; 
    flex-flow: 'column nowrap';
    background: #fff;
    box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.16);
    display: none;
    transition: ease-out;
    position: absolute;
    border-radius:15px;
    margin-top:10px;
}
.btn-date.active{
    color: white;
    font-weight: 600;
    background-color: #7367F0;
    border-color: transparent;
}
.cont-calendar.active {
    display: block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0px, 5px);
    z-index: 9999;
}
`;

/**
 * ? $$$$$$$$$$$$$$$$$$$$$$$$$$$ estilos para tarjeta $$$$$$$$$$$$$$$$$$$$$$$$$$$
 */
const cardProduct = styled.div`
width: 100%;
border-radius:.5rem;
box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
min-height:300px;
background-color: #FFFFFF;
`

const cardProductHeader = styled.div`
width:100%;
height: 30px;
background:#FFFFFF;
border-top-left-radius:.5rem;
border-top-right-radius:.5rem;
display: flex;
justify-content: space-between;
align-items:center;
padding-left:10px;
`;

const contBtnActionsCard = styled.div`
background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
width: 40px;
height: 100%;
margin-top:0;
margin-bottom:0;
margin-right:0;
border-top-right-radius:.5rem;
border-bottom-left-radius:.5rem;
display: flex;
justify-content: center;
align-items:center;
padding:5px;
`;

const btnOneAction = styled.button`
border-radius: 50px;
width: 25px;
height: 25px;
display: flex;
justify-content: center;
background-color: transparent;
color: #FFFFFF;
align-items: center;
border: none;
outline: none !important;
transition: all ease 0.02s;
&:hover{
    background-color: rgb(255 255 255/ 40%);
}
`;

const contDataText = styled.div`
width:100%;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
padding:0 10px;
margin-top: ${props => props.mt || '5px'};
margin-bottom: ${props => props.mb || '5px'};
`;

const contImgAndText = styled.div`
width: 100%;
display:flex;
justify-content: flex-start;
align-items:center;
/* border: 1px solid; */
`;
const contImg = styled.div`
min-width:120px;
max-width:120px;
min-height:120px;
max-height:120px;
width:120px;
height:120px;
background-color: rgb(112 112 112/ 20%);
border-radius:.5rem;
margin-right:10px;
margin-left:10px;
background-image: url(${props => props.url ? `${props.url}` : ""});
background-repeat: no-repeat;
background-size: contain;
background-position:center;
`;

const contTextOfText = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content: flex-start;
align-items: flex-start;
align-self: flex-start;
`;

const textAreaCont = styled.div`
width:100%;
border:none!important;
resize: none;
display: flex;
flex-wrap: wrap;
`

const contTwoText = styled.div`
text-align: left;
color:#707070;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
width:auto;
padding:5px;
display: flex;
align-items: center;
justify-content: flex-start;
`;

const contOptions = styled.div`
    box-shadow: 0px 0px 5px 2px rgb(112 112 112 / 42%);
    height: auto;
    width: 250px;
    max-height: 250px !important;
    overflow-x: hidden;
    overflow-y: ${props => props.active ? 'auto' : 'hidden'};
    border-radius: .3rem;
    background-color: #FFFFFF;
    position: absolute;
    transform: translateY(2.5rem);
    left:auto;
    right:3rem;
    display: ${props => props.active ? 'block' : 'none'};
    transition: all ease-in 0.2s;
    /* width */
    ::-webkit-scrollbar {
        width: 2.5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(94, 84, 142);
        border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
    }
`;

const listOption = styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
`;
const listOptionItems = styled.li`
padding:10px;
border-bottom: 1px solid rgb(112 112 112/ 36%);
color: #707070;
font-size:12pt;
cursor: pointer;
text-align: start;
&:hover {
    background-color: rgb(112 112 112/ 11%);
}
`;
/**
 * ? $$$$$$$$$$$$$$$$$$$$$$$$$$$ estilos para tarjeta $$$$$$$$$$$$$$$$$$$$$$$$$$$
 */
/**
 * ? $$$$$$$$$$$$$$$$$$$$$$$$$$$ estilos detail $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
 */
const contNavbar = styled.ul`
width:100%;
display:flex;
justify-content:center;
align-items:center;
flex-wrap:wrap;
/* border:1px solid; */
list-style:none;
padding-left:0;
`
const navBarItems = styled.li`
padding:10px 20px;
color: ${props => props.active ? '#FFFFFF' : "#707070"};
background: ${props => props.active ? ' linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%)' : "transparent"};
border-radius:.5rem;
box-shadow: ${props => props.active ? '2px 0px 6px #7367f0' : ''};
cursor: pointer;
min-width:200px;
height:50px;
display:flex;
justify-content:center;
align-items:center;
transition: all ease-in 0.02s;
`;

const contChatAndOthers = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:flex-end;
flex-wrap:wrap-reverse;
`;
const contSteps = styled.div`
    min-width:320px;
    max-width:630px;
    min-height:300px;
    max-height:750px;
    width:630px;
    margin: 10px;
    background:#FFFFFF;
    box-shadow: 0px 0px 5px 2px rgb(112 112 112 / 42%);
    border-radius: .5rem;
    display:flex;
    flex-direction:column;
    justify-content:${props => props.active ? 'center' : 'flex-start'};
    align-items:flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;
/**
 * ? step Two styles
 */
const stepOne = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
`;
const stepOneHeader = styled.div`
width:100%;
margin-top:0;
min-height:40px;
height: auto;
padding:10px;
text-align:center;
`;
const stepOneBody = styled.div`
width:100%;
margin-top:10px;
`;


/**
 * ? chat styles
 */
const chatCont = styled.div`
    min-width:300px;
    max-width:500px;
    width:400px;
    min-height:600px;
    max-height:auto;
    height:auto;
    margin: 10px;
    background:#FFFFFF;
    box-shadow: 0px 0px 5px 2px rgb(112 112 112 / 42%);
    border-radius: .5rem;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
`;
const chatHeader = styled.div`
    width:100%;
    height:40px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding:10px;
    border-radius: .5rem .5rem 0px 0px;
    background: #dddaf2;
    margin-top:0;
`;
const chatFooter = styled.div`
width:100%;
min-height: 40px;
display:flex;
justify-content:center;
align-items:center;
margin-bottom:0;
margin-top:0;
background:#DDDAF2;
/* border:1px solid; */
padding:5px 10px;
`;
const btnSendMsg = styled.button`
border-radius:50px;
width:30px;
height:30px;
border:none;
outline:none !important;
color:#FFFFFF;
background:#7367F0;
margin-left:10px;
display:flex;
align-items:center;
justify-content:center;
`;
const chatContAvatar = styled.div`
width:30px;
height:30px;
border:1px solid #7377F0;
display:flex;
justify-content:center;
align-items:center;
background: rgb(112 112 112/ 20%);
border-radius:50px;
font-size:11pt;
color:#7377F0;
font-weight:500;
`;

const chactContBody = styled.div`
width:100%;
min-height: 470px;
max-height:550px;
height: 520px;
display: flex;
flex-direction: column-reverse;
justify-content: flex-start;
align-items: center;
overflow-y:auto;
overflow-x:hidden;
padding: 10px 0;
margin-top:0;
margin-bottom:0;
background-image: url('/imgChat.svg');
background-color: #F2F0F7;
background-repeat: repeat-y;
::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`
const contMsgChat = styled.div`
width:100%;
height: auto;
padding:5px;
display:flex;
align-items:center;
margin-top: 5px;
margin-bottom: 5px;
justify-content:${props => props.courier === '1' ? 'flex-end' : 'flex-start'};
`
const contMsgChatSend = styled.div`
min-width:200px;
max-width: 290px;
min-height: 30px;
background:  ${props => props.courier === '1' ? 'linear-gradient(80deg,#7367f0,#9e95f5);' : '#DDDAF2'};;
display:flex;
flex-wrap:wrap;
justify-content:flex-start;
align-items:center;
padding:5px 10px;
font-size:12pt;
border-radius:.5rem;
overflow-wrap: anywhere;
text-align: justify;
color:${props => props.courier === '1' ? '#FFFFFF' : '#707070'};
box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
`

const contImgStepOne = styled.div`
    min-width:300px;
    max-width:400px;
    width:400px;
    min-height:300px;
    max-height:400px;
    margin: 10px auto 10px auto;
    background:${props => props.url ? 'transparent' : "rgb(112 112 112 / 20%)"};
    background-image: url(${props => props.url ? `${props.url}` : ""});
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center;
`;
const contSubImgStepOne = styled.div`
    min-width:300px;
    min-height:40px;
    max-height:100px;
    margin: 0 10px 10px 10px;
    background: transparent;
    height: 80px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:.5rem;
`;
const subImgStepOne = styled.div`
    width:80px;
    height:80px;
    margin-left:5px;
    margin-right:5px;
    /* border:1px solid; */
    border-radius:.5rem;
    background:${props => props.url ? 'transparent' : "rgb(112 112 112 / 20%)"};
    transition: all ease-out 0.2s;
    border: 1px solid ${props => props.active ? 'transparent' : '#FFFFFF'};
    box-shadow: ${props => props.active ? '0px 0px 9px 2px #7367f0' : '0px 0px 0px transparent'};
    background-image: url(${props => props.url ? `${props.url}` : ""});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:center;
`;
/**
 * ? step two styles
 */
const stepTwo = styled.div`
 width:100%;
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 align-items:flex-start;
`;
const stepTwoHeader = styled.div`
width:100%;
margin-top:0;
height: 40px;
padding:10px;
text-align:start;
`;
const stepTwoBody = styled.div`
width:100%;
margin-top:10px;
`;

/**
 * ? step three styles
 */
const stepThree = styled.div`
 width:100%;
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 align-items:flex-start;
`;
const stepThreeHeader = styled.div`
width:100%;
margin-top:0;
height: 40px;
padding:10px;
text-align:start;
`;
const stepThreeBody = styled.div`
width:100%;
margin-top:10px;
`;

/**
 * ? $$$$$$$$$$$$$$$$$$$$$$$$$$$ estilos detail $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
 */

const contInputs = styled.div`
width:100%;
display:flex;
justify-content: space-between;
align-items:center;
flex-wrap:wrap;
padding:10px;
`;

const btnSave = styled.button`
padding: 8px 16px;
border-radius: .5rem;
color: white;
background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
font-size:12pt;
font-weight:500;
text-align:center;
border:none;
outline: none!important;
transition: all ease-out 0.02s;
&:hover {
    box-shadow: 0 1px 20px 1px #7367F0!important;
}
`;
const BtnClose = styled.button`
padding: 8px 16px;
border-radius: .5rem;
color: #707070;
background: #F5F5F6;
font-size:12pt;
font-weight:500;
text-align:center;
border:none;
outline: none!important;
transition: all ease-out 0.02s;

`;

const inputRadio = styled.div`
    .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    }
    .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
    }
    .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #7367f0;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    }
    .cbx:hover span:first-child {
    border-color: #7367f0;
    }

    .inp-cbx:checked + .cbx span:first-child {
    background: #7367f0;
    border-color: #7367f0;
    animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
    }

    @keyframes wave {
    50% {
        transform: scale(1);
    }
    }
`;

const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    // font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: ${props => props.hg || '300px'};
    background: #fff;
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    border-radius: .357rem;
    border: 1px solid #d8d6de;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: rgba(112, 112, 112, 0.61);
        }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;

const textAreaView = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    // font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    min-height: 100px;
    height: auto;
    background: #fff;
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    border-radius: .357rem;
    border: 1px solid rgb(112 112 112 / 50%);
    text-align: justify;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;
const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const listItemsinfo = styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
padding: 0 10px;
`;
const listIteminfo = styled.li`
width: 100%;
padding: 10px;
border-bottom:1px solid rgb(112 112 112 / 50%);
display:flex;
justify-content: flex-start;
align-items:center;
text-align:justify;
flex-wrap:wrap;
`;

const BackgroundModal = styled.div.attrs(props => ({
    d: props.active ? 'flex' : 'none'
}))`
width: 100%;
display:  ${props => props.d};
justify-content: center;
align-items: center;
padding: 10px;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: 100%;
transition: display .3s ease;
background-color: rgb(251 251 251 / 64%);
z-index: 999;
`;

const ContModal = styled.div.attrs(props => ({
    bg: props.bg || '#FFFFFF',
    w: props.w || 'auto',
    minw: props.minw || '330px',
    maxw: props.maxw || '1000px',
    t: props.active ? 'translateY(0px)' : 'translateY(146px)',
    o: props.active ? '1' : '0'
}))`
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
background: ${props => props.bg};
border-radius: .425rem;
min-width: ${props => props.minw};
max-width: ${props => props.maxw};
min-height: 200px;
padding: 10px;
/* border: 1px solid red; */
width: ${props => props.w};
transition: transform .9s ease-out, opacity 1s ease-in-out;
min-height: 300px;
transform: ${props => props.t};
opacity: ${props => props.o};
z-index: 999;
max-width: 650px;
margin-top: 10px;
margin-bottom: 10px;
margin-left: 10px;
margin-right: 10px;
flex-direction: column;
display: flex;
justify-content: center;
`;

const ContHeaderModal = styled.div`
width: 100%;
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 0;
margin-bottom: auto;
background: #F0F0F0;
padding: 10px;
border-radius: .425rem;
`;

const ContBodyModal = styled.div`
width: 100%;
height: 400px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
margin-top: 0;
margin-bottom: 0;
`;

const ContInputsToSearch = styled.div`
display: flex;
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
flex-wrap: wrap;
margin-bottom: 10px;
`;

const ContFooterModal = styled.div`
width: 100%;
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: auto;
margin-bottom: 0;
`;

const ContTable = styled.div`
width: 100%;
height: 300px;
overflow-y: auto;
margin-top: 0;
margin-bottom: 0;
position: relative;
`

const TableList = styled.table`
width: 100%;
position: relative;
border-collapse: collapse;
border: 1px solid #F0F0F0;
`;

const Thead = styled.thead`
width: 100%;
position: sticky;
top: 0;
background: #F0F0F0;
`;

const Tbody = styled.tbody`
width: 100%;
border: 1px solid red;
max-height: 290px;
overflow-y: auto;
`;

const TrList = styled.tr.attrs(props => ({
    border: props.border || 'none'
}))`
width: 100%;
border: ${props => props.border};
`;

const TdList = styled.td.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'justify',
    backGroundColor: props.backGroundColor || '#FFFFFF',
    padding: props.padding || '0.50rem',
    verticalAlign: props.verticalAlign || 'text-top',
    border: props.border || 'none'
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
max-width: ${props => props.width};
min-width: ${props => props.minWidth};
word-break: break-all;
vertical-align: ${props => props.verticalAlign};
background: ${props => props.backGroundColor};
border: ${props => props.border};
`;
const TdListLoad = styled.td.attrs(props => ({
    // we can define static props
    width: props.width || '100px',
    minWidth: props.minWidth || '100px',
    textAlign: props.textAlign || 'justify',
    backGroundColor: props.backGroundColor || '#FFFFFF',
    padding: props.padding || '0.50rem',
    verticalAlign: props.verticalAlign || 'text-top',
    border: props.border || 'none'
}))`
text-align: ${props => props.textAlign};
padding: ${props => props.padding};
width: ${props => props.width};
max-width: ${props => props.width};
min-width: ${props => props.minWidth};
word-break: break-all;
vertical-align: ${props => props.verticalAlign};
border: ${props => props.border};
max-height: 30px;
background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
background-repeat: no-repeat;
background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
animation: loading 1.5s infinite;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
`;

const GridStyles = {
    BackgroundModal,
    ContBodyModal,
    ContInputsToSearch,
    TdListLoad,
    ContTable,
    TdList,
    TableList,
    Thead,
    TrList,
    Tbody,
    BtnClose,
    ContModal,
    ContHeaderModal,
    cont,
    contChild,
    header,
    contGridData,
    filtersCont,
    input,
    inputByChat,
    text,
    textTwo,
    filtersBody,
    contInputAndLabel,
    select,
    dataPikerInput,
    headerGridData,
    inputSearch,
    btnFloat,
    contInputSearch,
    listData,
    itemListData,
    cardProduct,
    cardProductHeader,
    contBtnActionsCard,
    btnOneAction,
    contDataText,
    contImgAndText,
    contImg,
    contTextOfText,
    contTwoText,
    textAreaCont,
    contOptions,
    listOption,
    listOptionItems,
    contNavbar,
    navBarItems,
    contChatAndOthers,
    stepOne,
    chatCont,
    stepOneHeader,
    contSteps,
    stepOneBody,
    contImgStepOne,
    contSubImgStepOne,
    subImgStepOne,
    stepTwo,
    stepTwoHeader,
    stepTwoBody,
    stepThree,
    stepThreeHeader,
    stepThreeBody,
    contInputs,
    btnSave,
    inputRadio,
    textArea,
    chatHeader,
    chatContAvatar,
    chatFooter,
    btnSendMsg,
    chactContBody,
    contMsgChat,
    contMsgChatSend,
    contLoader,
    listItemsinfo,
    listIteminfo,
    textAreaView,
    textLink,
    ButtonList,
    ButtonItem,
    OptionListButton,
    ContFooterModal
}

export default GridStyles;