import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LandingPageStyle from '../../assets/css/components/home/LandingPage'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHistory } from 'react-router-dom';
import { faLightbulb, faPiggyBank, faGlobe, faRetweet, faDesktop, faToolbox, faAward, faMoneyBill, faChevronRight, faChevronLeft, faArrowUp} from "@fortawesome/free-solid-svg-icons";

export default function LandingPage() {

    const history = useHistory();

    const handleClick = () => {
        history.push('/login'); 
    };

    const StyledIcon = styled(FontAwesomeIcon)`
        font-size: 5rem; // Tamaño por defecto

        @media (max-width: 768px) {
            font-size: 4rem; // Tamaño para pantallas menores a 768px
        }

        @media (max-width: 576px) {
            font-size: 3rem; // Tamaño para pantallas menores a 480px
        }
    `;

    //Slider
    const images = [
        '/logo_clientes/logo_k6.png',
        '/logo_clientes/logo_bks.jpg',
        '/logo_clientes/logo_ecology.png',
        '/logo_clientes/logo_newbalance.png',
        '/logo_clientes/logo_wilson.png',
        '/logo_clientes/logo_eureka.png',
        '/logo_clientes/logo_212.webp',
        '/logo_clientes/logo_cashea.webp',
        '/logo_clientes/logo_mercadolibre.svg',
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [imagesPerPage, setImagesPerPage] = useState(3);
    const [animationDirection, setAnimationDirection] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    // Función para actualizar el número de imágenes por página según el tamaño de la pantalla
    const updateImagesPerPage = () => {
        if (window.innerWidth < 600) {
            setImagesPerPage(1); // 1 imagen para pantallas pequeñas
        } else if (window.innerWidth < 900) {
            setImagesPerPage(3); // 3 imágenes para pantallas medianas
        } else {
            setImagesPerPage(5); // 5 imágenes para pantallas grandes
        }
    };

    // Obtener las imágenes a mostrar
    const selectedImages = [];
    for (let i = 0; i < imagesPerPage; i++) {
        selectedImages.push(images[(currentIndex + i) % images.length]);
    }

    useEffect(() => {
        updateImagesPerPage(); // Inicializa el número de imágenes al cargar

        window.addEventListener('resize', updateImagesPerPage); // Escucha cambios en el tamaño de la ventana
        
        startInterval(); // Inicia el intervalo

        return () => {
            clearInterval(intervalId); // Limpia el intervalo al desmontar
            window.removeEventListener('resize', updateImagesPerPage); // Limpia el evento al desmontar
        };
    }, []);

    // Animacion cuando se activa prev o next en el slider
    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimationDirection('');
        }, 250); //Duración de la animación
    
        return () => clearTimeout(timer);
    }, [currentIndex]);

    // Función para iniciar el intervalo de tiempo que se encarga de rotar las imagenes del slider
    const startInterval = () => {
        const id = setInterval(() => {
            next();
        }, 5000); // Cambia cada 5 segundos
        setIntervalId(id);
    };

    // Función que se encarga de reiniciar el intervalo de tiempo que se encarga de rotar las imagenes del slider
    const resetInterval = () => {
        clearInterval(intervalId); // Limpia el intervalo actual
        startInterval(); // Reinicia el intervalo
    };

    //Se muestra la siguiente imagen
    //Manual es true cuando el usuario presiona next y el false cuando el timer que maneja el slider llega a 0
    const next = (manual) => {
        setAnimationDirection('slide-right');
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        if(manual){
            resetInterval()
        }
    };
    
    //Se muestra la imagen anterior
    const prev = () => {
        setAnimationDirection('slide-left');
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        resetInterval()
    };

    //Animacion de layout
    const [isVisibleMision, setIsVisibleMision] = useState(false);
    const [isVisibleGrid, setIsVisibleGrid] = useState(false);
    const [isVisibleModulo, setIsVisibleModulo] = useState(false);
    const [isVisibleVentaja, setIsVisibleVentaja] = useState(false);

    const refMision = useRef();
    const refGrid = useRef();
    const refModulo = useRef();
    const refVentaja = useRef();

    //Contadores
    //Contador de productos
    const [productsNumber, setProductsNumber] = useState(0);
    const refProductsNumber = useRef();
    
    //Contador de marcas
    const [brandsNumber, setBrandsNumber] = useState(0);
    const [isVisibleBrandsNumber, setIsVisibleBrandsNumber] = useState(false);
    const refBrandsNumber = useRef();


    const handleScroll = () => {
        const rectMision = refMision.current.getBoundingClientRect();
        const rectGrid = refGrid.current.getBoundingClientRect();
        const rectModulo = refModulo.current.getBoundingClientRect();
        const rectVentaja = refVentaja.current.getBoundingClientRect();
        const rectBrandsNumber = refBrandsNumber.current.getBoundingClientRect();




        // Manejo de la visibilidad para Contador de marcas
        if (rectBrandsNumber.top < window.innerHeight && rectBrandsNumber.bottom > 0) {
            setIsVisibleBrandsNumber(true);
        } else {
            setIsVisibleBrandsNumber(false);
        }
      
        // Manejo de la visibilidad para Mision
        if (rectMision.top < window.innerHeight && rectMision.bottom > 0) {
            setIsVisibleMision(true);
        } else {
            setIsVisibleMision(false);
        }

        // Manejo de la visibilidad para OfreceGrid
        if (rectGrid.top < window.innerHeight && rectGrid.bottom > 0) {
            setIsVisibleGrid(true);
        } else {
            setIsVisibleGrid(false);
        }
    
        // Manejo de la visibilidad para ContOperatividadModulo
        if (rectModulo.top < window.innerHeight && rectModulo.bottom > 0) {
            setIsVisibleModulo(true);
        } else {
            setIsVisibleModulo(false);
        }

        // Manejo de la visibilidad para Ventajas
        if (rectVentaja.top < window.innerHeight && rectVentaja.bottom > 0) {
            setIsVisibleVentaja(true);
        } else {
            setIsVisibleVentaja(false);
        }
    };
  
    //handleScroll
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    //Contadores
    const numProductos = 35000;
    const numMarcas = 100;
    useEffect(() => {
        let interval;
        if (isVisibleBrandsNumber && brandsNumber < numMarcas) {
            interval = setInterval(() => {

                //Se actualiza el numero de marcas
                setBrandsNumber(prevBrandsNumber => {
                    return prevBrandsNumber + 1 >= numMarcas ? numMarcas : prevBrandsNumber + 1;
                });

                //Se actualiza el numero de productos
                if (brandsNumber == numMarcas-1) {
                    setProductsNumber(numProductos);
                } else {
                    setProductsNumber(prevProductsNumber => prevProductsNumber + 350);
                }

            }, 15);
        }
        return () => clearInterval(interval); // Limpiar el intervalo al desmontar
    }, [isVisibleBrandsNumber, brandsNumber]); // Se ejecuta cuando isVisible o BrandsNumber cambian




    return (
        <LandingPageStyle.Body>

            <LandingPageStyle.ContTopBar>

                <LandingPageStyle.Cont>

                    <LandingPageStyle.ContLogo>
                        <LandingPageStyle.ContLogoImg url="/logo.svg" />
                        <p style={{ margin: 0 }}>e-kommers.com</p>
                    </LandingPageStyle.ContLogo>

                    <LandingPageStyle.BtnIniciarTopBar onClick={handleClick}>Iniciar</LandingPageStyle.BtnIniciarTopBar>

                </LandingPageStyle.Cont>

            </LandingPageStyle.ContTopBar>


            <LandingPageStyle.ContTitle>
                <LandingPageStyle.ContTitleFlex>
                    <LandingPageStyle.Title>K A P I</LandingPageStyle.Title>
                    <LandingPageStyle.SubTitle>INTEGRADOR DE ECOMMERCE Y MARKETPLACE</LandingPageStyle.SubTitle>
                    <LandingPageStyle.BtnIniciar onClick={handleClick}>Iniciar</LandingPageStyle.BtnIniciar>
                </LandingPageStyle.ContTitleFlex>
            </LandingPageStyle.ContTitle>


            <LandingPageStyle.ContMision>
                
                <LandingPageStyle.MisionTitulo>NUESTRA MISIÓN</LandingPageStyle.MisionTitulo>
                <LandingPageStyle.MisionDescripcion  ref={refMision} isVisible={isVisibleMision}>Tenemos la certeza de que el futuro se encuentra en el ámbito digital. Para lograr nuestra meta, brindamos soluciones eficaces, eficientes y de alta calidad a todos nuestros clientes.</LandingPageStyle.MisionDescripcion>
            </LandingPageStyle.ContMision>



            <LandingPageStyle.ContOperatividad>

                <LandingPageStyle.ContOperatividadTitulo>OPERATIVIDAD ADQUIRIDA</LandingPageStyle.ContOperatividadTitulo>

                <LandingPageStyle.ContOperatividadModulos>

                        <LandingPageStyle.ContOperatividadModulosIzquierda ref={refModulo} isVisible={isVisibleModulo}>
                            <LandingPageStyle.ContOperatividadModulo>
                                <LandingPageStyle.OperatividadImg>
                                    <StyledIcon icon={faLightbulb} size="5x"/>
                                </LandingPageStyle.OperatividadImg>

                                <div>
                                    <LandingPageStyle.OperatividadTitulo>Creación y Gestión de Productos en Masa</LandingPageStyle.OperatividadTitulo>
                                    <LandingPageStyle.OperatividadDescripcion>Permite crear productos una sola vez y lanzarlos en múltiples marketplaces. Además, puedes modificar su estado en cualquier momento.</LandingPageStyle.OperatividadDescripcion>
                                </div>
                            </LandingPageStyle.ContOperatividadModulo>


                            <LandingPageStyle.ContOperatividadModulo>

                                <LandingPageStyle.OperatividadImg>
                                    <StyledIcon icon={faPiggyBank} size="5x"/>
                                </LandingPageStyle.OperatividadImg>

                                <div>
                                    <LandingPageStyle.OperatividadTitulo>Gestión de Pedidos Automáticos</LandingPageStyle.OperatividadTitulo>
                                    <LandingPageStyle.OperatividadDescripcion>Permite recibir diferentes pedidos de diversos marketplaces y ofrece la posibilidad de responder preguntas en los canales de preventa.</LandingPageStyle.OperatividadDescripcion>
                                </div>
                            </LandingPageStyle.ContOperatividadModulo>
                        </LandingPageStyle.ContOperatividadModulosIzquierda>


                        <LandingPageStyle.ContOperatividadModulosDerecha ref={refModulo} isVisible={isVisibleModulo}>
                            <LandingPageStyle.ContOperatividadModulo >

                                <LandingPageStyle.OperatividadImg>
                                    <StyledIcon icon={faRetweet} size="5x"/>
                                </LandingPageStyle.OperatividadImg>

                                <div>
                                    <LandingPageStyle.OperatividadTitulo>Actualización de Productos</LandingPageStyle.OperatividadTitulo>
                                    <LandingPageStyle.OperatividadDescripcion>Actualiza automáticamente los cambios en stock, precio, imágenes y otros campos en canales conectados.</LandingPageStyle.OperatividadDescripcion>
                                </div>

                            </LandingPageStyle.ContOperatividadModulo>


                            <LandingPageStyle.ContOperatividadModulo>
                                <LandingPageStyle.OperatividadImg>
                                    <StyledIcon icon={faGlobe} size="5x"/>
                                </LandingPageStyle.OperatividadImg>

                                <div>
                                    <LandingPageStyle.OperatividadTitulo>Facturación en Línea</LandingPageStyle.OperatividadTitulo>
                                    <LandingPageStyle.OperatividadDescripcion>Factura de forma individual o al público las ventas en marketplaces con un proceso fácil de gestionar.</LandingPageStyle.OperatividadDescripcion>                         
                                </div>
                            </LandingPageStyle.ContOperatividadModulo>
                        </LandingPageStyle.ContOperatividadModulosDerecha>

                   

                </LandingPageStyle.ContOperatividadModulos>

            </LandingPageStyle.ContOperatividad>


            <LandingPageStyle.ContOfrece>
                <LandingPageStyle.ContOfreceTitulo>¿QUÉ OFRECE KAPI?</LandingPageStyle.ContOfreceTitulo>

                <LandingPageStyle.OfreceGrid ref={refGrid} isVisible={isVisibleGrid}>

                <LandingPageStyle.OfreceGridElemento  grid_column={'1 / 4'}>
                    <LandingPageStyle.OfreceGridNumber>01</LandingPageStyle.OfreceGridNumber>
                    <div>
                        <LandingPageStyle.OfreceGridSubtitulo>Disminución de cuellos de botella</LandingPageStyle.OfreceGridSubtitulo>
                        <LandingPageStyle.OfreceGridDescripcion>Al utilizar un software integrador, se reduce la posibilidad de cuellos de botella y se mejora el cumplimiento de los acuerdos de nivel de servicio (SLA) para la entrega de pedidos.</LandingPageStyle.OfreceGridDescripcion>
                    </div>
                </LandingPageStyle.OfreceGridElemento>

                <LandingPageStyle.OfreceGridElemento  grid_column={'2 / 5'}>
                    <LandingPageStyle.OfreceGridNumber>02</LandingPageStyle.OfreceGridNumber>
                    <div>
                        <LandingPageStyle.OfreceGridSubtitulo>Recepción de pedidos de venta </LandingPageStyle.OfreceGridSubtitulo>
                        <LandingPageStyle.OfreceGridDescripcion>Podrás consolidar en un solo sitio todos los pedidos de distintos marketplaces.</LandingPageStyle.OfreceGridDescripcion>
                    </div>
                </LandingPageStyle.OfreceGridElemento>

                <LandingPageStyle.OfreceGridElemento  grid_column={'3 / 6'}>
                    <LandingPageStyle.OfreceGridNumber>03</LandingPageStyle.OfreceGridNumber>
                    <div>
                        <LandingPageStyle.OfreceGridSubtitulo>Estructura para el crecimiento</LandingPageStyle.OfreceGridSubtitulo>
                        <LandingPageStyle.OfreceGridDescripcion>KAPI permite establecer una estructura adecuada para el crecimiento de un negocio en marketplaces y diversas plataformas.</LandingPageStyle.OfreceGridDescripcion>
                    </div>
                </LandingPageStyle.OfreceGridElemento>

                <LandingPageStyle.OfreceGridElemento  grid_column={'4 / 7'}>
                    <LandingPageStyle.OfreceGridNumber>04</LandingPageStyle.OfreceGridNumber>
                    <div>
                        <LandingPageStyle.OfreceGridSubtitulo>Ahorro de tiempo y recursos</LandingPageStyle.OfreceGridSubtitulo>
                        <LandingPageStyle.OfreceGridDescripcion>La agrupación de información en una única plataforma ahorra tiempo en la gestión de productos y permite enfocarse en procesos de gestión más prioritarios.</LandingPageStyle.OfreceGridDescripcion>
                    </div>
                </LandingPageStyle.OfreceGridElemento>


                </LandingPageStyle.OfreceGrid>

            </LandingPageStyle.ContOfrece>


            <LandingPageStyle.ContVentajas>

                <LandingPageStyle.ContVentajasTitulo>VENTAJAS COMPETITIVAS</LandingPageStyle.ContVentajasTitulo>

                <LandingPageStyle.VentajasGrid>
                    
                    <LandingPageStyle.Ventajas ref={refVentaja} isVisible={isVisibleVentaja}>

                        <LandingPageStyle.Ventaja>

                            <LandingPageStyle.VentajaImg>
                                <StyledIcon icon={faDesktop} size="5x"/>
                            </LandingPageStyle.VentajaImg>

                            <div>
                                <LandingPageStyle.ContVentajaTitulo>Sistema en línea</LandingPageStyle.ContVentajaTitulo>
                                <LandingPageStyle.ContVentajaDescripcion>Sistema completamente en línea, que no requiere de una implementación costosa. Además, ofrece una configuración flexible, lo que permite una adaptación rápida.</LandingPageStyle.ContVentajaDescripcion>
                            </div>

                        </LandingPageStyle.Ventaja>

                        <LandingPageStyle.Ventaja>
                            <LandingPageStyle.VentajaImg>
                                <StyledIcon icon={faToolbox}  size="5x"/>
                            </LandingPageStyle.VentajaImg>
                            
                            <div>
                                <LandingPageStyle.ContVentajaTitulo>Integración</LandingPageStyle.ContVentajaTitulo>
                                <LandingPageStyle.ContVentajaDescripcion>Con la integración se aprovechan las ventajas únicas de cada uno de los sistemas.</LandingPageStyle.ContVentajaDescripcion>
                            </div>
                        </LandingPageStyle.Ventaja>

                        <LandingPageStyle.Ventaja>

                            <LandingPageStyle.VentajaImg>
                                <StyledIcon icon={faMoneyBill} size="5x" />
                            </LandingPageStyle.VentajaImg>

                            <div>
                                <LandingPageStyle.ContVentajaTitulo>Costo Accesible</LandingPageStyle.ContVentajaTitulo>
                                <LandingPageStyle.ContVentajaDescripcion>El costo mensual de las plataformas es muy asequible.</LandingPageStyle.ContVentajaDescripcion>
                            </div>
                        </LandingPageStyle.Ventaja>

                    </LandingPageStyle.Ventajas>

                    <LandingPageStyle.VentajasGrid04>
                        
                        <LandingPageStyle.VentajasKAPI>
                            <LandingPageStyle.VentajasKAPITitulo>K A P I</LandingPageStyle.VentajasKAPITitulo>
                            <FontAwesomeIcon icon={faAward} size="5x"/>
                        </LandingPageStyle.VentajasKAPI>

                        <LandingPageStyle.VentajasKAPITexto>Vende en todos lados de forma sincronizada.</LandingPageStyle.VentajasKAPITexto>
                    </LandingPageStyle.VentajasGrid04>
                </LandingPageStyle.VentajasGrid>


            </LandingPageStyle.ContVentajas>


            <LandingPageStyle.ContEstadisticas>
                <div>
                    <LandingPageStyle.ContEstadistica>
                        <LandingPageStyle.EstadisticaTexto>Más de</LandingPageStyle.EstadisticaTexto>
                        <LandingPageStyle.Estadistica100 ref={refBrandsNumber}>{brandsNumber}</LandingPageStyle.Estadistica100>
                    </LandingPageStyle.ContEstadistica>
                    
                    <LandingPageStyle.EstadisticaTexto>Marcas confían en nosotros</LandingPageStyle.EstadisticaTexto>
                </div>

                <div>
                    <LandingPageStyle.ContEstadistica>
                        <LandingPageStyle.EstadisticaTexto>Más de</LandingPageStyle.EstadisticaTexto>
                        <LandingPageStyle.Estadistica100 ref={refProductsNumber}>{productsNumber}</LandingPageStyle.Estadistica100>
                    </LandingPageStyle.ContEstadistica>
                    
                    <LandingPageStyle.EstadisticaTexto>Productos publicados</LandingPageStyle.EstadisticaTexto>
                </div>
            </LandingPageStyle.ContEstadisticas>



            <LandingPageStyle.ContClientes>

                <LandingPageStyle.ClientesTitulo>Clientes</LandingPageStyle.ClientesTitulo>

                <LandingPageStyle.ClientesSlider>

                    <LandingPageStyle.ClientesBtn >
                        <FontAwesomeIcon icon={faChevronLeft} onClick={prev} size="3x" style={{ cursor: 'pointer' }}/>
                    </LandingPageStyle.ClientesBtn>

                    
                    <LandingPageStyle.Clientes >
                        {selectedImages.map((image, index) => (
                            <LandingPageStyle.Cliente className={animationDirection} key={index} url={image} />
                        ))}
                    </LandingPageStyle.Clientes>

                    <LandingPageStyle.ClientesBtn >
                        <FontAwesomeIcon icon={faChevronRight} onClick={next} size="3x" style={{ cursor: 'pointer' }} />
                    </LandingPageStyle.ClientesBtn>

                </LandingPageStyle.ClientesSlider>

            </LandingPageStyle.ContClientes>

            <LandingPageStyle.ContFooter>
                ©2024 Copyright: KAPI
            </LandingPageStyle.ContFooter>


        </LandingPageStyle.Body>
    )
}
