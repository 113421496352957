import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  BtnCloseMenu,
  ContMenuCard,
  ContTitleMenu,
  ListOptions,
  OptionList,
} from "../../../../../../../assets/css/components/admin/marketplace-to-marketplace/cardItem/CardItemStyle";
import { Text } from "../../../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts";
import ModalDetailProduct from "../../../../../../general/ModalDetailProduct";

export default function MenuCard({ active, setActive, data, findByThatCat }) {
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const closeMenu = () => {
    setActive(false);
  };
  return (
    <>
      <ContMenuCard active={active}>
        <BtnCloseMenu onClick={() => closeMenu()}>
          <BiX />
        </BtnCloseMenu>
        <ContTitleMenu>
          <Text size='12pt' fw='500'>
            Opciones
          </Text>
        </ContTitleMenu>
        <ListOptions>
          <OptionList onClick={handleModal}>
            <Text size='14px' fw='400'>
              <Button
                variant='link'
                className='text-secondary text-decoration-none'
                size='sm'
              >
                Detalle del Producto
              </Button>
            </Text>
          </OptionList>
          <OptionList onClick={()=> findByThatCat(data.odooId)}>
              <Text size="14px" fw="400">
                  Filtrar por Esta Categoría 
              </Text>
          </OptionList>
        </ListOptions>
      </ContMenuCard>
      <ModalDetailProduct
        show={showModal}
        handleModal={handleModal}
        id={data._id}
        plugin="odoo"
      />
    </>
  );
}
