import { faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import GridStyles from '../../../../assets/css/components/admin/products/Orders/GridStyles'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import OptionsCont from './components/OptionsCont'
import axios from 'axios';
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames'
import Pager from '../../../general/Pager'
import { Redirect } from "react-router-dom";
import Routes from '../../../../Routes'
import verifyAccessRole from '../../../../assets/js/verifyAccessRole'
import { BiFilterAlt,BiTrashAlt } from "react-icons/bi";
import CardProductCss from '../../../../assets/css/components/admin/products/ml/rangos-publicacion/CardProductCss'
import verifyThirdPartyUser from '../../../general/VerifyThirdPartyUser'
export default function Grid() {
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [openMenuOptionItem, setOpenMenuOptionItem] = useState('');
    const [pageItemCount, setPageItemCount] = useState(7);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [brands, setBrands] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [buyerName, setBuyerName] = useState(null);
    const [buyerNameSearch, setBuyerNameSearch] = useState('');
    const [plugin, setPlugin] = useState('amazon')
    const refInputBuyerName = useRef(null);
    const plugins = {
        ml: "Odoo",
        amazon: "Amazon",
    };

    useEffect(() => {
        verifyThirdPartyUser() && setPlugin('ml');
        const hasAccess = verifyAccessRole(Routes, 24);
        if (!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    }, []);


    const setOpenMenuOptionItemFunc = (v) => {
        if (v === openMenuOptionItem) {
            setOpenMenuOptionItem('')
        } else {
            setOpenMenuOptionItem(v)
        }
    }
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };
    // ? con este metodo obtenemos las ordenes de venta de ml
    const getOrders = async (entId, currentPage, dateFilterOrder, statusFilterOrder, orderIdFilterOrder, buyerName) => {
        globalContext.setLoaderPage(true);
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/order/${entId}/${plugin === 'ml' ? 'odoo' : 'amazon-scrap'}/ml`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                pageNumber: currentPage,
                pageItemCount: 15,
                filter: {
                    ...(dateFilterOrder && {date: dateFilterOrder}),
                    ...(statusFilterOrder && {status: statusFilterOrder}),
                    ...(orderIdFilterOrder && {mlId: orderIdFilterOrder}),
                    ...(buyerName && {buyer: buyerName})
                    //mlId: "4712702415"
                    //status: 'confirmed'
                    //date: '2021-08-12',
                }
            }
        })
        return res;
    }
    // ? con este metodo obtenemos los valores del producto en amazon con el id que nos da la respues ta del metodo getOrders
    const getDataProductAmazon = async (id, entId) => {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${id}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'es',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const getDataProductML = async ({id}) => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.setLoaderPage(false);
            return;
        }
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${id}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    };

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            globalContext.setLoaderPage(false);
            return;
        }
        getOrders(
            entId, 
            currentPage, 
            globalContext.dateFilterOrder, 
            globalContext.statusFilterOrder, 
            globalContext.orderIdFilterOrder,
            buyerName
            )
            .then(async resp => {
                // console.log('las ordenes', resp.data.data)
                let data = resp.data.data.orders;
                setTotal(resp.data.data.totalOrders);
                // verifiacamos que el obj no llegue vacio
                
                if(plugin === 'ml') {
                    for (let index = 0; index < data.length; index++) {
                        const {_id, ml, statusHistory, createdAt } = data[index];
                        const { _id: idProd } = ml.items[0]?.product || {};
                        console.log("imprimo la constante_______>", { _id: idProd })
                        console.log("statusHistory: ",statusHistory);
                        // const dataAmazonScrap = ml.items[0]?.product ? ml.items[0].product.attrib.amazonScrapProduct : ml.items[0].amazonScrapProduct._id
                        await getDataProductML({id: idProd})
                            .then(({data:{data: info}}) => {
                                console.log("res: ",info);
                                console.log("lo que comparo con info", data[index].ml.items[0].product?.data_amazon)
                                if(info) {
                                    data[index].ml.items[0].product.data_amazon  = info;
                                }
                            })
                            .catch((err) => {
                                console.error(`Ha ocurrido un error con el siguiente sku ${ml.items[0]?.product?.attrib?.sku}`);
                                handleAxiosError(err, `Ha ocurrido un error con el siguiente sku ${ml.items[0]?.product?.attrib?.sku}`)
                            })              
                    }
                } else {
                if (data.length > 0) {
                    console.log('data con el error', data)
                    // recorremos el objeto para poder consulta y guadar los datos del objeto de amazon
                    for (let index = 0; index < data.length; index++) {
                        // ? obtenemos la data del producto de amazon y guardamos la misma el el objeto de ordenes
                        if (data[index].ml.items[0].product) {
                            const dataAmazonScrap = data[index].ml.items[0].product ? data[index].ml.items[0].product.attrib.amazonScrapProduct : data[index].ml.items[0].amazonScrapProduct._id
                            console.log('valor', dataAmazonScrap);
                            await getDataProductAmazon(dataAmazonScrap, entId)
                                .then((resp) => {
                                    // console.log(resp)
                                    const dataAmazon = resp.data.data.product;
                                    data[index].ml.items[0].product.data_amazon = dataAmazon.variants.length > 0 ? dataAmazon.variants[0] : dataAmazon;
                                    console.log(`data amazon vuelta `, dataAmazon);
                                })
                                .catch((err) => {
                                    console.log(index,'id',data[index].ml.items[0].product.attrib.amazonScrapProduct)
                                    handleAxiosError(`Un erro ocurrio con el siguiente sku ${data[index].asin}`)
                                })
                            }
                            //globalContext.setLoaderPage(false);
                    }
                    globalContext.setLoaderPage(false);
                } else {
                    globalContext.setLoaderPage(false);
                }
                }
            
                console.log("aqui traigo al data de ODOO", data)
                setData(data);
            })
            .catch(err => {
                globalContext.setLoaderPage(false);
                handleAxiosError(err)
            }).finally(() => globalContext.setLoaderPage(false));

    }, [globalContext.currentEnterpriseId, 
        currentPage, 
        globalContext.dateFilterOrder,
        globalContext.statusFilterOrder,
        globalContext.orderIdFilterOrder,
        buyerName, 
        plugin]);

    //? con este metodo obtenemos las tiendas oficiales
    useEffect(() => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        ).then((resp) => {
            const brands = resp.data.data.brands;
            setBrands(brands);
            //console.log('las marcas', brands);
        }).catch((err) => {
            handleAxiosError(err)
        });
    }, [globalContext.currentEnterpriseId]);

    // ? y con este la renderizamos
    const renderBrandMl = (mlData) => {
        let name = "";
        if (mlData.length > 0) {
            if (mlData[0].product) {
                if (brands.length > 0) {
                    const brand = brands.filter((b) => b.id === mlData[0].product.attrib.official_store_id);

                    if (brand.length > 0) {
                        name = brand[0].name;
                    }
                }
            }
        }
        //console.log('Nombre de la tienda', name)
        return name;
    }

    const renderStatusName = (status) => {
        switch (status) {
            case 'paid':
                return 'Pedido de venta';
            case 'confirmed':
                return 'Orden pagada';
            case 'bought':
                return 'Comprado';
            case 'bought_delayed':
                return 'Comprado en demora';
            case 'received_warehouse_int':
                return 'Recibido bodega miami';
            case 'transit_int':
                return 'En transito internacional';
            case 'customs_received':
                return 'Recibido aduana';
            case 'received_warehouse_local':
                return 'Recivido bodega venezuela';
            case 'transit_local':
                return 'Transito cliente';
            case 'delivered':
                return 'Producto entregado cliente';
            case 'lost':
                return 'Perdido';
            case 'canceled':
                return 'Orden cancelada';
            case 'defective':
                return 'Orden defectuosa';
            case 'return_provider':
                return 'Devolucion a provedor';
            case 'return_store':
                return 'Devolucion a tienda';
            case 'other':
                return 'Otros';
            default:
                return 'Pedido de venta';
        }
    }

    const calculTime = (fecha) => {
        const fechaHoy = new Date();
        const fechaActual = new Date(fecha);
        const date1utc = Date.UTC(fechaHoy.getFullYear(), fechaHoy.getMonth(), fechaHoy.getDate());
        const date2utc = Date.UTC(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());
        const day = 1000 * 60 * 60 * 24;
        return (date2utc - date1utc) / day
    }

    const clearDate = dateF => {
        const date = new Date(dateF)
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        return `${dd}-${mm}-${yyyy}`
    }
    const apliFilterBuyer = (e) => {
        e.preventDefault();
        // console.log('aquuuided', buyerNameSearch)
        if(buyerNameSearch.length > 0) {
            // console.log('es valida la condicion');
            setBuyerName(buyerNameSearch)
        }
    }

    const clearAllFilters = () => {
        globalContext.setStatusFilterOrder(null)
        globalContext.setDateFilterOrder(null)
        globalContext.setOrderIdFilterOrder(null);
        setBuyerName(null)
        setBuyerNameSearch('')
        refInputBuyerName.current.value = '';
    }
    return (
        <GridStyles.cont>
            {redirect}
            <GridStyles.header>
                <GridStyles.text fw="500" size="15pt">
                    Pedidos |
                </GridStyles.text>
                <GridStyles.text fw="400" size="15pt" ml="10px">
                    Listas de solicitudes
                </GridStyles.text>
                {!verifyThirdPartyUser() && 
                    <CardProductCss.select
                        defaultValue={plugin}
                        onChange={
                            (e) => setPlugin(e.target.value)
                        }
                    >
                        {Object.entries(plugins).map(
                            (val, i) => {
                                if (
                                    verifyThirdPartyUser() &&
                                    val[0] === "amazon"
                                )
                                    return null;
                                return (
                                    <option
                                        value={val[0]}
                                        key={i}
                                    >
                                        {val[1]}
                                    </option>
                                );
                            }
                        )}
                    </CardProductCss.select>
                }
            </GridStyles.header>

            <GridStyles.contGridData>
                <GridStyles.headerGridData>
                    <GridStyles.textTwo>
                        Total de Ordenes {total}
                    </GridStyles.textTwo>
                </GridStyles.headerGridData>
                <div style={{width: '100%'}} className='d-flex justify-space-between align-items-center'>
                <GridStyles.contInputSearch onSubmit={(e) => apliFilterBuyer(e)}>
                    <GridStyles.input 
                        type="text" 
                        placeholder="escriba aqui lo que desee buscar"
                        onChange={(e) => setBuyerNameSearch(e.target.value)}
                        ref={refInputBuyerName}
                        />
                    {
                        buyerName && (
                            <GridStyles.btnFloat onClick={() => {
                                setBuyerName(null)
                                setBuyerNameSearch('')
                                refInputBuyerName.current.value = ''
                            }}>
                                <FontAwesomeIcon icon={faTimes} />
                            </GridStyles.btnFloat>
                            )
                            }
                </GridStyles.contInputSearch>
                <GridStyles.ButtonList>
                        <GridStyles.ButtonItem br={
                            (globalContext.dateFilterOrder ||
                            globalContext.statusFilterOrder ||
                            globalContext.orderIdFilterOrder ||
                            buyerName) && '1px solid'
                        } onClick={() => globalContext.setModalFilterOrdes(true)}>
                            <GridStyles.OptionListButton>
                                <BiFilterAlt />
                            </GridStyles.OptionListButton>
                        </GridStyles.ButtonItem>
                        {
                            (globalContext.dateFilterOrder ||
                            globalContext.statusFilterOrder ||
                            globalContext.orderIdFilterOrder ||
                            buyerName) && (
                                <GridStyles.ButtonItem onClick={() => clearAllFilters()}>
                                    <GridStyles.OptionListButton>
                                        <BiTrashAlt />
                                    </GridStyles.OptionListButton>
                                </GridStyles.ButtonItem>
                            )
                        }
                    </GridStyles.ButtonList>
                </div>
                <GridStyles.listData>
                    {
                        (data.length > 0) &&
                        data.map((v, i) => {
                            return (
                                <GridStyles.itemListData key={i}>
                                    {
                                        v.ml?.items[0]?.product && (
                                            <>
                                            <OptionsCont
                                                idAmazon={v.ml?.items[0]?.product?.attrib?.amazonScrapProduct || v.ml?.items[0]?.product?._id}
                                                plugin={plugin}
                                                idorder={v._id} active={i === openMenuOptionItem}
                                                setActive={setOpenMenuOptionItem}
                                                statusTracking={v.amazonScrapStatusHistory ? v.amazonScrapStatusHistory : null}
                                                nroTracking={v.trackingNumber ? v.trackingNumber : null}
                                            />

                                            </>
                                        )
                                    }
                                    <GridStyles.cardProduct>
                                        <GridStyles.cardProductHeader>
                                            <GridStyles.text fw="500">
                                                {v.ml?.items[0]?.product && v.ml?.items[0]?.product?.title}
                                            </GridStyles.text>
                                            <GridStyles.contBtnActionsCard>
                                                <GridStyles.btnOneAction onClick={() => setOpenMenuOptionItemFunc(i)}>
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </GridStyles.btnOneAction>
                                            </GridStyles.contBtnActionsCard>
                                        </GridStyles.cardProductHeader>
                                        <GridStyles.contDataText mt="5px">
                                            <GridStyles.text fw="500" mt="0" mb="0">
                                                {`Tienda oficial: ${renderBrandMl(v.ml.items)}`}
                                            </GridStyles.text>
                                        </GridStyles.contDataText>
                                        <GridStyles.contImgAndText>
                                            <GridStyles.contImg 
                                                url={
                                                    v.ml?.items[0]?.product?.data_amazon?.images?.length > 0
                                                    ? v.ml?.items[0]?.product?.data_amazon?.images[0]
                                                    : v.ml?.items[0]?.product?.data_amazon?.thumbnail ??
                                                      ""
                                                    } />
                                            <GridStyles.contTextOfText>

                                                <GridStyles.contTextOfText>
                                                    <GridStyles.text fw="500" mt="0" mb="0">
                                                        Canal de venta
                                                    </GridStyles.text>
                                                </GridStyles.contTextOfText>
                                                <GridStyles.textAreaCont>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            ID DE LA VENTA-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml.buyerId}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            ID PUBLICACION-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml?.items[0]?.id}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            ID ORDEN ML-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml.orderId}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            CANTIDAD-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml?.items[0]?.product && v.ml?.items[0]?.product?.attrib?.sold_quantity}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            LINK-
                                                        </GridStyles.text>
                                                        <GridStyles.text longText={true} mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml?.items[0]?.product && v.ml?.items[0]?.product?.attrib?.permalink}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                </GridStyles.textAreaCont>

                                                <GridStyles.contTextOfText>
                                                    <GridStyles.text fw="500" mt="0" mb="0">
                                                        Datos del proveedor
                                                    </GridStyles.text>
                                                </GridStyles.contTextOfText>
                                                <GridStyles.textAreaCont>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            SKU-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.ml?.items?.[0]?.product?.data_amazon?.asin ?? v.ml?.items?.[0]?.product?.data_amazon?.attrib?.amazonScrapProductAsin ?? ""}
                                                            {/* {(v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon) && v.ml?.items[0]?.product?.data_amazon?.asin} */}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            PESO-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {`${(v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon) && v.ml?.items[0]?.product?.data_amazon?.weight} LBS`}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            DIMENSIONES-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                (v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon) &&
                                                                `
                                                                LONG-${v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon?.dimensions?.length}
                                                                L-${v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon?.dimensions?.width}
                                                                A-${v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon?.dimensions?.height}
                                                                `
                                                            }
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            PESO V-
                                                        </GridStyles.text>
                                                        {v.ml?.items[0]?.product && (<GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                (v.ml?.items[0]?.product?.data_amazon) &&
                                                                `${(((parseFloat(v.ml?.items[0]?.product?.data_amazon?.dimensions?.height) * parseFloat(v.ml?.items[0]?.product?.data_amazon?.dimensions?.width) * parseFloat(v.ml?.items[0]?.product?.data_amazon?.dimensions?.length)) * 1.2) / 166).toString().slice(0, 5)} (LBS) `}
                                                        </GridStyles.text>)}
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            PRECIO USD-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                (v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon) &&
                                                                `$${v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon?.price}`}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            PROVEEDOR-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {plugin === 'ml' ? "MERCADO LIBRE" : "AMAZON"}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            LINK-
                                                        </GridStyles.text>
                                                        <GridStyles.text longText={true} mt="0" mb="0" cl="#7367F0" fw="450" style={{ width: '90%' }}>
                                                            {/* {v.ml?.items[0]?.product?.data_amazon?.url || v.ml?.items[0]?.product.data_amazon?.attrib?.permalink} */}
                                                            {(v.ml?.items[0]?.product && v.ml?.items[0]?.product?.data_amazon) && v.ml?.items[0]?.product?.data_amazon?.url}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                </GridStyles.textAreaCont>

                                                <GridStyles.contTextOfText>
                                                    <GridStyles.text fw="500" mt="0" mb="0">
                                                        Seguimiento
                                                    </GridStyles.text>
                                                </GridStyles.contTextOfText>
                                                <GridStyles.textAreaCont>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            ESTATUS-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {v.amazonScrapStatusHistory ?
                                                                renderStatusName(v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].status)
                                                            : (v.statusHistory && v.statusHistory.length > 0) ? 
                                                                renderStatusName(v.statusHistory[v.statusHistory?.length - 1].status)
                                                            : "No posee"}
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            DIAS EN ULTIMO ESTATUS-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                v.amazonScrapStatusHistory ?
                                                                    v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].createdAt &&
                                                                    calculTime(v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].createdAt)
                                                                    : (v.statusHistory && v.statusHistory.length > 0 && v.statusHistory[v.statusHistory.length - 1]?.createdAt) ? 
                                                                    calculTime(v.statusHistory[v.statusHistory?.length - 1].createdAt)
                                                                : "No posee"
                                                            }
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            VIDA DE LA ORDEN (D)-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                calculTime(v.createdAt) + ' Dias'
                                                            }
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                    <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            FECHA ULTIMA NOVEDAD-
                                                        </GridStyles.text>
                                                        <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                v.amazonScrapStatusHistory ?
                                                                    (v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].novelties) &&
                                                                    (v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].novelties.length > 0) &&
                                                                    clearDate(v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1].novelties[0].createdAt)
                                                                    : (v.statusHistory && v.statusHistory.length > 0) ? 
                                                                    (v.statusHistory[v.statusHistory.length - 1].novelties?.length > 0) &&
                                                                    clearDate(v.statusHistory[v.statusHistory.length - 1].novelties[0]?.createdAt)
                                                                : "No posee"
                                                            }
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                   <GridStyles.contTwoText>
                                                        <GridStyles.text mt="0" mb="0">
                                                            NOVEDADES-
                                                        </GridStyles.text>
                                                        <GridStyles.text longText={true} mt="0" mb="0" cl="#7367F0" fw="450">
                                                            {
                                                                v.amazonScrapStatusHistory ?
                                                                    (v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1]?.novelties) &&
                                                                    (v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1]?.novelties?.length > 0) &&
                                                                    v.amazonScrapStatusHistory[v.amazonScrapStatusHistory.length - 1]?.novelties[0]?.novelty
                                                                    : v.statusHistory ?
                                                                    (v.statusHistory[v.statusHistory.length - 1]?.novelties) &&
                                                                    (v.statusHistory[v.statusHistory.length - 1]?.novelties?.length > 0) &&
                                                                    v.statusHistory[v.statusHistory.length - 1]?.novelties[0]?.novelty
                                                                    : 'No posee'
                                                            }
                                                        </GridStyles.text>
                                                    </GridStyles.contTwoText>
                                                </GridStyles.textAreaCont>

                                            </GridStyles.contTextOfText>
                                        </GridStyles.contImgAndText>

                                        {
                                            v.ml.buyerData &&
                                            (
                                                <GridStyles.contDataText mt="5px">
                                                    <GridStyles.text fw="500" mt="0" mb="0">
                                                        Datos del cliente
                                                    </GridStyles.text>
                                                    <GridStyles.textAreaCont>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                ID-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.docNumber || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                TLF-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.phone || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                CLIENTE-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {`${v.ml.buyerData.firstName} ${v.ml.buyerData.lastName}` || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                País-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.country || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                ESTADO-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.state || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                CIUDAD-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.city || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                DIRECCION-
                                                            </GridStyles.text>
                                                            <GridStyles.text longText={true} mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerData.address || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                        <GridStyles.contTwoText>
                                                            <GridStyles.text mt="0" mb="0">
                                                                USERNAME-
                                                            </GridStyles.text>
                                                            <GridStyles.text mt="0" mb="0" cl="#7367F0" fw="450">
                                                                {v.ml.buyerNickname || 'No posee'}
                                                            </GridStyles.text>
                                                        </GridStyles.contTwoText>
                                                    </GridStyles.textAreaCont>
                                                </GridStyles.contDataText>
                                            )
                                        }
                                    </GridStyles.cardProduct>
                                </GridStyles.itemListData>
                            )
                        })
                    }
                </GridStyles.listData>
                {
                    data.length > 0 &&
                    (
                        <div style={{ width: 100 + '%', }} className="d-flex justify-content-center mt-3">
                            <Pager
                                handleSetPage={setCurrentPage}
                                totalResults={total}
                                currentPage={currentPage}
                                resultsPerPage={pageItemCount}
                            />
                        </div>
                    )
                }
            </GridStyles.contGridData>
        </GridStyles.cont>
    )
}
