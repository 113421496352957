import React, { useContext, useEffect, useState } from "react";
import ProductsAssocStyle from "../../../../../assets/css/components/admin/products/ProductsAssoc.css";
import { Image, Form, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSave} from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import { Input } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts";

const MyModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          ¿DESEA DESVINCULAR EL PRODUCTO?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Introduzca la Contraseña de Aprobación</h4>
        <p>
          <Input value={props.valPass} onChange={e => props.setValPass(e.target.value)}/>
        </p>
        <p style={{ color: 'red' }}>{props.invalid ? "Ha ingresado una contraseña no valida.": ""}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={props.onHide}>CANCELAR</Button>
        <Button className={`btn btn-danger`} onClick={props.removeLinked}>ACEPTAR</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function ListProductsAssoc(props) {
  const [unlinkId, setUnlinkId] = useState(null);
  const [valPass, setValPass] = useState('');
  const [modal, setModal] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  
  function openModal(id) {
    setModal(true);
    setUnlinkId(id);
  }

  function closeModal() {
    setInvalid(false);
    setValPass(null);
    setModal(false);
    setUnlinkId(null);
  }
  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Remove a product from the linked list based on its ID
   */
  const removeLinked = () => {
    if(valPass === process.env.REACT_APP_PROD_UNLINKID ) {
      closeModal()
      props.updateLinkedList(
        props.linked.filter((l) => l.product._id.toString() !== unlinkId.toString())
      );
    } else {
      setInvalid(true);
    }
  };

  /**
   * Save the current linked list
   */
  const saveChanges = () => {
    let deseaAsociar = window.confirm("¿Desea asocciar estos productos?");
    console.log('props')
    console.log(props)
    if (deseaAsociar === true) {
      const entId = globalContext.currentEnterpriseId;
      globalContext.setLoaderPage(true);
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/product/link/${entId}`,
          {
            productId: props.productId,
            toLinkData: props.linked.map((l) => {
              return {
                id: l.product._id,
                maxStock: l.maxStock,
                priceMultiplier: l.priceMultiplier,
                stockDivisor: l.stockDivisor,
                plugin: l.product.plugin,
                vtexAccId: l.product.accId,
              };
            }),
          },
          {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "x-access-token": localStorage.getItem(keyAccessToken),
              "x-user-id": localStorage.getItem(keyUserId),
            },
          }
        )
        .then((resp) => {
          const data = resp.data.data;

          props.updateLinkedList(
            props.linked.map((l) => {
              l.saved = true;
              return l;
            })
          );

          globalContext.showModalWithMsg(
            <div>
              <span>Se actualizaron {data.numUpdatedProducts} productos</span>
              {
                data.numFailedProducts && (
                  <>
                    <br/><span>Fallo la actualizacion de {data.numFailedProducts} productos. Los errores fueron:</span>
                    { data.errorMsg.map((msg, msgIdx) => {
                      return <span key={msgIdx}><br/>- {msg}</span>
                    }) }
                  </>
                )
              }
            </div>
          );
        })
        .catch((err) => {
          if (axios.isCancel(err)) return; //Don't show axios cancel error

          globalContext.showModalWithMsg(
            "Disculpe, hubo un error al intentar actualizar los productos asociados."
          );
          console.error(err, err.response);
        })
        .finally(() => {
          globalContext.setLoaderPage(false);
        });
    }
  };

  /**
   * Updates the value of a linked item
   * @param {Event} e The change event
   * @param {string} prodId The prouct ID
   * @param {string} field The field to update
   */
  const updateLinkedValue = (e, prodId, field) => {
    props.updateLinkedList(
      props.linked.map((l) => {
        if (l.product._id.toString() !== prodId.toString()) return l;

        l[field] = e.target.value || undefined;
        return l;
      })
    );
  };

  return (
    <ProductsAssocStyle>
      <div className="list-products-asocc">
        <div className="limiter">
          <div className="container-table100">
            <div className="wrap-table100 col-12 mt-3">
              <div className="table100">
                <table>
                  <thead>
                    <tr className="table100-head">
                      <th className="column1">IMAGEN</th>
                      <th className="column2">PROD</th>
                      <th className="column3">PLUGIN</th>
                      <th className="column4">MUL. PRECIO</th>
                      <th className="column5">MAX STOCK</th>
                      <th className="column6">DIV. STOCK</th>
                      <th className="column7">
                        <button onClick={saveChanges}>
                          <FontAwesomeIcon icon={faSave} />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.linked.map((l) => {
                      const prod = l.product;
                      const varDesc = !prod.variantDesc ? null : (
                        <p
                          className="nv"
                          style={{ color: l.saved ? "#5e548e" : "#c81816" }}
                        >
                          {prod.variantDesc}
                        </p>
                      );

                      /**
                       * ? img odoo
                       */
                      let thumbnailSrc = "";

                      if (prod.thumbnail) {
                        if (prod.plugin === "odoo") {
                          thumbnailSrc = (prod.thumbnail && typeof prod.thumbnail == "object" && prod.thumbnail.url) ? prod.thumbnail.url
                            : (prod.thumbnail && typeof prod.thumbnail == "string") ? `data:image/png;base64,${prod.thumbnail}`
                            : false;
                        } else {
                          thumbnailSrc = prod.thumbnail;
                        }
                      }
                      return (
                        <tr key={l.product._id}>
                          <td className="column1">
                            <Image
                              src={thumbnailSrc}
                              alt=""
                            />
                          </td>
                          <td className="column2">
                            <p
                              className="nv"
                              style={{ color: l.saved ? "#5e548e" : "#c81816" }}
                            >
                              {prod.title}
                              {prod.mlBrandName && (
                                <>
                                  <br />
                                  <span>
                                    <i>{prod.mlBrandName}</i>
                                  </span>
                                </>
                              )}
                            </p>
                            {varDesc}
                          </td>
                          <td className="column3">
                            <p className="nv">{prod.plugin}</p>
                          </td>
                          <td className="column4">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                updateLinkedValue(
                                  e,
                                  prod._id,
                                  "priceMultiplier"
                                );
                              }}
                              value={l.priceMultiplier}
                            />
                          </td>
                          <td className="column5">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                updateLinkedValue(e, prod._id, "maxStock");
                              }}
                              value={l.maxStock}
                            />
                          </td>
                          <td className="column6">
                            {props.isFromMainPlugin && (
                              <Form.Control
                                type="text"
                                onChange={(e) => {
                                  updateLinkedValue(
                                    e,
                                    prod._id,
                                    "stockDivisor"
                                  );
                                }}
                                value={l.stockDivisor}
                              />
                            )}
                          </td>
                          <td className="column7">
                            <button
                              onClick={() => {
                                openModal(prod._id);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyModal show={modal} onHide={()=> setModal(false)} removeLinked={removeLinked} valPass={valPass} setValPass={setValPass} invalid={invalid}/>
    </ProductsAssocStyle>
  );
}
