import styled from "styled-components";
const UserConfigNewUserStyled = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .NewUserCont {
    // font-family: "Roboto Slab", serif !important;
    /*border: 1px solid #e5e9f2;*/
  }
  .goBack {
    .btn-back {
      border: 1px solid rgb(94, 84, 142);
      color: rgb(94, 84, 142);
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      transition: all ease 450ms;
      &:hover {
        background: rgb(94, 84, 142);
        color: white;
      }
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
  .btn-save-user {
    // font-family: "Roboto Slab", serif !important;
    border: 1px solid rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    padding: 8px 16px;
    background: transparent;
    border-radius: 5%;
    transition: all ease 450ms;
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
  .input-group-text {
    // font-family: "Roboto Slab", serif !important;
    background: white;
    color: #5e548e;
    width: 80px;
  }
  p {
    // font-family: "Roboto Slab", serif !important;
    color: rgb(94, 84, 142);
    font-weight: bold;
  }
  label {
    // font-family: "Roboto Slab", serif !important;
    color: #808080;
  }
  input {
    ::-webkit-input-placeholder {
      /* Edge */
      color: #808080;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #808080;
    }

    ::placeholder {
      color: #808080;
    }
    outline: none;
    box-shadow: none;
    &:focus,
    &:active,
    &:active:focus {
      box-shadow: none;
      border-color: rgb(94, 84, 142);
    }
  }
  #asdasd {
    // font-family: "Roboto Slab", serif !important;
    outline: none;
    box-shadow: none;
    &:focus,
    &:active,
    &:active:focus {
      box-shadow: none;
      border-color: rgb(94, 84, 142);
    }
  }
  .save-register {
    color: #02c39a;
    font-size: 30pt;
  }
  .error-register {
    color: #d3514d;
    font-size: 30pt;
  }
  .btn-add-again-user {
    font-weight: bolder;
    outline: none;
    box-shadow: none;
    border: solid 1px rgb(94, 84, 142);
    color: rgb(94, 84, 142);
    background: transparent;
    border-radius: 5px;
    padding: 8px 16px;
    transition: all ease 450ms;
    &:hover {
      background: rgb(94, 84, 142);
      color: white;
    }
  }
  /**
  *? panallas de save error y form
   */
  .form-user,
  .save-screen,
  .error-screen {
    display: none;
    transition: all ease 450ms;
  }
  .save-screen.active,
  .error-screen.active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .form-user.active {
    display: block;
  }
`;
export default UserConfigNewUserStyled;
