import React, { useContext, useState, useEffect } from "react";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";

//* metodo que imprime el step
import PrintStep from "./PrintSteps";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";


export default function OdooConfig() {
  const [redirect, setRedirect] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 45);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
  }, []);
  //[globalContext.parentEntId]);

  /**
   * Sync the pricelist data
   */
  const syncPriceLists = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    if (!window.confirm(`Desea descargas los datos de sus tarifas?`)) return;

    globalContext.setLoaderPage(true);
    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}/sync`,
      {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      }
    ).then(resp => {
      globalContext.showModalWithMsg(
        "Los datos fueron descargados."
      );
    }).catch((err) => {
      if (axios.isCancel(err)) return; //Don't show axios cancel error

      globalContext.showModalWithMsg(
        "Disculpe, hubo un error al intentar descargar los datos."
      );
      console.error(err, err.response);
    }).finally(() => {
      globalContext.setLoaderPage(false);
    });
  }

  /**
   * Sync the taxes lists data
   */
  const syncTaxLists = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    if (!window.confirm(`Desea descargas los datos de sus impuestos?`)) return;

    globalContext.setLoaderPage(true);
    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-tax/${entId}/sync`,
      {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      }
    ).then(resp => {
      globalContext.showModalWithMsg(
        "Los datos fueron descargados."
      );
    }).catch((err) => {
      if (axios.isCancel(err)) return; //Don't show axios cancel error

      globalContext.showModalWithMsg(
        "Disculpe, hubo un error al intentar descargar los datos."
      );
      console.error(err, err.response);
    }).finally(() => {
      globalContext.setLoaderPage(false);
    });
  }

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  return (
    <OdooStyle>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="/admin/plugins/my-plugins/my-menu-plugins">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-center"
        >
          <div className="odooFormBody">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="hearderFormOdoo"
                >
                  <p> Rellene los Siguientes Campos para el plugin de Odoo</p>
                </Col>
              </Row>
              <PrintStep />
            </Container>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="footerFormOdoo">
          <button className="download-tarifa" onClick={syncPriceLists}>
            Descargar tarifas
          </button>
          <button className="download-tarifa" onClick={syncTaxLists}>
            Descargar impuestos
          </button>
        </Col>
      </Row>
    </OdooStyle>
  );
}
