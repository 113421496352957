import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import {
  ProductDetailStyle,
  PrimaryText,
} from "../../../../../assets/css/components/admin/products/ProductDetail.css";

import CardProductDetail from "../../product/components/CardProductDetail";
import ListOtherProducts from "../../product/components/ListOtherProducts";
import ListProductsAssoc from "../../product/components/ProductsAssoc";
import axios from "axios";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import { getVariantDescription } from "../../../../../assets/js/productHelper";
import Routes from "../../../../../Routes";
import verifyAccessRole from "../../../../../assets/js/verifyAccessRole";
import verifyThirdPartyUser from "../../../../general/VerifyThirdPartyUser";

  /**
   * Funcion para eliminar toda etiqueta html
   * @param {string} txt
   * @returns
   */
  const discardHTML = (txt) => {
    const tagsExceptBR = /<\/?(?!br)[^>]+(>|$)/g;
    const tagsBR = /<\/?[br][^>]+(>|$)/g;
    let textoLimpio = txt.replace(tagsExceptBR, "");
    textoLimpio = textoLimpio.replace(tagsBR, "\n");
    return textoLimpio;
  };

export const LoadingSpinner = () => {
  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{ minHeight: "500px" }}
    >
      <Spinner animation='grow' size='lg' style={{ color: "#7367F0" }} />
    </div>
  );
};

export default function ProductsDetail({ prodId, setModal, plugin }) {
  const globalContext = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  let pluginName = "";

  switch (plugin) {
    case "odoo":
      pluginName = "Odoo";
      break;

    case "ml":
      pluginName = "Mercadolibre";
      break;

    case "ln":
      pluginName = "Linio";
      break;
    case "df":
      pluginName = "Dafiti";
      break;
    case "vtex":
      pluginName = "Vtex";
      break;
    default:
      pluginName = "Odoo";
      break;
  }

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [isFromMainPlugin, setIsFromMainPlugin] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [mlBrandName, setMlBrandName] = useState("");
  const [barCode, setBarCode] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [officialStoreId, setOfficialStoreId] = useState(0);
  const [hasVariants, setHasVariants] = useState(false);
  const [variantDesc, setVariantDesc] = useState("");
  const [variants, setVariants] = useState([]);
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState("");
  const [marca, setMarca] = useState("")
  const [weight, setWeight] = useState("");
  const [volume, setVolume] = useState("");
  const [lenght, setLenght] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [linked, setLinked] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productToLinkId, setProductToLinkId] = useState(prodId);
  const [calculatedPrice,setCalculatedPrice] = useState(0);
  const [formula, setFormula] = useState('');
  const [earning, setEarned] = useState(0);
  const [parentId, setParentId] = useState(prodId);
  const [isVariant, setIsVariant] = useState(null);
  const [baseProduct, setBaseProduct] = useState(null);
  const [categories, setCategories] = useState([])

  /**
   * Update the state
   * @param {object} data The data to set
   */
  const setProductData = (data) => {
    if (data.title) setTitle(data.title);
    if (data.price || data.price === 0) setPrice(data.price);
    if (data.stock || data.stock === 0) setStock(data.stock);
    if (data.thumbnail) setThumbnail(data.thumbnail);
    if (typeof data.hasVariants !== "undefined")
      setHasVariants(data.hasVariants);
    if (data.description) setDescription(discardHTML(data.description));
    if (data.marca) setMarca(data.marca);
    if (data.weight) setWeight(data.weight);
    if (data.volume) setVolume(data.volume);
    if (data.lenght) setLenght(data.lenght);
    if (data.width) setWidth(data.width);
    if (data.height) setHeight(data.height);
    if (data.variantDesc) setVariantDesc(data.variantDesc);
    if (data.variants) setVariants(data.variants);
    if (data.linked) setLinked(data.linked);
    if (data.barCode) setBarCode(data.barCode);
    if (data.mlBrandName) setMlBrandName(data.mlBrandName);
    if (data.officialStoreId) setOfficialStoreId(data.officialStoreId);
    if (data.productToLinkId) setProductToLinkId(data.productToLinkId);
    if (data.categories) setCategories(data.categories);
    if (data.images) setImages(data.images);
  };

  /**
   * Updates the linked list
   * @param {Array} linked The new list
   */
  const updateLinkedList = (linked) => {
    setLinked(linked.map((l) => l));
  };

  /**
   * Search the user main plugin
   */
  const searchUseMainPlugin = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((res) =>
        setIsFromMainPlugin(res.data.data == plugin ? true : false)
      )
      .catch((err) => {
        setModal(false);
        console.error("Search the user main plugin", err);
      });
  };

  /**
   * Verify Access
   */
  const verifyAccess = () => {
    const hasAccess = verifyAccessRole(Routes, 86);
    if (!hasAccess) {
      setModal(false);
    }
  };

  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;

    if (!globalContext.currentEnterpriseId) return setModal(false);

    const headerParams = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    switch (plugin) {
      case "odoo":
        const url = `${process.env.REACT_APP_BACKEND_URL}/v1/${plugin}/details/${entId}/${prodId}`;
        axios
          .get(url, {
            cancelToken: axiosCancelTokenSource.token,
            headers: headerParams,
            params: {
              variants: true,
              linked: true,
            },
          })
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, plugin);
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              description: data.attrib.description,
              weight: data.attrib.weight,
              volume: data.attrib.volume,
              lenght: data.attrib.x_studio_product_pack_lenght,
              width: data.attrib.x_studio_product_pack_width,
              height: data.attrib.x_studio_product_pack_height,
              marca: data.attrib.x_studio_marca,
              thumbnail:
                data.thumbnail &&
                typeof data.thumbnail == "object" &&
                data.thumbnail.url
                  ? data.thumbnail.url
                  : data.thumbnail && typeof data.thumbnail == "string"
                  ? `data:image/png;base64,${data.thumbnail}`
                  : false,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.barCode,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);

            //Set the variation data
            if (data.isVariant) {
              setVariantDesc(getVariantDescription(data, plugin));
            }

            setLoading(!loading);
          })
          .catch((err) => {
            if (axios.isCancel(err)) return setModal(false); //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            setModal(false);
            console.error("Get the product data: ", err, err.response);
          });
        break; //End odoo

      case "ml":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${prodId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                variants: true,
                linked: true,
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, "ml");
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              thumbnail: data.thumbnail,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.barCode,
              officialStoreId: data.attrib.official_store_id,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);

            //Set the variant data (if the product is a variant)
            if (data.isVariant) {
              const desc = getVariantDescription(data, "ml");
              setVariantDesc(desc);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            console.error(err, err.response);
          });
        break; //End ml
      /**
       * ! linio
       */
      case "ln":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/linio/${entId}/${prodId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                variants: true,
                linked: true,
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, "ln");
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              thumbnail: data.thumbnail,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.sellerSku,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);
            //setRequestedIsVariant(data.isVariant);
            //setRequestedHasVariants(data.hasVariants);

            //Set the variant data (if the product is a variant)
            if (data.isVariant) {
              const desc = getVariantDescription(data, "ln");
              setVariantDesc(desc);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            console.error(err, err.response);
          });
        break; //End ln
      case "df":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-product/${entId}/${prodId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                variants: true,
                linked: true,
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, "df");
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              thumbnail: data.thumbnail,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.sellerSku,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);

            //Set the variant data (if the product is a variant)
            if (data.isVariant) {
              const desc = getVariantDescription(data, "df");
              setVariantDesc(desc);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            console.error(err, err.response);
          });
        break; //End df
      default:
        break;
    }
    //eslint-disable-next-line
  }, [globalContext.currentEnterpriseId]);

  /**
   * Load the brands list
   */
  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        const brands = resp.data.data.brands;
        setBrands(brands);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        console.error(err, err.response);
      });
    //eslint-disable-next-line
  }, [globalContext.currentEnterpriseId]);

  /**
   * Update the ML brand name
   */
  useEffect(() => {
    let name = "";
    if (officialStoreId && brands.length > 0) {
      const brand = brands.filter((b) => b.id === officialStoreId);

      if (brand.length > 0) {
        name = brand[0].name;
      }
    }
    setMlBrandName(name);
  }, [brands, officialStoreId]);

  /**
   * Cancel axios requests before unmount
   */
  const cancelAxiosRequestsBeforeUnmount = () => {
    return () => axiosCancelTokenSource.cancel("Canceled by unmount");
  };

  async function getPublishingPrice (variantId) {
    const entId = globalContext.currentEnterpriseId;
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/product-price-rate/calculate-publishingining-price/`, {
        entId: entId,
        prodId: variantId,
        plugin: plugin,
        countryCode: entId === "60944575461add5b1957ef8b" ? 'VE' : 'CO'
    }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    }).then(({data: { data }}) => {
      // console.log(data);
      setCalculatedPrice(data.total);
      setEarned(data.earning);
      setFormula(data.formula);
    }).catch(err => console.error(err));
}

  useEffect(() => {
    verifyAccess();
    searchUseMainPlugin();
    cancelAxiosRequestsBeforeUnmount();
    //eslint-disable-next-line
  }, []);

  return (
    <ProductDetailStyle>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container fluid>
          <div className='mx-3'>
            <Row>
              <PrimaryText>
                <h4>{title}</h4>
              </PrimaryText>
            </Row>
            <Row>
              <PrimaryText>
                <h6>{pluginName}</h6>
              </PrimaryText>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardProductDetail
                calculatedPrice={calculatedPrice}
                earning={earning}
                getPublishingPrice={getPublishingPrice}
                formula={formula}
                plugin={plugin}
                variants={variants}
                pluginName={pluginName}
                description={description}
                marca={marca}
                weight={weight}
                volume={volume}
                lenght={lenght}
                width={width}
                height={height}
                price={price}
                stock={stock}
                thumbnail={thumbnail}
                variantDesc={variantDesc}
                barCode={barCode}
                mlBrandName={mlBrandName}
                productToLinkId={productToLinkId}
                setProductData={setProductData}
                updateLinkedList={updateLinkedList}
                setVariantDesc={setVariantDesc}
                parentId={parentId}
                categories={categories}
                images={images}
                minHeight='357px'
                title={title}
              />
            </Col>
          </Row>
          {(!hasVariants && !verifyThirdPartyUser()) && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='pt-5'>
                <ListOtherProducts
                  excludePlugin={plugin}
                  linked={linked}
                  updateLinkedList={updateLinkedList}
                  brands={brands}
                  productId={productToLinkId}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='pt-5'>
                <ListProductsAssoc
                  linked={linked}
                  updateLinkedList={updateLinkedList}
                  productId={productToLinkId}
                  brands={brands}
                  isFromMainPlugin={isFromMainPlugin}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </ProductDetailStyle>
  );
}
