import React, { useState, useEffect, useContext } from 'react';
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import Pager from "../../../../general/Pager";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from "axios";
import {Spinner} from 'react-bootstrap';
import {
    keyUserId,
    keyAccessToken
  } from "../../../../../assets/js/SessionStorageNames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
function SelectCategory(props) {
    /**
     * * para el seteo de las categorias
     */
    const {setCategoryMl, setCategoryMlText, nextStep, setCategoryMlData} = props;

    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const pageItemCount = 15;
    const [totalCategories, setTotalCategories] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoriesP, setCategoriesP] = useState(null);
    const [categoriesPatherBefore, setCategoriesPatherBefore] = useState([null]);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [showButton, setShowButton] = useState(false)
    const [getAll, setGetAll] = useState(false);
    const [categoriesText, setCategoriesText] = useState([]);
    const [valueInputFilter, setValueInputFilter] = useState('');
    /**
 * Handle the axios error
 * @param {Error} err The thrown error
 * @param {string} msg A message to show in the modal
 * @param {boolean} consoleLog If should log the error in the console
 */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    /**
     * ? seteamos el valor de la categoria padre @c._id
     * * agg la trasa de los padres q se a ido agg al igual q el texto
     */
    const chageCategori = (c) => {
        setCurrentPage(1);
        setFilter('')
        setCategoriesPatherBefore([...categoriesPatherBefore, c._id]);
        setCategoriesP(c._id);
        setGetAll(true);
        setShowButton(true);
        if (categoriesText.length > 0) {
            setCategoriesText([...categoriesText,' > '+c.name]);
        }else {
            setCategoriesText([...categoriesText, c.name]);
            setCategoryMlData(c)
        }
    }
    /**
     * este metodo es para volver en las categorias 
     */
    const functSetCategoryMl = (v) => {
        setCategoryMl(v)
    }
    const functSetCategoryMlText = (v) => {
        setCategoryMlText(v)
    }
    const backTo = () => {
        const findCateP = categoriesPatherBefore.pop();
        setCategoriesPatherBefore(categoriesPatherBefore.filter((e)=> (e !== findCateP)))
        setCategoriesP(categoriesPatherBefore.pop());
        const findCate = categoriesText.pop();
        setCategoriesText(categoriesText.filter((e)=> (e !== findCate)));
    }
    const getCategories = async (entId,currentPage, pageItemCount, categoriesP, getAll, filter) => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-category/${entId}`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            cancelToken: axiosCancelTokenSource.token,
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                parent: categoriesP,
                filter: {
                    getAll: getAll,
                    name: filter,
                },
            }
        })
        .then((r)=> {
            if(r.data.data.categories.length < 1) {
                console.error('es menor a cero  ')
                setIsLoad(false);
                if (filter.length < 1) {
                    functSetCategoryMlText(categoriesText.join('').toString());
                    functSetCategoryMl(categoriesP)
                    nextStep();
                }
            }else {
                console.table(r.data.data.categories)
                setTotalCategories(r.data.data.totalCategories)
                setCategories(r.data.data.categories);
                setIsLoad(false);
            }
            
        })
        .catch((e)=>{ handleAxiosError(e, 'Presentamos un error al cargar las categorias')})
    }
    useEffect(() => {
        const isActive = globalContext.modalMultiplePost;
        if(!isActive) return;
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        setIsLoad(true);
        getCategories(entId,currentPage, pageItemCount, categoriesP, getAll, filter);
        /**
         * ! verificamos q el padre de las categorias no este null o sea undefined
         */
        if((categoriesP === null || typeof categoriesP === 'undefined') && filter.length > 0) {
            setShowButton(false);
            setGetAll(true)
        }

        if (categoriesP) {
            setShowButton(true)
        }
    },[categoriesP, currentPage, getAll, showButton, categoriesPatherBefore, filter,  globalContext.currentEnterpriseId, globalContext.modalMultiplePost]);
    
    const setFilterData = (event) => {
        event.preventDefault();
        setFilter(valueInputFilter)
        setGetAll(true)
    }

    return (
        <PublishProduct.categoriesList>
            <div className="contenerdor-categories">
                <div className="header">
                    <p>
                        Seleccione una categoría para ML
                    </p>
                </div>
                <div className="cont-categories">
                    <div className="header-cont-categories">
                    {showButton &&
                    (
                        <PublishProduct.buttonBack onClick={() => {backTo()}} active={showButton}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </PublishProduct.buttonBack>
                    )}
                    <form onSubmit={(e) => setFilterData(e)} style={{width: '100%'}}>
                        <PublishProduct.input placeholder="Nombre de la categoria" onChange={(e) => setValueInputFilter(e.target.value)} />
                     </form>
                    </div>
                    
                    <div className="d-flex justify-content-start align-items-center grid-text-categorie" >
                        {categoriesText.map((c,i)=> (<p className="tittle" key={i}>{c}</p>))}
                    </div>
                    <PublishProduct.bodyCategories>
                        <div className={`load-screen ${isLoad ? 'active' : ''}`}>
                            <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        <ul className={`list-c ${isLoad ? 'active' : ''}`}>
                            {
                                categories.map((c, i) => {
                                    return (
                                        <li key={i} onClick={()=>{chageCategori(c)}}>{c.country}-{c.name}</li>
                                    )
                                })
                            }
                        </ul>
                    </PublishProduct.bodyCategories>
                    <Pager
                        handleSetPage={setCurrentPage}
                        totalResults={totalCategories}
                        currentPage={currentPage}
                        resultsPerPage={pageItemCount}
                    />
                </div>
            </div>
        </PublishProduct.categoriesList>
    );
}

export default SelectCategory;