import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import ConfigStyled from "../../../../assets/css/components/admin/config/ConfigStyled";
import { Button } from "../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { SelectSearch } from "../../../general/SelectSearch";
import { SelectCredential } from "../../menus/components/SelectCredential";
import { ToggleSwitch } from "../../../general/ToggleSwitch";
import { Row, Col, Form } from "react-bootstrap";

export const Thirteen = () => {
    const plugin = "wc";
    const [isSending, setIsSending] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [config, setConfig] = useState({});
    const [defaultConfig, setDefaultConfig] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [pageId, setPageId] = useState("");
    const [brandsList, setBrandsList] = useState([]);
    const [showedBrands, setShowedBrands] = useState([]);
    const [brandSearch, setBrandSearch] = useState("");

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (msg) globalContext.showModalWithMsg(msg);
    };

    /**
     * Function to get the Woocommerce Configuration
     * @returns
     */
    function getConfigWc() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setIsSending(true);
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${entId}/${plugin}`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then(({ data }) => {
                const {
                    data: { configData },
                } = data;
                //Set the default config
                setDefaultConfig(configData);
                setIsSending(false);

                //Set the actual config as a copy of the default
                setConfig(Object.assign({}, configData));
            })
            .catch((err) => {
                setIsSending(false);
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la configuracion actual."
                );
            });
    }

    /**
     * function to get the attributes of Odoo
     * @returns
     */
    async function getOdooAttrib() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        const newConfig = config.newConfig || false;
        if (newConfig && !pageId) return;
        await setIsSending(true);

        const data = {
            enterpriseId: entId,
            ...(newConfig && { idPage: pageId }),
        };
        await axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/woo-prod/show-attributes-woo`,
                {
                    ...data,
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then(
                ({
                    data: {
                        data: { attributes },
                    },
                }) => {
                    const filteredAttributes = attributes.map((obj) => ({
                        id: obj.id,
                        value: obj.name,
                    }));
                    setAttributes(filteredAttributes);
                }
            )
            .catch((err) => {
                setIsSending(false);
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo cargar la configuracion actual."
                );
            });

        setIsSending(false);
    }

    /**
     * function to get the brands from odoo
     * @returns 
     */
    async function getMarcas() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        
        setIsSending(true);

        await axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/existing-brands`,
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then(({ data: { data: { brands } } }) => {
                setBrandsList(brands);
                setShowedBrands(brands);
            })
            .catch((err) => {
                console.error("err: ",err)
                handleAxiosError(
                    err,
                    "Disculpe, no se pudo consultar las marcas."
                );
            }).finally(()=>{
                setIsSending(false);
            });
    }

    // function to select or deselect brands
    function handleBrand(value) {
        console.log(value);
        const brands = config.pages?.find((v) => v.idPage === pageId)?.brands || [];
        const indexVal = brands.indexOf(value);
        const currentPagesConf = config.pages || [];
        const brandsSelected = indexVal === -1 ? [...brands, value] : brands.slice(0, indexVal).concat(brands.slice(indexVal + 1));
        let newPagesConf = [];
        if (currentPagesConf.length > 0) {
            const exist = currentPagesConf.find(
                (v) => v.idPage.toString() === pageId.toString()
            );
            if (exist) {
                console.log("currentPagesConf: ", currentPagesConf);
                for (let pageConf of currentPagesConf) {
                    if (pageConf.idPage.toString() === pageId.toString()) {
                        pageConf.brands = brandsSelected;
                    }
                    newPagesConf.push(pageConf);
                }
            } else {
                newPagesConf.push(...currentPagesConf, {
                    idPage: pageId,
                    brands: brandsSelected,
                });
            }
        } else {
            newPagesConf.push({ idPage: pageId, brands: parseInt(value) });
        }
        console.log("newPagesConf: ", newPagesConf);
        setConfig((prevConf) => ({ ...prevConf, pages: newPagesConf }));
        
    }

    /**
     * function to update the pages config data
     * @param {*} param0
     */
    function updatePageData({ target }) {
        let { name, value, type, checked } = target;
        value = type === "checkbox" ? checked : value;
        const currentPagesConf = config.pages || [];
        let newPagesConf = [];
        // console.log("currentPagesConf.length: ", currentPagesConf.length);
        if (currentPagesConf.length > 0) {
            const exist = currentPagesConf.find(
                (v) => v.idPage.toString() === pageId.toString()
            );
            if (exist) {
                console.log("currentPagesConf: ", currentPagesConf);
                for (let pageConf of currentPagesConf) {
                    // console.log("pageConf: ",pageConf);
                    // console.log(`${pageConf.idPage.toString()} === ${pageId.toString()}`)
                    if (pageConf.idPage.toString() === pageId.toString()) {
                        pageConf[name] = name.includes(['cronEnabled','cronOrderEnabled']) ? parseInt(value) : value;
                    }
                    newPagesConf.push(pageConf);
                }
                console.log("newPagesConf: ", newPagesConf);
            } else {
                newPagesConf.push(...currentPagesConf, {
                    idPage: pageId,
                    [name]: name.includes(['cronEnabled','cronOrderEnabled']) ? parseInt(value) : value,
                });
            }
        } else {
            newPagesConf.push({ idPage: pageId, [name]: name.includes(['cronEnabled','cronOrderEnabled']) ? parseInt(value) : value });
        }
        setConfig((prevConf) => ({ ...prevConf, pages: newPagesConf }));
    }

    /**
     * Function to update the generic configuration of plugin WC
     * @param {*} param0
     */
    function updateConfigState({ target }) {
        let { name, value, type, checked } = target;
        value = type === "checkbox" ? checked : value;
        setConfig((prevConf) => ({ ...prevConf, [name]: value }));
    }

    const saveChanges = async () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            handleAxiosError({}, "Disculpe, debe seleccionar una empresa.");
            return;
        }

        if (!window.confirm("Desea guardar los cambios?")) return;

        setIsSending(true);

        await axios
            .put(
                `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/wp/${entId}/${plugin}`,
                {
                    config,
                },
                {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((resp) => {
                //Set the actual config as the default data
                setDefaultConfig(Object.assign(defaultConfig, config));
                globalContext.showModalWithMsg(`Los datos fueron guardados`);
            })
            .catch((err) => {
                console.error(err.response?.data);
                handleAxiosError(err, err.response?.data?.message);
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    async function searchBrandHandler(text) {
        const regex = new RegExp(text, "i");
        const resultado = brandsList.filter((elemento) => regex.test(elemento));
        setShowedBrands(resultado);
        setBrandSearch(text);
    }

    async function preselectedBrands() {
        
    }

    useEffect(() => {
        getConfigWc();
        getOdooAttrib();
        getMarcas();
        //eslint-disable-next-line
        preselectedBrands();
    }, [globalContext.currentEnterpriseId, pageId]);

    return (
        <ConfigStyled.ContForm isSingle={true}>
            <div>
                <br />
                <h5>Configuración Base de Woocommerce</h5>
                <hr />
            </div>

            {!config.newConfig && (
                <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                        Atributos Registrados
                    </ConfigStyled.text>
                    {/* todo hay que corregir los atributos mostrados para que solo muestre uno por página */}
                    <SelectSearch
                        disabled={isSending}
                        getId={true}
                        options={attributes}
                        name='idPage'
                        placeholder='Buscar Atributos'
                        selected={config.brandAttributeId || null}
                        emptyMessage={
                            "Debe enviar y sincronizar los atributos a ésta web."
                        }
                    />
                </ConfigStyled.ContInputAndLabel>
            )}

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size='12pt'>
                    ID del Atributo de Marca
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    placeholder='ID del Atributo de Marca'
                    name='brandAttributeId'
                    value={config.brandAttributeId || ""}
                    onChange={(e) => updateConfigState(e)}
                />
            </ConfigStyled.ContInputAndLabel>

            <ConfigStyled.ContInputAndLabel>
                <ConfigStyled.text size='12pt'>
                    Cantidad de Decimales que puede tener el Precio
                </ConfigStyled.text>
                <ConfigStyled.input
                    readOnly={isSending}
                    placeholder='Cantidad de Decimales que puede tener el Precio'
                    name='priceDecimalPlaces'
                    value={config.priceDecimalPlaces || ""}
                    onChange={updateConfigState}
                />
            </ConfigStyled.ContInputAndLabel>

            <ToggleSwitch
                onChange={updateConfigState}
                name='newConfig'
                checked={config.newConfig}
                showState={true}
            />

            {config.newConfig && (
                <>
                    <ConfigStyled.ContInputAndLabel>
                        <ConfigStyled.text size='12pt'>
                            <h5>Páginas Web</h5>
                        </ConfigStyled.text>
                        <SelectCredential returnValue={setPageId} />
                    </ConfigStyled.ContInputAndLabel>

                    {pageId && (
                        <>
                            {config.newConfig && (
                                <ConfigStyled.ContInputAndLabel>
                                    <ConfigStyled.text size='12pt'>
                                        Atributos Registrados
                                    </ConfigStyled.text>
                                    <SelectSearch
                                        disabled={isSending}
                                        getId={true}
                                        options={attributes}
                                        name='idPage'
                                        placeholder='Buscar Atributos'
                                        selected={
                                            config.pages?.find(
                                                (v) => v.idPage === pageId
                                            )?.brandAttributeId || null
                                        }
                                        emptyMessage={
                                            pageId
                                                ? "Debe enviar y sincronizar los atributos a ésta web."
                                                : "Debe seleccionar una página web."
                                        }
                                    />
                                </ConfigStyled.ContInputAndLabel>
                            )}
                            <ConfigStyled.ContInputAndLabel>
                                <ConfigStyled.text size='12pt'>
                                    Id del Atributo de Marca
                                </ConfigStyled.text>
                                <ConfigStyled.input
                                    readOnly={isSending}
                                    placeholder='Id del Atributo de Marca'
                                    name='brandAttributeId'
                                    value={
                                        config.pages?.find(
                                            (v) => v.idPage === pageId
                                        )?.brandAttributeId || ""
                                    }
                                    onChange={updatePageData}
                                />
                            </ConfigStyled.ContInputAndLabel>

                            <ConfigStyled.ContInputAndLabel>
                                <ConfigStyled.text size='12pt'>
                                    Cantidad de Decimales que puede tener el
                                    Precio
                                </ConfigStyled.text>
                                <ConfigStyled.input
                                    readOnly={isSending}
                                    placeholder='Cantidad de Decimales que puede tener el Precio'
                                    name='priceDecimalPlaces'
                                    value={
                                        config.pages?.find(
                                            (v) => v.idPage === pageId
                                        )?.priceDecimalPlaces || ""
                                    }
                                    onChange={updatePageData}
                                />
                            </ConfigStyled.ContInputAndLabel>

                            <Row>
                                <Col>
                                    <br />
                                    <h6>Configuración Base del Cron de</h6>
                                    <hr />
                                </Col>
                            </Row>

                            <Row>
                                <ConfigStyled.ContInputAndLabel>
                                    <Form.Group
                                        as={Col}
                                        controlId='my_multiselect_field'
                                    >
                                        <Form.Label>
                                            Marcas con que Funcionará el Cron
                                        </Form.Label>

                                        <Form.Control
                                            type='text'
                                            placeholder='Buscar Marca'
                                            value={brandSearch}
                                            onChange={(e) =>
                                                searchBrandHandler(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <Form.Control
                                            as='select'
                                            multiple
                                            value={config.pages?.find(
                                                (v) => v.idPage === pageId
                                            )?.brands || []}
                                            onClick={e => handleBrand(e.target.value)}
                                        >
                                            {showedBrands.map((v) => (
                                                <option value={v} key={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </Form.Control>

                                        {(config.pages?.find(
                                            (v) => v.idPage === pageId
                                        )?.brands || []).join(", ")}
                                    </Form.Group>
                                </ConfigStyled.ContInputAndLabel>
                            </Row>

                            <Row>
                                <Col>
                                    <ConfigStyled.ContInputAndLabel>
                                        <ConfigStyled.text size='12pt'>
                                            Publicacióm y Actualización de Productos
                                        </ConfigStyled.text>
                                        <ToggleSwitch
                                            size='md'
                                            onChange={updatePageData}
                                            name='cronEnabled'
                                            checked={
                                                config.pages?.find(
                                                    (v) => v.idPage === pageId
                                                )?.cronEnabled || false
                                            }
                                            showState={true}
                                        />
                                    </ConfigStyled.ContInputAndLabel>
                                </Col>
                                <Col>
                                    <ConfigStyled.ContInputAndLabel>
                                        <ConfigStyled.text size='12pt'>
                                            Consulta de Órdenes
                                        </ConfigStyled.text>
                                        <ToggleSwitch
                                            size='md'
                                            onChange={updatePageData}
                                            name='cronOrderEnabled'
                                            checked={
                                                config.pages?.find(
                                                    (v) => v.idPage === pageId
                                                )?.cronOrderEnabled || false
                                            }
                                            showState={true}
                                        />
                                    </ConfigStyled.ContInputAndLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <ConfigStyled.ContInputAndLabel>
                                        <ConfigStyled.text size='12pt'>
                                            Evitar Actualizar Descripción
                                        </ConfigStyled.text>
                                        <ToggleSwitch
                                            size='md'
                                            onChange={updatePageData}
                                            name='skipDescription'
                                            checked={
                                                config.pages?.find(
                                                    (v) => v.idPage === pageId
                                                )?.skipDescription || false
                                            }
                                            showState={true}
                                        />
                                    </ConfigStyled.ContInputAndLabel>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}

            <ConfigStyled.ARow>
                <Button disabled={isSending} onClick={saveChanges}>
                    {isSending ? "Espere" : "Guardar"}
                </Button>
            </ConfigStyled.ARow>
        </ConfigStyled.ContForm>
    );
};
