import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../assets/js/SessionStorageNames";

export const sendCredencials = (data) => {
    const {url, consumerKey, consumerSecret, version, enterpriseId, credId, wpUser, wpPass, source, website } = data;

    const dataToSend =  {
        url,
        ...(consumerKey && {consumerKey}),
        ...(consumerSecret && {consumerSecret}),
        version,
        enterpriseId,
        ...( wpUser && { wpUser } ),
        ...( wpPass && { wpPass } ),
        ...(credId) && {credId: credId},
        ...( source && {source} ),
        ...( website && {website} ),
    };

    console.log(dataToSend);
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/wooUser`,
        {
            ...dataToSend
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}


export const getCredencials = async  (enterpriseId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/get-cred/${enterpriseId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

export const getAllCredencials = async  (enterpriseId) => {
    const resp = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/get-credentials/${enterpriseId}`,
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return resp
}

export const addNewCredential = async ({
    url,
    consumerKey,
    consumerSecret,
    version,
    enterpriseId,
    wpPass,
    wpUser,
    source,
    website,
}) => {
        const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/add-credential`,
        {
            consumerKey,
            consumerSecret,
            enterpriseId,
            url,
            version,
            ...(wpPass && { wpPass } ),
            ...(wpUser && { wpUser } ),
            ...(source && { source } ),
            ...(website && { website } ),
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}

export const deleteCredential = async ({ enterpriseId, idPage }) => {
    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };
    const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/woo-acc/credentials/delete`,
        {
            idPage,
            enterpriseId,
        },
        {
            headers,
        }
    );

    return res;
};

export const getEnterpriseConfig = async ({enterpriseId, plugin}) => {
    if(!enterpriseId || !plugin) return ("Error, debe ingresar tanto el id de la empresa como el plugin a consultar con los formatos necesarios.");
    const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
    };

    const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/plugin-config/${enterpriseId}/${plugin}`,
        {
            headers,
        }
    )
    return res;
}