import React, { useState } from 'react'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import Navbar from './Navbar';
import Campaign from './Campaign';
import CampaignList from './CampaignList';

export default function CasheaCampaign() {
    const [currentStep, setCurrentStep] = useState(0);

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (<Campaign />)
            case 1:
                return (<CampaignList setCurrentStep={setCurrentStep} />)
            default:
                return (<CampaignList />);
        }
    }

    return (
        <PedidosYaStyle.Cont>
            <PedidosYaStyle.Header>
                <PedidosYaStyle.Text size="15pt" fw="500">
                    Campañas de Cashea
                </PedidosYaStyle.Text>
            </PedidosYaStyle.Header>
            <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <PedidosYaStyle.ContStep>
                {renderStep()}
            </PedidosYaStyle.ContStep>
        </PedidosYaStyle.Cont>
    )
}