import styled from 'styled-components';

const cont = styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: flex-start;
`;
const btn = styled.button`
padding:${props => props.pd || "8px 16px"};
display:flex;
justify-content:center;
align-items:center;
font-size:${props => props.fontSize || "12pt"};
outline:none !important;
color: ${props => props.colorFont || "#7364060"};
background-color: ${props => props.bgColor || "#7364060"};
border:none;
border-radius: 5px;
margin-top: ${props=> props.mt || '0'};
margin-left:10px;
`;
const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;

const contInputAndLabel = styled.div`
margin-left: ${props=> props.ml || '0'};
display:flex;
flex-direction: ${props=> props.fd || 'column'};
justify-content: flex-start;
align-items: flex-start;
width:100%;
@media (max-width: 700px) {
    flex-direction: column;
}
`;

const contInputAndButton = styled.div`
display:flex;
justify-content: flex-start;
align-items: center;
width:100%;
`;

const contFormSearch = styled.form`
display:flex;
width: 100%;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
width:350px;
`;

const iconInfo = styled.span`
border-radius:50px;
background-color:#7367F0;
color: #FFF;
width:25px;
height:25px;
border-color:1px solid #7367F0;
font-size:10pt;
display: flex;
justify-content:center;
align-items:center;
align-self: flex-end;
margin-left:10px;
&:hover{
    cursor: pointer;
}
`;

const input = styled.input`
    padding-left:10px;
    // font-family: "Berlin Rounded";
    width: ${props=> props.wd || '100%'};
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    outline: none !important;
    color: #000;
    font-size: 12pt;
    font-weight: 300;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12pt;
        color: #595858;
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
        }
`;

const contDataproductCard = styled.div`
border-radius: 5px;
background: #fff;
border: 0.5px solid rgba(112, 112, 112, 0.65);
width:100%;
min-width:300px;
margin-top:10px;
min-height:100px;
height:auto;
padding:10px;
`;

const contGrid = styled.div`
margin-top:10px;
width:100%;
max-width:100%;
display: grid;
grid-gap: 0.5rem;
grid-template-columns: repeat(auto-fit, minmax(50px, auto));
@media (min-width: 300px) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(50px, 100%));
}
@media (min-width: 900px) {
    justify-content:flex-start  ;
    grid-template-columns: repeat(auto-fit, minmax(50px, ${props=> props.gtcr || 'auto'}));
}
@media (min-width: 1044px) {
    justify-content:flex-start  ;
    grid-template-columns: repeat(auto-fit, minmax(50px, ${props=> props.gtcrt || 'auto'}));
}
`;
const tableDataCard = styled.table`
margin-top:10px;
caption-side: top;
  border: none;
  border-collapse: collapse;
  width:100%;
  td,
  th {
    border: none;
  }
  thead > tr {
    background-color: #F5F5F6;
    line-height: 40px;
    td {
        text-align: left;
        text-indent:5px;
    }
  }

  tbody {
    td {
        text-align:left;
        text-indent:10px;
    }
  }
`
const openModalFormula = styled.div`
    background-color: rgba(0,0,0,0.08);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    transition: all ease 0.08s;
    display:flex;
    justify-content:center;
    align-items:center;
    transform: ${props => props.active ? 'translateY(0px)': 'translateY(-10000px)'};
    padding:15px;
    overflow:auto;
`;

const modalFormula = styled.div`
background-color: #FFF;
border-radius: .5rem;
width:100%;
min-width:300px;
min-height:500px;
margin-left:auto;
margin-right:auto;
margin-top:auto;
margin-bottom:auto;
transition: all ease 0.08s;
transform: ${props => props.active ? 'translateY(0px)': 'translateY(-10000px)'};
`
const headerModalFormula = styled.div`
width: 100%;
height:45px;
background: #f5f5f6;
border-top-right-radius:.5rem;
border-top-left-radius:.5rem;
display: flex;
flex-direction:row;
justify-content: space-between;
align-items:center;
margin-top:0;
padding: 0 10px;
`
const btnCloseModalFormula = styled.button`
width:30px;
height:30px;
border-radius:50px;
outline:none !important;
background-color: #7367F0;
color:#FFF;
display:flex;
justify-content:center;
align-items:center;
border:none;
`;
const bodyModalFormula = styled.div`
display:flex;
justify-content:center;
align-items:flex-start;
flex-direction: row;
width:100%;
`;

const contListFormula = styled.ul`
list-style:none;
padding-left:0;
width: 100%;
`;
const contTextFormula = styled.li`
padding:10px 15px;
border-bottom: 0.3px solid rgba(112, 112, 112, 0.5);
display: flex;
align-items: center;
justify-content:flex-start;
span{
    color: #ea5455;
    font-weight: bolder;
    margin-left:2.5px;
    margin-right:2.5px;
}
`;
const contTextFormulaLetter = styled.p`
margin-bottom:0;
color: ${props => props.c || "#707070"};
font-weight: ${props => props.fw || "lither"};
`


const carouselImagesProduct = styled.ul`
width:100%;
overflow-y: auto;
max-width:100%;
border-radius: 5px;
background: #f5f5f6;
min-height:90px;
display: flex;
align-items: center;
justify-content: flex-start;
list-style: none;
margin-bottom: 0;
padding: 10px 0;
padding-left: 0;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #FFFFFF;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
      border-radius: 50px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#7367f0 0%, rgba(115, 103, 240, 0.7) 100%);
    }
`;

const carouselImageProducts = styled.li`
display:flex;
justify-content: flex-end;
background-image: url(${props=> props.img ? `${props.img}` : ""});
min-width: 80px;
max-width: 80px;
background-repeat: no-repeat;
background-size: contain;
background-color: #FFFFFF;
min-height: 80px;
max-height: 80px;
background-position: center;
margin-left: 5px;
margin-right:5px;
border: 1px solid ${props => props.active ? 'transparent' : '#FFFFFF'};
border-radius: 5px;
box-sizing:border-box;
box-shadow: ${props => props.active ? '0px 0px 9px 2px #7367f0' : '0px 0px 0px transparent'};
transition: all ease-in-out 0.3s;
`;

const select = styled.select`
    height: 40px;
    width: 300px;
    padding: 4px;
    border: 0.5px solid rgba(112, 112, 112, 0.7);
    border-radius: 5px;
`;

const CardProductCss = {
    select,
    cont,
    btn,
    text,
    contInputAndLabel,
    contFormSearch,
    input,
    contInputAndButton,
    contDataproductCard,
    contGrid,
    tableDataCard,
    iconInfo,
    openModalFormula,
    modalFormula,
    headerModalFormula,
    bodyModalFormula,
    btnCloseModalFormula,
    contListFormula,
    contTextFormula,
    contTextFormulaLetter,
    carouselImagesProduct,
    carouselImageProducts
}
export default CardProductCss;