import React from "react";
import FormProduct from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import { Spinner1 } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/spinners";

export default function ViewProductsAfterToPost({ isLoad, data }) {

  return (
    <>
        <FormProduct.Contform style={{ marginTop: 45 }}>
          <FormProduct.HeaderContForm>
            <Text size="13pt" fw="500" cl="#FFFFFF">
              Subiendo productos a cashea
            </Text>
          </FormProduct.HeaderContForm>
          <h1 style={{fontSize:'1.5rem', fontWeight:'bold', padding:'3rem 1rem'}}>Este proceso se esta ejecutando en segundo plano.</h1>
        </FormProduct.Contform>
    </>
  );
}
