import React from 'react';
import Select from 'react-select';
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';

const SelectEnterprise = ({ setEnterprise, enterprise, enterprises, setMainRate, setPromotionalRate, setRatesData }) => {
    let options = [];

    if(enterprises){
        options = enterprises.map(ent => ({
            value: ent.id,
            label: ent.name,
        }));
    }
    

    const handleChange = (selectedOption) => {
        setEnterprise(selectedOption); 
        if(setMainRate && setPromotionalRate && setRatesData){
            setMainRate(); 
            setPromotionalRate(); 
            setRatesData([]);
        }
        
    };

    return (
        <RC_Style.ContEnterprisesSelect>
            <RC_Style.Label htmlFor="enterprise">Seleccionar la compañía</RC_Style.Label>
            <Select
                id="enterprise"
                value={options.find(option => option.value === enterprise.value)}
                onChange={handleChange} 
                options={options}
                styles={RC_Style.customStyles}
                isClearable={false}
            />
        </RC_Style.ContEnterprisesSelect>
    );
};

export default SelectEnterprise;