import styled from 'styled-components';
import { Link } from "react-router-dom";


const cont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;

    
    a{
        width: 100%;
        text-decoration: none !important;
    }

    &:last-child {
    margin-bottom: 250px;
  }
`;

const Dashboard = styled(Link)`
    font-size: 1.3rem;
    font-weight: bold;
    color: #78548E;
    padding: 10px 0 0 0;
    text-decoration: none; 
    
    &:hover {
        color: #7367f0; 
        transition: color 0.5s ease; 
    }
`;


const Title = styled.h3`
    font-size: 1.3rem;
    font-weight: bold;
    color: #78548E;
    padding: 20px 0 0 0;
    padding-left: ${props => props.padding || '0px'};
    cursor: pointer;
    
    &:hover {
        color: #7367f0; 
        transition: color 0.5s ease; 
    }    

`;

const LinkSidebar = styled.div`

width:300px;
height: 40px;
padding:10px 0;
padding-left: ${props => props.padding || '0px'};
display:flex;
justify-content:flex-start;
align-items:center;
cursor: pointer;
border-radius:5px;
background: ${props=>props.active ? 'linear-gradient(118deg,#7367f0,rgba(115,103,240,.7))' : "transparent"};
box-shadow:  ${props=>props.active ? '2px 0px 6px #7367f0' : ''};
&:hover {
background-color: ${props=>props.active ? '':'#F5F5F6'};
}
span {
    margin-right:10px;
}
p {
    margin-bottom: 0;
    width: fit-content;
}


`;
const LinkSidebarUl = styled.ul`
width:100%;
height: auto;
padding:0;
margin-top: ${props=> props.marginTop || "0"};
`;
const LinkSidebarUlSub = styled.ul`
width:100%;
transition: all ease 0.5s;
height: ${props=> props.active ? 'auto': "0px"};
overflow: ${props=> props.active ? 'visible': "hidden"};
padding: 0 15px;
`;
const LinkSidebarLiParent = styled.li`
width:100%;
height: 40px;
padding:10px;
display:flex;
justify-content:flex-start;
align-items:center;
cursor: pointer;
border-radius:5px;
background-color: ${props=>props.active ? '#F5F5F6': "transparent"};
&:hover {
background-color:#F5F5F6;
}
`;
const LinkSidebarLiChild = styled.li`
width:100%;
height: 40px;
padding:10px;
display:flex;
justify-content:flex-start;
align-items:center;
cursor: pointer;
margin-top: ${props=> props.marginTop || "0"};
border-radius:5px;
background: ${props=>props.active ? 'linear-gradient(118deg,#7367f0,rgba(115,103,240,.7))' : "transparent"};
box-shadow:  ${props=>props.active ? '2px 0px 6px #7367f0' : ''};
&:hover {
background-color: ${props=>props.active ? '':'#F5F5F6'};
}
`;

const iconList = styled.span`
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;1,300&display=swap');
color: ${props => props.color || "#707070"};
font-size: ${props => props.fontSize || "12px"};
font-weight: ${props => props.fontWeight || 300};
font-family: 'Work Sans', sans-serif;
margin-top:${props => props.marginTop || 0};
margin-left:${props => props.marginLeft || 0};
margin-right:${props => props.marginRight || 0};
margin-bottom:${props => props.marginBottom || 0};
transition: all ease 0.5s;
transform: rotate(${props => props.active ? '180deg' : '0deg'});
`;


const marginBottom = styled.div`
    min-width: 1px;
    min-height: 150px;

`;

const LinksAnotherMenuCss = {
    cont,
    marginBottom,
    Dashboard,
    Title,
    LinkSidebar,
    LinkSidebarUl,
    LinkSidebarLiParent,
    iconList,
    LinkSidebarLiChild,
    LinkSidebarUlSub
}

export default LinksAnotherMenuCss;