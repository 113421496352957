import styled from "styled-components";

export const ContCard = styled.div`
/* min-width: 280px;
max-width: 280px; */
width: 100%;
min-height: 330px;
max-height: 330px;
border-radius: 5px;
position: relative;
background: #FFFFFF;
box-shadow: 0 2px 8px 0 rgb(34 41 47 / 14%);
overflow: hidden;
`;

export const ContImgCard = styled.div.attrs(props => ({
    url: props.url || '',
    bgc: props.load ? '#f0f0f0' : '#FFFFFF'
}))`
background-image: ${props => `url(${props.url})`};
background-position: center;
background-size: contain;
background-repeat: no-repeat;
padding: 5px;
background-color: ${props => props.bgc};
width: 100%;
min-height: 180px;
max-height: 180px;
position: relative;
`;

export const BtnOptionsCard = styled.button`
position: absolute;
top: 0;
left: auto;
right: 0;
bottom: auto;
display: flex;
justify-content: center;
align-items: center;
background: rgba(196, 196, 196, 0.33);
border-radius: 0px 5px;
width: 30px;
height: 30px;
border: none;
outline: none !important;
color: #878787;
font-size: 12pt;
z-index: 2;
`;

export const BtnNextImg = styled.button.attrs(props => ({
    left: props.l || '10px',
    right: props.r || 'auto'
}))`
position: absolute;
top: 50%;
left: ${props => props.left};
right: ${props => props.right};
bottom: auto;
display: flex;
justify-content: center;
align-items: center;
background: rgba(196, 196, 196, 0.33);
border-radius: 50px;
width: 30px;
height: 30px;
border: none;
outline: none !important;
color: #878787;
font-size: 12pt;
`;

export const ContDataInfoOne = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 10px;
`;

export const ContDataInfoTitle = styled.div`
padding: 0 10px;
width:100%;
font-weight: normal;
font-size: 13px;
line-height: 15px;
color: #515151;
text-align:justify;
font-weight:lighter;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
@media(max-width: 600px) {
    -webkit-line-clamp: 2;
}
`
export const BtnSelectCard = styled.button.attrs(props => ({
    bgc: props.active ? "#7367F0" : "#E3E3E3"
}))`
width:100%;
background: ${props => props.bgc};
border-radius: 0 0 5px 5px;
height: 30px;
position: absolute;
top: auto;
left: 0;
right: 0;
bottom: 0;
outline: none !important;
border: none;
display: flex;
justify-content: center;
align-items: center;

`;

export const ContIconsInfo = styled.div`
width:100%;
display: flex;
justify-content: flex-start;
align-items: center;
position: absolute;
left: 10px;
right: auto;
top: 10px;
bottom: auto;
`
export const ContIconInfo = styled.span.attrs(props => ({
    mr: props.mr || '0',
    ml: props.ml || '0'
}))`
width: 30px;
height: 30px;
background: rgba(0,0,0, 0.3);
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
color: #FFFFFF;
font-size: 12pt;
margin-left: ${props => props.ml};
margin-right: ${props => props.mr};
`;

export const ContMenuCard = styled.div.attrs(props => ({
    translateX: props.active ? "translateX(0px)" : "translateX(200px)"
}))`
position: absolute;
top: 0;
bottom: 0;
left: auto;
right: 0;
height: 100%;
width: 180px;
z-index: 4;
background: #FFFFFF;
transition: all ease-in-out .3s;
box-shadow: -6px 0px 16px 2px rgb(0 0 0 / 25%);
border-radius: 5px;
transform: ${props => props.translateX}
`;

export const BtnCloseMenu = styled.button`
position: absolute;
top: 0;
left: 0;
right: auto;
bottom: auto;
width: 30px;
height: 30px;
border: none;
outline: none !important;
border-top-left-radius: 5px;
border-bottom-right-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
z-index: 2;
color: #FFFFFF;
background-color: #7367F0;
`;

export const ContTitleMenu = styled.div`
width: 100%;
height: 30px;
position: absolute;
top: 0;
left: 0;
right: auto;
bottom: auto;
display: flex;
justify-content: center;
align-items: center;
`
export const ListOptions = styled.ul`
width: 100%;
margin-top: 32px;
list-style: none !important;
padding-left: 0;
`

export const OptionList = styled.li`
width: 100%;
height: 40px;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 0px 10px;
cursor: pointer;
&:hover {
    background: #E3E3E3;
}
`;

export const ContImgLoad = styled.div`
padding: 5px;

background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
background-repeat: no-repeat;
background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
animation: loading 1.5s infinite;

width: 100%;
min-height: 180px;
max-height: 180px;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
`;

export const ContLabelLoad = styled.div.attrs(props => ({
    w: props.w || '100%'
}))`
border-radius: 50px;
width: ${props => props.w};
min-height: 20px;
background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
background-repeat: no-repeat;
background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
animation: loading 1.5s infinite;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
`;

