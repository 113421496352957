import React, { useEffect, useState } from "react";
import { BiDownArrow } from "react-icons/bi";
import {
    SearchWrapper,
    Input,
    Suggestions,
    Suggestion,
    SearchContent,
    DivId,
    IconContainer,
} from "../../assets/css/components/general/SelectSearch";

export const SelectSearch = ({
    options,
    getId,
    placeholder = "Ingrese los datos",
    disabled,
    emptyMessage,
    selected,
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [selectedId, setSelectedId] = useState("");

    function showSuggestionsFunction(action) {
        if (disabled) return;
        setShowSuggestions(action);
    }

    const onSearchChange = (e) => {
        const value = e.target.value;

        setSearchTerm(value);

        setFilteredSuggestions(
            options
                .filter((suggestion) =>
                    suggestion.value.toLowerCase().includes(value.toLowerCase())
                )
                .sort((a, b) => a.value.localeCompare(b.value))
        );

        setShowSuggestions(true);
    };

    const onSuggestionClick = (e) => {
        const selectedSuggestion = options.find(
            (suggestion) => suggestion.id.toString() === e.target.id.toString()
        );
        setSearchTerm(selectedSuggestion.value);
        setSelectedId(selectedSuggestion.id);
        setShowSuggestions(false);
    };

    useEffect(() => {
        if (options.length > 0) {
            if (selected) {
                const select = options.find(
                    (suggestion) => suggestion.id.toString() === selected.toString()
                );
                if (select) {
                    setSearchTerm(select.value);
                    setSelectedId(select.id);
                }
            }
            setFilteredSuggestions(
                options.sort((a, b) => a.value.localeCompare(b.value))
            );
        }
    }, [options, selected]);

    return (
        <SearchContent onFocus={() => showSuggestionsFunction(true)}>
            <SearchWrapper
                getId={getId}
                colspan={options.length <= 0 ? 13 : getId ? 10 : 13}
            >
                <Input
                    type='text'
                    placeholder={placeholder}
                    value={
                        options.length <= 0
                            ? emptyMessage || "Lista vacía"
                            : searchTerm
                    }
                    onChange={onSearchChange}
                    style={
                        options.length > 0
                            ? !getId
                                ? {
                                      borderRadius: "4px",
                                      borderRight: "1px solid #ccc",
                                  }
                                : {
                                      borderRadius: "4px 0px 0px 4px",
                                      borderRight: "0px solid #ccc",
                                  }
                            : {
                                  borderRadius: "4px",
                                  borderRight: "1px solid #ccc",
                              }
                    }
                    disabled={disabled}
                />
                {disabled ? (
                    ""
                ) : (
                    <IconContainer
                        focused={showSuggestions}
                        onClick={() =>
                            showSuggestionsFunction(!showSuggestions)
                        }
                    >
                        <BiDownArrow />
                    </IconContainer>
                )}
                <Suggestions show={showSuggestions}>
                    {filteredSuggestions.map((suggestion, index) => (
                        <Suggestion
                            key={index}
                            id={suggestion.id}
                            onClick={onSuggestionClick}
                        >
                            {suggestion.value}
                        </Suggestion>
                    ))}
                </Suggestions>
            </SearchWrapper>
            {options.length > 0 && getId && (
                <DivId>
                    {selectedId ? `ID: ${selectedId}` : "Seleccione una Opción"}
                </DivId>
            )}
        </SearchContent>
    );
};
