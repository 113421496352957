import styled, { keyframes } from "styled-components";

export const ContainerBody = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center; 
    min-width: 320px;
    min-height: calc( 100vh - 80px);
    overflow-x: hidden;
    background-color: #e8e8e8;
`

export const Header = styled.div`
    background-color: #023D82;
    width: 100%;
    position: relative;
    transition: all .25s;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 769px){
        flex-direction: column;
        padding: 1.5rem 0;
        height: 100% !important;
    }
`

export const Menu = styled.ul` 
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    @media screen and (max-width: 769px){
        margin-top:1.5rem;
    }
    & a {
        text-decoration: none
        }
        
    & li {
        color: white;
        cursor: pointer;
        width: 100%;
        text-align: center;
        padding: 0 10px;
        transition: all .25s;
    }
    & li:hover{
        color: #FFAC00;
        border-bottom: 2px solid #FFAC00;
        padding-bottom: 11px;


    }
`

export const SpacingBg = styled.div`
    background-color: #023D82;
    width: 100%;
    position:absolute;
    transition: all .25s;
    display: flex;
    align-items: center;
    justify-content: center
`
export const TrackerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const StepperItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #5ccb5f;
        top: 45%;
        left: -50%; 
        z-index: 2;
        height: 50px;
        width: 10px
    };

    &:after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #5ccb5f;
        top: 45%;
        left: 50%;
        z-index: 2;
        width: 60px;
        height: 68px;
        transform: rotate(90deg);
    };

    &.active {
        font-weight: "bold" };
    }

    &.completed:after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #5ccb5f;
        top: 45%;
        left: 50%;
        z-index: 3;
    }
    &.last:after{
        display:none
    }

    &:first-child::before {
        content: none;
    }
    &:last-child::after {
        content: none;
    }
`

export const StepCounter = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ccc;

    .completed & {
        background-color: #5ccb5f;
    };
    
    .completed.active & {
        width: 28px;
        height: 28px;
        margin-right: 7px
    };
    
    & svg {
        display: none;
    }

    .completed.active & svg {
        display: block;
    }
`

export const Location = styled.div`
    display: flex;
    justify-content: space-between;
`

export const InputCodeWrapper = styled.form`
    display: flex;
    align-items: center;


` 
 
export const InputCode = styled.input`
    min-height: 48px;
    width: 380px;
    padding: 0 1rem;
    color: #000;
    font-size: 20px;
    border: 1px solid #7367F0;
    border-radius: 6px 0 0 6px;
    background-color: transparent;

    
    .&:focus, &:focus-visible {
        border-color: #3898EC;
        outline: none;
    }
    @media screen and (max-width: 450px){
        width: 300px;
        font-size: 17px;

    }
`

export const ButtonSubmit = styled.input`
    min-height: 50px;
    width: 150px;
    padding: .5em 1em;
    border: 1px solid #7367F0;
    border-radius: 0 6px 6px 0;
    background-color: #7367F0;
    color: #FFF;
    font-size: 18px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;

    &:hover {
        background-color: #5e5dcd;
    }
`
export const Button = styled.button`
    min-height: 35px;
    width: 135px;
    padding: .5em 1em;
    border: 1px solid #7367F0;
    border-radius: 0 6px 6px 0;
    background-color: #7367F0;
    color: #FFF;
    font-size: 18px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;

    &:hover {
        background-color: #5e5dcd;
    }
    @media screen and (max-width: 769px){
        margin: auto;
        width: 160px;
        font-size: 16px;
        margin-top: 1rem;
        border-radius: 0;


    }
`
export const InfoHome = styled.p`
    width: 80%;
    margin-top: 20px;
    text-align: center;
    @media screen and (max-width: 769px){
        width: 95%;
    }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
    width: 110px;
    height: 110px;
    border-bottom: 2px solid #7367F0;
    animation: ${rotate} 2s linear infinite;
    border-radius: 100%;
`

export const ContainerStatusParent = styled.div `
    display:flex;
    align-items:center;
    justify-content:center;
    height: 100vh;
    width: 100%;
    position: fixed; 
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(5px);
`
export const ContainerStatus = styled.div `
    background-color: white;
    padding: 50px;
    width: 90%;
    height: fit-content;
    max-width: 800px;
    margin: 30px auto;
    border-radius: 15px;
    max-width: 400px;
    border: 1px solid #00000050;
    @media screen and (max-width: 769px){
        padding: 20px;

    }
`

export const SearchContainer = styled.div`
    background-color: white;
    padding: 50px;
    width: 90%;
    max-width: 800px;
    margin: auto;
    border-radius: 15px;
    @media screen and (max-width: 769px){
        padding: 20px;
        div {
            flex-direction: column !important;
            justify-content: center;

        }
    }
`

export const InfoEnvio = styled.table`
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
    @media screen and (max-width: 769px){
        width: 100%;
    }
   .&.th, .&.td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    .& th {
      background-color: #f2f2f2;
      font-size: 18px;
      text-align: center;
    }
    .& td {
      font-size: 16px;
    }
    .& tr:nth-child(even) {
      background-color: #f9f9f9;
    }
`
export const InfoEnvioTH = styled.th`
    background-color: #f2f2f2;
    font-size: 18px;
    text-align: center;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`
export const InfoEnvioTD = styled.td`
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
      font-size: 16px;

    // .& tr:nth-child(even) {
    //   background-color: #f9f9f9;
    // }
`