import styled from "styled-components";

const Cont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const ContNavbar = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const MenuOptions = styled.ul`
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
`;

const Header = styled.div.attrs((props) => ({
    textAlign: props.textAlign || "flex-start",
}))`
    width: 100%;
    display: flex;
    text-align: ${(props) => props.textAlign};
    justify-content: ${(props) => props.textAlign};
    align-items: center;
    /* border: 1px solid; */
    margin-top: 10px;
    margin-bottom: 10px;
`;

const OptionMenu = styled.li.attrs((props) => ({
    backGround: props.active ? "#7367F0" : "#F0F0F0",
    color: props.active ? "#FFFFFF" : "#707070",
}))`
    padding: 1rem;
    font-weight: 500;
    margin-left: 0.5rem;
    margin-right: auto.5rem;
    border-radius: 0.429rem;
    background: ${(props) => props.backGround};
    color: ${(props) => props.color};
    transition: all ease-in-out 0.2s;
    cursor: pointer;
`;

const Text = styled.p.attrs((props) => ({
    cl: props.cl || "#707070",
    size: props.size || ".857rem",
    ml: props.ml || "0",
    mr: props.mr || "0",
    fw: props.fw || "lighter",
    mt: props.mt || ".2857rem",
    mb: props.mb || ".2857rem",
    longText: props.longText ? "ellipsis" : "",
    longTextTwo: props.longText ? "hidden" : "",
    longTextThree: props.longText ? "nowrap" : "",
}))`
    color: ${(props) => props.cl};
    font-size: ${(props) => props.size};
    margin-left: ${(props) => props.ml};
    margin-right: ${(props) => props.mr};
    font-weight: ${(props) => props.fw};
    margin-top: ${(props) => props.mt};
    margin-bottom: ${(props) => props.mb};
    text-overflow: ${(props) => props.longText};
    overflow: ${(props) => props.longTextTwo};
    white-space: ${(props) => props.longTextThree};
    width: ${({width}) => width || "100%"}
`;

const ContStep = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    min-height: 200px;
    border-radius: 0.428rem;
    padding: 1rem;
`;

const ContInputAndLabel = styled.div.attrs((props) => ({
    w: props.w || "300px",
    fld: props.fld || "column",
    jsc: props.jsc || "space-between",
    ali: props.ali || "flex-start",
    mr: props.mr || "0",
}))`
    grid-column: span ${({colSpan}) => colSpan || 1 }; 
    height: auto;
    display: flex;
    width: ${(props) => props.w};
    flex-direction: ${(props) => props.fld};
    justify-content: ${(props) => props.jsc};
    align-items: ${(props) => props.ali};
    margin-right: ${(props) => props.mr};
    padding-right: 10px;
`;

const ContForm = styled.div`
    min-width: 300px;
    width: 770px;
    border-radius: 0.428rem;
    border: 1px solid #f0f0f0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    @media (max-width: 320px) {
        left: -25px;
        width: 100%;
        position: relative;
    }
    @media (min-width: 321px) and (max-width: 576px) {
        margin-left: 0px;
        width: 100%;   
    }
`;

const HeaderForm = styled.div`
    width: 100%;
    padding: 0.5rem;
    text-align: left;
    border-radius: 0.428rem 0.428rem 0 0;
    border-bottom: 1px solid #ebe9f1;
`;

const BodyForm = styled.div`
    min-height: 100px;
    background: #ffffff;
    padding: 1rem;
    width: 100%;
    display: block;
`;
const BodyFormGrid = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(355px, 355px));
    transition: all ease-in-out 0.2s;
    
    @media (min-width: 321px) and (max-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 100%));
        
    }

`;
const FooterForm = styled.div`
    width: 100%;
    min-height: 50px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0px;
    border-top: 1px solid #ebe9f1;
    padding: 0.5rem;
`;

const BtnSend = styled.div.attrs((props) => ({
    background: props.background || "#7367F0",
    color: props.color || "#FFFFFF",
}))`
    padding: 6px 16px;
    border-radius: 0.25rem;
    outline: none !important;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    border: none !important;
    &:disabled {
        background: #9991f5;
    }
    cursor: pointer;
    transition: all ease-in-out .2s;
    &:hover {
        background-color: #9990fc;
        box-shadow: 0 3px 5px 0 #c3c3c3;
    }
`;

const Input = styled.input`
    display: block;
    width: ${({width}) => width || '100%' };
    height: 38px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    position: relative;
    &:focus {
        color: #6e6b7b;
        outline: 0;
        background-color: #fff;
        border-color: #7367f0;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
    ::placeholder {
        color: #c0c0c1;
        font-size: 1rem;
    }
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
`;

const InputText = styled.input``;

const select = styled.select`
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Berlin Rounded";
    width: 100%;
    height: 38px;
    border-radius: 0.357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    ::placeholder {
        font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
    }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
    @media (max-width: ${(props) => props.selectMq || "989px"}) {
        width: 100%;
    }
`;

const SelectWeb = styled.select.attrs(props => ({
    width: props.width || '100%',
    height: props.height || '2.5rem',
    fontSize: props.fontSize || '18px',
    fontWeight: props.fontWeight || '460',
  }))`
    padding-left: 10px;
    padding-right: 10px;
    height: ${props => props.height};
    border-radius: 0.357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.width};
    color: #707070;
    width: ${props => props.width};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    ::placeholder {
        font-weight: ${props => props.fontWeight};
        font-size: 18px;
        color: rgba(112, 112, 112, 0.61);
    }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
    @media (max-width: ${(props) => props.selectMq || "989px"}) {
        width: ${props => props.width};
    }
`;

const inputFile = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: flex-start;
    .file {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 2.5rem;
    }
    .file input {
        min-width: 14rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 1;
    }
    .file-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        height: 2.5rem;
        padding: 0.5rem 1rem;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        border: 0.075rem solid #ddd;
        border-radius: 0.428rem;
        // box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .file-custom:after {
        content: "Seleccione su archivo";
    }
    .file-custom:before {
        border-radius: 0 0.428rem 0.428rem 0;
        position: absolute;
        top: -0.075rem;
        right: -0.075rem;
        bottom: -0.075rem;
        z-index: 6;
        display: block;
        content: "Buscar";
        height: 2.5rem;
        padding: 0.5rem 1rem;
        line-height: 1.5;
        color: #ffffff;
        background-color: #7367f0;
        border: 0.075rem solid #7367f0;
        border-radius: 0 0.25rem 0.25rem 0;
    }
    cursor: pointer;

    /* Focus */
    .file input:focus ~ .file-custom {
        box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
    }
`;

const ContBadgeError = styled.div.attrs((props) => ({
    height: props.active ? "auto" : "0px",
    padding: props.active ? "1rem" : "0",
    opacity: props.active ? "1" : "0",
    backGround: props.error ? "#ea5455" : "#7367F0",
}))`
    border-radius: 0.358rem;
    z-index: 5000;
    background: ${(props) => props.backGround};
    min-width: 300px;
    width: 350px;
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
    opacity: ${(props) => props.opacity};
    position: fixed;
    bottom: 0px;
    right: 0px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: opacity 0.15s ease-in-out, height 0.15s ease-in-out,
        padding 0.1s ease-in-out;
    @media (max-width: 576px) {
        margin: 0px;
        width: 100%;   
        height: auto !important;
        min-height: ${(props) => props.height};
    }
`;

const BtnToggleBadge = styled.button`
    width: 25px;
    height: 25px;
    outline: none !important;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    left: auto;
    top: auto;
    right: 10px;
    bottom: auto;
`;

const ContIconNadInputPass = styled.div`
    width: 100%;
    position: relative;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const BtnShowPass = styled.button`
    transition: 0.3s;
    width: 30px;
    height: 30px;
    outline: none !important;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    color: #707070;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    left: auto;
    top: auto;
    right: 10px;
    bottom: auto;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const ContTable = styled.div`
    padding: 1rem;
    min-width: 300px;
    width: 700px;
    min-height: 100px;
    border-radius: 0.429rem;
    background: #ffffff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const HeaderContTable = styled.div`
    width: 100%;
    text-align: left;
    border-radius: 0.428rem 0.428rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const TableResult = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: none;
    font-size: 0.857rem;
    letter-spacing: 0.5px;
    color: #6e6b7b;
    display: block;
`;
const TableResultThead = styled.thead`
    width: 100%;
    background: #f3f2f7;
    border-radius: 0.429rem;
    border-bottom: 2px solid #ebe9f1;
`;
const TableResultTh = styled.th.attrs((props) => ({
    // we can define static props
    width: props.width || "100px",
    minWidth: props.minWidth || "100px",
    textAlign: props.textAlign || "start",
    padding: props.padding || "0.50rem",
}))`
    text-align: ${(props) => props.textAlign};
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
`;

const TableResultTbody = styled.tbody`
    width: 100%;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    display: inline-block;
    overflow-y: auto;
    max-height: 500px;
`;

const TableResultTr = styled.tr`
    width: 100%;
    border-bottom: 2px solid #ebe9f1;
`;
const TableResultTd = styled.td.attrs((props) => ({
    // we can define static props
    width: props.width || "100px",
    minWidth: props.minWidth || "100px",
    textAlign: props.textAlign || "justify",
    backGroundColor: props.backGroundColor || "#FFFFFF",
    padding: props.padding || "0.50rem",
    verticalAlign: props.verticalAlign || "text-top",
}))`
    text-align: ${(props) => props.textAlign};
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    word-break: break-all;
    vertical-align: ${(props) => props.verticalAlign};
    background: ${(props) => props.backGroundColor};
`;

const BtnAndIcon = styled.button.attrs((props) => ({
    mr: props.mr || "0",
    ml: props.ml || "0",
}))`
    height: 38px;
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: none !important;
    border: none;
    background: #7367f0;
    color: #ffffff;
    min-width: 100px;
    width: auto;
    max-width: 200px;
    margin-right: ${(props) => props.mr};
    margin-left: ${(props) => props.ml};
    &:disabled {
        background: #7367f08f;
        cursor: no-drop;
    }
`;

const ContIconBtn = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    margin-right: 5px;
`;
const ContIconBtnLoad = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    margin-right: 5px;
    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
`;

const BtnIcon = styled.button.attrs((props) => ({
    bgColor: props.bgColor || "#FFFFFF",
    color: props.color || "#707070",
    bRadius: props.bRadius || "50px",
    width: props.width || "100px",
    height: props.height || "38px",
    mRight: props.mRight || "0",
    mLeft: props.mLeft || "0",
    mTop: props.mTop || "0",
    mBottom: props.mBottom || "0",
}))`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin-right: ${(props) => props.mRight};
    margin-left: ${(props) => props.mLeft};
    margin-top: ${(props) => props.mTop};
    margin-bottom: ${(props) => props.mBottom};
    height: ${(props) => props.height};
    border: none;
    outline: none;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    border-radius: ${(props) => props.bRadius};
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`;

const contLoader = styled.div`
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .spinner-border {
        border: 0.25em solid #7367f0;
        border-right-color: transparent;
    }
`;

const BackgroundModal = styled.div.attrs((props) => ({
    d: props.active ? "flex" : "none",
}))`
    width: 100%;
    display: ${(props) => props.d};
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: display 0.3s ease;
    background-color: rgb(251 251 251 / 64%);
`;

const contModal = styled.div.attrs((props) => ({
    bg: props.bg || "#FFFFFF",
    w: props.w || "auto",
    t: props.active ? "translateY(0px)" : "translateY(146px)",
    o: props.active ? "1" : "0",
}))`
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background: ${(props) => props.bg};
    border-radius: 0.425rem;
    min-width: 300px;
    width: ${(props) => props.w};
    transition: transform 0.9s ease-out, opacity 1s ease-in-out;
    min-height: 300px;
    transform: ${(props) => props.t};
    opacity: ${(props) => props.o};
`;

const HeaderModal = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ebe9f1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
`;

const FooterModal = styled.div`
    width: 100%;
    height: 50px;
    border-top: 1px solid #ebe9f1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: 0;
`;

const BodyModal = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
`;

const BtnCloseModal = styled.button`
    width: 30px;
    height: 30px;
    border: none;
    border-top-right-radius: 0.425rem;
    border-bottom-left-radius: 0.425rem;
    outline: none !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #7367f0;
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    bottom: auto;
`;

const ContInputBtn = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 38px;
`;

const BtnAdd = styled.button`
    width: 38px;
    height: 38px;
    border-top-right-radius: 0.425rem;
    border-bottom-right-radius: 0.425rem;
    outline: none !important;
    background: #7367f0;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0;
    border: none;
`;

const ContListOptions = styled.ul`
    min-width: 280px;
    width: 100%;
    border-radius: 0.357rem;
    border: 1px solid #d0cccc;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 90px;
    max-height: 300px;
    margin-top: 10px;
    overflow-y: auto;
`;

const ListItems = styled.li`
    background: #7367f0;
    min-width: 100px;
    width: auto;
    max-width: 120px;
    position: relative;
    font-size: 10pt;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
    margin-right: 10px;
    display: flex;
    border-radius: 0.357rem;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;
`;

const CirculBtn = styled.button.attrs((props) => ({
    w: props.w || "30px",
    h: props.h || "30px",
    bColor: props.bColor || "#d8d6de",
    bColorActive: props.bColorActive || "#0E6995",
    color: props.color || "#707070",
    bgColor: props.bgColor || "#FFFFFF",
    positionBtn: props.position || "absolute",
    marginLeft: props.marginLeft || "0",
    marginRight: props.marginRight || "0",
    left: props.left || "auto",
    right: props.right || "10px",
}))`
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    width: ${(props) => props.w};
    height: ${(props) => props.h};
    border: 1px solid;
    border-color: ${(props) => props.bColor};
    background: ${(props) => props.bgColor};
    outline: none !important;
    border-radius: 50px;
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${(props) => props.positionBtn};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    &:focus,
    &:active {
        border-color: ${(props) => props.bColorActive};
    }
    &:disabled {
        opacity: 0.7;
        cursor: progress;
    }
`;

const ContInputButtonGroup = styled.div`
    margin: ${({m}) => m || '0px'};
    margin-left: ${({ml}) => ml || '0px'};
    margin-right: ${({mr}) => mr || '0px'};
    margin-top: ${({mt}) => mt || '0px'};
    margin-bottom: ${({mb}) => mb || '0px'};
    width: ${({width}) => width || '100%'};
    display: flex;
    * {
        height: 38px !important;
        border-radius: 0px !important;
    }
    *:last-child {
        border-radius: 0 0.25rem 0.25rem 0 !important;
    }
    *:first-child {
        border-radius: 0.25rem 0 0 0.25rem !important;
    }
`;

const InputFileGrouped = styled.div`
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    .file {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 2.5rem;
    }
    .file input {
        min-width: 14rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 1;
    }
    .file-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        height: 2.5rem;
        padding: 6px 16px;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        border: 0.075rem solid #ddd;
        border-radius: 0.25rem 0 0 0.25rem !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .file-custom:after {
        content: "Seleccione su archivo";
    }
    .file-custom:before {
        padding: 6px 16px;
        position: absolute;
        top: -0.05rem;
        right: -0.075rem;
        bottom: -0.05rem;
        z-index: 6;
        display: block;
        content: "Buscar";
        line-height: 1.5;
        color: #ffffff;
        background-color: #7367f0;
        border: 0.075rem solid #7367f0;
    }
    cursor: pointer;

    /* Focus */
    .file input:focus ~ .file-custom {
        box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
    }
`;

const Section = styled.div`
    margin-bottom: 20px;
    width: 100% !important;
    border: 1px solid #cccccc;
    border-radius: .428rem;
    padding: 5px;
`;

const Alert = styled.div`
    z-index: 5000000; 
    position: fixed;
    bottom: 10px;
    transition: 0.5s;
    right: -150vw;
    &.active {
        right: 15px;
    }
`;

const PedidosYaStyle = {
    Cont,
    ContNavbar,
    MenuOptions,
    OptionMenu,
    ContStep,
    Text,
    select,
    Header,
    ContInputAndLabel,
    ContForm,
    HeaderForm,
    BodyForm,
    FooterForm,
    BtnSend,
    Input,
    inputFile,
    ContBadgeError,
    BtnToggleBadge,
    ContIconNadInputPass,
    BtnShowPass,
    BodyFormGrid,
    ContTable,
    TableResult,
    TableResultThead,
    TableResultTh,
    TableResultTbody,
    TableResultTr,
    TableResultTd,
    HeaderContTable,
    BtnAndIcon,
    ContIconBtn,
    BtnIcon,
    contLoader,
    BackgroundModal,
    contModal,
    HeaderModal,
    BtnCloseModal,
    BodyModal,
    FooterModal,
    ContIconBtnLoad,
    ContInputBtn,
    BtnAdd,
    ContListOptions,
    ListItems,
    CirculBtn,
    ContInputButtonGroup,
    InputFileGrouped,
    Section,
    SelectWeb,
    InputText,
    Alert,
};

export default PedidosYaStyle;
