import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { BiPause, BiPlus, BiX } from 'react-icons/bi'
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle'
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import getAllCampaign from '../functions/getAllCampaign';
import { deleteProductsCampaign } from '../functions/deleteProductsCampaign';

export default function CampaignList({ setCurrentStep, idPage }) {
    const globalContext = useContext(GlobalContext);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadTwo, setIsLoadTwo] = useState(true);
    const [data, setData] = useState([]);
    const [showBadge, setShowBadge] = useState(false);
    const [colorError, setColorError] = useState(true);
    const [error, setError] = useState('');

    const closeBadge = (e) => {
        e.preventDefault();
        setShowBadge(false);
    }

    useEffect(() => {
        const entId = globalContext.currentEnterpriseId
        if (!entId) return;
        //add get campaign
        getAllCampaign(entId)
            .then((resp) => {
                setData(resp.data.data.campaigns);
                setIsLoadTwo(false)
                // console.log(resp.data.data)
            })
            .catch((error) => {
                console.log(error)
                setColorError(true)
                setError(error.message)
                setShowBadge(true)
            })
    }, [globalContext.currentEnterpriseId])

    const deleteCampaign = (id, getIdPage) => {
        const entId = globalContext.currentEnterpriseId
        console.log(`ID page remove: ${getIdPage}`)
        if (!entId) return;
        setIsLoadTwo(true)
        deleteProductsCampaign(entId, id, getIdPage)
        .then((resp) => {
            console.log(`ID page remove: ${getIdPage}`)
            setIsLoadTwo(false)
            setError('el proceso se ejecuta en segundo plano')
            setColorError(false)
            setShowBadge(true)
            setIsLoadTwo(false)
            const newDataCampaign = data.filter(v =>  v._id !== id);
            setData(newDataCampaign);
        }).catch((error) => {
            setIsLoadTwo(false)
            setError(error.message)
            setColorError(true)
            setShowBadge(true)
        })
    }

    return (
        <>
        <PedidosYaStyle.ContBadgeError active={showBadge} error={colorError}>
                <PedidosYaStyle.Text size=".875rem" cl="#FFFFFF">
                    {error}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge onClick={(e) => closeBadge(e)}>
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <PedidosYaStyle.ContTable style={{justifyContent: 'center'}}>
                <PedidosYaStyle.HeaderContTable>
                    <PedidosYaStyle.Text size="13pt" fw="500">
                        Listado de Campañas
                    </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderContTable>
                <PedidosYaStyle.TableResult>
                    <PedidosYaStyle.TableResultThead style={{width: '100%'}}>
                        <PedidosYaStyle.TableResultTr>
                            <PedidosYaStyle.TableResultTh width="500px" minWidth="100px" textAlign="center">
                                Nombre de la campaña
                            </PedidosYaStyle.TableResultTh>
                            <PedidosYaStyle.TableResultTh width="300px" minWidth="100px" textAlign="center">
                                Pagina id
                            </PedidosYaStyle.TableResultTh>
                            <PedidosYaStyle.TableResultTh width="100px" minWidth="100px" textAlign="center">
                                Accion
                            </PedidosYaStyle.TableResultTh>
                        </PedidosYaStyle.TableResultTr>
                    </PedidosYaStyle.TableResultThead>
                    {
                        isLoadTwo ? (
                            <PedidosYaStyle.TableResultTbody style={{width: '100%'}}>
                                <PedidosYaStyle.TableResultTr>
                                    <PedidosYaStyle.TableResultTd width="100%" minWidth="100px" textAlign="center">
                                        <PedidosYaStyle.contLoader>
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Cargando...</span>
                                            </Spinner>
                                        </PedidosYaStyle.contLoader>
                                    </PedidosYaStyle.TableResultTd>
                                </PedidosYaStyle.TableResultTr>

                            </PedidosYaStyle.TableResultTbody>
                        ) :
                            (
                                <PedidosYaStyle.TableResultTbody style={{width: '100%'}}>
                                    {
                                        data.length > 0 &&
                                        data.map(v => {
                                            console.log(data)
                                            return (
                                                <PedidosYaStyle.TableResultTr key={v._id}>
                                                    <PedidosYaStyle.TableResultTd width="500px" minWidth="100px" textAlign="center">
                                                        {v.name}
                                                    </PedidosYaStyle.TableResultTd>
                                                    <PedidosYaStyle.TableResultTd width="300px" minWidth="100px" textAlign="center">
                                                        {v.idPage}
                                                    </PedidosYaStyle.TableResultTd>
                                                    <PedidosYaStyle.TableResultTd width="100px" minWidth="100px" textAlign="center" backGroundColor="#F3F2F7">
                                                        <div className="d-flex justify-content-center align-items-center" style={{ with: '100%' }}>
                                                            {
                                                                isLoad ?
                                                                    (
                                                                        <PedidosYaStyle.contLoader>
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="sr-only">Cargando...</span>
                                                                            </Spinner>
                                                                        </PedidosYaStyle.contLoader>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <PedidosYaStyle.BtnIcon
                                                                                onClick={() => deleteCampaign(v._id, v.idPage)}
                                                                                mRight="auto"
                                                                                mLeft="auto"
                                                                                width="30px"
                                                                                height="30px">
                                                                                <BiPause />
                                                                            </PedidosYaStyle.BtnIcon>
                                                                        </>
                                                                    )
                                                            }
                                                        </div>
                                                    </PedidosYaStyle.TableResultTd>
                                                </PedidosYaStyle.TableResultTr>
                                            )
                                        })
                                    }
                                </PedidosYaStyle.TableResultTbody>
                            )
                    }
                </PedidosYaStyle.TableResult>
            </PedidosYaStyle.ContTable>
        </>
    )
}