import { faAngleLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import CategoriesCss from '../../../../../../assets/css/components/admin/categories/CategoriesCss'
import { keyAccessToken, keyUserId } from '../../../../../../assets/js/SessionStorageNames';
import { GlobalContext } from '../../../../../../contexts/GlobalContext';
import Pager from '../../../../../general/Pager';

export default function SelectCategory(props) {

    const globalContext = useContext(GlobalContext);

    const { active, setActive } = props;

    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [isLoad, setIsLoad] = useState(false);
    const [isSucces, setIsSucces] = useState(false);
    const pageItemCount = 20;
    const [totalCategories, setTotalCategories] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [categories, setCategories] = useState([]);
    const [valueInputFilter, setValueInputFilter] = useState('');
    const [categoriesPatherBefore, setCategoriesPatherBefore] = useState([null]);
    const [categoriesText, setCategoriesText] = useState(['Categorias']);
    const [categoriesP, setCategoriesP] = useState(null);
    const [includeTree, setIncludeTree] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const SearchCategorie = useRef(null);
    const [categoryByRate, setCategoryByRate] = useState(null);
    const [getAll, setGetAll] = useState(false)
    const closeModal = (v) => {
        setTotalCategories('')
        setCategories('');
        setActive(v);
        setIsSucces(false)
    }
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }

        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    useEffect(() => {
        if(!active) return;
        setIsLoad(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/categories`, {
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                parent: categoriesP,
                filter: {
                    includeTree: false,
                    getAll: true,
                    search: filter,
                    lang: 'en'
                },
            },
            cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
        })
        .then((r)=> {
            // if(r.data.data.categories.length === 0 ) {
            //     setIsLoad(false);
            //     globalContext.setCategorieTextRateMlAm(categoryByRate.name.es || categoryByRate.name.en);
            //     globalContext.setCategorieIdRateMlAm(categoryByRate._id)
            //     setTotalCategories('')
            //     setCategories('');
            //     setCategoriesP(null)
            //     setActive(false);
            //     setIncludeTree(false);
            //     setShowButton(false);
            //     setCategoriesText(['Categorias']);
            // }else {
                setTotalCategories(r.data.data.totalCategories)
                setCategories(r.data.data.categories);
                setIsLoad(false);
            //}
            
        })
        .catch((e)=>{ handleAxiosError(e, 'Presentamos un error al cargar las categorias')})
        /**
         * ! verificamos q el padre de las categorias no este null o sea undefined
         */
        // if(categoriesP === null || typeof categoriesP === 'undefined' && filter.length > 0) {
        //     setShowButton(false);
        // }
        // if (categoriesP) {
        //     setShowButton(true)
        // }

        // if (filter.length === 0) {
        //     setGetAll(false);
        // }
    },[active, currentPage, showButton, filter]);
    //},[categoriesP, active, currentPage, includeTree, showButton, categoriesPatherBefore, filter, getAll]);


    const setFilterData = (event) => {
        event.preventDefault();
        setFilter(valueInputFilter);
    }

    // const chageCategory = (c) => {
    //     setCategoryByRate(c)
    //     setCategoriesPatherBefore([...categoriesPatherBefore, c.node]);
    //     setCategoriesText([...categoriesText,'>'+c.name.en]);
    //     setCategoriesP(c.node);
    //     setIncludeTree(true);
    //     setShowButton(true);
    //     setFilter('')
    //     SearchCategorie.current.value = ''
    // }
    const chageCategory = (c) => {
        globalContext.setCategorieTextRateMlAm(c.name.es || c.name.en);
        globalContext.setCategorieIdRateMlAm(c._id)
        setTotalCategories('')
        setCategories('');
        setCategoriesP(null)
        setActive(false);
        setIncludeTree(false);
        //setShowButton(false);
        //setCategoriesText(['Categorias']);
        SearchCategorie.current.value = ''
        setFilter('')
    }
    // const backTo = () => {
    //     const findCateP = categoriesPatherBefore.pop();
    //     setCategoriesPatherBefore(categoriesPatherBefore.filter((e)=> (e !== findCateP)))
    //     setCategoriesP(categoriesPatherBefore.pop());
    //     const findCate = categoriesText.pop();
    //     setCategoriesText(categoriesText.filter((e)=> (e !== findCate)));
    //     setFilter('')
    // }
    
    return (
        <CategoriesCss.contModal active={active}>
            <CategoriesCss.modalCategorie active={active} w="1000px">
                <CategoriesCss.headerModal>
                    <CategoriesCss.textHeader size='12pt'>
                        Seleccione la categoria
                    </CategoriesCss.textHeader>
                    <CategoriesCss.btnCloseModal onClick={() => closeModal(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </CategoriesCss.btnCloseModal>
                </CategoriesCss.headerModal>
                <CategoriesCss.bodyModal>
                    <CategoriesCss.constGrid shadow="none" w="100%" ml="0" mt='10px'>
                        <CategoriesCss.heaterGrid>
                            <CategoriesCss.textHeader size='12pt'>
                                Seleccione su categoria de ML
                            </CategoriesCss.textHeader>
                            <div className="d-flex justify-content-start align-items-center w-100">
                                <form onSubmit={(e) => setFilterData(e)} style={{width: '100%'}}>
                                    <CategoriesCss.inputSearch type="text" placeholder="Escriba aqui..." onKeyDown={(e) => setValueInputFilter(e.target.value)} ref={SearchCategorie}/>
                                </form>
                            </div>
                            <div className="d-flex justify-content-start align-items-center" style={{width:100+'%'}}>
                                {categoriesText.map((c,i)=> (<p className="tittle" key={i}>{c}</p>))}
                            </div>
                        </CategoriesCss.heaterGrid>
                        <CategoriesCss.bodyGrid>
                            {
                                isLoad ? (
                                    <div className={`load-screen ${isLoad ? 'active' : ''}`}>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) :
                                    (
                                        (
                                            categories.length > 0 &&
                                            categories.map((c, i) => {
                                                return (
                                                    <CategoriesCss.constDataGrid key={i} onClick={()=>{chageCategory(c)}}>
                                                        {/* <CategoriesCss.contGridDataTwo> */}
                                                            <CategoriesCss.dataGrid>
                                                                <p>
                                                                    Nombre
                                                                </p>
                                                                <p>
                                                                    {c.name.es || c.name.en}
                                                                </p>
                                                            </CategoriesCss.dataGrid>
                                                        {/* </CategoriesCss.contGridDataTwo> */}
                                                    </CategoriesCss.constDataGrid>
                                                )
                                            })
                                        )
                                    )
                            }
                        </CategoriesCss.bodyGrid>
                        <CategoriesCss.footerGrid>
                            <Pager
                                handleSetPage={setCurrentPage}
                                totalResults={totalCategories}
                                currentPage={currentPage}
                                resultsPerPage={pageItemCount} />
                        </CategoriesCss.footerGrid>
                    </CategoriesCss.constGrid>
                </CategoriesCss.bodyModal>
            </CategoriesCss.modalCategorie>
        </CategoriesCss.contModal>
    )
}
