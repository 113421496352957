import React from 'react';
import RC_Style from '../../../../../assets/css/components/admin/plugins/reportesComercialesStyle';

const SubmitButton = ({label}) => {
    return (
        <RC_Style.ContFormButton>
            <RC_Style.Button type="submit">{label}</RC_Style.Button>
        </RC_Style.ContFormButton>
    );
};


export default SubmitButton;