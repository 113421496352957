import { useContext, useState } from "react";
import {
  ButtonSubmit,
  ContainerBody,
  ContainerStatus,
  ContainerStatusParent,
  Header,
  InfoEnvio,
  InputCode,
  InputCodeWrapper,
  SearchContainer,
  SpacingBg,
  Spinner,
  StepCounter,
  StepperItem,
  TrackerWrapper,
  Menu,
  InfoEnvioTD,
  InfoEnvioTH,
  Button,
  InfoHome
} from "../../assets/css/components/home/Tracking";
import axios from "axios";
import React from "react";

import { getTrackingInfo } from '../../hooks/api/tracking.js';

function Tracking() {
  const [state, setState] = useState();
  const [trackingCode, setTrackingCode] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const status = [
    "Recibido en Bodega",
    "Procesado",
    "Empacado para Envío a Destino",
    "Enviado a Destino",
    "Recibido en Aduana",
    "Entregado",
  ];

  const trackShipment = async (code) => {
    setIsLoading(true);
    setState(undefined);

    try {
      console.log('track: ',code)
      const data = (await getTrackingInfo({trackingCode: code})).data.data.response;
      if(data === undefined) {
        setIsLoading(false);
        setMessage('Vuelve a intentarlo por favor')
        setTimeout(() => {
          setMessage('')
        }, 1500);
        return
      }
      // const date = new Date(data.created).toLocaleDateString('es-ve', { weekday:"long", year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: 'numeric' }).split(',')
      const date = new Date(data.created)
        .toLocaleDateString("es-ve", {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .split(",");

      // setState({...data, tracking_code: trackingCode, date: `${date[0]} a las ${date[1]}`})
      setState({ ...data, tracking_code: code, date: `${date[0]}` });
      setIsLoading(false);
      // await globalContext.showModalWithMsg(message)

    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setMessage('Ha ocurrido un error, verifica que la id sea correcta')
        setTimeout(() => {
          setMessage('')
        }, 2500);

    }
  };

  return (
    <ContainerBody>
      <Header style={{ height: state ? 90 : 115, top: 0 }}>
            <img src={require('../../assets/img/png/logo212I.png')} alt="Logo" style={{ width: state ? 90 : 120, top: 0 }}/>
            <Menu>
              <a href="https://212internacional.com" target="_blank"><li>Ir a 212 Internacional</li></a> 
              <a href="https://212internacional.com/shop-2/" target="_blank"><li> Tienda</li></a>
            </Menu>
      </Header>

      {!message ? (<SearchContainer style={{ marginTop: state ? 80 : 'auto'}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <div style={{display: 'flex'}}>
            <InputCode
                type="text"
                placeholder="Código del paquete"
                name="input"
                onChange={(e) => setTrackingCode(e.target.value)}
                value={trackingCode}
              />
              <Button onClick={() => trackShipment(trackingCode)}>Buscar</Button>
          </div>

          {!isLoading && !state && (
            <InfoHome>
              Consulta fácilmente el estado de tu pedido ingresando la ID del
              producto. Nuestra aplicación te informa si ha sido enviado,
              recibido o si aún está pendiente. ¡Mantente al tanto de tus
              entregas en tiempo real!
            </InfoHome>
          )}
          {/* {state && <p style={{color: '#777'}}>Track another shipment</p>} */}

          {!isLoading && state && (
            <p
              onClick={() => {
                setState(undefined);
                setTrackingCode("");
              }}
              style={{ cursor: "pointer" }}
            >
              Volver
            </p>
          )}
        </div>

        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "50px",
              alignItems: 'center',
            }}
          >
            <Spinner />
          </div>
        )}
      </SearchContainer>): <div style={{height: '50vh', margin: 'auto', display:'flex', alignItems:'center'}}>
        <p style={{fontSize: '1.75rem', fontWeight: 'bold'}}>{message}</p>
      </div>}

      {state && (
        <>
          <div
            style={{
              backgroundColor: "white",
              padding: "50px",
              width: "90%",
              maxWidth: 800,
              margin: "30px auto",
              borderRadius: 15,
            }}
          > 
            <InfoEnvio className="info-envio">
              <thead>
                <tr>
                  <InfoEnvioTH colSpan="2" style={{textAlign:'center'}}>INFORMACIÓN DEL ENVÍO</InfoEnvioTH>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <InfoEnvioTD>N° de Tracking:</InfoEnvioTD>
                    <InfoEnvioTD>{state.tracking}</InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>N° de Warehouse:</InfoEnvioTD>
                    <InfoEnvioTD>{state.id}</InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>Fecha de creación del envío:</InfoEnvioTD>
                    <InfoEnvioTD style={{ textTransform: "capitalize" }}>{state.date}</InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>Producto:</InfoEnvioTD>
                    <InfoEnvioTD>{state.descripcion}</InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>Origen:</InfoEnvioTD>
                    <InfoEnvioTD>{(state.paquete_completo.origen.nombre).replace(/LETTER/g, '')}</InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>Destino:</InfoEnvioTD>
                    <InfoEnvioTD>
                      {state.paquete_completo.pais.nombre},{" "}
                      {state.paquete_completo.pais.codigo}
                    </InfoEnvioTD>
                  </tr>
                  <tr>
                    <InfoEnvioTD>Tipo de envío:</InfoEnvioTD>
                    <InfoEnvioTD style={{ textTransform: "capitalize" }}>
                      {state.paquete_completo.tipo_carga.codigo}
                    </InfoEnvioTD>
                  </tr>
                  <tr> 
                    <InfoEnvioTD>Estado del producto:</InfoEnvioTD>
                    <InfoEnvioTD style={{ textTransform: "capitalize" }}>
                      <Button
                        onClick={() => setShowStatus(true)}
                      >
                        Ver aqui
                      </Button>
                    </InfoEnvioTD>
                  </tr>
              </tbody>
            </InfoEnvio>
          </div>
          {showStatus && (
            <ContainerStatusParent>
              <ContainerStatus>
                <h2 style={{ marginTop: 0, marginBottom: 30 }}>
                  Estado del producto
                </h2>
                <TrackerWrapper>
                  {state.paquete_movimientos.slice(0, 6).map((item, index) => {
                    const isLast =
                      index ===
                      state.paquete_movimientos.slice(0, 6).length - 1;
                    const date = new Date(item.fecha)
                      .toLocaleDateString("es-ve", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                      })
                      .split(",");

                    return (
                      <div
                        key={item + index}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          height: 60,
                        }}
                      >
                        <StepperItem
                          className={`completed active ${isLast ? "last" : ""}`}
                        >
                          <StepCounter>
                            <svg
                              height="12px"
                              width="12px"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 17.837 17.837"
                              xmlSpace="preserve"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g>
                                  {" "}
                                  <path
                                    style={{ fill: "#ffffff" }}
                                    d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                                  ></path>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </StepCounter>
                        </StepperItem>
                        <div style={{ marginTop: "-7px" }}>
                          <p
                            style={{
                              minWidth: 225,
                              textAlign: "start",
                              margin: 0,
                            }}
                          >
                            {status[index]}
                          </p>
                          <p style={{ margin: 0, textAlign: "start" }}>
                            {date}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </TrackerWrapper>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <Button
                    style={{borderRadius: 7}}
                    onClick={() => setShowStatus(false)}
                  >
                    Cerrar
                  </Button> 
                </div>
              </ContainerStatus>
            </ContainerStatusParent>
          )}
        </>
      )}

      <SpacingBg style={{ height: state ? 0 : 80, bottom: 0 }}></SpacingBg>
    </ContainerBody>
  );
}

export default Tracking;
