import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../../assets/js/SessionStorageNames";

const sendVtexProd = (
  enterpriseId,
  productIds, 
  category,
  accId
) => { 

  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/v1/vtex-prod/${enterpriseId}/acc/${accId}/uploadProduct`,
    {
      products: productIds,
      category,
    },
    { 
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    }
  );
};


const postOneOdooVtex = async (
  enterpriseId,
  paramsToSend,
  category,
  accId
) => {
  let data = [];
    try {
      const send = await sendVtexProd(
          enterpriseId,
          paramsToSend,
          category,
          accId
      );
      console.log("respuesta ++++++++++", send.data);
      // datagood.push(send.data.message);
      data = send.data
      return data;
    } catch (error) {
      console.error(error);
    }
};

export default postOneOdooVtex;
