import React, { useState, useContext, useEffect } from "react";
import UserConfigNewUserStyled from "../../../assets/css/components/admin/user-config/UserConfigNewUser.css";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Link } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  keyUserId,
  keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Routes from "../../../Routes";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";

export default function UserConfigUpdateUser({ match }) {
  const globalContext = useContext(GlobalContext);
  const userId = match.params.id;
  const [redirect, setRedirect] = useState(null);
  
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [name, setName] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [enterprises, setEnterprises] = useState([]);
  const [enterprisesOptions, setEnterprisesOptions] = useState([]);
  const [formSave, setFormSave] = useState("active");
  const [goodSave, setGoodSave] = useState("");
  const [erroSave, setErrorSave] = useState("");
  const [error, setError] = useState("");
  const [rolRoute, setRoles] = useState("");
  const [dailyGoalForKbt, setDailyGoalForKbt] = useState('');
  const [isKbtUser, setIsKbtuser] = useState(false);
  const [country, setcountry] = useState('');
  const [third, setThird] = useState(false);
  const rolesSend = [
    {id: 'SuperAdmin', name: 'SuperAdmin'},
    {id: 'Admin', name: 'Admin'},
    {id: 'Gerente', name: 'Gerente'},
    {id: 'Champion', name: 'Champion'},
    {id: 'Preguntas', name: 'Preguntas'},
  ]

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 9);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
},[]);
  
  /**
   *
   * @param {*} e
   * save user data
   */
  const saveUser = (e) => {
    e.preventDefault();
    globalContext.setLoaderPage(true);
    const data = {
      name: name,
      email: correo,
      rolRoute: rolRoute,
      enterprises: enterprises,
      country: country,
      isKbtUser: isKbtUser,
      ...( dailyGoalForKbt.length > 0 && {dailyGoalForKbt:parseInt(dailyGoalForKbt)}),
      third,
    };

    if (password) {
      data.password = password;
    }

    axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/user/${userId}`,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data,
    })
      .then((res) => {
        setGoodSave("active");
      })
      .catch((err) => {
        setErrorSave("active");
      })
      .finally(() => {
        setFormSave("");
        globalContext.setLoaderPage(false);
      });
  };

  /**
   * Load the enterprises list
   */
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/`, {
      params: {
        pageNumber: 1,
        pageItemCount: 500,
      },
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    }).then((res) => {
      setEnterprisesOptions(res.data.data.enterprises);
    })
    .catch((err) => {
      if (axios.isCancel(err)) return; //Don't show axios cancel error
      globalContext.showModalWithMsg('Disculpe ha ocurrido un error al intentar cargar la lista de empresas');
      console.log(err, err.response);
    });
  }, []);

  /**
   * Load the user data
   */
  useEffect(() => {
    globalContext.setLoaderPage(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/user/${userId}`,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    })
      .then((res) => {
        const data = res.data.data;
        console.log('alad', data)
        setName(data.name);
        setCorreo(data.email);
        setEnterprises(data.enterprises.map(e => e.id));
        setRoles(data.rolRoute)
        data.country && setcountry(data.country);
        data.isKbtUser && setIsKbtuser(data.isKbtUser);
        data.dailyGoalForKbt && setDailyGoalForKbt(data.dailyGoalForKbt);
        setThird(data.third||false);
      })
      .catch((err) => {
        setFormSave("");
        setErrorSave("active");
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        globalContext.showModalWithMsg('Disculpe ha ocurrido un error al intentar cargar los datos del usuario');
        console.log(err, err.response);
      })
      .finally(() => {
        globalContext.setLoaderPage(false);
      });
    //eslint-disable-next-line
  }, []);

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  console.log('boolean', isKbtUser)
  return (
    <UserConfigNewUserStyled>
      <div className="goBack d-flex justify-content-start mt-2">
        <Link to="/admin/user-config/dashboard">
          <button className="btn-back">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
      </div>
      <div className="NewUserCont mt-5 mb-5">
        <div className={`form-user ${formSave}`}>
          <Form onSubmit={saveUser}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className="h3">Actualizar los datos de {name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        Nombre
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      value={name}
                      placeholder="Ingrese el nombre"
                      aria-label="Ingrese el nombre"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon2">
                        Correo
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      value={correo}
                      placeholder="Ingrese la direccion de correo"
                      aria-label="Ingrese la direccion de correo"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon3">Clave</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      value={password}
                      placeholder="Ingrese su clave de acceso"
                      aria-label="Ingrese su clave de acceso"
                      aria-describedby="basic-addon3"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon4">Clave</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Confirme su clave de acceso"
                      aria-label="Confirme su clave de acceso"
                      aria-describedby="basic-addon4"
                      onChange={(e) => setCheckPassword(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group>
                    <Form.Label>Role a asignar</Form.Label>
                    <Form.Control
                      value={rolRoute}
                      as="select"
                      id="asdasd"
                      onChange={e => setRoles(e.target.value)}
                    >
                      <option value="">Seleccione uno</option>
                      {
                        rolesSend.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group>
                    <Form.Label>Rol de Terceros</Form.Label>
                    <Form.Control
                      value={third}
                      as="select"
                      onChange={(e) => setThird(e.target.value)}
                    >
                      <option value={false} >No</option>
                      <option value={true} >Sí</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group>
                    <Form.Label>Pais</Form.Label>
                    <Form.Control
                      value={country}
                      as="select"
                      id="asdasd"
                      onChange={e => setcountry(e.target.value)}
                    >
                      <option value="">Seleccione uno</option>
                      <option value='VE'>VE</option>
                      <option value='CO'>CO</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group>
                    <Form.Label>Es usuario KBT?</Form.Label>
                    <Form.Control
                      value={isKbtUser}
                      as="select"
                      id="asdasd"
                      onChange={e => setIsKbtuser(e.target.value)}
                    >
                      <option value="">Seleccione uno</option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {
                  isKbtUser && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputGroup className="mb-3">
                  <Form.Label>Meta diaria</Form.Label>
                    <FormControl
                      value={dailyGoalForKbt}
                      placeholder="Meta diaria"
                      aria-label="Meta diaria"
                      aria-describedby="basic-addon4"
                      onChange={(e) => setDailyGoalForKbt(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                  )
                }
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group>
                    <Form.Label>Empresas</Form.Label>
                    <Form.Control
                      value={enterprises}
                      as="select"
                      multiple
                      id="asdasd"
                      onChange={e => setEnterprises([...e.target.options].filter(o => o.selected).map(o => o.value))}
                    >
                      {
                        enterprisesOptions.map(e => <option key={e._id} value={e._id}>{e.name}</option>)
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <button className="btn-save-user">Guardar</button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
        <div className={`save-screen ${goodSave}`}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p className="h2">Actualizacion exitosa!</p>
                <p className="save-register">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`error-screen ${erroSave}`}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p className="h2">{error}</p>
                <p className="error-register">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <button
                  className="btn-add-again-user"
                  onClick={() => {
                    setErrorSave("");
                    setFormSave("active");
                  }}
                >
                  Intentar de nuevo
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </UserConfigNewUserStyled>
  );
}
