import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductDetailStyle from "../../../../assets/css/components/admin/products/ProductDetail.css";
import CardProductDetail from "./components/CardProductDetail";
import ListVariants from "./components/ListVariants";
import ListOtherProducts from "./components/ListOtherProducts";
import ListProductsAssoc from "./components/ProductsAssoc";
import axios from "axios";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import { getVariantDescription } from "../../../../assets/js/productHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";

export default function ProductsDetail({ match }) {
  const globalContext = useContext(GlobalContext);
  const prodId = match.params.id;
  let pluginName = "";

  switch (match.params.plugin) {
    case "odoo":
      pluginName = "Odoo";
      break;

    case "ml":
      pluginName = "Mercadolibre";
      break;

      case "ln":
        pluginName = "Linio";
      break;
      case "df":
        pluginName = "Dafiti";
      break;
      case "fl":
        pluginName = "Falabella";
      break;
      case "vtex":
        pluginName = "Vtex";
      break;
      default:
        pluginName = "Odoo";
        break;
  }

  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [redirect, setRedirect] = useState(null);
  const [isFromMainPlugin, setIsFromMainPlugin] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [mlBrandName, setMlBrandName] = useState("");
  const [barCode, setBarCode] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [officialStoreId, setOfficialStoreId] = useState(0);
  const [hasVariants, setHasVariants] = useState(false);
  const [variantDesc, setVariantDesc] = useState("");
  const [variants, setVariants] = useState([]);
  const [linked, setLinked] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productToLinkId, setProductToLinkId] = useState(prodId);
  const [categories, setCategories] = useState([])
  const [images, setImages] = useState([])
  const [parentId, setParentId] = useState(prodId);
  const [isVariant, setIsVariant] = useState(null);
  const [baseProduct, setBaseProduct] = useState(null);

  /**
   * Update the state
   * @param {object} data The data to set
   */
  const setProductData = (data) => {
    if(data.isVariant) setIsVariant(data.isVariant);
    if (data.title) setTitle(data.title);
    if (data.price || data.price === 0) setPrice(data.price);
    if (data.stock || data.stock === 0) setStock(data.stock);
    if (data.thumbnail) setThumbnail(data.thumbnail);
    //if (typeof data.isVariant !== "undefined") setIsVariant(data.isVariant);
    if (typeof data.hasVariants !== "undefined")
      setHasVariants(data.hasVariants);
    if (data.variantDesc) setVariantDesc(data.variantDesc);
    if (data.variants) setVariants(data.variants);
    if (data.linked) setLinked(data.linked);
    if (data.barCode) setBarCode(data.barCode);
    if (data.mlBrandName) setMlBrandName(data.mlBrandName);
    if (data.officialStoreId) setOfficialStoreId(data.officialStoreId);
    if (data.productToLinkId) setProductToLinkId(data.productToLinkId);
    if (data.categories) setCategories(data.categories);
    if (data.images) setImages(data.images);
  };

  /**
   * Updates the linked list
   * @param {Array} linked The new list
   */
  const updateLinkedList = (linked) => {
    setLinked(linked.map((l) => l));
  };

  /**
   * Search the user main plugin
   */
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((res) => setIsFromMainPlugin(res.data.data == match.params.plugin ? true : false))
      .catch((err) => console.log(err));
  }, []);

  /**
   * Get the product data
   */

   useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 39);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
},[]);

  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;

    if (!globalContext.currentEnterpriseId) return

    const headerParams = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    switch (match.params.plugin) {
      case "odoo":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/${prodId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                variants: true,
                linked: true,
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, "odoo");
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);
            
            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              thumbnail:
                (data.thumbnail && typeof data.thumbnail == "object" && data.thumbnail.url) ? data.thumbnail.url
                  : (data.thumbnail && typeof data.thumbnail == "string") ? `data:image/png;base64,${data.thumbnail}`
                  : false,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.barCode,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });
            //setRequestedIsVariant(data.isVariant);
            //setRequestedHasVariants(data.hasVariants);

            //Set the variation data
            if (data.isVariant) {
              setVariantDesc(getVariantDescription(data, "odoo"));
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            console.error(err, err.response);
          });
        break; //End odoo

      case "ml":
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/${prodId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                variants: true,
                linked: true,
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;

            const variants = data.variants;
            //Set the variants description
            if (variants.length > 0) {
              for (const v of variants) {
                v.variantDesc = getVariantDescription(v, "ml");
              }
            }

            const linked = data.linked;
            //Set the linked variants description
            if (linked.length > 0) {
              for (const l of linked) {
                l.saved = true;
                if (l.product.isVariant) {
                  l.product.variantDesc = getVariantDescription(
                    l.product,
                    l.product.plugin
                  );
                }
              }
            }

            setParentId(data.baseProduct || prodId);
            data.baseProduct && setBaseProduct(data.baseProduct);

            setProductData({
              title: data.title,
              price: data.price,
              stock: data.stock,
              thumbnail: data.thumbnail,
              isVariant: data.isVariant,
              hasVariants: data.hasVariants,
              variants,
              linked,
              barCode: data.attrib.barCode,
              officialStoreId: data.attrib.official_store_id,
              categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });
            //setRequestedIsVariant(data.isVariant);
            //setRequestedHasVariants(data.hasVariants);

            //Set the variant data (if the product is a variant)
            if (data.isVariant) {
              const desc = getVariantDescription(data, "ml");
              setVariantDesc(desc);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
              "Disculpe, hubo un error al intentar cargar los datos del producto."
            );
            console.error(err, err.response);
          });
        break; //End ml
        /**
         * ! linio
         */
        case "ln":
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/v1/linio/${entId}/${prodId}`,
              {
                cancelToken: axiosCancelTokenSource.token,
                headers: headerParams,
                params: {
                  variants: true,
                  linked: true,
                },
              }
            )
            .then((resp) => {
              const data = resp.data.data;
  
              const variants = data.variants;
              console.log(variants)
              //Set the variants description
              if (variants.length > 0) {
                for (const v of variants) {
                  v.variantDesc = getVariantDescription(v, "ln");
                }
              }
  
              const linked = data.linked;
              //Set the linked variants description
              if (linked.length > 0) {
                for (const l of linked) {
                  l.saved = true;
                  if (l.product.isVariant) {
                    l.product.variantDesc = getVariantDescription(
                      l.product,
                      l.product.plugin
                    );
                  }
                }
              }
  
              setParentId(data.baseProduct || prodId);
              data.baseProduct && setBaseProduct(data.baseProduct);

              setProductData({
                title: data.title,
                price: data.price,
                stock: data.stock,
                thumbnail: data.thumbnail,
                isVariant: data.isVariant,
                hasVariants: data.hasVariants,
                variants,
                linked,
                barCode: data.attrib.sellerSku,
                //officialStoreId: data.attrib.official_store_id,
                categories: data.categories,
              ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
            });
              //setRequestedIsVariant(data.isVariant);
              //setRequestedHasVariants(data.hasVariants);
  
              //Set the variant data (if the product is a variant)
              if (data.isVariant) {
                const desc = getVariantDescription(data, "ln");
                setVariantDesc(desc);
              }
            })
            .catch((err) => {
              if (axios.isCancel(err)) return; //Don't show axios cancel error
  
              globalContext.showModalWithMsg(
                "Disculpe, hubo un error al intentar cargar los datos del producto."
              );
              console.error(err, err.response);
            });
          break; //End ln
          case "df":
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-product/${entId}/${prodId}`,
                {
                  cancelToken: axiosCancelTokenSource.token,
                  headers: headerParams,
                  params: {
                    variants: true,
                    linked: true,
                  },
                }
              )
              .then((resp) => {
                const data = resp.data.data;
    
                const variants = data.variants;
                console.log(variants)
                //Set the variants description
                if (variants.length > 0) {
                  for (const v of variants) {
                    v.variantDesc = getVariantDescription(v, "df");
                  }
                }
    
                const linked = data.linked;
                //Set the linked variants description
                if (linked.length > 0) {
                  for (const l of linked) {
                    l.saved = true;
                    if (l.product.isVariant) {
                      l.product.variantDesc = getVariantDescription(
                        l.product,
                        l.product.plugin
                      );
                    }
                  }
                }

                setParentId(data.baseProduct || prodId);
                data.baseProduct && setBaseProduct(data.baseProduct);
    
                setProductData({
                  title: data.title,
                  price: data.price,
                  stock: data.stock,
                  thumbnail: data.thumbnail,
                  isVariant: data.isVariant,
                  hasVariants: data.hasVariants,
                  variants,
                  linked,
                  barCode: data.attrib.sellerSku,
                  //officialStoreId: data.attrib.official_store_id,
                  categories: data.categories,
                  ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
                });
                //setRequestedIsVariant(data.isVariant);
                //setRequestedHasVariants(data.hasVariants);
    
                //Set the variant data (if the product is a variant)
                if (data.isVariant) {
                  const desc = getVariantDescription(data, "df");
                  setVariantDesc(desc);
                }
              })
              .catch((err) => {
                if (axios.isCancel(err)) return; //Don't show axios cancel error
    
                globalContext.showModalWithMsg(
                  "Disculpe, hubo un error al intentar cargar los datos del producto."
                );
                console.error(err, err.response);
              });
            break; //End df
            case "fl":
              axios
                .get(
                  `${process.env.REACT_APP_BACKEND_URL}/v1/fala-product/${entId}/${prodId}`,
                  {
                    cancelToken: axiosCancelTokenSource.token,
                    headers: headerParams,
                    params: {
                      variants: true,
                      linked: true,
                    },
                  }
                )
                .then((resp) => {
                  const data = resp.data.data;
                  setTitle(data.title);

                  const variants = data.variants;
                  console.log(variants)
                  //Set the variants description
                  if (variants.length > 0) {
                    for (const v of variants) {
                      v.variantDesc = getVariantDescription(v, "fl");
                    }
                  }

                  const linked = data.linked;
                  //Set the linked variants description
                  if (linked.length > 0) {
                    for (const l of linked) {
                      l.saved = true;
                      if (l.product.isVariant) {
                        l.product.variantDesc = getVariantDescription(
                          l.product,
                          l.product.plugin
                        );
                      }
                    }
                  }

                  setParentId(data.baseProduct || prodId);
                  data.baseProduct && setBaseProduct(data.baseProduct);

                  setProductData({
                    title: data.title,
                    price: data.price,
                    stock: data.stock,
                    thumbnail: data.thumbnail,
                    isVariant: data.isVariant,
                    hasVariants: data.hasVariants,
                    variants,
                    linked,
                    barCode: data.attrib.sellerSku,
                    //officialStoreId: data.attrib.official_store_id,
                    categories: data.categories,
                    ...((data.attrib.imagesPlus?.length > 0) && {images: data.attrib.imagesPlus.map(v => v.url)} ),
                  });
                  //setRequestedIsVariant(data.isVariant);
                  //setRequestedHasVariants(data.hasVariants);

                  //Set the variant data (if the product is a variant)
                  if (data.isVariant) {
                    const desc = getVariantDescription(data, "fl");
                    setVariantDesc(desc);
                  }
                })
                .catch((err) => {
                  if (axios.isCancel(err)) return; //Don't show axios cancel error

                  globalContext.showModalWithMsg(
                    "Disculpe, hubo un error al intentar cargar los datos del producto."
                  );
                  console.error(err, err.response);
                });
              break;
            default:
              break
    }
  }, [globalContext.currentEnterpriseId]);

  /**
   * Load the brands list
   */
  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`,
      {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      }
    ).then((resp) => {
      const brands = resp.data.data.brands;
      setBrands(brands);
    }).catch((err) => {
      if (axios.isCancel(err)) return; //Don't show axios cancel error
      console.error(err, err.response);
    });
  }, [globalContext.currentEnterpriseId]);

  /**
   * Update the ML brand name
   */
  useEffect(() => {
    let name = "";
    if (officialStoreId && brands.length > 0) {
      const brand = brands.filter((b) => b.id === officialStoreId);

      if (brand.length > 0) {
        name = brand[0].name;
      }
    }
    setMlBrandName(name);
  }, [brands, officialStoreId]);

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  return (
    <ProductDetailStyle>
      {redirect}
      <div className="goBack d-flex justify-content-start mt-2">
        <button
          className="btn-back"
          onClick={() => {
            setRedirect(<Redirect to="/admin/productos/list-products" />);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      {globalContext.currentEnterpriseId ? 
        <Container fluid className="mt-2">
          <Row>
              <Col xs={12} sm={12} md={7} lg={7} xl={7} className="pt-5">
                <CardProductDetail
                  pluginName={pluginName}
                  title={title}
                  price={price}
                  stock={stock}
                  thumbnail={thumbnail}
                  variantDesc={variantDesc}
                  barCode={barCode}
                  mlBrandName={mlBrandName}
                  productToLinkId={productToLinkId}
                  parentId={parentId}
                  categories={categories}
                  images={images}
                  minHeight='357px'
                />
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5} className="pt-5">
                <ListVariants
                  entId={globalContext.currentEnterpriseId}
                  plugin={match.params.plugin}
                  variants={variants}
                  setProductData={setProductData}
                  updateLinkedList={updateLinkedList}
                  setVariantDesc={setVariantDesc}
                  height='357px'
                  isVariant={isVariant}
                  baseProduct={baseProduct}
                  pluginName={match.params.plugin}
                />
              </Col>
          </Row>
          {!hasVariants && (
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pt-5">
                <ListOtherProducts
                  excludePlugin={match.params.plugin}
                  linked={linked}
                  updateLinkedList={updateLinkedList}
                  brands={brands}
                  productId={productToLinkId}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pt-5">
                <ListProductsAssoc
                  linked={linked}
                  updateLinkedList={updateLinkedList}
                  productId={productToLinkId}
                  brands={brands}
                  isFromMainPlugin={isFromMainPlugin}
                />
              </Col>
            </Row>
          )}
        </Container>
      : 
        <PedidosYaStyle.Text style={{color: "red"}} className="d-flex align-items-center justify-content-center" mt="0px" mb="3px" size='11pt' fw='550'>
          Debe ingresar una empresa.
        </PedidosYaStyle.Text>
      }
    </ProductDetailStyle>
  );
}
