import React, { useEffect, useState } from "react";
import {
    Switch,
    SwitchInput,
    SwitchLabel,
} from "../../assets/css/components/general/ToggleSwitch";

export const ToggleSwitch = ({ onChange, name, checked, showState, size = 'lg', stateTexts = ["Activo", "Inactivo"] }) => {
    const [check, setCheck] = useState(checked || false);

    const handleChange = (e) => {
        setCheck(e.target.checked);
        onChange(e);
    };

    useEffect(() => {
      setCheck(checked);
    }, [checked])
    

    return (
        <SwitchLabel>
            <SwitchInput
                size={size}
                name={name}
                checked={check}
                type='checkbox'
                onChange={handleChange}
            />
            <Switch size={size}/>
            {showState && <span>{check ? stateTexts[0] : stateTexts[1]}</span>}
        </SwitchLabel>
    );
};

export default ToggleSwitch;
