import styled from "styled-components";

const cont = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content: flex-start;
height:auto;
padding-bottom: 10px;
padding-top: 10px;
`;
const header = styled.div`
width:100%;
align-self: flex-start;
display:flex;
align-items:center;
justify-content:flex-start;
margin-top:10px;
`;

const text = styled.p`
margin-bottom:  ${props=> props.mb || '0'};
color: ${props=> props.cl || '#707070'};
font-size: ${props=> props.size || '12px'};
margin-left: ${props=> props.ml || '0'};
margin-right: ${props=> props.mr || '0'};
font-weight: ${props=> props.fw || 'lighter'};
margin-top: ${props=> props.mt || '0'};
`;

const contTable = styled.div`
min-width: 280px;
min-height: ${props => props.minHeight || '350px'};
width: 95%;
max-width: 1200px;
border: none;
border-radius: .428rem;
background-clip: border-box;
background: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
margin-top: 10px;
align-self: center;
margin-left: 10px;
margin-right: 10px;
margin-bottom: 20px;
`;

const headerContTable = styled.div`
/* border: 1px solid red; */
width: 100%;
height: 60px;
border-top-left-radius: 0.5rem;
border-top-right-radius: 0.5rem;
display: flex;
justify-content: space-between;
flex-wrap: wrap-reverse;
align-items: center; 
padding: 10px;
@media screen and (max-width: 761px) {
    height: 110px;
    justify-content: center;
    align-items: space-between;
}
`;
const bodyContentTable =  styled.div`
min-height: 20px;
max-height: 600px;
height: 500px;
width: 100%;
/* border:1px solid red; */
overflow-y: auto;
overflow-x: auto;
/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 50px;

}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7367f0;
}
`;
const footerContTable = styled.div`
/* border: 1px solid red; */
width: 100%;
height: 60px;
border-bottom-left-radius: 0.5rem;
border-bottom-right-radius: 0.5rem;
display: flex;
justify-content: flex-end;
align-items: center; 
padding-right: 10px;
padding-top: 10px;
`;

const contActionOne = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
min-width: 280px;
width: 100%;
/* border: 1px solid red; */
`;
const contActionTwo = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
min-width: 280px;
width: 350px;
/* border: 1px solid red; */
`;

const input = styled.input`
    width: ${props => props.w || '100%'};
    height: 38px;
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    padding:6px 16px;
    font-size:12pt;
    color: rgb(112, 112, 112);
    font-weight: 600;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 1);
        }
        &:focus, &:active {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
    margin-top : ${props => props.mt || 0};
`;

const anotherCont = styled.div`
width: 100%;
display: flex;
justify-content: ${props => props.jc || 'center'};
align-items: ${props => props.ali || 'center'};
margin-top: ${props => props.top || '0px'};
padding-right: ${props => props.pr || '0px'};
padding-left: ${props => props.pl || '0px'};
`;
const selectCuston = styled.select`
    padding-left:10px;
    padding-right:10px;
    // font-family: "Berlin Rounded";
    width: ${props => props.wd || '100%'};
    margin-left: ${props => props.ml || '0'};
    margin-right: ${props => props.mr || '0'};
    height: 38px;
    border-radius: .357rem;
    background: white;
    border: 1px solid #d8d6de;
    outline: none !important;
    // font-family: "Berlin Rounded";
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
        &:focus {
            border: 0.5px solid rgba(115, 103, 240, 0.7);
            box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
        }
        @media(max-width: ${props => props.selectMq || '989px'}) {
        width:100%;
    }
`;

const textArea = styled.textarea`
    padding-left:10px;
    padding-right:10px;
    // font-family: "Berlin Rounded";
    width: 100%;
    margin-left: ${props=> props.ml || '0'};
    margin-right: ${props=> props.mr || '0'};
    height: ${props=> props.hg || '150px'};
    border-radius: .357rem;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    border: 1px solid #d8d6de;
    outline: none !important;
    color: #707070;
    font-size: 12pt;
    font-weight: 300;
    resize:none;
    ::placeholder {
        // font-family: "Berlin Rounded";
        font-weight: 300;
        font-size: 12px;
        color: rgba(112, 112, 112, 0.61);
        }
    &:focus {
        border: 0.5px solid rgba(115, 103, 240, 0.7);
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
        /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg,#7367f0,rgba(115,103,240,.7));
        border-radius: 50px;
        
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {        
        box-shadow: 2px 0px 6px #7367f0;
        
    }
`;
const btnAction = styled.button`
    width: auto;
    height: 38px;
    background: #7367f0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:0.5rem;
    border: none;
    outline: none !important;
    margin-right: 5px;
    font-weight: bold;
    transition: all ease 0.5s;
    font-size:12pt;
    color: white;
    margin-left:10px;
    &:hover {
        box-shadow: 0 3px 10px 0 #7367f0;
    }
    &:disabled {
        cursor: no-drop;
        background: #7367f099;
    }
`;

const tableData = styled.table`
width: 100%;
border-collapse: collapse;
position: relative;
`;
const tableDataThead = styled.thead`
position: sticky;
margin-top: 0px;
width: 100%;
`;
const tableDataTh = styled.th`
background: #f3f2f7;
height: 40px;
width: ${props => props.w || 'auto'};
text-align: ${props => props.ta || 'left'};;
text-indent: 10px;
color:#707070;
text-transform: uppercase;
font-size: .857rem;
letter-spacing: .5px;
`;

const tableDataTbody = styled.tbody`
width: 100%;
`;

const tableDataTr = styled.tr`
border-top: 1px solid #ebe9f1;
height: auto;
max-height: 60px;
`;

const tableDataTd = styled.td`
background: white;
height: 40px;
width: ${props => props.w || 'auto'};
max-width: ${props => props.w || 'auto'};
text-align: ${props => props.ta || 'left'};
padding: 10px;
color:#707070;
font-size: 12pt;
overflow-wrap: break-word;
word-wrap: break-word;
hyphens: auto;
`;

const formFilter = styled.div`
min-width: 280px;
width: 95%;
max-width: 1200px;
background: white;
align-items: center;
border-radius: .428rem;
background-clip: border-box;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
margin-top: 10px;
align-self: center;
margin-left: 10px;
margin-right: 10px;
margin-bottom: 20px;
padding:10px;
`

const gridForm = styled.div`
width: 100%;
display: grid;
grid-gap: 0.5rem;
padding: 10px;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

const contInputAndLabel = styled.div`
width:100%;
height:auto;
display:flex;
flex-direction:${props => props.fld || 'column'};
justify-content: ${props => props.jsc || 'space-between'};
align-items:${props => props.ali || 'flex-start'};
`;

const contIcons = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

const btnActionIcon = styled.button`
width: 35px;
height: 35px;
outline: none !important;
border-radius: 50%;
border: none;
margin-left: 2.5px;
margin-right: 2.5px;
display: flex;
align-items: center;
justify-content: center;
background: transparent;
transition: all ease-out 0.2s;
color: rgb(112 112 112 / 45%);
&:hover{
    color: #7367F0;
}
`;

const contLoader = styled.div`
width: 100%;
height: 100%;
margin-top: auto;
margin-bottom: auto;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
.spinner-border {
    border: .25em solid #7367f0;
    border-right-color: transparent;
}
`;

const ContModal = styled.div`
height: 100%;
transition: all ease-in 0.01s;
width:100%;
background-color:rgba(112,112,112,0.50);
position: fixed;
left:0;
top:0;
right:0;
bottom:0;
transition: all ease-out 0.1s;
visibility: ${props=> props.active ? 'visible' : 'hidden'};
z-index: 9;
display:flex;
justify-content:center;
align-items:center;
display: flex;
justify-content:center;
align-items:center;
overflow-y: auto;
padding: 1rem;
`;

const ModalTest = styled.div`
transition: all ease-in 0.01s;
visibility: ${props=> props.active ? 'visible' : 'hidden'};
min-width: 280px;
min-height: 350px;
width: 95%;
max-width: 1000px;
border: none;
border-radius: .428rem;
background-clip: border-box;
background: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
margin-top: 10px;
align-self: center;
margin-left: 10px;
margin-right: 10px;
margin-bottom: 20px;
`;

const HeaderModal = styled.div`
width: 100%;
height: 50px;
background: #f3f2f7;
color:#707070;
padding: 0.5rem;
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 0;
margin-bottom: 1rem;
`;

const FooterModal = styled.div`
width: 100%;
height: 50px;
background: #f3f2f7;
color:#707070;
padding: 0.5rem;
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 1rem;
margin-bottom: 0;
`;

const BodyModal = styled.div`
width: 100%;
min-height: 300px;
padding: 1rem;

`;
const ListFiltersStyles = {
    cont,
    header,
    text,
    contTable,
    headerContTable,
    bodyContentTable,
    footerContTable,
    contActionOne,
    contActionTwo,
    input,
    btnAction,
    tableData,
    tableDataThead,
    tableDataTh,
    tableDataTbody,
    tableDataTr,
    tableDataTd,
    formFilter,
    contInputAndLabel,
    selectCuston,
    anotherCont,
    gridForm,
    textArea,
    contIcons,
    btnActionIcon,
    contLoader,
    ContModal,
    ModalTest,
    HeaderModal,
    FooterModal,
    BodyModal
}

export default ListFiltersStyles;