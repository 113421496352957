import React from "react";
import { Image } from "react-bootstrap";

export default function ListPhotos({ photos, setThumbnail }) {
  return (
    <>
      {photos?.length > 0 && (
          photos.map((v, i) => (
            <div className='m-1 border rounded text-center' key={i}>
              <Image src={v} width={100} className='border rounded' style={{cursor: 'pointer'}} onClick={() => setThumbnail(v)}/>
            </div>
          ))
      )}
    </>
  );
}
