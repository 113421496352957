import React, { useContext, useEffect, useState } from "react";
import CardProductDetailStyle from "../../../../../assets/css/components/admin/products/CardProductDetail.css";
import {
  Container,
  Row,
  Col,
  Image,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import ImgTest from "../../../../../assets/img/jpeg/img_test.jpg";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
  third,
  userRole,
} from "../../../../../assets/js/SessionStorageNames";
import ListVariantsModal from "./ListVariantsModal";
import ListPhotos from "./ListPhotos";
import { Collapse } from "react-bootstrap";
import { FaSortDown } from "react-icons/fa";
import verifyThirdPartyUser from "../../../../general/VerifyThirdPartyUser";
import PedidosYaStyle from "../../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { Spinner } from 'react-bootstrap';
import ListFiltersStyles from "../../../../../assets/css/components/admin/filters/ListFiltersCss";

export default function CardProductDetail(props) {
  const globalContext = useContext(GlobalContext);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const numberFormat = new Intl.NumberFormat("de-DE");
  const [showFormula, setShowForula] = useState(false)
  const [openDesc, setOpenDesc] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem(userRole)

  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    console.log("user GlobalContext: ",globalContext.user);
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setThumbnail(props.thumbnail)
    props.title && setLoading(false);
  },[props])

  const sendProductToFtp = (productToLinkId) => {
    globalContext.setOpenAddProductFtpId(productToLinkId);
    globalContext.setOpenAddProductFtp(true);
  };
  const downloadImages = () => {
    const entId = globalContext.currentEnterpriseId;
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-image/${entId}/download-images-product/${props.productToLinkId}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((res) => {
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg(
          `El proceso se estara ejecutando en segundo plano`
        );
      })
      .catch((error) => {
        globalContext.showModalWithMsg(`${error}`);
        globalContext.setLoaderPage(false);
        console.error(error);
      });
  };

  function roundDecimals(num) {
    const round = parseFloat(num.toFixed(2))
    return numberFormat.format(round);
  }

  const syncOdooProduct = () => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) {
      globalContext.showModalWithMsg("La empresa...");
      return;
    }
    globalContext.setLoaderPage(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/sync/${props.productToLinkId}`,
        {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        }
      )
      .then((resp) => {
        globalContext.setLoaderPage(false);
        globalContext.showModalWithMsg(`El proceso finalizo con exito`);
      })
      .catch((err) => {
        globalContext.showModalWithMsg(err.message);
        globalContext.setLoaderPage(false);
      });
  };
  return (
    <CardProductDetailStyle style={{ transition: ".5s" }} minHeight={props.minHeight || '300px'}>
      <div className={`CardBody ${loading ? 'd-flex align-items-center justify-content-center' : ''}`}>
        {loading ? 
            <Col className="">
              <ListFiltersStyles.contLoader>
                  <Spinner animation="border" role="status">
                      <span className="sr-only">Cargando...</span>
                  </Spinner>
              </ListFiltersStyles.contLoader>
            </Col>
          :
          <Container>
            <Row className='my-2'>
              {props.images.length > 0 && 
                <Col style={{overflow: 'scroll', maxHeight: props.minHeight}} className="p-0">
                  <ListPhotos photos={props.images} setThumbnail={setThumbnail}/>
                </Col>
              }
              <Col xs={12} md={12} lg={5} className='d-flex p-2'>
                <Row>
                  <Col
                    className='d-flex align-items-center justify-content-center'
                    xs={12}
                  >
                    <Row>
                      <Col>
                        <Image
                          src={thumbnail || ImgTest}
                          alt='img product'
                          rounded
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Row>
                  <Col xs={12} className='align-middle text-left'>
                    <div className='info-produc text-letf'>
                      {props.marca && (
                        <>
                          <Row>
                            <Col className='d-flex border-bottom mb-2'>
                              <h5>Marca:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.marca}
                              </h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col className='d-flex' xs={6}>
                              <h5>Peso:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.weight}
                              </h5>
                            </Col>
                            <Col className='d-flex' xs={6}>
                              <h5>Volumen:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.volume}
                              </h5>
                            </Col>
                            <Col className='d-flex' xs={6}>
                              <h5>Largo:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.lenght}
                              </h5>
                            </Col>
                            <Col className='d-flex' xs={6}>
                              <h5>Ancho:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.width}
                              </h5>
                            </Col>
                            <Col className='d-flex' xs={6}>
                              <h5>Alto:</h5>
                              <h5 className='primary-text'>
                                &nbsp;{props.height}
                              </h5>
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className=''></div>
                      {props.mlBrandName && (
                        <>
                          <br />
                          <span>
                            <i>{props.mlBrandName}</i>
                          </span>
                        </>
                      )}
                      <p className='text-lp' style={{minHeight: '50px !important'}}>{props.variantDesc || props.title}</p>
                      {props.barCode && (
                        <h5 className='text-pp'>{props.barCode}</h5>
                      )}
                      <hr />
                      <Row>
                        <Col className='d-inline-flex'>
                          <h5>Costo:&nbsp;</h5>
                          <h5 className='text-pp'>
                            {roundDecimals(props.price)}$
                          </h5>
                        </Col>
                        {verifyThirdPartyUser() ? 
                          <>
                            <Col className='d-flex' xs={6}>
                              {props.calculatedPrice ?
                                <>
                                  <h5>Precio de Venta:&nbsp;</h5>
                                  <h5 className='text-pp'>
                                    {roundDecimals(props.calculatedPrice)}$
                                  </h5>
                                </>
                              : <>&nbsp;</>
                              }
                            </Col>
                            <Col className='d-flex' xs={6}>
                              {props.calculatedPrice ?
                                <>
                                  <h5>Ganancia Bruta:&nbsp;</h5>
                                  <h5 className='text-pp'>
                                    {roundDecimals(props.earning)}$
                                  </h5>
                                </>
                                : <>&nbsp;</>
                              }
                            </Col>
                            <Col xs={6}>
                              {props.calculatedPrice 
                                ?
                                  <Button
                                    className='button'
                                    onClick={() => setShowForula(!showFormula)}
                                  >
                                    {showFormula ? 'Ocultar' : 'Mostrar'} Formula
                                  </Button>
                                : <>&nbsp;</>
                              }
                            </Col>
                            <Col xs={12}>
                              {showFormula ? props.formula : ''}
                            </Col>
                          </>
                        : ''}
                      </Row>
                      <p className='text-sp'>{props.stock} Stock</p>

                      {role === "Admin" && 
                        <>
                          <Row>
                            <Col>
                              <p className='text-sp'>ID Mongo del Padre:</p>
                              <PedidosYaStyle.Text mt="0px" mb="3px" size='11pt' fw='550'>
                                {props.parentId}
                              </PedidosYaStyle.Text>
                            </Col>
                          </Row>
                          {props.categories.length > 0 && 
                            <Row>
                              <Col>
                                <p className='text-sp'>ID Categoría{props.categories.length > 1 && "s"}:</p>
                                <ul>
                                  {props.categories.map(v => 
                                    <li>
                                      <PedidosYaStyle.Text mt="0px" mb="0px" size='10pt' fw='500'>
                                        {v}
                                      </PedidosYaStyle.Text>
                                    </li>
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          }
                        </>
                      }
                    </div>
                  </Col>
                </Row>
                <Row>
                  {!verifyThirdPartyUser() &&
                    <Col xs={12}>
                      <ButtonGroup aria-label='Basic example'>
                        {props.productToLinkId && (
                          <Button
                            className='button'
                            onClick={() => sendProductToFtp(props.productToLinkId)}
                          >
                            Agregar para FTP
                          </Button>
                        )}
                        <Button
                          className='button'
                          onClick={() => {
                            syncOdooProduct();
                          }}
                        >
                          Descargar datos
                        </Button>
                        <Button className='button' onClick={() => downloadImages()}>
                          Descargar Imagenes faltantes
                        </Button>
                      </ButtonGroup>
                    </Col>
                  }
                  <Col>
                    {props.variants && (
                      <ListVariantsModal
                        entId={globalContext.currentEnterpriseId}
                        plugin={props.plugin}
                        variants={props.variants}
                        setProductData={props.setProductData}
                        updateLinkedList={props.updateLinkedList}
                        setVariantDesc={props.setVariantDesc}
                        getPublishingPrice={props.getPublishingPrice}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {props.description && (
              <Row>
                <Col>
                  <Row>
                    <Col
                      className='primary-text border-bottom'
                      onClick={() => setOpenDesc(!openDesc)}
                      aria-controls='example-collapse-text'
                      aria-expanded={openDesc}
                    >
                      <h3>
                        Descripción{" "}
                        <FaSortDown
                          style={{
                            transform: openDesc ? "rotateX(180deg)" : "",
                            position: "relative",
                            top: openDesc ? "10px" : "-5px",
                            transition: ".5s",
                          }}
                        />
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='desc mb-4 pl-4 pt-4'>
                      <Collapse in={openDesc}>
                        <div id='example-collapse-text'>{props.description}</div>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Container>
        }
      </div>
    </CardProductDetailStyle>
  );
}
