import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConfigStyled from '../../../../assets/css/components/admin/config/ConfigStyled';
import ListFiltersStyles from '../../../../assets/css/components/admin/filters/ListFiltersCss'
import { GlobalContext } from '../../../../contexts/GlobalContext';
import Pager from '../../../general/Pager';
import TableData from '../componets/TableData';
import axios from 'axios'
import { keyAccessToken, keyUserId } from '../../../../assets/js/SessionStorageNames';
import { Button } from '../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles';
export default function Seven() {
    const globalContext = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageItemCount, setPageItemCount] = useState(8);
    const [totalRecords, setTotalrecords] = useState(1);
    const [isLoader, setIsLoader] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("");
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());

    function searchUsers() {
        setIsLoader(true)
        const entId = globalContext.currentEnterpriseId;
        if(!entId) return;
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/user/`, {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                    entId: entId,
                    pageNumber: currentPage,
                    pageItemCount: pageItemCount,
                    filter: { name: filter, email: filter },
                },
            })
            .then((resp) => {
                const data = resp.data.data;
                setTotalrecords(data.totalUsers);
                setData(data.users);
                setIsLoader(false)
            })
            .catch((err) => {
                if (axios.isCancel(err)) return; //Don't show axios cancel error
    
                globalContext.showModalWithMsg(
                    "Disculpe, hubo un error al intentar cargar la lista de usuarios."
                );
                setIsLoader(false)
                console.error(err, err.response);
            });

    }

    useEffect(() => {
        searchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageItemCount, globalContext.currentEnterpriseId]);

    return (
        <ConfigStyled.ContFormTwo color="transparent" notShador={true}>
            <ListFiltersStyles.contTable minHeight='300px'>
                        <ListFiltersStyles.headerContTable>
                            <ListFiltersStyles.contActionOne>
                                <ListFiltersStyles.text size='12pt' mr='10px'>
                                    Registros
                                </ListFiltersStyles.text>
                                <ListFiltersStyles.selectCuston
                                    wd='70px'
                                    value={pageItemCount}
                                    onChange={(e) => setPageItemCount(e.target.value)}
                                    disabled={isLoader}
                                >
                                    <option value={8}>8</option>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={65}>65</option>
                                    <option value={85}>85</option>
                                    <option value={100}>100</option>
                                </ListFiltersStyles.selectCuston>
                                <Link to="new-user" style={{ textDecoration: 'none' }}>
                                    <Button disabled={isLoader}>
                                        Crear Nuevo
                                    </Button>
                                </Link>
                            </ListFiltersStyles.contActionOne>
                            {/* <ListFiltersStyles.contActionTwo>
                                <ListFiltersStyles.input
                                    onChange={(e) => setFilter(e.target.value)}
                                    placeholder='Buscar' 
                                    disabled={isLoader} />
                                    <Button onClick={searchUsers}>Buscar</Button>
                            </ListFiltersStyles.contActionTwo> */}
                        </ListFiltersStyles.headerContTable>
                {!isLoader ? (
                    <>
                        <ListFiltersStyles.bodyContentTable>
                            <TableData
                                data={data}
                                refreshUsersList={searchUsers}
                            />
                        </ListFiltersStyles.bodyContentTable>
                    </>
                ) : (
                    <ListFiltersStyles.contLoader>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </ListFiltersStyles.contLoader>
                )}
                        <ListFiltersStyles.footerContTable>
                            <Pager
                                handleSetPage={setCurrentPage}
                                totalResults={totalRecords}
                                currentPage={currentPage}
                                resultsPerPage={pageItemCount}
                                disabled={isLoader}
                            />
                        </ListFiltersStyles.footerContTable>
            </ListFiltersStyles.contTable>
        </ConfigStyled.ContFormTwo>
    )
}
