import styled from "styled-components";

const SearchWrapper = styled.div`
    grid-column: 1 / ${({ colspan }) => (colspan ? colspan : "1")};
    position: relative;
    margin: ${({ margin }) => (margin ? margin : "0")};
    width: 100%;
    position: relative;
`;
const Input = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
    &:focus {
        border: 1px solid #d3d0e7;
    }
`;
const Suggestions = styled.div`
    display: ${({ show }) => (show ? "block" : "none")};
    position: absolute;
    background-color: #f9f9f9;
    width: ${({ width }) => (width ? width : "100%")};
    min-width: 160px;
    max-height: 250px;
    overflow: scroll;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
`;
const Suggestion = styled.div`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover {
        background-color: #ddd;
    }
`;

const SearchContent = styled.div`
    width: ${({ width }) => (width ? width : "100%")};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0px;
`;

const DivId = styled.div`
    border: 1px solid #ccc;
    border-radius: 0px 4px 4px 0px;
    padding: 12px 20px;
    margin: 8px 0;
    grid-column: 13 / 10;
`;

const IconContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 12px;
    transition: 0.5s;
    rotate: x ${(props) => (props.focused ? "180deg" : "0deg")};
`;

export {
    SearchWrapper,
    Input,
    Suggestions,
    Suggestion,
    SearchContent,
    DivId,
    IconContainer,
};
