import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../../assets/js/SessionStorageNames";
import { useContext } from "react";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";


const useCasheaCampaign = () => {    
    const globalContext = useContext(GlobalContext);

    const sendCampaign = async (enterpriseId, pricelistId, plusPercent, dateFrom, dateTo, nameCampaign) => {

        try {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(`Creando campaña, puede visualizar su estatus en la vista de segundo plano`);
    
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-campaign/send-from-odoo-pricelist`,
                {
                    pricelistId,
                    plusPercent,
                    enterpriseId, 
                    dateFrom,
                    dateTo,
                    nameCampaign
                }, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            });
    
            return res;
    
        } catch (err) {
            globalContext.setLoaderPage(false);
            console.error(err);
            globalContext.showModalWithMsg(
                `Ocurrió un error al crear la campaña ${err}`
            );
        }
    }


    const deleteProductsCampaign = async (enterpriseId, campaignId) => {

        try {
            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(`Eliminado campaña, puede visualizar su estatus en la vista de segundo plano`);
    
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-campaign/delete-from-campign-id`,
                {
                    campaignId,
                    enterpriseId
                }, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })

            console.log(`res: ${res}`);
            console.log(`res: ${JSON.stringify(res)}`);
    
            return res;
    
        } catch (err) {
            globalContext.setLoaderPage(false);
            console.error(err);
            globalContext.showModalWithMsg(
                `Ocurrió un error al eliminar la campaña ${err}`
            );
        }
    }

    
    return {sendCampaign, deleteProductsCampaign};
};

export default useCasheaCampaign;