import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from "react-router-dom";
import Routes from "../../../../../Routes";
import verifyAccessRole from "../../../../../assets/js/verifyAccessRole";
import PedidosYaStyle from '../../../../../assets/css/components/admin/plugins/PedidosYaStyle';
import Navbar from '../components/Navbar';
import FormuserFtp from '../components/FormuserFtp';
import SucursalList from '../components/SucursalList'
import FormSucursal from '../components/FormSucursal';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
export default function FormIndex() {
    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [StatusDropDown, setStatusDropDown] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);//change to one
    const [currentSucursal, setCurrentSucursal] = useState(null);
    const [sections, setSections] = useState([]);
    const [currentSections, setCurrentSections] = useState(null);

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 45);
        if(!hasAccess || globalContext.parentEntId) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    },[globalContext.parentEntId]);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (<FormuserFtp StatusDropDown={StatusDropDown} />)
            case 2:
                return ( 
                    <SucursalList
                        setCurrentStep={setCurrentStep}
                        currentSucursal={currentSucursal}
                        setCurrentSucursal={setCurrentSucursal}
                    />)
            case 3:
                return (
                    <FormSucursal
                        StatusDropDown={StatusDropDown}
                        setCurrentStep={setCurrentStep}
                        currentSucursal={currentSucursal}
                        setCurrentSucursal={setCurrentSucursal}
                    />
                )
            default:
                //return (<SectionsList />)
                return (<FormuserFtp StatusDropDown={StatusDropDown} />);
        }
    }


    return (
        <PedidosYaStyle.Cont>
            {redirect}
            <PedidosYaStyle.Header>
                <PedidosYaStyle.Text size="15pt" fw="500">
                    Configuracion para Anican
                </PedidosYaStyle.Text>
            </PedidosYaStyle.Header>
            <Navbar currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <PedidosYaStyle.ContStep>
                {renderStep()}
            </PedidosYaStyle.ContStep>
        </PedidosYaStyle.Cont>
    )
}
