

import { useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { keyUserId, keyAccessToken, keyUserActiveEnterpriseId } from "../../../../../assets/js/SessionStorageNames";

const useRcReport = () => {
    const globalContext = useContext(GlobalContext);

    const getInventoryReport = async (userName, userPassword, enterpriseInfo, login) => {

        let data;
        const ent_id= localStorage.getItem(keyUserActiveEnterpriseId);
        if(!login){ //Generar reporte
            
            if (!userName || !userPassword || !enterpriseInfo || !ent_id) return;

            console.log(`--> ${userName} --> ${userPassword} ---> ${enterpriseInfo}`);
            globalContext.setLoaderPage(true);

            const enterprise = {
                id: enterpriseInfo.value,
                name: enterpriseInfo.label,
            };

            data = {
                enterpriseId: ent_id,
                odooEnterpriceInfo: enterprise,
                odooUser: userName,
                odooPassword: userPassword,
                login: login
            };
        }
        else{   //Login

            if (!userName || !userPassword || !ent_id) return;
            console.log(`--> ${userName} --> ${userPassword} `);

            data = {
                enterpriseId: ent_id,
                odooUser: userName,
                odooPassword: userPassword,
                login: login
            };
        }
        
        // Validar los parámetros

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        try {
            console.log(`Se está procesando el reporte`);
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/report-sales-costs`,
                headers: headers,
                data: data,
            });

            if(login){
                console.log(response);
                return response.data.data; // Devuelve los datos obtenidos
            }

            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
                `La descarga ha iniciado correctamente, puede visualizar su estatus en la vista de segundo plano`
            );
        } catch (err) {
            globalContext.setLoaderPage(false);
            console.error(err);
            globalContext.showModalWithMsg(
                `Ocurrió un error al descargar su archivo ${err}`
            );
        }
    };


    const getPriceReport = async (userName, userPassword, enterpriseInfo, odooMainRateInfo, odooSecondRateId, login, rate) => {

        let data;
        const ent_id= localStorage.getItem(keyUserActiveEnterpriseId);
        if(!login && !rate){ //Generar reporte
            
            if (!userName || !userPassword || !enterpriseInfo || !ent_id || !odooMainRateInfo || !odooSecondRateId) return;

            console.log(`--> ${userName} --> ${userPassword} ---> ${enterpriseInfo}  ---> ${odooMainRateInfo} ---> ${odooSecondRateId}`);
            globalContext.setLoaderPage(true);

            const enterprise = {
                id: enterpriseInfo.value,
                name: enterpriseInfo.label,
            };

            data = {
                enterpriseId: ent_id,
                odooEnterpriceInfo: enterprise,
                odooUser: userName,
                odooPassword: userPassword,
                login: login,
                rate: rate,
                odooMainRateInfo: odooMainRateInfo,
                odooSecondRateId: odooSecondRateId
            };
        }
        else if(login && !rate){    //Login

            if (!userName || !userPassword || !ent_id) return;
            console.log(`--> ${userName} --> ${userPassword} `);

            data = {
                enterpriseId: ent_id,
                odooUser: userName,
                odooPassword: userPassword,
                login: login,
                rate: rate,
            };
        }
        else if(!login && rate){    //fetch rates
            if (!userName || !userPassword || !enterpriseInfo || !ent_id ) return;

            console.log(`--> ${userName} --> ${userPassword} ---> ${enterpriseInfo}`);


            const enterprise = {
                id: enterpriseInfo.value,
                name: enterpriseInfo.label,
            }; 

            data = {
                enterpriseId: ent_id,
                odooEnterpriceInfo: enterprise,
                odooUser: userName,
                odooPassword: userPassword,
                login: login,
                rate: rate,
            };
        }

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        try {
            console.log(`Se está procesando el reporte`);
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-report/report-product-pricelist`,
                headers: headers,
                data: data,
            });

            if((!login && rate) || (login && !rate) ){
                console.log(response);
                return response.data.data; // Devuelve las empresas o las tarifas
            }

            globalContext.setLoaderPage(false);
            globalContext.showModalWithMsg(
                `La descarga ha iniciado correctamente, puede visualizar su estatus en la vista de segundo plano`
            );
        } catch (err) {
            globalContext.setLoaderPage(false);
            console.error(err);
            globalContext.showModalWithMsg(
                `Ocurrió un error al descargar su archivo`
            );
        }
    };



    return { getInventoryReport, getPriceReport};
};

export default useRcReport;