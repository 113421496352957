import styled from "styled-components";
const ListVariantStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
  .list-variant {
    background-color: white;
    border-top: 1px solid #e5e9f2;
    visibility: visible;
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    // min-height: 300px !important;
    max-height: 300px !important;
    // height: 300px !important;
    overflow: hidden scroll;
    margin-top: 5px;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(94, 84, 142);
      border-radius: 50px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .nv {
    color: rgb(94, 84, 142);
  }
  .dv {
    color: gray;
  }
  .nv,
  .dv {
    margin-bottom: 0.2rem;
    font-size: 10pt;
    font-family: "Roboto Slab", serif !important;
  }
  .imgVariant {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
    border-radius: 10px !important;
  }
`;
export default ListVariantStyle;
