
import styled, {keyframes } from 'styled-components';

const colorPrimario = '#7367f0'
const colorSecundario = '#F8F8F8'

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0; /* Comienza oculto */
  }
  100% {
    transform: translateX(0);
    opacity: 1; /* Termina visible */
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0; /* Comienza oculto */
  }
  100% {
    transform: translateX(0);
    opacity: 1; /* Termina visible */
  }
`;

const slideInFromLeftNoOpacity = keyframes`
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideInFromRightNoOpacity = keyframes`
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
`;


const fadeIn = keyframes`
  0% {
    opacity: 0; /* Comienza completamente transparente */
  }
  100% {
    opacity: 1; /* Termina completamente visible */
  }
`;


const Body = styled.div`
  //overflow-x: hidden;
  height: 100vh; /* Asegúrate de tener una altura definida */

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colorPrimario};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Cont = styled.div`
width:95%;
display:  flex;
flex-direction: row;
justify-content: space-between;
align-items:center;
margin: 0 auto;
`;

const ContTopBar = styled.div`
background-image: linear-gradient(to right, ${colorSecundario} , ${colorPrimario});
padding: 15px 0;
`;

const ContLogo = styled.div`
display:  flex;
flex-direction: row;
justify-content: space-between;
align-items:center;
gap: 10px;
color: #000000;
font-size: large;

@media (max-width: 576px) {
  margin: 0 auto;
}
`;

const ContLogoImg = styled.div`
width: 50px;
height: 50px;
background-image: url(${props => props.url || ''});
background-position: center;
background-size: 100%;
background-repeat: no-repeat;
`;

const BtnIniciarTopBar = styled.button`
outline: none;
border: none;
border-color: ${colorPrimario};
width:75px;
height: 38px;
color: #000000;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: ${colorSecundario};
padding: .150rem;
border-radius: 0.358rem;

&:hover {
  color: ${colorPrimario}; 
  transition: color 0.3s ease; 
}
`;

const ContTitle = styled.div`
background-image: url('/Banner.png');
background-repeat: no-repeat;
background-size: cover;
background-position:  center;

@media (max-width: 1224px) {
  background-position:  95% 50%;;
}

@media (max-width: 576px) {
  background-position:  left;
}

`;

const ContTitleFlex = styled.div`
display:  flex;
flex-direction: column;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 120px 0;
width:95%;

@media (max-width: 1224px) {
  align-items:flex-start;
}

@media (max-width: 576px) {
  align-items:center;
}
`;


const Title = styled.h1`
width:75%;
text-align: center;
font-size: 125px;
font-weight: bold;

@media (max-width: 1224px) {
  text-align: start;
  margin-left: 15px;
}

@media (max-width: 576px) {
  text-align: center;
  font-size: 80px;
  margin-left: 0;
}
`;

const SubTitle = styled.p`
width:75%;
text-align: center;
font-size: xx-large;
font-weight: bold;
margin: 0;

@media (max-width: 1224px) {
  text-align: start;
  max-width: 50%;
  margin-left: 35px;
}

@media (max-width: 576px) {
    text-align: center;
    font-size: large;
    margin-left: 0;
}
`;

const BtnIniciar = styled.button`
outline: none;
border: none;
border-color: ${colorPrimario};
width:25%;
height: 38px;
color: white;
font-size: 12pt;
font-weight: 500;
text-align: center;
background-color: ${colorPrimario};
padding: .150rem;
border-radius: 0.358rem;
margin-top: 1rem;

&:hover {
        color: #000000; 
        transition: color 0.3s ease; 
}

@media (max-width: 1224px) {
  margin-left: 45px;
}
@media (max-width: 576px) {
  margin-left: 0;
}
`;




const ContMision = styled.div`
display:  flex;
flex-direction: column;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 75px 0;
background: ${colorPrimario};
color: #FFFFFF;

`;

const MisionTitulo = styled.h2`
width:75%;
text-align: center;
font-size: 2.5rem;
font-weight: bold;
padding-bottom: 15px;
margin: 0;
`;

const MisionDescripcion = styled.p`
width:75%;
text-align: center;
font-size: 1.3rem;
line-height: 1.4rem;
margin: 0;

position: relative;
animation: ${props => (props.isVisible ? fadeIn : 'none')} 1s ease-out forwards;
`;



const ContOperatividad = styled.div`
display:  flex;
flex-direction: column;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 90px 0;
background: ${colorSecundario};
`;

const ContOperatividadTitulo = styled.h2`
width:95%;
font-size: 2.5rem;
font-weight: bold;
padding-bottom: 25px;
text-align: center;
margin: 0;
margin-bottom: 25px;
`;

const ContOperatividadModulos = styled.div`
width:95%;
display:  grid;
grid-template-columns: 1fr 1fr;
align-items:start;
gap: 30px;
column-gap: 50px;

@media (max-width: 768px) {
    width:90%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
}
`;

const ContOperatividadModulosDerecha = styled.div`
    animation: ${props => (props.isVisible ? slideInFromRight : 'none')} 1s ease-out forwards;
`;

const ContOperatividadModulosIzquierda = styled.div`
    animation: ${props => (props.isVisible ? slideInFromLeft : 'none')} 1s ease-out forwards;
`;


const ContOperatividadModulo = styled.div`
display:  flex;
flex-direction: row;
align-items:start;
gap: 10px;

animation: ${props => (props.isVisible ? slideInFromLeft : 'none')} 1s ease-out forwards;

@media (max-width: 576px) {
    gap: 30px;
    align-items:center;
}
`;

const OperatividadImg = styled.div`
display:flex;
justify-content: center;
margin: 0 auto;
min-width: 150px;


@media (max-width: 768px) {
    min-width: 100px;
}

@media (max-width: 576px) {
    min-width: 50px;
}
`;


const OperatividadTitulo = styled.h3`
font-size: 1.4rem;
font-weight: bold;
margin: 0;
`;

const OperatividadDescripcion = styled.p`
text-align: justify;
max-width: 90%;
min-height: 100px;
margin: 0;

font-size: 1.1rem;
line-height: 1.4rem;
margin-top: 5px;
`;



const ContOfrece = styled.div`
display:  flex;
flex-direction: column;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 80px 0;
background: ${colorPrimario};
color: #FFFFFF;
`;

const ContOfreceTitulo = styled.h2`
width:95%;
font-size: 2.5rem;
font-weight: bold;
padding-bottom: 25px;
text-align: center;
`;



const OfreceGrid = styled.div`
width:85%;
display:  grid;
grid-template-columns: repeat(6, 1fr);
align-items:center;
row-gap: 30px;
position: relative;
animation: ${props => (props.isVisible ? slideInFromLeft : 'none')} 1s ease-out forwards;

@media (max-width: 576px) {
    display:  flex;
    flex-direction: column;
}
`;

// Componente OfreceGrid01 con la animación
const OfreceGridElemento = styled.div`
  grid-column: ${props => props.grid_column};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const OfreceGridSubtitulo = styled.h3`
font-weight: bold;
font-size: 1.4rem;
font-weight: bold;
margin: 0;
`;

const OfreceGridDescripcion = styled.p`
font-size: 1.1rem;
line-height: 1.4rem;
margin-top: 5px;
`;

const OfreceGridNumber = styled.p`
font-size: 75px;
margin: 0;
@media (max-width: 576px) {
    font-size: 40px;
}
`;



const ContVentajas = styled.div`
display:  flex;
flex-direction: column;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 70px 0;
background: ${colorSecundario};
color: #000000;
`;

const ContVentajasTitulo = styled.h2`
width:75%;
text-align: center;
font-size: 2.5rem;
font-weight: bold;
padding-bottom: 15px;
margin: 0;
margin-bottom: 20px;

@media (max-width: 576px) {
    padding-bottom: 30px;
}
`;



const VentajasGrid = styled.div`
width:85%;
display:  grid;
grid-template-columns: repeat(2, 1fr);
align-items:center;
gap: 50px;
`;

const Ventajas = styled.div`
grid-column: 1 / 2;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content: center;
gap: 30px;
position: relative;

animation: ${props => (props.isVisible ? slideInFromLeft : 'none')} 1s ease-out forwards;

@media (max-width: 768px) {
    grid-column: 1 / 3; 

}
`;

const Ventaja = styled.div`
display:  flex;
flex-direction: row;
align-items:center;
justify-content: flex-start;
gap: 0px;
text-align: justify;


@media (max-width: 768px) {
    gap: 0px;

}
@media (max-width: 576px) {
    gap: 0px;
}
`;


const VentajasGrid04 = styled.div`
grid-column: 2 / 3;

display:  flex;
flex-direction: column;
align-items:center;


@media (max-width: 768px) {
    grid-column: 1 / 3; 
    grid-row: auto;
}

@media (max-width: 576px) {
    grid-column: 1 / 3; 
    grid-row: auto;
}

`;

const VentajaImg = styled.div`
display:flex;
justify-content: center;
align-items: center;
margin: 0 auto; 
min-width: 150px;

@media (max-width: 768px) {
    min-width: 125px;
}

@media (max-width: 576px) {
    min-width: 100px;
}

`;

const ContVentajaTitulo = styled.h3`
font-size: 1.4rem;
font-weight: bold;
margin: 0;
`;

const ContVentajaDescripcion = styled.p`
font-size: 1.1rem;
line-height: 1.4rem;
margin-top: 5px;
`;

const VentajasKAPI = styled.div`
display:  flex;
flex-direction: row;
align-items:center;
gap: 20px;
`;

const VentajasKAPITitulo = styled.p`
font-size: 50px;
font-weight: bold;
`;

const VentajasKAPITexto = styled.p`
font-size: 1.4rem;
font-weight: bold;
text-align: center;
color: ${colorPrimario};
margin: 0;
`;



const ContEstadisticas = styled.div`
display:  flex;
flex-direction: row;
justify-content: space-evenly;
align-items:center;
gap: 10px;
margin: 0 auto;
padding: 50px 0;
background: ${colorPrimario};
color: #FFFFFF;
@media (max-width: 576px) {
    flex-direction: column;
    gap: 30px;
}
`;


const ContEstadistica = styled.div`
display:  flex;
flex-direction: row;
gap: 10px;
justify-content: center;
align-items:center;

line-height: 1.4rem;
margin: 0;
`;


const EstadisticaTexto = styled.p`
text-align: center;

font-weight: bold;
margin: 0;
font-size: 1.2rem;
`;

const Estadistica100 = styled.p`
text-align: center;

font-weight: bold;
margin: 0;
font-size: 2.5rem;
`;



const ContClientes = styled.div`
display:  flex;
flex-direction: column;
gap: 35px;
margin: 0 auto;
padding: 85px 0;
background: ${colorSecundario};
text-align: center;

@media (max-width: 576px) {
padding-bottom: 130px;
}

`;

const ClientesTitulo = styled.h2`
font-size: 2.4rem;
font-weight: bold;
margin: 0;
`;



const ClientesSlider = styled.div`
width:50%;
display:  flex;
flex-direction: row;
align-items:center;
justify-content: center;
gap: 10px;
margin: 0 auto;  
`;


const Clientes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 75px;
    justify-content: space-evenly;
    
`;



const Cliente = styled.div`
    width: 125px;
    height: 125px;
    background-image: url(${props => props.url || ''});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

    transition: transform 0.25s ease-in-out; /* Transición suave */
    
    &.slide-left {
        animation: ${slideInFromLeftNoOpacity} 0.25s forwards; /* Animación al ir a la izquierda */
    }

    &.slide-right {
        animation: ${slideInFromRightNoOpacity} 0.25s forwards; /* Animación al ir a la derecha */
    }
`;


const ClientesBtn = styled.div`
    display:flex;
    align-items:center;
    width: 125px;
    height: 125px;
    background-color: ${colorSecundario}; 
    padding-inline: 50px;
    position: relative; 
    z-index: 9999;
`;



const ContFooter = styled.div`
    display:  flex;
    flex-direction: column;
    align-items:center;
    gap: 10px;
    margin: 0 auto;
    padding: 15px 0;
    background: ${colorPrimario};
    color: #FFFFFF;
`;



const ContScrollToTop = styled.div`
  position: fixed;
  bottom: 40px; /* Distancia desde el fondo */
  right: 25px;  /* Distancia desde la derecha */
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  cursor: pointer;
`;

const LandingPageStyle = {

    Body,

    Cont,
    ContTopBar,
    ContLogo,
    ContLogoImg,
    BtnIniciarTopBar,

    ContTitle,
    ContTitleFlex,
    Title,
    SubTitle,
    BtnIniciar,

    ContMision,
    MisionTitulo,
    MisionDescripcion,

    ContOperatividad,
    ContOperatividadModulos,
    ContOperatividadModulosDerecha,
    ContOperatividadModulosIzquierda,
    ContOperatividadTitulo,
    ContOperatividadModulo,
    OperatividadImg,
    OperatividadTitulo,
    OperatividadDescripcion,

    ContOfreceTitulo,
    ContOfrece,
    OfreceGrid,
    OfreceGridElemento,
    OfreceGridSubtitulo,
    OfreceGridDescripcion,
    OfreceGridNumber,

    ContVentajas,
    ContVentajasTitulo,
    VentajasGrid,
    Ventajas,
    Ventaja,
    VentajasGrid04,
    VentajaImg,
    ContVentajaTitulo,
    ContVentajaDescripcion,
    VentajasKAPI,
    VentajasKAPITitulo,
    VentajasKAPITexto,

    ContEstadisticas,
    ContEstadistica,
    EstadisticaTexto,
    Estadistica100,

    ContClientes,
    ClientesTitulo,
    ClientesSlider,
    Clientes,
    Cliente,
    ClientesBtn,


    ContFooter,

    ContScrollToTop
}

export default LandingPageStyle;