import React, { useContext, useState, useEffect } from "react";
import { Form, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserEnterpriseNewStyle from "../../../assets/css/components/admin/enterprise/new/UserEnterpriseNewStyle.css";
import { Link } from "react-router-dom";
import {
  keyUserId,
  keyAccessToken,
} from "../../../assets/js/SessionStorageNames";
import ModalMsm from "../../general/ModalMsm";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import Routes from "../../../Routes";
import verifyAccessRole from "../../../assets/js/verifyAccessRole";
import ConfigStyled from "../../../assets/css/components/admin/config/ConfigStyled";
import ToggleSwitch from "../../general/ToggleSwitch";

export default function UserEnterpriseUpdate({ match }) {
  /**
   * * id enterprice
   */
  const globalContext = useContext(GlobalContext);
  const enterpriceId = match.params.id;
  const [redirect, setRedirect] = useState(null);
  const axios = require("axios");
  const [name, setName] = useState("");
  const [tlf, setTlf] = useState("");
  const [direccion, setDireccion] = useState("");
  const [responsable, setResponsable] = useState("");
  const [parent, setParent] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [loading, setLoading] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [showedBrands, setShowedBrands] = useState([]);
  const [brandSearch, setBrandSearch] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([])
  const [enableBrands, setEnableBrands] = useState(true);
  /**
   * session storage
   */

   useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 52);
    if(!hasAccess) {
        setRedirect(
            <Redirect to={`/admin/dashboard`} />
        )
    }
  },[]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/${enterpriceId}`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    })
      .then((res) => {
        res.data.data?.brands === null ? setEnableBrands(false) : setEnableBrands(true); 
        setSelectedBrands(res.data.data?.brands || []);
        setName(res.data.data.name);
        setTlf(res.data.data.tlf);
        setDireccion(res.data.data.direccion);
        setResponsable(res.data.data.responsable);
        setParent(res.data.data.parentId && res.data.data.parentId.name);
      })
      .catch((err) => {
        setError(err.response?.data?.message);
        setShow(true);
      });
    //eslint-disable-next-line
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise/${enterpriceId}`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
      data: {
        name: name,
        tlf: tlf,
        direccion: direccion,
        brands: enableBrands ? selectedBrands : null,
      },
    })
      .then((res) => {
        setError(res.data.message);
        globalContext.reloadNavEnterpriseOptions();
        setShow(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setShow(true);
      });
  };

  function handleEnableBrands({ target: { checked } }) {
    setEnableBrands(checked);
  }
  
  /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
  };

  /**
     * function to get the brands from odoo
     * @returns 
     */
  async function getBrands() {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    
    setLoading(true);

    await axios
        .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}/existing-brands`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        )
        .then(({ data: { data: { brands } } }) => {
            setBrandsList(brands);
            setShowedBrands(brands);
        })
        .catch((err) => {
            console.error("err: ",err)
            handleAxiosError(
                err,
                "Disculpe, no se pudo consultar las marcas."
            );
        }).finally(()=>{
            setLoading(false);
        });
  }

  async function searchBrandHandler(text) {
    const regex = new RegExp(text, "i");
    const resultado = brandsList.filter((elemento) => regex.test(elemento));
    setShowedBrands(resultado);
    setBrandSearch(text);
  }

  // function to select or deselect brands
  function handleBrand(value) {
    const indexVal = selectedBrands.indexOf(value);
    const brandsSelected = indexVal === -1 ? [...selectedBrands, value] : selectedBrands.slice(0, indexVal).concat(selectedBrands.slice(indexVal + 1));
    setSelectedBrands(brandsSelected);
  }
  useEffect(() => {
    getBrands();
  },[globalContext.currentEnterpriseId])
  
  return (
    <UserEnterpriseNewStyle>
      {redirect}
      <ModalMsm
        show={show}
        error={error}
        handleClose={handleClose}
        txtButton="Cerrar"
      />
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-left mt-3 align-items-center content-breadcrumb"
        >
          <div className="goBack d-flex justify-content-start mt-2">
            <Link to="/admin/enterprise/das-enterprise">
              <button className="btn-back">
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </Link>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex justify-content-center"
        >
          <div className="EnterPriceFormBody">
            <Form onSubmit={submitHandler}>
              <Container fluid>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Empresa Padre</Form.Label>
                      <br/><Form.Label>{ parent || "Ninguna" }</Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        placeholder="Nombre de la empresa"
                        name="nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Telefono</Form.Label>
                      <Form.Control
                        placeholder="Telefono de la empresa"
                        name="telefono"
                        value={tlf}
                        onChange={(e) => setTlf(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Group>
                      <Form.Label>Reponsable</Form.Label>
                      <Form.Control
                        placeholder="Responsable de la empresa"
                        name="responsable"
                        value={responsable}
                        onChange={(e) => setResponsable(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Group>
                      <Form.Label>Direccion</Form.Label>
                      <Form.Control
                        placeholder="Direccion de la empresa"
                        name="direccion"
                        value={direccion}
                        onChange={(e) => setDireccion(e.target.value)}
                      ></Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>
                      Marcas habilitadas: {!enableBrands ? "Sin permisos" : selectedBrands.length < 1 ? "TODAS" : "("+selectedBrands.length+")"}
                    </Form.Label>
                  </Col>
                  <Col>
                    <ToggleSwitch
                      size='md'
                      onChange={handleEnableBrands}
                      checked={enableBrands}
                      showState={true}
                      stateTexts={["Habilitado", "Inhabilitado"]}
                    />
                  </Col>
                </Row>
                <Row>
                    <ConfigStyled.ContInputAndLabel>
                        <Form.Group
                            as={Col}
                            controlId='my_multiselect_field'
                        >
                            <Form.Control
                                disabled={loading}
                                type='text'
                                placeholder='Buscar Marca'
                                value={brandSearch}
                                onChange={(e) =>
                                    searchBrandHandler(
                                        e.target.value
                                    )
                                }
                            />
                            {enableBrands && !loading && 
                              <Form.Control
                                  as='select'
                                  multiple
                                  onClick={e => handleBrand(e.target.value)}
                                  value={selectedBrands}
                              >
                                  {showedBrands.map((v) => (
                                      <option value={v} key={v}>
                                          {v}
                                      </option>
                                  ))}
                              </Form.Control>
                            }

                            {selectedBrands.join(", ")}
                        </Form.Group>
                    </ConfigStyled.ContInputAndLabel>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <button className="btn-save-enterprise">Guardar</button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </Col>
      </Row>
    </UserEnterpriseNewStyle>
  );
}
