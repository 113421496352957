import React, { useContext, useState, useEffect } from "react";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
} from "../../../../assets/js/SessionStorageNames";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
    Form,
    Col,
    Row,
    InputGroup,
    Container,
    Nav,
    Card,
} from "react-bootstrap";
import Routes from "../../../../Routes";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import { Button } from "../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { BiX } from "react-icons/bi";

export default function FalabellaConfig() {
    const [usuario, setUsuario] = useState("");
    const [redirect, setRedirect] = useState(null);
    const [apiKey, setApiKey] = useState("");
    const [step, setStep] = useState(0);
    const globalContext = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({});

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 66);
        if (!hasAccess || globalContext.parentEntId) {
            setRedirect(<Redirect to={`/admin/dashboard`} />);
        }
    }, [globalContext.parentEntId]);

    useEffect(() => {
        vaciarCampos();
        if (!globalContext.currentEnterpriseId) {
            setStep(0);
        } else {
            setStep(1);
        }
    }, [globalContext.currentEnterpriseId]);

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/fala-acc/flUser`,
                {
                    enterpriseId: entId,
                    usuario: usuario,
                    apiKey: apiKey,
                },
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then((res) => {
                console.log(res);
                vaciarCampos();
                globalContext.showModalWithMsg("Datos guardados exitosamente!");
                setLoading(false);
            })
            .catch((err) => {
                handleAxiosError(err);
                setLoading(false);
            });
    };
    //todo traer los datos

    const userView = () => {
        return (
            <>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Container fluid>
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <Card
                                        className='my-5 '
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={7}
                                    >
                                        <Card.Body>
                                            <Card.Title className='title text-center'>
                                                Rellene los siguientes campos
                                                para el plugin de Falabella
                                            </Card.Title>
                                            <hr />
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Form.Group>
                                                        <label
                                                            for='usuario'
                                                            className='mb-1'
                                                        >
                                                            ID de usuario
                                                        </label>
                                                        <InputGroup className='mb-3'>
                                                            <Form.Control
                                                                disabled={
                                                                    loading &&
                                                                    true
                                                                }
                                                                placeholder='Usuario'
                                                                id='formContacInput-three'
                                                                name='usuario'
                                                                value={usuario}
                                                                onChange={(e) =>
                                                                    setUsuario(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Form.Group>
                                                        <label
                                                            for='apiKey'
                                                            className='mb-1'
                                                        >
                                                            API Key
                                                        </label>
                                                        <InputGroup className='mb-3'>
                                                            <Form.Control
                                                                disabled={
                                                                    loading &&
                                                                    true
                                                                }
                                                                placeholder='ApiKey'
                                                                id='formContacInput-three'
                                                                name='apiKey'
                                                                value={apiKey}
                                                                onChange={(e) =>
                                                                    setApiKey(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className='footerFormOdoo'
                    >
                        <button
                            className='download-tarifa'
                            onClick={(e) => {
                                submitHandler(e);
                            }}
                            disabled={loading && true}
                        >
                            Guardar
                        </button>
                    </Col>
                </Row>
            </>
        );
    };

    function getFalabellaOrders() {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            setNotification({
                msg: "Debe seleccionar una empresa",
                error: true,
            });
            return;
        }
        setLoading(true);

        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/fala-orders/orders/${entId}`,
                {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                }
            )
            .then(({ data }) => {
                setLoading(false);
                console.log(data);
                globalContext.showModalWithMsg(
                    `El proceso ha iniciado correctamente: ${data.message}`
                );
            })
            .catch((err) => {
                console.error("ERROR: ", err.response);
                handleAxiosError(err);
                setLoading(false);
                setNotification({
                    msg: `Se ha producido un error al obtener las ordenes de Falabella ${err.response?.data?.message}`,
                    error: true,
                });
            });
    }

    /**
     * function to do the products acction
     * receiving a string to change the url direction
     * @param {*} url
     * @returns
     */
    function falabellaActions(url) {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) {
            setNotification({
                msg: "Debe seleccionar una empresa",
                error: true,
            });
            return;
        }
        setLoading(true);

        const direction = `${
            process.env.REACT_APP_BACKEND_URL
        }/v1/fala-product/${url}${url === "downProducts" ? "/" + entId : ""}`;

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };

        const consulta = url === "downProducts" ? 
            axios.get(direction, { headers }) : 
            axios.post(direction,{ entId: globalContext.currentEnterpriseId }, { headers })

        consulta.then(({ data }) => {
                setLoading(false);
                console.log(data);
                globalContext.showModalWithMsg(
                    `El proceso ha iniciado correctamente: ${data.message}`
                );
            })
            .catch((err) => {
                console.error("ERROR: ", err.response);
                handleAxiosError(err);
                setLoading(false);
                setNotification({
                    msg: `Se ha producido un error al ${
                        url === "downProducts"
                            ? "descargar y sincronizar los"
                            : url === "update-prices"
                            ? "actualizar los precios de los"
                            : ""
                    } productos de Falabella ${err.response?.data?.message}`,
                    error: true,
                });
            });
    }

    function vaciarCampos() {
        setUsuario("");
        setApiKey("");
    }

    const productsView = () => {
        return (
            <Row>
                <Col className='d-flex justify-content-center'>
                    <Container fluid>
                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <Card
                                    className='my-5 '
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={11}
                                >
                                    <Card.Body>
                                        <Card.Title className='title text-center'>
                                            Falabella
                                        </Card.Title>
                                        <hr />
                                        <Row>
                                            <Col
                                                className='mb-3'
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <p className='mb-1'>
                                                    Descargar y Sincronizar
                                                    Productos de Falabella
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        falabellaActions(
                                                            "downProducts"
                                                        )
                                                    }
                                                    disabled={loading && true}
                                                    width='100%'
                                                >
                                                    Descargar y Sincronizar
                                                </Button>
                                            </Col>
                                            <Col
                                                className='mb-3'
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <p className='mb-1'>
                                                    Actualizar Precios y Stock de Falabella
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        falabellaActions(
                                                            "update-prices"
                                                        )
                                                    }
                                                    disabled={loading && true}
                                                    width='100%'
                                                >
                                                    Actualizar
                                                </Button>
                                            </Col>
                                            <Col
                                                className='mb-3'
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <p className='mb-1'>
                                                    Obtener Ordenes de Falabella
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        getFalabellaOrders()
                                                    }
                                                    disabled={loading && true}
                                                    width='100%'
                                                >
                                                    Obtener
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        );
    };

    const showedView = () => {
        switch (step) {
            case 0:
                return (
                    <Container>
                        <Row>
                            <Col className='d-flex justify-content-center mt-5 pt-5'>
                                <h5 className='text-danger'>
                                    Debe seleccionar una empresa.
                                </h5>
                            </Col>
                        </Row>
                    </Container>
                );
            case 1:
                return userView();
            case 2:
                return productsView();
            default:
                return userView();
        }
    };

    function changeStep(number) {
        if (step !== 0) {
            setStep(number);
            vaciarCampos();
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setNotification({});
        }, 5000);
    }, [notification]);

    return (
        <>
            <PedidosYaStyle.ContBadgeError
                active={notification?.msg && true}
                error={notification.error}
            >
                <PedidosYaStyle.Text size='.875rem' cl='#FFFFFF'>
                    {notification.msg}
                </PedidosYaStyle.Text>
                <PedidosYaStyle.BtnToggleBadge
                    onClick={(e) => setNotification({})}
                >
                    <BiX />
                </PedidosYaStyle.BtnToggleBadge>
            </PedidosYaStyle.ContBadgeError>
            <OdooStyle>
                {redirect}
                <div className='goBack d-flex justify-content-start mt-2'>
                    <div className='row'>
                        <div className='col-2'>
                            <Link to='/admin/plugins/my-plugins/my-menu-plugins'>
                                <button className='btn-back'>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                            </Link>
                        </div>
                        <div className='col'>
                            <Nav variant='pills' defaultActiveKey='configurar'>
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={() => changeStep(1)}
                                        eventKey={
                                            loading || step === 0
                                                ? "disabled"
                                                : "configurar"
                                        }
                                        disabled={
                                            loading || (step === 0 && true)
                                        }
                                    >
                                        Usuario
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={() => changeStep(2)}
                                        eventKey={
                                            loading || step === 0
                                                ? "disabled"
                                                : "app"
                                        }
                                        disabled={
                                            loading || (step === 0 && true)
                                        }
                                    >
                                        Productos
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </div>
                {showedView()}
            </OdooStyle>
        </>
    );
}
