import React, { useContext, useEffect, useState } from 'react'
import ListOfProductsToSetParams from '../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/ListOfProductsToSetParams'
import { GlobalContext } from '../../../../../../contexts/GlobalContext'

export default function ListProductsToSetParams({ sendToFormData, paramsToSend, setParamsToSend }) {
    const globalContext = useContext(GlobalContext);
    const [isOn, setIsOn] = useState(0); 
    const removeProduct = (currentPosition) => {
        const updatedList = [...paramsToSend];
        updatedList.splice(currentPosition, 1);
        const updatedParams = globalContext.listProductsToSend.filter((_, index) => index !== currentPosition);
        globalContext.setListProductsToSend(updatedParams);
        setParamsToSend(updatedList)
       
    };

    return (
        <ListOfProductsToSetParams.ContListProd>
            <ListOfProductsToSetParams.ListProduts>
                {
                    globalContext.listProductsToSend.length > 0 &&
                    globalContext.listProductsToSend.map((v, i) => {
                        return (
                            <ListOfProductsToSetParams.ItemsListProduts
                                key={v._id}
                                active={isOn === i}
                                url={v.thumbnail}
                                load={false} 
                                onClick={() => {
                                    sendToFormData(v, i);
                                    setIsOn(i)
                                }
                            }
                                >
                                    <ListOfProductsToSetParams.Close onClick={()=> removeProduct(i)}></ListOfProductsToSetParams.Close>
                            </ListOfProductsToSetParams.ItemsListProduts>
                        )
                    })
                }
                
                
            </ListOfProductsToSetParams.ListProduts>
        </ListOfProductsToSetParams.ContListProd>
    )
}
