/* eslint-disable no-loop-func */
import React, { useState, useEffect, useContext, useRef } from 'react';
import MultiScraperStyles from "../../../../assets/css/components/admin/products/amazon/MultiScraperStyles";
import Pager from "../../../general/Pager";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import axios from "axios";
import FilterCategoriesAmazon from './FilterCategoriesAmazon';
import {
    keyUserId,
    keyAccessToken,
    produtsUpdateAndPublish
} from "../../../../assets/js/SessionStorageNames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTasks, faGripHorizontal, faGripLines, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';
import CardComponent from './components/CardComponent';
import CardErrorComponent from './components/CardErrorComponent';
import Routes from '../../../../Routes';
import verifyAccessRole from '../../../../assets/js/verifyAccessRole';
import sendProductWoo from './functions/woocomerce/sendProductWoo';
import Select from '../../../general/Select';
import CategoriesList from './components/CategoriesList';
import CardComponentAli from './components/CardComponentAli';
import CardComponentCron from './components/CardComponentCron';
import DateTime from '../../cron-reports/components/DateTime';
function HistoryProductsCbt() {
    const skuInput = useRef(null)
    const countInput = useRef(null)
    const inputEl = useRef(null);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const globalContext = useContext(GlobalContext);
    const [multiSave, setMultiSave] = useState([]);
    const [itemsAdds, setItemsAdds] = useState([]);
    const [multiDelete, setMultiDelete] = useState([]);
    const [multiDeleteWithoutError, setMultiDeleteWithoutError] = useState([]);
    const [itemsAddsByDelete, setItemsAddsByDelete] = useState([]);
    const [itemsAddsByDeleteWithOutError, setItemsAddsByDeleteWithOutError] = useState([]);
    const [asin, setAsin] = useState('');
    const [filter, setFilter] = useState('');
    const [leng, setLang] = useState('en');
    const [pageItemCount, setPageItemCount] = useState(10);
    const [filterByStock, setFilterByStock] = useState(1);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [productos, setProductos] = useState(null);
    const [styleGridCard, setStyleGridCard] = useState(1);
    const [changeToRow, setChangeToRow] = useState(false);
    const [valueInputFilter, setValueInputFilter] = useState('');
    const [categories, setCategories] = useState('');
    const [valueInputFilterAsin, setValueInputFilterAsin] = useState('');
    const [valueInputItemCount, setValueInputItemCount] = useState('');
    const [findCategoryByiconBtn, setFindCategoryByiconBtn] = useState(false);
    const [currentCategorieText, setCurrentCategorieText] = useState('');
    const [redirect, setRedirect] = useState(null);
    const [openMenuOptionItem, setOpenMenuOptionItem] = useState('');
    const [mlPublis, setMlPublis] = useState(false);
    const [showErrorProd, setShowErrorProd] = useState(true);
    const [isDeleted, setIsDeleted] = useState([]);
    const [inDeleteProcess, setInDeleteProcess] = useState([]);
    const [isScrapingAgain, setIsScrapingAgain] = useState([]);
    const [itemsToUpdateAndPublish, setItemsToUpdateAndPublish] = useState([]);
    const [deliveryDaysProd, setDeliveryDaysProd] = useState(false);
    const [pluginToSearch, setPluginToSearch] = useState('AmazonScraper');
    const [cronSearch, setCronSearch] = useState('');
    const [mlActives, setMlActives] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [rescrap, setRescrap] = useState(true);
    const [rescrapedFromDate, setRescrapedFromDate] = useState('');
    const [rescrapedToDate, setRescrapedToDate] = useState('')
    const [hasErrorMsg, setHasErrorMsg] = useState(false)

    useEffect(() => {
        const hasAccess = verifyAccessRole(Routes, 37);
        if (!hasAccess) {
            setRedirect(
                <Redirect to={`/admin/dashboard`} />
            )
        }
    }, []);

    const setOpenMenuOptionItemFunc = (v) => {
        if (v === openMenuOptionItem) {
            setOpenMenuOptionItem('')
        } else {
            setOpenMenuOptionItem(v)
        }
    }
    const onButtonClick = (e) => {
        inputEl.current.value = '';
        setValueInputFilter('')
        setFilter('')
    };
    /**
     * @param {*} item objeto
     * @param {*} itemIndex index del objeto a pasar
     * ! este metodo nos sirve para agg los items q se guardaran masivamente
     */
    const addItem = (item, itemIndex) => {
        const isHere = itemsAdds.find((e) => (e === itemIndex));
        if (isHere === undefined) {
            setMultiSave([...multiSave, item]);
            setItemsAdds([...itemsAdds, itemIndex])
        } else {
            setMultiSave(multiSave.filter((e) => (e._id !== itemIndex)))
            setItemsAdds(itemsAdds.filter((e) => (e !== itemIndex)))
        }
    }
    const addItemByDeleteFunc = (itemId, index) => {
        const isHere = itemsAddsByDelete.find((e) => (e === itemId));
        if (isHere === undefined) {
            setMultiDelete([...multiDelete, {
                id: itemId,
                index: index
            }]);
            setItemsAddsByDelete([...itemsAddsByDelete, itemId])
        } else {
            setMultiDelete(multiDelete.filter((e) => (e._id !== itemId)))
            setItemsAddsByDelete(itemsAddsByDelete.filter((e) => (e !== itemId)))
        }
    }
    const addItemByDeleteWithOutErrorFunc = (itemId, index) => {
        const isHere = itemsAddsByDelete.find((e) => (e === itemId));
        if (isHere === undefined) {
            setMultiDeleteWithoutError([...multiDeleteWithoutError, {
                id: itemId,
                index: index
            }]);
            setItemsAddsByDeleteWithOutError([...itemsAddsByDeleteWithOutError, itemId])
        } else {
            setMultiDeleteWithoutError(multiDeleteWithoutError.filter((e) => (e._id !== itemId)))
            setItemsAddsByDeleteWithOutError(itemsAddsByDeleteWithOutError.filter((e) => (e !== itemId)))
        }
    }


    const deleteProductAxiosMethod = async (arrayIds, entId) => {
        let res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            data: {
                prodIds: arrayIds
            }
        })
        return res;
    }

    const deletePlusOfOne = async () => {
        const entId = globalContext.currentEnterpriseId;
        let idsByDelete = [];
        if (multiDelete.length > 0) {
            globalContext.setLoaderPage(true);
            multiDelete.forEach((v) => {
                idsByDelete.push(v.id);
            })
            await deleteProductAxiosMethod(idsByDelete, entId)
                .then((res) => {
                    globalContext.setLoaderPage(false);
                    setIsDeleted([...isDeleted, ...idsByDelete]);
                    globalContext.showModalWithMsg(
                        `Se eliminaron ${idsByDelete.length} productos!`
                    );
                    setMultiDelete([]);
                    setItemsAddsByDelete([])
                }).catch((err) => {
                    globalContext.setLoaderPage(false);
                    handleAxiosError(err, 'Presentamos un error #985!')
                    setMultiDelete([]);
                    setItemsAddsByDelete([]);
                })
        }
    }

    const deletePlusOfOneWithoutError = async () => {
        const entId = globalContext.currentEnterpriseId;
        let idsByDelete = [];
        if (multiDeleteWithoutError.length > 0) {
            globalContext.setLoaderPage(true);
            multiDeleteWithoutError.forEach((v) => {
                idsByDelete.push(v.id);
            })
            await deleteProductAxiosMethod(idsByDelete, entId)
                .then((res) => {
                    globalContext.setLoaderPage(false);
                    setIsDeleted([...isDeleted, ...idsByDelete]);
                    globalContext.showModalWithMsg(
                        `Se eliminaron ${idsByDelete.length} productos!`
                    );
                    setMultiDeleteWithoutError([]);
                    setItemsAddsByDeleteWithOutError([])
                }).catch((err) => {
                    globalContext.setLoaderPage(false);
                    handleAxiosError(err, 'Presentamos un error #985!')
                    setMultiDeleteWithoutError([]);
                    setItemsAddsByDeleteWithOutError([]);
                })
        }
    }
    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const entId = globalContext.currentEnterpriseId;
    useEffect(() => {
        if (!entId) return;
        //? validamos si es ali o amazon
        switch (pluginToSearch) {
            case 'AmazonScraper':
                if (leng !== '') {
                    globalContext.setLoaderPage(true);
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
                        params: {
                            pageNumber: currentPage,
                            pageItemCount: pageItemCount.length > 0 ? pageItemCount : 10,
                            filter: {
                                asin: asin,
                                title: filter,
                                lang: 'es',
                                categoriesIds: categories !== '' ? categories : globalContext.idCategoryFind,
                                publishedOnMl: mlPublis,
                                dataComplete: true,
                                maxDeliveryDays: deliveryDaysProd && 7,
                                rescrapedFromDate: rescrapedFromDate,
                                rescrapedToDate: rescrapedToDate,
                                stock: filterByStock
                            },
                        },
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    })
                        .then((r) => {
                            //console.log('data de los productos', r.data.data)
                            setProductos(r.data.data.products)
                            setTotal(r.data.data.totalProducts)
                            globalContext.setLoaderPage(false);
                        })
                        .catch((e) => { handleAxiosError(e, 'Presentamos un error al cargar el historial'); globalContext.setLoaderPage(false); })
                } else {
                    setProductos(null)
                    setTotal(1)
                }
                break;
            case 'Ali':
                if (leng !== '') {
                    globalContext.setLoaderPage(true);
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/aliexp-prod/${entId}/prod`, {
                        params: {
                            page: currentPage,
                            limit: pageItemCount.length > 0 ? pageItemCount : 16,
                            filter: {
                                title: filter,
                            },
                        },
                        cancelToken: axiosCancelTokenSource.token,
                        headers: {
                            "x-api-key": process.env.REACT_APP_API_KEY,
                            "x-access-token": localStorage.getItem(keyAccessToken),
                            "x-user-id": localStorage.getItem(keyUserId),
                        },
                    })
                        .then((r) => {
                            console.log('data de los productos ali', r.data.data)
                            setProductos(r.data.data.products)
                            setTotal(r.data.data.total)
                            globalContext.setLoaderPage(false);
                        })
                        .catch((e) => { handleAxiosError(e, 'Presentamos un error al cargar el historial'); globalContext.setLoaderPage(false); })
                } else {
                    setProductos(null)
                    setTotal(1)
                }

                break;
            case 'rescrapedHistory':
                console.log('====================================');
                console.log('rescrapedHistory');
                console.log('====================================');
                globalContext.setLoaderPage(true);
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amaz-prod/rescraped-history/${entId}`, {
                    headers: {
                        "x-api-key": process.env.REACT_APP_API_KEY,
                        "x-access-token": localStorage.getItem(keyAccessToken),
                        "x-user-id": localStorage.getItem(keyUserId),
                    },
                    params: {
                        pageNumber: currentPage,
                        pageItemCount: pageItemCount.length > 0 ? pageItemCount : 16,
                        filter: {
                            asin: asin,
                            published: mlPublis,
                            cron: cronSearch,
                            active: mlActives === 'false' ? false : mlActives,
                            fromDate: fromDate,
                            toDate: toDate,
                            hasErrorMsg: hasErrorMsg

                        }
                    }
                }).then(resp => { setTotal(resp.data.data.total); console.log(resp.data.data.data); setProductos(resp.data.data.data); globalContext.setLoaderPage(false); }).catch(err => { handleAxiosError(err); globalContext.setLoaderPage(false); });
                break
            default:
                break;
        }
        console.log(filterByStock, 'filterByStock')
    }, [currentPage, filter, asin, leng, entId, pageItemCount, categories, mlPublis, globalContext.idCategoryFind, showErrorProd, deliveryDaysProd, pluginToSearch, cronSearch, mlActives, fromDate, toDate, rescrap, rescrapedFromDate, rescrapedToDate, filterByStock]);

    const selectAll = async (item) => {
        let filterinProducts = [];
        console.log('itemsss', item);
        await item.forEach((t) => {
            if (t.variants && t.variants.length > 0) {
                if ((t.variants[0].price !== 0 || t.variants[0].dealPrice !== 0) && t.variants[0].weight !== 0 && t.variants[0].dimensions && t.variants[0].images.length > 0 && !t.variants[0].ml) {
                    filterinProducts.push(t)
                }
            } else {
                if (t.data && (t.data.amazonProductNew || t.data.amazonProductOriginal)) {

                    if (t.data.amazonProductNew) {
                        if ((t.data.amazonProductNew.price !== 0 || t.data.amazonProductNew.dealPrice !== 0) && t.data.amazonProductNew.weight !== 0 && t.data.amazonProductNew.dimensions && t.data.amazonProductNew.images.length > 0 && !t.data.amazonProductNew.ml && !t.errorMessage) {
                            filterinProducts.push(t.data.amazonProductNew)
                        }
                    } else if ((t.data.amazonProductOriginal.price !== 0 || t.data.amazonProductOriginal.dealPrice !== 0) && t.data.amazonProductOriginal.weight !== 0 && t.data.amazonProductOriginal.dimensions && t.data.amazonProductOriginal.images.length > 0 && !t.data.amazonProductOriginal.ml && !t.errorMessage) {
                        filterinProducts.push(t.data.amazonProductOriginal);
                    }
                }
                else if ((t.price !== 0 || t.dealPrice !== 0) && t.weight !== 0 && t.dimensions && t.images.length > 0 && !t.ml) {
                    filterinProducts.push(t)
                }
            }
        }
        )
        setMultiSave(filterinProducts);
        console.log('**** que hay aqui *****', filterinProducts);
        const arr = filterinProducts.map(t => t._id)
        setItemsAdds(arr);
    }

    const selectAllToDelete = async (item) => {
        let filterinProducts = [];
        await item.forEach((t) => {
            if (t.variants && t.variants.length > 0) {
                if ((t.variants[0].price === 0 && t.variants[0].dealPrice === 0) || t.variants[0].weight === 0 || !t.variants[0].dimensions || t.variants[0].images.length === 0) {
                    filterinProducts.push(t)
                }
            } else {
                if ((t.price === 0 && t.dealPrice === 0) || t.weight === 0 || !t.dimensions || t.images.length === 0) {
                    filterinProducts.push(t)
                }
            }
        }
        )
        let arr = [];
        let arrId = [];
        filterinProducts.map((t, i) => {
            arr.push({ id: t._id, index: i });
            arrId.push(t._id);
        }
        )
        setMultiDelete(arr);
        setItemsAddsByDelete(arrId);
    }

    const selectAllToDeleteWithOutError = async (item) => {
        let filterinProducts = [];
        await item.forEach((t) => {
            if (t.variants && t.variants.length > 0) {
                if ((t.variants[0].price !== 0 || t.variants[0].dealPrice !== 0) && t.variants[0].weight !== 0 && t.variants[0].dimensions && t.variants[0].images.length > 0 && !t.variants[0].ml) {
                    filterinProducts.push(t)
                }
            } else {
                if ((t.price !== 0 || t.dealPrice !== 0) && t.weight !== 0 && t.dimensions && t.images.length > 0 && !t.ml) {
                    filterinProducts.push(t)
                }
            }
        }
        )
        let arr = [];
        let arrId = [];
        filterinProducts.map((t, i) => {
            arr.push({ id: t._id, index: i });
            arrId.push(t._id);
        }
        )
        setMultiDeleteWithoutError(arr);
        setItemsAddsByDeleteWithOutError(arrId);
    }

    const cleanSkuInput = () => {
        skuInput.current.value = ''
        setAsin('')
    }
    const cleanCountInput = () => {
        countInput.current.value = '';
        setPageItemCount(16)
    }
    const desSelectedAll = () => {
        setMultiSave([]);
        setItemsAdds([]);
    }
    const changeStyledGridToRow = () => {
        setChangeToRow(true);
        setStyleGridCard(2);
    }
    const changeStyledGridToColunm = () => {
        setChangeToRow(false);
        setStyleGridCard(1);
    }
    const setFilterData = (event) => {
        event.preventDefault();
        cleanSkuInput()
        cleanCountInput()
        setFilter(valueInputFilter)
    }
    const setFilterDataAsin = (event) => {
        event.preventDefault();
        setAsin(valueInputFilterAsin.trim())
        cleanCountInput()
        onButtonClick()
        setCurrentPage(1)
        skuInput.current.value = valueInputFilterAsin.trim()
    }

    const findCategoryByicon = (v) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        setCurrentPage(1);
        let listCategoryToSend = [];
        let categoritextToShow = [];
        for (const cat of v) {
            listCategoryToSend.push(cat._id);
            categoritextToShow.push(cat.name.en);
        }
        setFindCategoryByiconBtn(true);
        globalContext.setActiveFiltertCategory(false);
        setCategories([listCategoryToSend.pop()]);
        globalContext.setIdCategoryFind([listCategoryToSend.pop()]);
        setCurrentCategorieText(categoritextToShow.join(' > '));
        globalContext.setIdCategoryFindText(categoritextToShow.join(' > '));
    }
    const findCategoryByList = (ids, textCat) => {
        setCurrentPage(1);
        setCategories([ids.pop()]);
        setCurrentCategorieText(textCat.join(' > '));
        globalContext.setIdCategoryFind(ids);
        globalContext.setIdCategoryFindText(textCat.join(' > '));
        setFindCategoryByiconBtn(true);
        globalContext.setActiveFiltertCategory(true)
    }

    const publicMultiProduct = () => {
        globalContext.setModalMultiplePost(true);
        globalContext.setModalMultiplePostData(multiSave);
    }

    const getDataAmazon = async (amazonId, entId) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products/${amazonId}`, {
            params: {
                pageNumber: 1,
                pageItemCount: 1,
                filter: {
                    lang: 'en',
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
        return res;
    }

    const scarpingAgain = (url, idPrduct, indice) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;
        if (!url) return;
        globalContext.setLoaderPage(true);
        setIsScrapingAgain([...isScrapingAgain, idPrduct]);
        // console.log('id en proceso', idPrduct)
        const processCheckIntervalTime = 5000;
        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        };
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/parse-product`, {
            cancelToken: axiosCancelTokenSource.token,
            headers,
            params: {
                url,
                langEs: true,
                langEn: true,
            }
        })
            .then(resp => {
                const data = resp.data.data;
                if (data.monitor) {
                    //If the process ID was received, start monitoring
                    let waitingMonitorResponse = false;
                    const interval = setInterval(() => {
                        if (!waitingMonitorResponse) {
                            waitingMonitorResponse = true;
                            axios
                                .get(
                                    `${process.env.REACT_APP_BACKEND_URL}/v1/process-monitor/${data.monitor}`,
                                    {
                                        cancelToken: axiosCancelTokenSource.token,
                                        headers,
                                    }
                                )
                                .then((resp) => {
                                    const data = resp.data.data;

                                    if (data.advance >= 100) {
                                        // ? si finaliza el scrapin buscamos los dats de ese producto y actualizamos su posicion
                                        clearInterval(interval);
                                        getDataAmazon(idPrduct, globalContext.currentEnterpriseId)
                                            .then((res) => {
                                                const data = res.data.data.product
                                                let copyArrayHistory = [...productos];
                                                copyArrayHistory[indice] = data;
                                                setProductos(copyArrayHistory);
                                                globalContext.setLoaderPage(false);
                                                setIsScrapingAgain(isScrapingAgain.filter(v => v !== idPrduct));
                                            })
                                            .catch((err) => {
                                                handleAxiosError(
                                                    err,
                                                    "Disculpe, tenems problemas de conexion."
                                                );
                                                // console.log('id del error', idPrduct)
                                                globalContext.setLoaderPage(false);
                                                setIsScrapingAgain(isScrapingAgain.filter(v => v !== idPrduct));
                                            })
                                    }
                                })
                                .catch((err) => {
                                    handleAxiosError(
                                        err,
                                        "Disculpe, no se pudo culminar la descarga del producto."
                                    );
                                    clearInterval(interval);
                                    globalContext.setLoaderPage(false);
                                    setIsScrapingAgain(isScrapingAgain.filter(v => v !== idPrduct));
                                })
                                .finally(() => {
                                    globalContext.setLoaderPage(false);
                                    waitingMonitorResponse = false;
                                    setIsScrapingAgain(isScrapingAgain.filter(v => v !== idPrduct));
                                });
                        }
                    }, processCheckIntervalTime);
                }
            })
            .catch((err) => {
                handleAxiosError(
                    err,
                    "Disculpe, ocurrio un error al actualizar la informacion."
                );
                globalContext.setLoaderPage(false);
                setIsScrapingAgain(isScrapingAgain.filter(v => v !== idPrduct));
            });
    }

    const downloadRepo = async (parametro) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        const entId = globalContext.currentEnterpriseId;
        const whatIs = parametro === 'amazon' ? 'report' : 'reportMl';
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/${entId}/${whatIs}/xlsx`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId)
            },
            params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount.length > 0 ? pageItemCount : 10,
                filter: {
                    asin: asin,
                    title: filter,
                    lang: 'es',
                    categoriesIds: categories !== '' ? categories : globalContext.idCategoryFind,
                    publishedOnMl: mlPublis,
                    dataComplete: true,
                    maxDeliveryDays: deliveryDaysProd && 7,
                    rescrapedFromDate: rescrapedFromDate,
                    rescrapedToDate: rescrapedToDate,
                    stock: filterByStock
                },
            },
        })
            .then(() => {
                globalContext.showModalWithMsg('Su desarga se esta ejecutando en segundo plano, revise el monitor de proceso')
            }).catch((err) => {
                handleAxiosError(err, 'Ha ocurrido un error #7898494')
            })
    }

    const downloadRepoTwo = async (parametro) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        const entId = globalContext.currentEnterpriseId;
        const whatIs = parametro === 'amazon' ? 'report' : 'reportMl2';
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-report/${entId}/${whatIs}/xlsx`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            }
        }).
            then(response => {
                globalContext.showModalWithMsg('Su desarga se esta ejecutando en segundo plano, revise el monitor de proceso')
            }).catch((err) => {
                handleAxiosError(err, 'Ha ocurrido un error #7898494')
            })
    }

    const deleteAProduct = (IdProduct, index) => {
        if (pluginToSearch === 'Ali' || pluginToSearch === 'rescrapedHistory') {
            globalContext.showModalWithMsg('Funcion no disponible')
            return;
        }
        globalContext.setLoaderPage(true);
        setInDeleteProcess([...inDeleteProcess, IdProduct]);
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            data: {
                //prodId: '607dbb89c3a2841e73407bc7',
                prodId: IdProduct
            }
        }).then(() => {
            globalContext.setLoaderPage(false);
            setIsDeleted([...isDeleted, IdProduct]);
            globalContext.showModalWithMsg(
                `Producto eliminado exitosamente!`
            );
            setInDeleteProcess(inDeleteProcess.filter(e => e !== IdProduct))
        }).catch((err) => {
            globalContext.setLoaderPage(false);
            handleAxiosError(
                err,
                "Disculpe,  ocurrio un error al eliminar este producto."
            );
            setInDeleteProcess(inDeleteProcess.filter(e => e !== IdProduct))
            // console.log(err)
        })
    }
    //* metodo para agg muchos  productos en el array
    //* y mandarlos a la vista para que sean actualizados y publicados
    const addManyToPublishAndUpdate = async (item) => {
        let filterinProducts = [];
        await item.forEach((t) => {
            filterinProducts.push(t._id)
        }
        )
        setItemsToUpdateAndPublish(filterinProducts);
    }

    const deleteAllProductToSendUpdate = () => {
        setItemsToUpdateAndPublish([]);
    }
    //* no redirecciona a la pantalla para sleccionar los que se van a actualizar
    const goToViewUpdateAndPublish = () => {
        localStorage.setItem(produtsUpdateAndPublish, itemsToUpdateAndPublish);
        setRedirect(
            <Redirect to={`/admin/productos/update-and-publish`} />
        )
    }

    const sendProdWooFunc = async (arrIds) => {
        try {
            const res = await sendProductWoo(arrIds, globalContext.currentEnterpriseId, axiosCancelTokenSource);
            console.log('esta bien creo!', res)
        } catch (error) {
            console.log('esta bien creo!', error)
        }
    }

    const getWeightAndDimentions = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/report/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            console.log('la respuesta', resp)
            globalContext.setLoaderPage(false);
        }).catch(err => {
            handleAxiosError(err,
                "Disculpe, tenems problemas de conexion."
            );
            globalContext.setLoaderPage(false);
        })
    }
    const getWeightAndDimentionsVariants = () => {
        const entId = globalContext.currentEnterpriseId;
        if (!entId) return;

        globalContext.setLoaderPage(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/test/reportVar/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        }).then(resp => {
            globalContext.setLoaderPage(false);
        }).catch(err => {
            handleAxiosError(err,
                "Disculpe, tenems problemas de conexion."
            );
            globalContext.setLoaderPage(false);
        })
    }

    const updateTime = () => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}/set-manufacturing-time`, {
            amazonScraped: true,
            setTo: 10,
            lowerThan: 10,
        }, {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        })
            .then(res => {
                alert('[rpceso segundo plano');
                console.log(res);
            }).catch(err => console.log(err))
    }

    return (
        <>
            {redirect}
            <CategoriesList findCategoryByList={findCategoryByList} />
            <MultiScraperStyles.header>
                Historial de productos obtenidos por el scraping
            </MultiScraperStyles.header>
            {
                (findCategoryByiconBtn || categories.length > 0 || globalContext.idCategoryFind.length > 0) && (
                    <div style={{ width: '100%', marginBottom: 5 }} className="d-flex justify-content-start align-items-center">
                        <MultiScraperStyles.headerCat>
                            {currentCategorieText.length > 0 ? currentCategorieText : globalContext.idCategoryFindText}
                        </MultiScraperStyles.headerCat>
                        <MultiScraperStyles.btnClearCategory ml="10px" wd="30px" onClick={
                            () => {
                                setCategories('');
                                globalContext.setIdCategoryFind('')
                                globalContext.setIdCategoryFindText('')
                                setFindCategoryByiconBtn(false);
                                setCurrentCategorieText('');
                                globalContext.setActiveFiltertCategory(true);
                            }
                        }>
                            <FontAwesomeIcon icon={faTimes} />
                        </MultiScraperStyles.btnClearCategory>
                    </div>
                )
            }
            <MultiScraperStyles.listProducts>
                <MultiScraperStyles.divContFilters>
                    {
                        leng.length > 0 && (
                            <>
                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        ¿Mostrar productos publicados?
                                    </MultiScraperStyles.text>
                                    <MultiScraperStyles.contInputRadio>
                                        <MultiScraperStyles.inputRadio type="radio" id="publis1" value={true} checked={mlPublis} name="isPublish" onChange={(e) => setMlPublis(true)} />
                                        <label htmlFor="st1">Si</label>
                                        <MultiScraperStyles.inputRadio type="radio" id="publis2" value={false} checked={!mlPublis} name="isPublish" ml="10px" onChange={(e) => setMlPublis(false)} />
                                        <label htmlFor="st2">No</label>
                                    </MultiScraperStyles.contInputRadio>
                                </MultiScraperStyles.contInputAndLabel>

                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        Plugin
                                    </MultiScraperStyles.text>
                                    <div className="cont">
                                        <div style={{ width: '100%' }}>
                                            <MultiScraperStyles.select defaultValue={pluginToSearch} mt="10px" ref={countInput} onChange={(e) => {
                                                setProductos(null)
                                                setPluginToSearch(e.target.value)
                                            }}>
                                                <option value="">Seleccione uno</option>
                                                <option value="AmazonScraper">Amazon</option>
                                                <option value="Ali">Ali Express</option>
                                                <option value="rescrapedHistory">Historial de scrapeados por el cron</option>

                                            </MultiScraperStyles.select>
                                        </div>

                                    </div>
                                </MultiScraperStyles.contInputAndLabel>

                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        Filtrar por fecha de actualizacion del cron
                                    </MultiScraperStyles.text>
                                    <div className="cont">
                                        <div style={{ width: '100%' }}>
                                            <DateTime
                                                setValue={setRescrapedFromDate}
                                                originalDate={true}
                                                valueDefaultText="Fecha Desde" />
                                        </div>
                                    </div>
                                    <div className="cont" style={{ marginTop: 10 }}>
                                        <div style={{ width: '100%' }}>
                                            <DateTime
                                                style={{ marginTop: 5 }}
                                                originalDate={true}
                                                setValue={setRescrapedToDate}
                                                valueDefaultText="Fecha Hasta" />
                                        </div>
                                    </div>
                                </MultiScraperStyles.contInputAndLabel>


                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        Cantidad de productos
                                    </MultiScraperStyles.text>
                                    <div className="cont">
                                        <div style={{ width: '100%' }}>
                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setPageItemCount(e.target.value)}>
                                                <option value="">Seleccione uno</option>
                                                <option value="15">15</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                                <option value="250">250</option>
                                                <option value="500">500</option>
                                            </MultiScraperStyles.select>
                                        </div>

                                    </div>
                                </MultiScraperStyles.contInputAndLabel>
                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        SKU del producto a buscar
                                    </MultiScraperStyles.text>
                                    <div className="cont">
                                        <form onSubmit={(e) => setFilterDataAsin(e)} style={{ width: '100%' }}>
                                            <MultiScraperStyles.inputOne placeholder="SKU" onChange={(e) => { setValueInputFilterAsin(e.target.value) }} mt="10px" ref={skuInput} />
                                        </form>
                                        <MultiScraperStyles.btnFloat onClick={(e) => cleanSkuInput()} style={{ marginTop: 10 }}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </MultiScraperStyles.btnFloat>
                                    </div>
                                </MultiScraperStyles.contInputAndLabel>

                                {
                                    pluginToSearch === 'rescrapedHistory' ?
                                        (
                                            <>
                                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                                        Cron de scrapeo
                                                    </MultiScraperStyles.text>
                                                    <div className="cont">
                                                        <div style={{ width: '100%' }}>
                                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setCronSearch(e.target.value)}>
                                                                <option value="">Seleccioneuno</option>
                                                                <option value="scrapScrapedProductsWithKeepa">Cron de Keepa</option>
                                                                <option value="scrapScrapedProducts">Cron VPN y TOR</option>
                                                            </MultiScraperStyles.select>
                                                        </div>
                                                    </div>
                                                </MultiScraperStyles.contInputAndLabel>

                                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                                        Filtrar por activos en ML
                                                    </MultiScraperStyles.text>
                                                    <div className="cont">
                                                        <div style={{ width: '100%' }}>
                                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setMlActives(e.target.value)}>
                                                                <option value="">Seleccione uno</option>
                                                                <option value={true}>Si</option>
                                                                <option value={false}>No</option>
                                                            </MultiScraperStyles.select>
                                                        </div>
                                                    </div>
                                                </MultiScraperStyles.contInputAndLabel>

                                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                                        Filtrar por valores con errores
                                                    </MultiScraperStyles.text>
                                                    <div className="cont">
                                                        <div style={{ width: '100%' }}>
                                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setHasErrorMsg(e.target.value)}>
                                                                <option value="">Seleccione uno</option>
                                                                <option value={true}>Si</option>
                                                                <option value={false}>No</option>
                                                            </MultiScraperStyles.select>
                                                        </div>
                                                    </div>
                                                </MultiScraperStyles.contInputAndLabel>


                                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                                        Filtrar por productos rescrapeados
                                                    </MultiScraperStyles.text>
                                                    <div className="cont">
                                                        <div style={{ width: '100%' }}>
                                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setRescrap(e.target.value)}>
                                                                <option value="">Seleccione uno</option>
                                                                <option value={true}>Si</option>
                                                                <option value={false}>No</option>
                                                            </MultiScraperStyles.select>
                                                        </div>
                                                    </div>
                                                </MultiScraperStyles.contInputAndLabel>

                                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                                        Filtrar por fecha
                                                    </MultiScraperStyles.text>
                                                    <div className="cont">
                                                        <div style={{ width: '100%' }}>
                                                            <DateTime
                                                                setValue={setFromDate}
                                                                originalDate={true}
                                                                valueDefaultText="Fecha Desde" />
                                                        </div>
                                                    </div>
                                                    <div className="cont" style={{ marginTop: 10 }}>
                                                        <div style={{ width: '100%' }}>
                                                            <DateTime
                                                                style={{ marginTop: 5 }}
                                                                originalDate={true}
                                                                setValue={setToDate}
                                                                valueDefaultText="Fecha Hasta" />
                                                        </div>
                                                    </div>
                                                </MultiScraperStyles.contInputAndLabel>

                                            </>
                                        )
                                        : ""
                                }
                                {
                                    itemsAdds.length > 0 && (
                                        <>
                                            <MultiScraperStyles.btnRedirect mt="10px" wd="100%"
                                                onClick={() => publicMultiProduct()}
                                            >
                                                Publicar: {itemsAdds.length} productos
                                            </MultiScraperStyles.btnRedirect>

                                            <MultiScraperStyles.btnRedirect mt="10px" wd="100%"
                                                onClick={() => sendProdWooFunc(itemsAdds)}
                                            >
                                                Publicar: {itemsAdds.length} productos en Woo
                                            </MultiScraperStyles.btnRedirect>

                                        </>
                                    )
                                }

                                {
                                    itemsToUpdateAndPublish.length > 0 && (
                                        <>
                                            <MultiScraperStyles.btnRedirect mt="10px" wd="100%"
                                                onClick={() => goToViewUpdateAndPublish()}
                                            >
                                                Ir a Actualizar: {itemsToUpdateAndPublish.length} productos
                                            </MultiScraperStyles.btnRedirect>
                                        </>
                                    )
                                }
                                {
                                    itemsAddsByDelete.length > 0 && (
                                        <MultiScraperStyles.btnDelete mt="10px" wd="100%"
                                            onClick={() => deletePlusOfOne()}
                                        >
                                            Eliminar: {itemsAddsByDelete.length} productos con error
                                        </MultiScraperStyles.btnDelete>
                                    )
                                }
                                {
                                    itemsAddsByDeleteWithOutError.length > 0 && (
                                        <MultiScraperStyles.btnDelete mt="10px" wd="100%"
                                            onClick={() => deletePlusOfOneWithoutError()}
                                        >
                                            Eliminar: {itemsAddsByDeleteWithOutError.length} productos sin error
                                        </MultiScraperStyles.btnDelete>
                                    )
                                }

                                {
                                    pluginToSearch === 'AmazonScraper' && (
                                        <MultiScraperStyles.btnRedirect mt="10px" wd="100%"
                                            onClick={() => updateTime()}
                                        >
                                            Actualizar tiempo de envio
                                        </MultiScraperStyles.btnRedirect>
                                    )
                                }
                                <MultiScraperStyles.btnRedirect mt="10px" wd="100%"
                                    onClick={() => {
                                        globalContext.setLisCategories(true)
                                    }}
                                >
                                    Filtrar por Categorias
                                </MultiScraperStyles.btnRedirect>
                                <MultiScraperStyles.contInputAndLabel jsc="space-between">
                                    <MultiScraperStyles.text fw="bolder" mb="5px">
                                        Filtrar por stock
                                    </MultiScraperStyles.text>
                                    <div className="cont">
                                        <div style={{ width: '100%' }}>
                                            <MultiScraperStyles.select mt="10px" ref={countInput} onChange={(e) => setFilterByStock(e.target.value)}>
                                                <option value="1">Stock superior a 0</option>
                                                <option value="0">Stock en 0</option>
                                                <option value="2">Todos los stocks</option>
                                            </MultiScraperStyles.select>
                                        </div>

                                    </div>
                                </MultiScraperStyles.contInputAndLabel>
                            </>
                        )
                    }

                </MultiScraperStyles.divContFilters>
                <MultiScraperStyles.divContData>
                    <div className="header">
                        <div className="cont">
                            <form onSubmit={(e) => setFilterData(e)} style={{ width: '100%' }}>
                                <MultiScraperStyles.inputOne placeholder="Escriba la palabra por la que desea buscar" onChange={(e) => setValueInputFilter(e.target.value)} ref={inputEl} />
                            </form>
                            <MultiScraperStyles.btnFloat onClick={(e) => onButtonClick(e)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </MultiScraperStyles.btnFloat>
                        </div>
                        <div className="cont-btn-header">
                            <Link to="list-products-cbt">
                                <MultiScraperStyles.btnRedirect ml="10px">
                                    Individual
                                </MultiScraperStyles.btnRedirect>
                            </Link>
                            <Link to="masive-scraping">
                                <MultiScraperStyles.btnRedirect ml="10px">
                                    Masiva
                                </MultiScraperStyles.btnRedirect>
                            </Link>
                            <Link to="scraping-by-url">
                                <MultiScraperStyles.btnRedirect ml="10px">
                                    URLs
                                </MultiScraperStyles.btnRedirect>
                            </Link>
                            {
                                productos ?
                                    (itemsAdds.length === 0 && itemsToUpdateAndPublish.length === 0) ?
                                        (
                                            <MultiScraperStyles.btnRedirect
                                                ml="10px"
                                                onClick={
                                                    () => {
                                                        selectAll(productos);
                                                        selectAllToDelete(productos)
                                                        selectAllToDeleteWithOutError(productos)
                                                        addManyToPublishAndUpdate(productos);
                                                    }
                                                }
                                                id="activeAll">
                                                <FontAwesomeIcon icon={faTasks} />
                                            </MultiScraperStyles.btnRedirect>
                                        )
                                        :
                                        (
                                            <MultiScraperStyles.btnRedirect
                                                ml="10px"
                                                onClick={
                                                    () => {
                                                        desSelectedAll();
                                                        deleteAllProductToSendUpdate();

                                                    }
                                                }
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </MultiScraperStyles.btnRedirect>
                                        ) : ''
                            }
                            <MultiScraperStyles.btnRedirect ml="10px"
                                active={styleGridCard === 1}
                                onClick={() => changeStyledGridToColunm()}>
                                <FontAwesomeIcon icon={faGripHorizontal} />
                            </MultiScraperStyles.btnRedirect>
                            <MultiScraperStyles.btnRedirect ml="10px"
                                active={styleGridCard === 2}
                                onClick={() => changeStyledGridToRow()}>
                                <FontAwesomeIcon icon={faGripLines} />
                            </MultiScraperStyles.btnRedirect>
                        </div>
                    </div>
                    <MultiScraperStyles.totalCount>
                        {
                            total > 0 && ('Total de productos - ' + total)
                        }
                        {
                            pluginToSearch === 'AmazonScraper' &&
                            (total > 0 && valueInputFilter.length > 0 || valueInputFilterAsin.length > 0) ? (
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <MultiScraperStyles.btnRedirect mr="10px" onClick={() => downloadRepo('amazon')}>
                                        Reporte Cashea xlsx
                                    </MultiScraperStyles.btnRedirect>
                                    </div>
                                    ): (
                                        <>
                                            <MultiScraperStyles.btnRedirect mr="10px" onClick={() => downloadRepo('amazon')}>
                                                Reporte Cashea xlsx
                                            </MultiScraperStyles.btnRedirect>
                                            <MultiScraperStyles.btnRedirect mr="10px" onClick={() => downloadRepo('ml')}>
                                                Reporte Ml xlsx
                                            </MultiScraperStyles.btnRedirect>
                                            <MultiScraperStyles.btnRedirect mr="10px" onClick={() => downloadRepoTwo('ml')}>
                                                Publicados Ml Amazon xlsx
                                            </MultiScraperStyles.btnRedirect>
                                            <MultiScraperStyles.btnRedirect mr="10px" onClick={() => getWeightAndDimentions()}>
                                                Reporte csv
                                            </MultiScraperStyles.btnRedirect>
                                            <MultiScraperStyles.btnRedirect mr="10px" onClick={() => getWeightAndDimentionsVariants()}>
                                                Reporte csv Variantes
                                            </MultiScraperStyles.btnRedirect>
                                            <MultiScraperStyles.btnInfo onClick={() => globalContext.setOpenInfoIcons(true)}>
                                                <FontAwesomeIcon icon={faInfo} />
                                            </MultiScraperStyles.btnInfo>
                                        </>
                                    )
                                }
                    </MultiScraperStyles.totalCount>
                    <MultiScraperStyles.gridData changeToRow={changeToRow}>
                        {
                            productos !== null &&
                            productos.map((t, i) => {
                                if (pluginToSearch === 'AmazonScraper') {

                                    if ((t.price === 0 && t.dealPrice === 0) || t.weight === 0 || !t.dimensions || t.images.length === 0) {
                                        return (
                                            <CardErrorComponent
                                                key={i}
                                                t={t}
                                                i={i}
                                                id={t._id}
                                                changeToRow={changeToRow}
                                                isDeleted={isDeleted}
                                                openMenuOptionItem={openMenuOptionItem}
                                                setOpenMenuOptionItem={setOpenMenuOptionItem}
                                                findCategoryByicon={findCategoryByicon}
                                                deleteAProduct={deleteAProduct}
                                                scarpingAgain={scarpingAgain}
                                                itemsAddsByDelete={itemsAddsByDelete}
                                                addItem={addItem}
                                                addItemByDeleteFunc={addItemByDeleteFunc}
                                                addItemByDeleteWithOutErrorFunc={addItemByDeleteWithOutErrorFunc}
                                                setOpenMenuOptionItemFunc={setOpenMenuOptionItemFunc}
                                                inDeleteProcess={inDeleteProcess}
                                                isScrapingAgain={isScrapingAgain}
                                                itemsToUpdateAndPublish={itemsToUpdateAndPublish}
                                                setItemsToUpdateAndPublish={setItemsToUpdateAndPublish}
                                            />
                                        )
                                    } else {
                                        return (
                                            <CardComponent
                                                isVariant={false}
                                                key={i}
                                                t={t} i={i} id={t._id} changeToRow={changeToRow}
                                                isDeleted={isDeleted}
                                                openMenuOptionItem={openMenuOptionItem}
                                                setOpenMenuOptionItem={setOpenMenuOptionItem}
                                                findCategoryByicon={findCategoryByicon}
                                                deleteAProduct={deleteAProduct}
                                                itemsAdds={itemsAdds}
                                                addItem={addItem}
                                                inDeleteProcess={inDeleteProcess}
                                                setOpenMenuOptionItemFunc={setOpenMenuOptionItemFunc}
                                                itemsToUpdateAndPublish={itemsToUpdateAndPublish}
                                                addItemByDeleteWithOutErrorFunc={addItemByDeleteWithOutErrorFunc}
                                                setItemsToUpdateAndPublish={setItemsToUpdateAndPublish}
                                            />
                                        )
                                    }

                                } else if (pluginToSearch === 'rescrapedHistory') {
                                    return (
                                        <CardComponentCron
                                            isVariant={false}
                                            key={i}
                                            mlStatusSet={t.mlStatusSet}
                                            errorMessage={t.errorMessage}
                                            t={t.data.amazonProductOriginal} i={i} id={t.data.amazonProductOriginal._id} changeToRow={changeToRow}
                                            isDeleted={isDeleted}
                                            openMenuOptionItem={openMenuOptionItem}
                                            setOpenMenuOptionItem={setOpenMenuOptionItem}
                                            findCategoryByicon={findCategoryByicon}
                                            deleteAProduct={deleteAProduct}
                                            itemsAdds={itemsAdds}
                                            addItem={addItem}
                                            inDeleteProcess={inDeleteProcess}
                                            setOpenMenuOptionItemFunc={setOpenMenuOptionItemFunc}
                                            itemsToUpdateAndPublish={itemsToUpdateAndPublish}
                                            addItemByDeleteWithOutErrorFunc={addItemByDeleteWithOutErrorFunc}
                                            setItemsToUpdateAndPublish={setItemsToUpdateAndPublish}
                                        />
                                    )

                                }
                                else {
                                    return (
                                        <CardComponentAli
                                            isVariant={false}
                                            key={i}
                                            product={t} i={i}
                                            changeToRow={changeToRow}
                                            isDeleted={isDeleted}
                                            openMenuOptionItem={openMenuOptionItem}
                                            setOpenMenuOptionItem={setOpenMenuOptionItem}
                                            findCategoryByicon={findCategoryByicon}
                                            deleteAProduct={deleteAProduct}
                                            itemsAdds={itemsAdds}
                                            addItem={addItem}
                                            inDeleteProcess={inDeleteProcess}
                                            setOpenMenuOptionItemFunc={setOpenMenuOptionItemFunc}
                                            itemsToUpdateAndPublish={itemsToUpdateAndPublish}
                                            addItemByDeleteWithOutErrorFunc={addItemByDeleteWithOutErrorFunc}
                                            setItemsToUpdateAndPublish={setItemsToUpdateAndPublish}
                                        />
                                    )
                                }
                            })
                        }
                    </MultiScraperStyles.gridData>
                    <div style={{ width: 100 + '%', }} className="d-flex justify-content-center mt-3">
                        <Pager
                            handleSetPage={setCurrentPage}
                            totalResults={total}
                            currentPage={currentPage}
                            resultsPerPage={pageItemCount}
                        />
                    </div>
                </MultiScraperStyles.divContData>

            </MultiScraperStyles.listProducts>
        </>
    );
}

export default HistoryProductsCbt;