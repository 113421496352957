import { apiRoutes } from "../../config"
import { api } from "../../utils/lib/api"

export const accVtex = async (method,ent, data) =>{
    try {
        if(method === "POST"){
            const url = `${apiRoutes.vtex.acc}/${ent}`
            return (await api.post(url, data)).data
        }
        if(method === "GET"){
            const url = `${apiRoutes.vtex.acc}/${ent}/acc/${data ? data : ''}`
            return (await api.get(url)).data
        }
    } catch (error) {
        console.error(error)
        return error
    }
}

export const syncProductsVtex = async (ent, accUser)=>{
    try {
        //Obtenemos el id de la cuenta de Vtex
        const accId = await accVtex('GET', ent, accUser).then((res)=> Object.values(res.data).map(item => item._id))        
        const url = `${apiRoutes.vtex.products}/${ent}/acc/${accId}/sync`
        return (await api.put(url)).data
    } catch (error) {
        console.log(error)
        throw new Error(error)

    }
}

export const downloadCategoriesVtex = async (ent, accUser)=>{
    try {
        //Obtenemos el id de la cuenta de Vtex
        const accId = await accVtex('GET', ent, accUser).then((res)=> Object.values(res.data).map(item => item._id))        
        const url = `${apiRoutes.vtex.categories}/${ent}/download/${accId}/categories`
        return (await api.get(url))
    } catch (error) {
        console.log(error)
        throw new Error(error)

    }
}

export const updateProductsVtex = async (ent, accUser, skuId)=>{
    try {
        //Obtenemos el id de la cuenta de Vtex
        const accId = await accVtex('GET', ent, accUser).then((res)=> Object.values(res.data).map(item => item._id))        
        const url = `${apiRoutes.vtex.products}/${ent}/acc/${accId}/updateProducts?productSkuId=${skuId}`
        return (await api.post(url))
    } catch (error) {
        console.log(error)
        throw new Error(error)

    }
}
// export const getWarehouses = async (ent) =>{
//     try {
//         const url = `${apiRoutes.vtex.getWarehouses}/${ent}/warehouses`
//         return (await api.get(url)).data
//     } catch (error) {
//         console.error(error)
//         return error
//     }
// }