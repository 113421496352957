import {
    faAngleDown,
    faCalculator,
    faInfo,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { set } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import CardProductCss from "../../../../../assets/css/components/admin/products/ml/rangos-publicacion/CardProductCss";
import {
    keyAccessToken,
    keyUserId,
} from "../../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import verifyThirdPartyUser from "../../../../general/VerifyThirdPartyUser";
//setOpenModalFormula
export default function CardProduct({
    tariff,
    earning,
    courierPlus,
    courierPlusIsPercent,
    poundPrice,
    additionalPoundPrice,
    safe,
    safeIsPercent,
    ccTax,
    ccTaxIsPercent,
    defaultTariff,
    defaultTariffIsPercent,
    iva,
    ivaIsPercent,
    defaultComission,
    defaultComissionIsPercent,
    setPlugin,
    plugin,
}) {
    const numberFormat = new Intl.NumberFormat("de-DE");
    const globalContext = useContext(GlobalContext);
    const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
    const [asin, setAsin] = useState("");
    const [data, setData] = useState(null);
    const [precioFinal, setPrecioFinal] = useState("");
    const [formula, setFormula] = useState("");
    const [earningUsd, setEarningUsd] = useState("");
    const [totalUsd, setTotalUsd] = useState("");
    const [showFormula, setShowFormula] = useState(false);
    const [positionActiveIfVariant, setPositionActiveIfVariant] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const entId = globalContext.currentEnterpriseId;
    const plugins = {
        odoo: "Odoo",
        amazonScrap: "Amazon",
    };

    /**
     * Handle the axios error
     * @param {Error} err The thrown error
     * @param {string} msg A message to show in the modal
     * @param {boolean} consoleLog If should log the error in the console
     */
    const handleAxiosError = (err, msg = null, consoleLog = true) => {
        if (axios.isCancel(err)) return; //Don't show axios cancel error
        if (err.response && err.response.data) {
            if (err.response.data.message) {
                globalContext.showModalWithMsg(err.response.data.message);
            } else {
                globalContext.showModalWithMsg(err.response.data);
            }
        } else if (msg) {
            globalContext.showModalWithMsg(msg);
        }
        if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
    };

    const getProduct = async () => {
        if (asin.length === 0) return;
        globalContext.setLoaderPage(true);
        const url =
            plugin === "odoo"
                ? `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}`
                : `${process.env.REACT_APP_BACKEND_URL}/v1/amazon-scrap/${entId}/products`;
        const resp = await axios.get(url, {
            params: {
                pageNumber: 1,
                pageItemCount: 15,
                filter: {
                    ...(plugin === "odoo"
                        ? {
                              variSku: asin,
                              onlySkuAvailable: true,
                          }
                        : {
                              asin: asin,
                              lang: "es",
                          }),
                },
            },
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
        });
        return resp;
    };

    const getPublishingPrice = async (prodId) => {
        if (!entId) {
            globalContext.showModalWithMsg(
                "Espere a que todos los datos esten cargados"
            );
            return;
        }
        const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/${
                plugin === "odoo" ? "odoo" : "amazon-scrap"
            }/${entId}/calculate-publish-price`,
            {
                prodId: prodId,
                config: {
                    tariff: tariff,
                    earning: earning,
                    courierPlus: parseInt(courierPlus),
                    courierPlusIsPercent: parseInt(courierPlusIsPercent),
                    poundPrice: parseInt(poundPrice),
                    additionalPoundPrice: parseInt(additionalPoundPrice),
                    safe: safe,
                    safeIsPercent: safeIsPercent,
                    ccTax: ccTax,
                    ccTaxIsPercent: ccTaxIsPercent,
                    defaultTariff: parseInt(defaultTariff),
                    defaultTariffIsPercent: defaultTariffIsPercent,
                    iva: parseInt(iva),
                    ivaIsPercent: true,
                    defaultComission: defaultComission,
                    defaultComissionIsPercent: defaultComissionIsPercent,
                    countryCode:
                        entId === "60944575461add5b1957ef8b" ? "VE" : "CO",
                },
            },
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            }
        );
        return res;
    };

    const getAllData = async (e) => {
        e.preventDefault();
        dataArray.length = 0;
        await getProduct()
            .then(async (r) => {
                const data =
                    plugin === "odoo"
                        ? r.data?.data?.products
                        : r.data?.data?.products[0];
                // console.log("data", data);
                setData(data);
                if (plugin === "odoo") {
                    if (data instanceof Array) {
                        const newArray = [];
                        for (let info of data) {
                            await getPublishingPrice(info._id)
                                .then(async (res) => {
                                    // console.log("res: ", res);
                                    const valores = res.data?.data;
                                    if (valores) {
                                        newArray.push({
                                            ...valores,
                                            title: info.title,
                                            attribs: info.attrib,
                                        });
                                        // console.log("valores: ", valores);
                                        // console.log("newArray: ", newArray);
                                    }
                                })
                                .catch((e) => {
                                    handleAxiosError(
                                        e,
                                        `Presentamos un error consultando los precios: ${
                                            e.response || e.message || e
                                        }`
                                    );
                                    console.error("ERROR: ", e);
                                    globalContext.setLoaderPage(false);
                                })
                                .finally(() => setDataArray(newArray));
                        }
                    } else {
                        await getPublishingPrice(data._id)
                            .then((res) => {
                                const valores = res.data.data;
                                // console.log("valores: ", valores);
                                if (valores.variants instanceof Array) {
                                    setPrecioFinal(valores);
                                    setFormula(valores);
                                    setEarningUsd(valores);
                                    setTotalUsd(valores);
                                } else {
                                    setData({ ...data, price: valores.price });
                                    setPrecioFinal(valores.total);
                                    setFormula(valores.formula);
                                    setEarningUsd(valores.earningUsd);
                                    setTotalUsd(valores.totalUsd);
                                }
                                //console.log('el precio',res)
                            })
                            .catch((e) => {
                                handleAxiosError(e, "Presentamos un error 508");
                                globalContext.setLoaderPage(false);
                            });
                    }
                } else {
                    await getPublishingPrice(data._id)
                        .then((res) => {
                            const valores = res.data.data;
                            if (valores.variants) {
                                setPrecioFinal(valores);
                                setFormula(valores);
                                setEarningUsd(valores);
                                setTotalUsd(valores);
                            } else {
                                setPrecioFinal(valores.total);
                                setFormula(valores.formula);
                                setEarningUsd(valores.earningUsd);
                                setTotalUsd(valores.totalUsd);
                            }
                            //console.log('el precio',res)
                        })
                        .catch((e) => {
                            handleAxiosError(e, "Presentamos un error 508");
                            globalContext.setLoaderPage(false);
                        });
                    globalContext.setLoaderPage(false);
                }
            })
            .catch((e) => {
                console.error("Error de consulta de producto por SKU: \n", e);
                handleAxiosError(
                    e,
                    `Error de consulta de producto por SKU: ${JSON.stringify(
                        e.response || e.message
                    )}`
                );
                globalContext.setLoaderPage(false);
            })
            .finally(() => {
                globalContext.setLoaderPage(false);
            });
    };

    function pricesNumberFormat(number) {
        const numberFormat = new Intl.NumberFormat("de-DE");
        return numberFormat.format(Math.ceil((number || 0) * 100) / 100);
    }

    useEffect(() => {
        if (globalContext.skuByRang.length > 0) {
            setAsin(globalContext.skuByRang);
        }
    }, [globalContext.skuByRang]);
    return (
        <>
            <CardProductCss.cont>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-9'>
                            <CardProductCss.contFormSearch
                                onSubmit={(e) => getAllData(e)}
                            >
                                <CardProductCss.contInputAndLabel>
                                    <CardProductCss.text mb='10px' fw='600'>
                                        SKU del producto
                                    </CardProductCss.text>
                                    <CardProductCss.contInputAndButton>
                                        <CardProductCss.input
                                            placeholder='Escriba el sku del producto'
                                            defaultValue={
                                                globalContext.skuByRang
                                            }
                                            wd='220px'
                                            onChange={(e) =>
                                                setAsin(e.target.value)
                                            }
                                        />
                                        <CardProductCss.btn
                                            colorFont='#FFFFFF'
                                            bgColor='#7367F0'
                                            pd='8px 12px'
                                        >
                                            Buscar
                                        </CardProductCss.btn>
                                    </CardProductCss.contInputAndButton>
                                </CardProductCss.contInputAndLabel>
                                <CardProductCss.contInputAndLabel ml='10px'>
                                    <CardProductCss.text mb='10px' fw='600'>
                                        &nbsp;
                                    </CardProductCss.text>
                                    <CardProductCss.select
                                        defaultValue={plugin}
                                        onChange={setPlugin}
                                    >
                                        {Object.entries(plugins).map(
                                            (val, i) => {
                                                if (
                                                    verifyThirdPartyUser() &&
                                                    val[0] === "amazonScrap"
                                                )
                                                    return null;
                                                return (
                                                    <option
                                                        value={val[0]}
                                                        key={i}
                                                    >
                                                        {val[1]}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </CardProductCss.select>
                                </CardProductCss.contInputAndLabel>
                            </CardProductCss.contFormSearch>
                        </div>
                        <div className='col-sm text-right'>
                            <div
                                style={{
                                    fontSize: "25px",
                                    marginTop: "26px",
                                    height: "100%",
                                    right: "0px",
                                }}
                            >
                                {plugins[plugin]}
                            </div>
                        </div>
                    </div>
                </div>
                {plugin === "odoo" ? (
                    <>
                        {dataArray.length > 0 &&
                            dataArray?.map((v) => (
                                <>
                                    <CardProductCss.contDataproductCard>
                                        <CardProductCss.contInputAndLabel fd='row'>
                                            <CardProductCss.text
                                                mr='5px'
                                                size='14pt'
                                            >
                                                Titulo del producto:
                                            </CardProductCss.text>
                                            <CardProductCss.text
                                                fw='600'
                                                size='14pt'
                                            >
                                                {v.title}
                                            </CardProductCss.text>
                                        </CardProductCss.contInputAndLabel>
                                        {v.variants.map((d) => (
                                            <>
                                                <CardProductCss.contGrid>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            USD Provedor:
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            <>${d.price}</>
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            Peso de envio (
                                                            {plugin === "odoo"
                                                                ? "KG"
                                                                : "LBS"}
                                                            ):
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            {v?.weight ||
                                                                v?.attribs
                                                                    .weight}{" "}
                                                            {plugin === "odoo"
                                                                ? "KG"
                                                                : "LBS"}
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                </CardProductCss.contGrid>
                                                <CardProductCss.contGrid>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            Precio en (USD):
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            $
                                                            {pricesNumberFormat(
                                                                d.total
                                                            )}
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            Precio en (COB/BSF):
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            {pricesNumberFormat(
                                                                d.total
                                                            )}
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            Monto total de la
                                                            ganancia (USD):
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            $
                                                            {pricesNumberFormat(
                                                                d.earningUsd
                                                            )}
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                    <CardProductCss.contInputAndLabel fd='row'>
                                                        <CardProductCss.text
                                                            mr='5px'
                                                            size='14pt'
                                                        >
                                                            Monto total de la
                                                            ganancia (%):
                                                        </CardProductCss.text>
                                                        <CardProductCss.text
                                                            fw='600'
                                                            size='14pt'
                                                        >
                                                            {pricesNumberFormat(
                                                                (d.earningUsd /
                                                                    d.totalUsd) *
                                                                    100
                                                            ) || 0}
                                                            %
                                                        </CardProductCss.text>
                                                    </CardProductCss.contInputAndLabel>
                                                </CardProductCss.contGrid>
                                                <hr />
                                            </>
                                        ))}
                                    </CardProductCss.contDataproductCard>
                                </>
                            ))}
                    </>
                ) : (
                    <>
                        {data && (
                            <CardProductCss.contDataproductCard>
                                <CardProductCss.carouselImagesProduct>
                                    {data &&
                                        data?.variants?.length > 0 &&
                                        data.variants?.map((v, i) => {
                                            return (
                                                <CardProductCss.carouselImageProducts
                                                    img={v.images[0]}
                                                    key={i}
                                                    onClick={() => {
                                                        setPositionActiveIfVariant(
                                                            i
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                </CardProductCss.carouselImagesProduct>
                                <CardProductCss.contInputAndLabel fd='row'>
                                    <CardProductCss.text mr='5px' size='14pt'>
                                        Titulo del producto:
                                    </CardProductCss.text>
                                    <CardProductCss.text fw='600' size='14pt'>
                                        {data && data?.variants?.length > 0
                                            ? data?.variants[
                                                  positionActiveIfVariant
                                              ]?.title?.es ||
                                              data?.variants[
                                                  positionActiveIfVariant
                                              ]?.title?.es
                                            : data?.title?.es ||
                                              data?.title?.en ||
                                              data?.title}
                                    </CardProductCss.text>
                                </CardProductCss.contInputAndLabel>

                                <CardProductCss.contGrid>
                                    <CardProductCss.contInputAndLabel fd='row'>
                                        <CardProductCss.text
                                            mr='5px'
                                            size='14pt'
                                        >
                                            USD Provedor:
                                        </CardProductCss.text>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                        >
                                            {data && data?.variants?.length > 0
                                                ? `$${numberFormat.format(
                                                      data?.variants[
                                                          positionActiveIfVariant
                                                      ]?.price
                                                  )}`
                                                : `$${numberFormat.format(
                                                      data?.price
                                                  )}`}
                                        </CardProductCss.text>
                                    </CardProductCss.contInputAndLabel>

                                    {data &&
                                    ((data?.variants?.length > 0 &&
                                        data?.variants[positionActiveIfVariant]
                                            ?.weight) ||
                                        data?.weight ||
                                        data?.attrib.weight) ? (
                                        <CardProductCss.contInputAndLabel fd='row'>
                                            <CardProductCss.text
                                                mr='5px'
                                                size='14pt'
                                            >
                                                Peso de envio (
                                                {plugin === "odoo"
                                                    ? "KG"
                                                    : "LBS"}
                                                ):
                                            </CardProductCss.text>
                                            <CardProductCss.text
                                                fw='600'
                                                size='14pt'
                                            >
                                                {data &&
                                                data?.variants?.length > 0
                                                    ? `${data?.variants[positionActiveIfVariant]?.weight}(LBS)`
                                                    : `${
                                                          data?.weight ||
                                                          data?.attrib.weight
                                                      } (${
                                                          plugin === "odoo"
                                                              ? "KG"
                                                              : "LBS"
                                                      })`}
                                            </CardProductCss.text>
                                        </CardProductCss.contInputAndLabel>
                                    ) : (
                                        ""
                                    )}

                                    {data?.dimensions && (
                                        <CardProductCss.contInputAndLabel fd='row'>
                                            <CardProductCss.text
                                                mr='5px'
                                                size='14pt'
                                            >
                                                Dimensiones del paquete:
                                            </CardProductCss.text>
                                            <CardProductCss.text
                                                fw='600'
                                                size='14pt'
                                            >
                                                {data &&
                                                data?.variants?.length > 0
                                                    ? `Alto ${data?.variants[positionActiveIfVariant]?.dimensions.height} Largo ${data?.variants[positionActiveIfVariant]?.dimensions.width} Longitud ${data?.variants[positionActiveIfVariant]?.dimensions.length}`
                                                    : `Alto ${data?.dimensions?.height} Largo ${data?.dimensions?.width} Longitud ${data?.dimensions?.length}`}
                                            </CardProductCss.text>
                                        </CardProductCss.contInputAndLabel>
                                    )}
                                    {data?.dimensions && (
                                        <CardProductCss.contInputAndLabel fd='row'>
                                            <CardProductCss.text
                                                mr='5px'
                                                size='14pt'
                                            >
                                                Peso volumetrico:
                                            </CardProductCss.text>
                                            <CardProductCss.text
                                                fw='600'
                                                size='14pt'
                                            >
                                                {data &&
                                                data?.variants?.length > 0
                                                    ? `${(
                                                          (parseFloat(
                                                              data?.variants[
                                                                  positionActiveIfVariant
                                                              ]?.dimensions
                                                                  .height
                                                          ) *
                                                              parseFloat(
                                                                  data
                                                                      ?.variants[
                                                                      positionActiveIfVariant
                                                                  ]?.dimensions
                                                                      .width
                                                              ) *
                                                              parseFloat(
                                                                  data
                                                                      ?.variants[
                                                                      positionActiveIfVariant
                                                                  ]?.dimensions
                                                                      .length
                                                              ) *
                                                              1.2) /
                                                          166
                                                      )
                                                          .toString()
                                                          .slice(0, 5)} (LBS) `
                                                    : `${(
                                                          (parseFloat(
                                                              data?.dimensions
                                                                  ?.height
                                                          ) *
                                                              parseFloat(
                                                                  data
                                                                      ?.dimensions
                                                                      ?.width
                                                              ) *
                                                              parseFloat(
                                                                  data
                                                                      ?.dimensions
                                                                      ?.length
                                                              ) *
                                                              1.2) /
                                                          166
                                                      )
                                                          .toString()
                                                          .slice(0, 5)} (LBS) `}
                                            </CardProductCss.text>
                                        </CardProductCss.contInputAndLabel>
                                    )}
                                </CardProductCss.contGrid>
                                <CardProductCss.contGrid>
                                    <CardProductCss.contInputAndLabel fd='row'>
                                        <CardProductCss.text
                                            mr='5px'
                                            size='14pt'
                                        >
                                            Precio en (USD):
                                        </CardProductCss.text>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                        >
                                            {totalUsd.variants
                                                ? `$${numberFormat.format(
                                                      Math.ceil(
                                                          totalUsd.variants[
                                                              positionActiveIfVariant
                                                          ]?.totalUsd
                                                      )
                                                  )}`
                                                : `$${pricesNumberFormat(
                                                      totalUsd
                                                  )}`}
                                        </CardProductCss.text>
                                    </CardProductCss.contInputAndLabel>
                                    <CardProductCss.contInputAndLabel fd='row'>
                                        <CardProductCss.text
                                            mr='5px'
                                            size='14pt'
                                        >
                                            Precio en (COB/BSF):
                                        </CardProductCss.text>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                        >
                                            {precioFinal?.variants
                                                ? numberFormat.format(
                                                      Math.ceil(
                                                          precioFinal?.variants[
                                                              positionActiveIfVariant
                                                          ]?.total
                                                      )
                                                  )
                                                : pricesNumberFormat(
                                                      precioFinal
                                                  )}
                                        </CardProductCss.text>
                                    </CardProductCss.contInputAndLabel>
                                    <CardProductCss.contInputAndLabel fd='row'>
                                        <CardProductCss.text
                                            mr='5px'
                                            size='14pt'
                                        >
                                            Monto total de la ganancia (USD):
                                        </CardProductCss.text>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                        >
                                            {earningUsd.variants
                                                ? `$${pricesNumberFormat(
                                                      earningUsd.variants[
                                                          positionActiveIfVariant
                                                      ]?.earningUsd
                                                  )}`
                                                : `$${pricesNumberFormat(
                                                      earningUsd
                                                  )}`}
                                        </CardProductCss.text>
                                    </CardProductCss.contInputAndLabel>
                                    <CardProductCss.contInputAndLabel fd='row'>
                                        <CardProductCss.text
                                            mr='5px'
                                            size='14pt'
                                        >
                                            Monto total de la ganancia (%):
                                        </CardProductCss.text>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                        >
                                            {earningUsd?.variants
                                                ? `${
                                                      earningUsd?.variants[
                                                          positionActiveIfVariant
                                                      ]?.earningUsd > 0
                                                          ? pricesNumberFormat(
                                                                (earningUsd
                                                                    ?.variants[
                                                                    positionActiveIfVariant
                                                                ]?.earningUsd /
                                                                    earningUsd
                                                                        ?.variants[
                                                                        positionActiveIfVariant
                                                                    ]
                                                                        ?.totalUsd) *
                                                                    100
                                                            )
                                                          : 0
                                                  }%`
                                                : `${
                                                      earningUsd > 0
                                                          ? pricesNumberFormat(
                                                                (earningUsd /
                                                                    totalUsd) *
                                                                    100
                                                            )
                                                          : 0
                                                  }%`}
                                        </CardProductCss.text>
                                    </CardProductCss.contInputAndLabel>
                                </CardProductCss.contGrid>
                                <CardProductCss.contGrid>
                                    <CardProductCss.btn
                                        style={{ marginLeft: "0px" }}
                                        colorFont='#FFFFFF'
                                        bgColor='#7367F0'
                                        pd='8px 12px'
                                        onClick={() =>
                                            setShowFormula(!showFormula)
                                        }
                                    >
                                        {showFormula
                                            ? "Ocultar formula"
                                            : "Mostrar formula"}
                                    </CardProductCss.btn>
                                </CardProductCss.contGrid>
                                {showFormula && (
                                    <CardProductCss.contGrid>
                                        <CardProductCss.text
                                            fw='600'
                                            size='14pt'
                                            ml='10px'
                                        >
                                            {formula.variants
                                                ? formula.variants[
                                                      positionActiveIfVariant
                                                  ]?.formula
                                                : formula}
                                        </CardProductCss.text>
                                    </CardProductCss.contGrid>
                                )}
                            </CardProductCss.contDataproductCard>
                        )}
                    </>
                )}
            </CardProductCss.cont>
        </>
    );
}
